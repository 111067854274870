import React, { useContext, useEffect, useRef, useState, useReducer  } from "react";
import api from "../../services/api";

import { makeStyles } from "@material-ui/core/styles";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
//import { Chart } from 'react-chartjs-2'
import {Line} from 'react-chartjs-2';
import {Bar} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: "#eee",
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    minWidth: 120,
    width: 120,
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fafafa",
    padding: theme.spacing(1),
  },

  serachInputWrapper: {
    flex: 1,
    background: "#fff",
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },

  badge: {
    right: "-10px",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },
  sendMessageIcons: {
    color: "grey",
    },
    uploadInput: {
    display: "none",
    },
}));



const UserStats = () => {
  const classes = useStyles();

  const [searchParam, setSearchParam] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [importTicketModalOpen, setImportTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);

  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const userQueueIds = user.queues.map((q) => q.id);

  const [loading, setLoading] = useState(false);
  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

 
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedUserId, setSelectedUserId] = useState();
  const [userList, setUserList] = useState();

  const [ticketsPerUser, setTicketsPerUser] = useState([]);
  const [isFileOpen, setIsFileOpen] =useState(false);


  const toggleFilePopup = (myaction) => {
    setIsFileOpen(!isFileOpen);
  }
  

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
            const fetchTicketData = async () => {
              try {
                const ret  = await api.get("/ticketskpi");
                setTicketsPerUser(ret.data.userData);

              } catch (err) {
                toastError(err);
              }
            };
            fetchTicketData();
            clearTimeout(delayDebounceFn);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  });



  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN") {
      setShowAllTickets(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
    }
  }, [tab]);

  let searchTimeout;  

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const applyFilter = (values) =>{
    setTimeout(()=>{
      setShowAllTickets(true)
    }, 500)
    setTimeout(()=>{
      setSelectedUserId(values);
      setShowAllTickets(false);
    }, 500)
    
    

  }


  let t_labels=[];
  let t_data=[];
  let nameArray=[];

  for (let i = 0; i < ticketsPerUser.length;  i++) {
    nameArray=ticketsPerUser[i].name.split(" ");
    //t_labels.push(nameArray[0].substring(0,1)+nameArray[1].substring(0,1))
    t_labels.push(ticketsPerUser[i].name)
    t_data.push(ticketsPerUser[i].kpi)
	}
 
  const tickets = {
    labels: t_labels,
    datasets: [
      {
        label: '',
        color:"#DC143C",
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'rgba(141,64,237,1)',
        borderColor: 'rgba(74,20,140,1)',
        borderWidth: 2,
        data: t_data
      }
    ]
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' ,
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };

  return (
    <>
            <Bar  data={tickets} 
                  height={80}
                  options={{
                          plugins:{legend:{labels:{color:"#DC143C",boxWidth:0,boxHeight:0}}},
                          maintainAspectRatio: false,
                          pointBackgroundColor:"#DC143C",
                          title:{
                            display:true,
                            text:'Average Rainfall per month',
                            fontSize:20,
                            color:"#DC143C",
                          },
                          legend:{
                            display:true,
                            position:'right',
                            color:"#DC143C",
                          }
                        }}
              />   

    </>
             
  );
};

export default UserStats;
