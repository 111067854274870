
import React, { useState, useEffect, useReducer } from "react";
import ReactDOM from "react-dom";
import Board, { moveCard } from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";

import { makeStyles } from "@material-ui/core/styles";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import {blue, purple, red, green, teal, orange, amber} from '@material-ui/core/colors';

import openSocket from "../../services/socket-io";
import api from "../../services/api";

import {
	Button,
	TableBody,
	TableRow,
	TableCell,
	IconButton,
	Table,
	TableHead,
	Paper,
	Tooltip,
	Typography,
	CircularProgress,
} from "@material-ui/core";

import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import InfoIcon from '@material-ui/icons/Info';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import FunctionsIcon from '@material-ui/icons/Functions';
import FlashOnIcon from '@material-ui/icons/FlashOn';

import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

// Use your own styles to override the default styles
import "./styles.css";


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));
  

const board = {
  columns: [
    {
      id: 1,
      title: "Prospecção",
      backgroundColor: "#fff",
      cards: [
        {
          id: 1,
          title: " ",
          description: (
               <div>
                    <Card style={{maxWidth: 345}}>
                        <CardHeader
                                    avatar={
                                    <Avatar aria-label="recipe" style={{backgroundColor: red[500],}}>
                                        RC
                                    </Avatar>
                                    }
                                    action={
                                    <IconButton aria-label="settings">
                                        <InfoIcon />
                                    </IconButton>
                                    }
                                    title="FGTS - R$ 50.000"
                                    subheader="2022-12-28"
                                />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                <div><MonetizationOnIcon style={{color:green[500]}}/> Valor: <span style={{fontWeight:700}}>R$ 50.000</span></div>
                                <div><EventIcon style={{color:purple[500]}}/> Fechamento: <span style={{fontWeight:700}}>2022-12-28</span></div>
                                <div><PersonIcon  style={{color:teal[500]}} />Responsável: <span style={{fontWeight:700}}>Ricardo Carvalho</span></div>
                                <div><FunctionsIcon  style={{color:orange[500]}} />Probabilidade: <span style={{fontWeight:700}}>10%</span></div>
                                <div><FlashOnIcon style={{color:blue[500]}} />Proxima Atividade: <span style={{fontWeight:700, color:red[500]}}>2022-12-20</span></div>
                            </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                                <IconButton aria-label="add to favorites">
                                    <ThumbUpIcon style={{color:green[500]}} />
                                </IconButton>
                                <IconButton aria-label="share">
                                    <ThumbDownIcon style={{color:red[500]}} />
                                </IconButton>
                           
                        </CardActions>
                    </Card>
                </div> 
          )
        }
      ]
    },
    {
      id: 2,
      title: "Qualificação",
      cards: [
      ]
    },
    {
      id: 3,
      title: "Apresentação",
      cards: [
      ]
    },
    {
      id: 4,
      title: "Proposta",
      cards: [
      ]
    },
    {
        id: 5,
        title: "Negociação",
        cards: [
        
        ]
    },
    {
    id: 6,
    title: "Fechado",
    cards: [
        
    ]
    }
  ]



};

function generateRandomColor(){
  let maxVal = 0xFFFFFF; // 16777215
  let randomNumber = Math.random() * maxVal; 
  randomNumber = Math.floor(randomNumber);
  randomNumber = randomNumber.toString(16);
  let randColor = randomNumber.padStart(6, 0);   
  return `#${randColor.toUpperCase()}`
}

function setBackgoundColor(status){
  
          let bgColor;

          if(status==1){
            bgColor="#edfbe6";
          } else  if(status==0){
            bgColor="#fbe6e8";
          }else{
            bgColor="#ffffff";
          }
  return  bgColor;
}

const formatCard = (leafConfidence,kanbanCard) => {
   console.log("kanbanCard?.user?.name", kanbanCard)
   const names=kanbanCard?.user?.name.split(" ")
   const fstCap = names[0].substring(0, 1)
   const scdCap = names[1].substring(0, 1)
   const closeStatus = (kanbanCard.closingStatus == 1 || kanbanCard.closingStatus == 0 ? false: true )

   
   const handleClosingStatus = async (oppStatus,oppId) =>{
        let oppData = {closingStatus:oppStatus, oppId, oppData}
        console.log("handleClosingStatus")
        handleUpdateCard(oppId, oppData)
  }

  const handleUpdateCard = async (oppId,oppData) =>{
    try {
      const { data } = await api.put(`/opportunity/${oppId}`,oppData);
      console.log(data)
    
    } catch (err) {
    console.log(err);
    }
  }

  
    return (
      <div>
          <Card style={{maxWidth: 345, backgroundColor:setBackgoundColor(kanbanCard.closingStatus)}}>
              <CardHeader
                          avatar={
                          <Avatar aria-label="recipe" style={{backgroundColor: generateRandomColor(),fontWeight:600 }}>
                              {fstCap}{scdCap}
                          </Avatar>
                          }
                          action={
                          <IconButton aria-label="info">
                              <InfoIcon />
                          </IconButton>
                          }
                          title={kanbanCard.name}
                          subheader="2022-12-28"
                      />
              <CardContent>
                  <Typography variant="body2" color="textSecondary" component="p">
                      <div><MonetizationOnIcon style={{color:green[500]}}/> Valor: <span style={{fontWeight:700}}> R$ {kanbanCard.value}</span></div>
                      <div><EventIcon style={{color:purple[500]}}/> Fechamento: <span style={{fontWeight:700}}>2022-12-28</span></div>
                      <div><PersonIcon  style={{color:teal[500]}} />Responsável: <span style={{fontWeight:700}}>{kanbanCard.user.name}</span></div>
                      <div><FunctionsIcon  style={{color:orange[500]}} />Probabilidade: <span style={{fontWeight:700}}>{leafConfidence}%</span></div>
                      <div><FlashOnIcon style={{color:blue[500]}} />Proxima Atividade: <span style={{fontWeight:700, color:red[500]}}>2022-12-20</span></div>
                  </Typography>
              </CardContent>
              <CardActions disableSpacing>

                    { closeStatus && (
                      <>
                        <IconButton onClick={() => handleClosingStatus(1,kanbanCard.id)} >
                              <ThumbUpIcon style={{color:green[500], fontSize: 33}} />
                        </IconButton>
                    
                        <IconButton  onClick={() => handleClosingStatus(0, kanbanCard.id)}>
                                <ThumbDownIcon style={{color:red[500], fontSize: 33}}/>
                        </IconButton>
                      </>
                    )}
                            
                    { kanbanCard.closingStatus == 1 && (
                              <ThumbUpIcon style={{color:green[500], fontSize: 33}} />
                    )}
                    { kanbanCard.closingStatus == 0 && (
                        <ThumbDownIcon style={{color:red[500], fontSize: 33}}/>
                    )}
                 

              </CardActions>
          </Card>
      </div> 
    )

}

const reducer = (state, action) => {
  if (action.type === "LOAD_KANBAN") {
    const kanbanLeafs = action.payload;

    const newKanbanData = [];
    
    
    newKanbanData.push({columns: []})
    kanbanLeafs.forEach((kanbanLeaf) => {
        const newKanbanCard = [];

        const kanbanCards =  kanbanLeaf.ContactSalesOpportunities;
        const leafConfidence=kanbanLeaf.tagvalue;
  
        kanbanCards.forEach((kanbanCard) => {
          newKanbanCard.push( 
              {
                id: kanbanCard.id,
                title: " ",
                description: formatCard(leafConfidence,kanbanCard)   
              }
          )
        })

        if (newKanbanCard[0]){
              newKanbanData[0].columns.push({
                id: kanbanLeaf.id,
                title:  kanbanLeaf.name,
                cards: newKanbanCard
              });

        }else{
              newKanbanData[0].columns.push({
                id: kanbanLeaf.id,
                title:  kanbanLeaf.name,
                cards: []
              });
        } 
    });
    
    return newKanbanData[0];
  }

};

const Kanban = () => {

  const classes = useStyles();

  const [kanbanData, dispatch] = useReducer(reducer, {columns: []});
  
  useEffect(() => {

    if(!kanbanData.columns[0]?.title){
        const delayDebounceFn = setTimeout(() => {
          const fetchKanban = async () => {

          

            try {
              const { data } = await api.get("/contact/opportunities");
              console.log(data)
              dispatch({ type: "LOAD_KANBAN", payload: data.tags});
            
            } catch (err) {
            console.log(err);
            }
          };
          fetchKanban();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }
  });

  const handleUpdateCard = async (oppId,oppData) =>{
    try {
      const { data } = await api.put(`/opportunity/${oppId}`,oppData);
      console.log(data)
    
    } catch (err) {
    console.log(err);
    }
  }

  function ControlledBoard() {
    // You need to control the state yourself.
    console.log("ControlledBoard",kanbanData)
    const [controlledBoard, setBoard] = useState(kanbanData);
  
    function handleCardMove(_card, source, destination) {
      const updatedBoard = moveCard(controlledBoard, source, destination);
      console.log("CardMove",_card, source, destination)

      let oppData = {stageId:destination.toColumnId}

      handleUpdateCard(_card.id, oppData)

      setBoard(updatedBoard);
    }
  
    return (
      <Board onCardDragEnd={handleCardMove} disableColumnDrag>
        {controlledBoard}
      </Board>
    );
  }

  function UncontrolledBoard() {
    return (
      <Board
        allowRemoveLane
        allowRenameColumn
        allowRemoveCard
        onLaneRemove={console.log}
        onCardRemove={console.log}
        onLaneRename={console.log}
        initialBoard={board}
        allowAddCard={{ on: "top" }}
        onNewCardConfirm={(draftCard) => ({
          id: new Date().getTime(),
          ...draftCard
        })}
        onCardNew={console.log}
      />
    );
  }


  return (
    <MainContainer>
        <MainHeader>
            <Title>Funil de Vendas</Title>
            <MainHeaderButtonsWrapper>
            </MainHeaderButtonsWrapper>
        </MainHeader>

        <Paper>
            <div style={{minHeight: "100%"}}>
                <ControlledBoard />
            </div>
        </Paper>
   
    </MainContainer>
  );

};

export default Kanban;
