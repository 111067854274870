import React, { useEffect, useState } from "react";

import { 
    Chip,
    makeStyles,
    Paper,
    TextField
} from "@material-ui/core";

import api from "../../services/api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { i18n } from "../../translate/i18n";
import { isArray, isString } from "lodash";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	tagArea: {
        margin: 0,
        padding: "1px",
        borderBottom: "0px solid #c2c2c2",
    },
    tagInput: {
        border: "none!important",
        '& .MuiOutlinedInput-root, & fieldset': {
            borderRadius: "0",
            border: "none"
		},
    },
    chips: {
		display: "flex",
		flexWrap: "wrap",
		'&:hover': {
			filter: 'brightness(120%)',
		},
        zIndex:0
	},
	chip: {
		margin: 2,
	},
    noBorder: {
        border: "none",
      },
}));

export function TagLabelDisplayForCampaigns ({campaign, onFiltered, displayOnly}) {
    const classes = useStyles();

    const [tags, setTags] = useState([]);
    const [selecteds, setSelecteds] = useState([]);

    useEffect(() => {      
        if (campaign) {
            async function fetchData () {
                await loadTags();
                console.log("useEffect", campaign.nameFilter)
                if (Array.isArray(campaign.nameFilter)) {
                    setSelecteds(campaign.nameFilter);
                }
            }
            fetchData();
        }
    }, [campaign]);

    const createTag = async (data) => {
        try {
            const { data: responseData } = await api.post(`/tags`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    }

    const loadTags = async () => {
        try {
            const { data } = await api.get(`/tags/list`);
            setTags(data);
        } catch (err) {
            toastError(err);
        }
    }
/*
    const syncTags = async (data) => {
        try {
            const { data: responseData } = await api.post(`/tags/sync`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    }
*/
    const onTagChange = async (value, reason) => {
        setSelecteds(value);
        onFiltered(value);
        onChange(value, reason);
        console.log("onTagChange",selecteds)
       
    }

    const onChange = async (value, reason) => {
        let optionsChanged = []
        if (reason === 'create-option') {
            if (isArray(value)) {
                for (let item of value) {
                    if (isString(item)) {
                        const newTag = await createTag({ name: item })
                        optionsChanged.push(newTag);
                    } else {
                        optionsChanged.push(item);
                    }
                }
            }
            await loadTags();
        } else {
            optionsChanged = value;
        }
        setSelecteds(optionsChanged);
        //await syncTags({ ticketId: campaign.id, tags: optionsChanged });
    }

    return (
        <>
          
                   {selecteds.map((option, index) => (
                        <span>{option.name}, </span>    
                    ))}
            
        
        </>
    )
}