const messages = {
  pt: {
    translations: {
      signup: {
        title: "Cadastre-se",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!!!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          phone: "Número de Whatsapp"
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Entrar",
          register: "Não tem uma conta? Cadastre-se!",
        },
      },
      auth: {
        toasts: {
          success: "Login efetuado com sucesso!",
        },
      },
      mainDrawer: {
        listItems: {

          administration: "Administração",
          operation: "Operação",
          supervision:"Supervisão",
          
          business: "Negócios",

          dashboard: "Indicadores",
          dashboarddesc: "Veja aqui os indicadores de desempenho mais relevantes",
          connections: "Conexões",
          connectionsdesc: "Veja aqui o status das conexões ao Whatsapp",
          tickets: "Conversas",
          ticketsdesc: "Veja aqui as suas conversas",
          contacts: "Contatos",
          contactsdesc: "Veja aqui sua lista de contatos",
          groups: "Grupos",
          quickAnswers: "Respostas Rápidas",
          quickAnswersdesc: "Veja aqui suas mensagens padrão",
          queues: "Filas",
          queuesdesc: "Veja aqui as suas filas de distribuição",
          
          settings: "Configurações",
          settingsdesc: "Altere aqui as suas configurações",

          campaign: "Campanhas",
          campaigndesc: "Faça aqui a gestão de suas campanhas",

          broadcasts: "Disparos",
          broadcastsdesc: "Faça aqui a gestão de seus disparos",

          catalog: "Catálogo",
          catalogdesc: "Crie aqui o catálogo de sua loja",

          bots: "Menu de Recepção",
          botsdesc: "Veja aqui a estrutura do seu menu de recepção",
        
          categories: "Categorias",
          categoriesdesc: "Crie aqui as categorias de sua loja",

          softphone: "Telefone IP",
          softphonedesc: "Abra aqui o seu ramal IP",

          nlpconf: "Configuração NLP",
          nlpconfdesc: "Faça aqui a configuração da sua IA",

          tags: "Etiquetas",
          tagsdesc: "Faça aqui a configuração das suas Etiquetas",

          agenda: "Agendamentos",
          agendadesc: "Veja aqui seus agendamentos",

          users: "Usuários",
          usersdesc: "Faça aqui a gestão dos seus usuários",

          scheduledMessages: "Envios Futuros",
          scheduledMessagesdesc: "Faça aqui a gestão das suas mensagens agendadas",
          
          internalChat : "Chat Interno",
          internalChatdesc : "Converse com outros usuários do sistema",

          bizflow : "Bizflow",
          bizflowdesc : "Desenhe os seus chatbot",
          
          announcements:"Informativos",
          announcementdesc:"Faça aqui a gestão de seus informativos",

          messagesAPI : "Gateway/API",
          messagesAPIdesc : "Enviar mensagens de outros sistemas",

          upgrades : "Atualizações",
          upgradesdesc: "Acompanhe o histórico de atualizações",

          radar : "Radar de Atendimento",
          radardesc: "Monitorize os seus atendimentos"
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Sair",
          },
        },
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Tickets hoje: ",
          },
        },
        satisfaction:{
            global:{
              title:"Nível de Satisfação Atual"
            }
        },
        messages: {
          inAttendance: {
            title: "Em Atendimento"
          },
          salesfunnel:{
            title: "Valor Total, Ponderado, de Oportunidades, por Maturidade."
          },
          saleslist:{
            title: "Lista de Oportunidades Ativas, por Maturidade."
          },
          distribution:{
            title: "Distribuição por atendente"
          },
          filteredStats:{
            title: "Resumo, por período"
          },
          totals:{
            user: "Totais por usuário, para o período",
            queue: "Totais por fila, para o período",
            tag: "Uso de Etiqueta, para o período"
          },
          waiting: {
            title: "Aguardando"
          },
          closed: {
            title: "Finalizado"
          }
        }
      },
      upgrades:{
        title:"Histórico de Atualizações"
      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Conexão com o WhatsApp excluída com sucesso!",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida e tem impacto profundo na estabilidade do seu sistema. Se não tem certeza do que está fazendo, por favor, cancele esta operação.",
          disconnectTitle: "Desconectar",
          disconnectMessage: "Tem certeza? Você precisará ler o QR Code novamente.",
        },
        buttons: {
          add: "Adicionar WhatsApp",
          disconnect: "desconectar",
          stalled: "instável",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content: "Certifique-se de que seu celular esteja conectado à internet e tente novamente",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content: "Clique no íone 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
            content: "A sua conexão está estável e funcionando corretamente. Pode fazer seus atendimentos, sem nenhum impedimento.",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content: "Certifique-se de que seu celular esteja conectado à internet e clique no icone 'Renovar' para obter um novo QR Code",
          },
          stalled: {
            title: "A conexão com o WhatsApp está fora de serviço ",
            content: "Clique no icone 'Renovar' para obter um novo QR Code",
          },

        },
        table: {
          name: "Nome",
          number: "Número",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          outChannel: "Disparo",
          actions: "Ações",
          session: "Sessão",
          dailyMax: "Limite Diário"
        },
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      tickets: {
        toasts: {
          deleted: "O ticket que você estava foi deletado.",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Area de Trabalho" },
          closed: { title: "Resolvidos" },
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Buscar tickets e mensagens",
        },
        buttons: {
          showAll: "Todos",
          import: "Importar",
          importChats: "Importar Conversas"
        },
      },
      chat: {
        noTicketMessage: "Selecione um ticket para começar a conversar.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Filas",
      },
      ticketsUserSelect: {
        placeholder: "Atendentes",
      },
      ticketsList: {
        pendingHeader: "Aguardando",
        assignedHeader: "Atendendo",
        noTickets:{
          title:"Nada aqui!",
          message:"Nenhum ticket encontrado com esse status"
        },
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage: "Nenhum ticket encontrado com esse status ou termo pesquisado",
        connectionTitle: "Conexão que está sendo utilizada atualmente.",
        buttons: {
          accept: "Aceitar",
          spy: "Espiar"
        },
      },
      groups: {
        title: "Grupos",
        buttons:{
          manageContacts:"Contatos"
        }
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          importTitle: "Importar contatos",
          deleteMessage: "Tem certeza que deseja deletar este contato? Todos os tickets relacionados serão perdidos.",
          importMessage: "Deseja importar todos os contatos do telefone?",
        },
        miningModal:{
          rootNumber: "Número Inicial",
          generateNbr: "Quantidade a Minerar"
        },
        buttons: {
          import: "Importar Lista Telefônica",
          add: "Adicionar Contato",
          importXls: "Importar Planilha",
          addGroups: "Adicionar Grupos",
          mine: "Minerar Contatos",
          manageGroups:"Grupos"
        },
        table: {
          name: "Nome",
          whatsapp: "WhatsApp",
          email: "Email",
          type: "Tipo ",
          participants: "Participantes",
          invitationlink: "Link de Convite",
          actions: "Ações",
        },
      },
      bots: {
        title: "Estrutura do Menu de Recepção",
        table: {
          commandBot: "Nível",
          commandType: "Tipo",
          showMessage: "Mensagem de saudação",
          userId: "Atendente",
          queueId: "Filas",
          actions: "Ações",
          descriptionBot: "Descrição",
          category: "Categoria"
        },
        buttons: {
          add: "Adicionar nível",
          hierarchy: "Estrutura",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida!",
        },
        messages: {
          saved: "Nível adicionado com sucesso!",
          deleted: "Comando deletado com sucesso!",
        },
        hierarchy: {
          title: "Estrutura do menu de recepção",
        }
      },
      bizflow:{
        title:"BizFlow",
        buttons:{
          add:"Adicionar BizFlow"
        },
        table:{
          id: "Referência",
          name:"Nome",
          description:"Descrição",
          isActive: "Ativo",
          dataJson: "JSON"
        }
      },
      queues: {
        title: "Filas",
        table: {
          name: "Nome",
          color: "Cor",
          greeting: "Mensagem de saudação",

          startWork: "Abertura",
          endWork: "Fechamento",

          actions: "Ações",
        },
        buttons: {
          add: "Adicionar fila",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida! Os tickets dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída.",
        },
      },
      queueSelect: {
        inputLabel: "Filas",
      },
      quickAnswers: {
        title: "Respostas Rápidas",
        table: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Resposta Rápida",
        },
        toasts: {
          deleted: "Resposta Rápida excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Resposta Rápida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      users: {
        title: "Usuários",
        table: {
          name: "Nome",
          email: "Email",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Todos os dados do usuário serão perdidos. Os tickets abertos deste usuário serão movidos para a fila.",
        },
      },
      settings: {
        success: "Configurações salvas com sucesso.",
        title: "Configurações",
        settings: {
          userCreation: {
            name: "Criação de usuário",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          sideMenu: {
            name: "Menu Lateral Inicial",
            note: "Se habilitado, o menu lateral irá iniciar fechado",
            options: {
              enabled: "Aberto",
              disabled: "Fechado",
            },
          },
          call: {
            name: "Aceitar chamadas",
            note: "Se desabilitado, o cliente receberá uma mensagem informando que não aceita chamadas de voz/vídeo",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
        },
      },
      categories: {
        title: "Categorias",
        table: {
          categoryName: "Categoria",
          mustaccio: "Forma de envio",
          displayName: "Nome de Exibição",
          isActive:"Ativo",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Categoria",
        },
        toasts: {
          deleted: "Categoria excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir esta Categoria: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      campaign: {
        title: "Lista de Campanhas",
        searchPlaceholder: "Pesquisar...",
        buttons: {
          add: "Adicionar Campanha",
          addcampaignplan: "Adicionar Plano de Campanha",
        },
        confirmationModal: {
          deleteTitle: "Deseja remover a campanha ",
          deleteMessage: "De momento não é possivel remover a campanha. Por favor clique no botão 'Cancelar'",
        },
        table: {
          planname: "Plano de Campanha",
          name: "Campanha",
          status: "Status",
          to: "Segmento",
          image: "Imagem",
          action: "Ação",
          active: "Ativa",
          processing: "Em Processamento",
          sendfrom: "Data de Envio",
          sendfromdetail: "Data de Envio (aaaa-mm-dd)",
          groups: "Grupos",
          contacts: "Contatos",
          tags: "Etiquetas",
          nameFilter: "Filtro de Nome",
          channels: "Canais WA"
        },
        modal: {
          name: "Nome da Campanha"
        }
      },
      campaignPlan: {
        modal: {
          edit: "Editar Plano de Campanha",
          add: "Adicionar Plano de Campanha",
          planName: "Nome do Plano de Campanha"
        }
      },
      catalog: {
        title: "Catálogo de Itens",
        searchPlaceholder: "Pesquisar...",
        buttons: {
          add: "Adicionar Item",
          category: "Adicionar Categoria",
        },

        confirmationModal: {
          deleteTitle: "Deseja remover o item ",
          deleteMessage: "Todos os dados do item serão perdidos, incluido os respetivos textos e fotos.",
        },
        table: {
          itemId: "Ref.",
          productImg: "Imagem",
          itemName: "Item",
          description: "Descrição",
          manufacturer: "Fabricante",
          dimensions: "Apresentação",
          unitName: "Unidade",
          pax: "Para Quantos",
          category: "Categoria",
          order: "Ordenação",
          baseValue: "Preço de Venda",
          deliveryValue: "Valor da Entrega",
          action: "Ações",
        },
      },
      catalogModal: {
        title: {
          add: "Adicionar item de catálogo",
          edit: "Editar item de catálogo",
        },
        form: {
          productImg: "Imagem",
          itemName: "Item",
          description: "Descrição",
          manufacturer: "Fabricante",
          dimensions: "Apresentação",
          category: "Categoria",
          unitName: "Unidade",
          pax: "Para Quantos",
          category: "Categoria",
          order: "Ordenação",
          baseValue: "Preço de Venda",
          deliveryValue: "Valor da Entrega",
          action: "Ações",
          userData1: "User Data 1",
          userData2: "User Data 2",
          userData3: "User Data 3",
          userData4: "User Data 4",
          userData5: "User Data 5",
          userData6: "User Data 6",
          userData7: "User Data 7",
          userData8: "User Data 8",
          userData9: "User Data 9",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "O item foi guardado de uma forma bem sucedida.",
      },
      categorySelect: {
        inputLabel: "Categoria de Item de Catálogo"
      },
      messagesList: {
        feed:{
          ticketSeparator : "Protocolo: "
        },
				header: {
					assignedTo: "Atribuído à:",
					buttons: {
						return: "Retornar",
						resolve: "Concluir",
						reopen: "Reabrir",
						history: "Histórico",
						accept: "Aceitar",
						video:	"Chamada Video",
						workpad:"Workpad",
						screen:"ScreenShare",
						phone: "Chamada IP",
            transfer: "Transferir",
            delete: "Deletar",
            task: "Tarefa",
            produce:"Produzir Relatório de Auditoria",
            download:"Baixar Relatório de Auditoria"
					},
					instruction:"Vamos fazer uma sessão colaborativa. Por favor abra o seguinte link:\n",
					workpad:"Vamos fazer uma sessão colaborativa. Por favor abra o seguinte link:\n",
					video:"Vamos fazer uma chamada de video. Por favor abra o seguinte link:\n",
					screen:"Vou compartilhar minha tela com você. Por favor abra o seguinte link:\n",
					phone:"Vamos fazer uma chamada telefônica. Por favor abra o seguinte link:\n",
				},
			},
      messagesInput: {
        placeholderOpen: "Digite uma mensagem ou tecle ''/'' para utilizar as respostas rápidas cadastrada",
        placeholderClosed: "Reabra ou aceite esse ticket para enviar uma mensagem.",
        signMessage: "Assinar",
      },
      contactDrawer: {
        header: "Dados do contato",
        buttons: {
          edit: "Editar contato",
        },
        extraInfo: "Outras informações",
        salesOpp: "Oportunidades de Negócio",
      },
      ticketOptionsMenu: {
        delete: "Deletar",
        transfer: "Transferir",
        confirmationModal: {
          title: "Deletar o ticket do contato",
          message: "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Deletar",
        reply: "Responder",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      broadcastsModal: {
        title: {
          title: "Disparos",
          create: "Criar Novo Disparo",
        },
        form: {
          title: "Enviar Novo Disparo",
          contacts: {
            title: "Contatos",
            placeholder: "Insira os contatos em formato internacional (código de país, DDD e número), um por linha:\n556132466145\n556140428274",
          },
          message: {
            title: "Mensagem",
            placeholder: "Olá, tudo bem?\nTenho uma oferta exclusiva para você.\nPodemos conversar?",
          },
          id: {
            title: "ID de Conexão do WhatsApp",
            placeholder: "Você poder pegar seu ID de conexão na Página de Administração de Conexões",
          },
        },
        buttons: {
          submit: "Enviar",
          cancel: "Cancelar",
        },
        toasts: {
          sending: "Suas mensagens foram enviadas. Por favor, aguarde nesta página.",
          sent: "Mensagem enviada para o número: "
        },
      },  
      whatsappModal: {
        title: {
          add: "Adicionar WhatsApp",
          edit: "Editar WhatsApp",
        },
        form: {
          name: "Nome",
          number: "Número",
          default: "Canal Padrão",
          outbound: "Canal de Disparo",
          farewellMessage: "Mensagem de despedida",
          dailyMax: "Limite Diário de Disparo"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "WhatsApp salvo com sucesso.",
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
          importXls: "Importar Planilha",
          xlssuccess: "A planilha foi importada com sucesso.",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações Adicionais",
          name: "Nome",
          number: "Número do Whatsapp",
          email: "Email",
          extraName: "Nome do campo",
          extraValue: "Valor",
          salesOpps:"Oportunidades de Negócio",
          oppName:" Nome da Opp",
          oppValue:"Valor da Opp",
          oppStage:"Estágio da Opp",
          type: "Tipo de Contato"



        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          addSalesOpp: "Adicionar oportunidade",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },
      announcements: {
        title: "Informativos",
        searchPlaceholder: "Pesquisa",
        buttons: {
          add: "Novo Informativo",
          contactLists: "Listas de Informativos",
        },
        table: {
          priority: "Prioridade",
          title: "Título",
          text: "Texto",
          mediaName: "Arquivo",
          status: "Status",
          actions: "Ações",
        },
        dialog: {
          edit: "Edição de Informativo",
          add: "Novo Informativo",
          update: "Editar Informativo",
          readonly: "Apenas Visualização",
          form: {
            priority: "Prioridade",
            title: "Title",
            text: "Texto",
            mediaPath: "Arquivo",
            status: "Status",
          },
          buttons: {
            add: "Adicionar",
            edit: "Atualizar",
            okadd: "Ok",
            cancel: "Cancelar",
            close: "Fechar",
            attach: "Anexar Arquivo",
          },
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        toasts: {
          success: "Operação realizada com sucesso",
          deleted: "Registro excluído",
        },
      },
      
      


      groupModal: {
        title: {
          add: "Adicionar Grupo(s)",
          edit: "Editar Grupo"
        },
        error: {
          name: "O nome do grupo é obrigatório.",
          root: " Não esquecer a raiz de sequência, #3 últimos dígitos",
          admin1: "O grupo precisa de um Admin 1",
          admin2: "O grupo precisa de um Admin 2",
          instances: "Precisa de iinformar o número de grupos sequênciais a serem criados",
          maxparticipants: "Precisa informar o numero máximo de participantes de cada grupo",
          media: "Uma imagem para o perfil de grupo é obrigatória"
        },
        form: {
          name: "Nome do Grupo",
          admin1: "Administrador 1",
          admin2: "Administrador 2",
          instances: "Número de Instâncias",
          maxparticipants: "Limite de participantes",
          root: "Numeração"
        }
      },
      categoriesModal: {
        title: {
          add: "Adicionar uma Categoria",
          edit: "Editar uma Categoria",
        },
        form: {
          categoryName: "Nome da Categoria",
          mustaccio: "Forma de envio",
          displayName:"Nome de Exibição",
          isActive:"Ativo"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Categoria salva com sucesso.",
      },
      quickAnswersModal: {
        title: {
          add: "Adicionar Resposta Rápida",
          edit: "Editar Resposta Rápida",
        },
        form: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Resposta Rápida salva com sucesso.",
      },
      queueModal: {
        title: {
          add: "Adicionar fila",
          edit: "Editar fila",
        },
        notification: {
          title: "Fila salva com sucesso!",
        },
        form: {
          id: "Id",
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",

          startWork: "Abertura",
          endWork: "Fechamento",
          absenceMessage: "Mensagem de ausência",
          nps: "Ativar NPS",
          encouragingMessage: "Mensagem de Incentivo",
          url: "URL"

        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Digite para buscar usuários",
        fieldQueueLabel: "Transferir para fila",
        fieldConnectionLabel: "Transferir para conexão",
        fieldQueuePlaceholder: "Selecione uma fila",
        fieldConnectionPlaceholder: "Selecione uma conexão",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      inviteTicketModal: {
        title: "Convidar Usuário",
        fieldLabel: "Digite para buscar usuários",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Convidar Usuário",
          cancel: "Cancelar",
        },
      },
      BookingModal:{
        toasts: {
          success: "Tarefa agendada com sucesso."
        }

      },
      historyTicketModal: {
        title: "Histórico de Atendimento",
        fieldData: "Data e Hora",
        fieldUser: "Usuário",
        fieldAction: "Ação",
        fieldtargetUser: "Destinatário",
        defaultUser: "Sistema"
      },
      campaignModal: {
        title: {
          mainTitle: "Dados para Campanha",
          success: "A campanha foi criada corretamente",
          nameerror: "O nome da campanha é uma informação obrigatória",
          isgrouperror: "É necessário definir se a campanha se destina a Grupos ou Contatos",
          namefiltererror: "É necessário preencher o campo de filtro de nome. Use '%' para todos.",
          numberfiltererror: "É obrigatório definir um filtro para os canais de saida. Use '%' para todos.",
          sendfromerror: "É obrigatório definir a data e hora de início de envio"
        },
        form: {
          name: "Nome da Campanha",
          segment: "Segmento",
          contacts: "Contatos",
          groups: "Grupos",
          leads: "Leads",
          geoselection:"Polígono Geo",
          nameFilter: "Filtro de Nome",
          includeDefault: "Incluir Número Padrão",
          notinclude: "Não Incluir",
          include: "Incluir",
          sendFrom: "Iniciar envio em",
          channels: "Canais WA",
        },
      },
      campaignTextModal: {
        title: "Lista de Mensagens para Campanha",
        messagebox: "Corpo da Mensagem",
        searchPlaceholder: "Pesquisar...",
        success: "Mensagem foi salva corretamente",
        deleted: "Mensagem foi removida corretamente",
        buttons: {
          add: "Adicionar Campanha",
        },
        table: {
          text: "Texto da Mensagem",
          actions: "Ações",
          sequence: "Sequência",
        },
      },
      campaignButtonModal: {
        title: "Lista de botões para Campanha",

        url1 : "Endereço Web 1",
        url1Placeholder : "http://www.mware.com.br",
        url1Caption: "Etiqueta para Endereço Web 1",
        url1CaptionPlaceholder : "Visite nosso site",
        
        url2 : "Endereço Web 2",
        url2Placeholder : "https://wa.me/55658400000?text=Queria+saber+mais",
        url2Caption: "Etiqueta para Endereço Web 2",
        url2CaptionPlaceholder : "Nos chame no Whatsapp",

        phone1 : "Telefone para Contato",
        phone1Placeholder : "+55658400000",
        phone1Caption: "Etiqueta para Telefone para Contato",
        phone1CaptionPlaceholder : "Ligue Agora",
        

        nbrContato : "Telefone de contato",
        nbrContatoCaption : "Etiqueta para Telefone de Contato",
        nbrContatoExample : "Ligue Agora",

        mensagem : "Texto da Mensagem",
        mensagemPlaceholder : "Nossa empresa é lider de mercado e tem para você....",


        jid : "Número de Destino",
        jidPlaceholder :  "+55658400000 (formato whatsapp)",

        sendTest : "Enviar Mensagem de Teste"

      },





      newTicketModal: {
        title: "Criar Ticket",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
      },
      botModal: {
        title: {
          add: "Adicionar nível",
          edit: "Editar nível",
        },
        form: {
          commandBot: "Nível",
          descriptionBot: "Descrição",
          commandType: "Tipo",
          showMessage: "Mensagem",
          userId: "Atendente",
          queueId: "Fila",
        },
        messages: {
          saved: "Nível salvo com sucesso!",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      scheduledMessages: {
        buttons: {
          add: "Novo agendamento"
        },
        confirmationModal: {
          delete: {
            message: "Tem certeza que deseja excluir este agendamento? Esta açào não pode ser revertida.",
            title: "Excluir agendamento"
          }
        },
        title: "Mensagens Agendadas",
        toasts: {
          cantDelete: "Não é possível excluir agendamentos já enviados.",
          deleted: "Agendamento excluido com sucesso."
        }
      },

      bookingModal:{
        form:{
          title: "Calendarizar tarefa",
          titleContact: "Calendarizar tarefa relativa a {{ contact }}",
          date: "Data e hora de execução",
          namePlaceholder: "Digite o nome da tarefa",
          descriptionPlaceholder: "Digite a descrição da tarefa",
          message: "Descrição",
        },
        toasts: {
          error: "Erro ao realizar calendarização",
          missingMessage: "Insira uma descrição da tarefa",
          missingSchedule: "Informe a data e horário para execução",
          success: "Tarefa calendarizada com sucesso",
        }
    },
      scheduledMessagesModal: {
        form: {
          date: "Data e hora do envio",
          message: "Conteúdo da mensagem",
          number: "Número com DDI",
          title: "Agendar mensagem",
          titleContact: "Agendar envio para {{ contact }}",
        },
        toasts: {
          error: "Erro ao realizar agendamento",
          missingConnection: "Selecione uma conexão",
          missingMessage: "Insira sua mensagem",
          missingNumber: "Insira um telefone",
          missingSchedule: "Informe a data e horário para envio",
          success: "Mensagem agendada com sucesso",
        }
      },    
      tags: {
        title: "Etiquetas",
        confirmationModal: {
          deleteTitle: "Excluir etiqueta",
          deleteMessage: "Você tem certeza de que quer excluir esta etiqueta? Esta ação não pode ser desfeita.",
        },
        table: {
          name: "Nome",
          color: "Cor",
          tickets: "Chats Etiquetados",
          actions: "Ações",
          hasTickets: "Sim",
          noTickets: "Não"
        },
        buttons: {
          add: "Nova Etiqueta",
        },
        toasts: {
          deleted: "Etiqueta excluída com sucesso.",
        },
        placeholder: {
          filterByName: "Etiquetas"
        }
      },
      tagModal: {
        title: {
          add: "Nova Etiqueta",
          edit: "Editar Etiqueta",
        },
        form: {
          name: "Nome",
          color: "Cor"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Etiqueta salva com sucesso.",
      },
      userModal: {
        form: {
          connection: {
            none: "Nenhuma conexão padrão",
            select: "Selecione uma conexão padrão",
            status: "Status"
          },
          email: "E-mail",
          name: "Nome",
          password: "Senha",
          profile: {
            name: "Perfil",
            role: {
              admin: "Administrador",
              dashboard: "Relatório",
              finance: "Financeiro",
              manager: "Gerente",
              super: "Super",
              supervisor: "Supervisor",
              support: "Suporte",
              user: "Usuário"
            }
          },
          sendFarewellMessage: {
            note: "Ao desmarcar essa opção, o contato deixará de receber mensagens de despedida no encerramento das conversas.",
            title: "Enviar mensagens de despedida ao final de atendimentos."
          },
          status: "Status"
        },
        success: "Usuário salvo com sucesso.",
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário"
        }
      },
      HelpVideo: {
        title: "Video de Explicação"
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_DEF_WAPP_FOUND: "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED: "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT: "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA: "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_INVALID_CREDENTIALS: "Erro de autenticação. Por favor, tente novamente.",
        ERR_SENDING_WAPP_MSG: "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Já existe um tíquete aberto para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
        ERR_USER_CREATION_DISABLED: "A criação do usuário foi desabilitada pelo administrador.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhum tíquete encontrado com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar tíquete no banco de dados.",
        ERR_FETCH_WAPP_MSG: "Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS: "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED: "A mensagem de saudação é obrigatório quando há mais de uma fila.",
        ERR_CATEGORYNAME_DUPLICATED: "Nome da Categoria já existe!",
        ERR_NO_CATEGORY_FOUND: "Categoria não encontrada.",
        ERR_CATALOG_DUPLICATED: "Nome do item do catalogo já existe!",
        ERR_NO_CATALOG_FOUND: "Catalogo não encontrada.",
      },
      validation: {
        buttons: {
        accept: "Aceitar",
          participate:"Participar",
          addUser: "Adicionar usuário",
          back: "Selecionar outro cliente.",
          cancel: "Cancelar",
          confirm: "Confirmar",
          contacts: {
            fileName: "Bits_-_Lista_de_Contatos"
          },
          create: "Criar",
          delete: "Excluir",
          download: "Baixar",
          edit: "Editar",
          login: "Já tem uma conta? Entre!",
          new: "Novo",
          newTicket: "Novo",
          ok: "Salvar",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          reopen: "Reabrir",
          resolve: "Finalizar",
          return: "Retornar",
          reviewContacts: "Revisar Contatos",
          importMinedContacts:"Importar Minerados",
          schedule: "Agendar",
          send: "Enviar",
          showAll: "Todos",
          start: "Iniciar",
          submit: "Cadastrar",
          support: "Suporte",
          transfer: "Transferir",
          update: "Atualizar"
        },
        dateNow: "Data anterior ao momento atual",
        genericError: "Ocorreu um erro inseperado e não foi possível realizar esta operação no momento",
        invalid_email: "E-mail inválido",
        locale: "pt-BR",
        medias: {
          fileTooBig: "O arquivo selecionado é muito grande.",
          hasOthers: " e outras {{ medias }} mídias anexadas.",
          none: "Nenhuma mídia selecionada",
          notCSV: "O arquivo .CSV não possui o formato correto. Por favor, use o arquivo de referência disponibilizado em página."
        },
        noNotifications: "Este navegador nào aceita notificações.",
        number: "Não é um número",
        options: {
          disabled: "Desabilitado",
          enabled: "Habilitado"
        },
        required: "Obrigatório",
        show: {
          false: "Ocultar",
          true: "Mostrar"
        },
        state: {
          active: "Ativo",
          inactive: "Inativo"
        },
        too_long: "Muito comprido",
        too_short: "Muito curto",
        weekdays: {
          friday: {
            abreviated: "Sex",
            full: "Sexta-feira"
          },
          monday: {
            abreviated: "Seg",
            full: "Segunda-feira"
          },
          saturday: {
            abreviated: "Sab",
            full: "Sábado"
          },
          sunday: {
            abreviated: "Dom",
            full: "Domingo"
          },
          thursday: {
            abreviated: "Qui",
            full: "Quinta-feira"
          },
          tuesday: {
            abreviated: "Ter",
            full: "Terça-feira"
          },
          wednesday: {
            abreviated: "Qua",
            full: "Quarta-feira"
          }
        },
        whatsappType: {
          business: "Empresa",
          enterprise: "Corporação",
          group: "Grupo",
          user: "Usuário"
        }
      },
      variables: {
        calendar: {
          day: "Dia",
          month: "Mês",
          week: "Semana",
          year: "Ano"
        },
        campaign: "Olá ",
        contact: {
          avatar: "Link para foto de perfil",
          dateCreate: "Data de criação do Contato",
          dateUpdate: "Data de atualização do Contato",
          email: "E-mail do contato",
          firstName: "Primeiro nome",
          fullName: "Nome completo",
          isGroup: "Contato é grupo?",
          phone: "Telefone do contato"
        },
        date: {
          br: "Data BR",
          day: "Dia",
          iso: "Data ISO",
          month: "Mês",
          us: "Data US",
          year: "Ano"
        },
        greeting: "Saudação",
        greetings: [
          "Boa madrugada",
          "Bom dia",
          "Boa tarde",
          "Boa noite"
        ],
        placeholders: {
          search: "Pesquisar..."
        },
        protocol: "Protocolo",
        table: {
          actions: "Ações",
          areaCode: "DDD",
          attachment: {
            none: "Sem anexo",
            title: "Anexo"
          },
          battery: "Bateria",
          block: "Bloqueado",
          checkWorkHours: "Horário de funcionamento",
          color: "Cor",
          connection: "Conexão padrão",
          contact: "Contato",
          countryCode: "DDI",
          createdAt: "Criado em",
          dateTime: "Data/Hora",
          default: "Padrão",
          outchannel: "Disparo", 
          email: "E-mail",
          greeting: "Mensagem de saudação",
          id: "ID",
          isGroup: "Grupo",
          lastUpdate: "Última atualização",
          message: "Mensagem",
          mute: "Mutado",
          name: "Nome",
          number: "Número",
          noTickets: "Não",
          number: "Número",
          phone: "Telefone",
          profile: "Perfil",
          quickAnswer: "Resposta Rápida",
          sentBy: "Enviado por",
          session: "Sessão",
          shortcut: "Atalho",
          status: {
            error: "Erro",
            pending: "Aguardando",
            sent: "Enviado",
            state: {
              error: "Erro",
              pending: "Aguardando",
              sent: "Enviada"
            },
            title: "Status"
          },
          systemTag: "Tag de Sistema",
          tickets: "Chats Etiquetados",
          whatsapp: "WhatsApp",
          whatsappType: "Tipo"
        },
        ticket: {
          dateCreate: "Data de criação do chat",
          dateUpdate: "Data de atualização do chat",
          id: "ID do chat",
          isGroup: "Chat é grupo?",
          lastMessage: "Última mensagem",
          queue: "Fila do chat",
          status: {
            awaiting: "Aguardando",
            closed: "Fechado",
            open: "Aberto",
            title: "Status do chat"
          },
          wppName: "Nome da conexão",
          wppStatus: {
            connected: "Conectado",
            disconnected: "Desconectado",
            pending: "Pendente",
            qrCode: "Aguardando leitura de QR Code",
            title: "Nome da conexão"
          }
        },
        time: {
          hour: "Hora",
          minute: "Minuto",
          second: "Segundo"
        },
        title: "Variáveis disponíveis",
        user: {
          email: "Meu e-mail",
          name: "Meu nome",
          profile: {
            title: "Meu perfil"
          }
        }
      },
      broadcasts:{
        variables:{
          title: "Variaveis para inclusão"
        },
        toasts:{
          error:{
            noWpId: "Canal whatsapp não selecionado",
            noContacts: "Sem contatos para disparo",
            noCredits:"Sem Créditos para disparo",
            saveCampaign: "Erro: "
          },
          saved:"Salvo Corretamente"
        },
        tooltips:{
          buyCredits:"Compre crédtos de disparo",
          creditsInfo: "Informação sobre créditos"
        },
        title: "Disparo",
        buttons:{
          reviewContacts: "Revisar Contatos",
          importMinedContacts:"Importar Contatos Minerados",
          send: "Enviar",
          credits:{
            left:"Créditos Disponívis"
          },
          example:{
            title:"Arquivo de Exemplo",
            fileName: "Arquivo de Exemplo"
          },
          edit:"Editar",
          add:"Adicionar"
        },
        table:{
          name:"Contato",
          number:"Número WA",
          status:{
              title: "Status",
              sent: "Enviado",
              error: "Com Erro",
              pending: "Pendente"
          },
          dateTime:"Data",
          actions: "Ações"
        },
        form:{
          title: "Adicionar Disparo",
          addContacts: "Adicionar Contatos",
          id:"Canal Whatsapp",
          message:"Mensagem",
          prefix: "Prefixo"
        }

      }

    },
   

  },
};

export { messages };
