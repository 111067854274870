import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import { makeStyles } from "@material-ui/core/styles";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Chip from '@material-ui/core/Chip';
import {blue, purple, red, green, teal, orange, yellow, amber} from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  ticketOpening: {
    padding: 2,
		paddingLeft: 5,
		paddingRight: 5,
    borderRadius: 3,
    fontSize: "0.9em",
    //fontWeight: "bold",
    // marginRight: "5px",
    // marginBottom: "3px",
    whiteSpace: "nowrap",
    backgroundColor: "#c2c9d6",
    color: "#fff",
    display: 'flex',
    alignItems: "flex-start",
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "flex-start",
    justifyContent: "center",
  },
  ticketIdleness: {
    marginBottom: 0,
    paddingBottom: -2,
    paddingLeft: 6,
    margin: 0,
    textAlign: 'center',
    fontWeight: 'bold',
    backgroundColor: "#f2f2f2",
    border: 'solid 1px',
    borderRadius: 12
  },
  timeTag: {
		right: "-47px",
		bottom: "2px",
		//background: "#beaed2",
		//color: "#000000",
		//border:"1px solid #CCC",
		padding: 2,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 3,
		fontSize: "0.9em"
	},
  chips: {
    display: "flex",
    flexWrap: "wrap",
    '&:hover': {
        filter: 'brightness(120%)',
    },
    },
    chip: {
        margin: 2,
        paddingLeft: 5,
        fontSize: "0.9em"
    },
    green1: {
      color: green[500]
      },
    red1: {
          color: red[500]
      },

    blue1: {
          color: blue[500]
      },
    teal1: {
          color: teal[500]
      },
    orange1: {
          color: orange[500]
      },
    purple1: {
          color: purple[500]
      },
    amber1: {
          color: amber[500]
      },
}))

const Stopwatch = (props) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const clientTimestampDiff = localStorage.getItem("clientTimestampDiff") || 0
  const { createdAt, ticketStatus, updatedAt, ticketQueue, type } = props
  const [createTimePending, setCreateTimePending] = useState();
  const [createDayPending, setCreateDayPending] = useState();
  const [updatedTimePending, setUpdatedTimePending] = useState();
  const [updatedDayPending, setUpdatedDayPending] = useState();
  const renderDelaySecond = 60 // Define o tempo a cada atualização a renderização do cronometro em segundos

const [timeIdlenessGreen,   setTimeIdlenessGreen]  = useState(2); //minutos
const [timeIdlenessYellow, setTimeIdlenessYellow]  = useState(4);//minutos
const [timeIdlenessOrange, setTimeIdlenessOrange]  = useState(5);//minutos





  useEffect(() => {
    const startDate = new Date();
    const created = new Date(createdAt);
    const updated = new Date(updatedAt);
    const updatedDiff = (updated.getTime() - startDate.getTime() - clientTimestampDiff);

    const updatedTimeMicroSeconds = Math.abs(Math.round(updatedDiff))

    setUpdatedTimePending(updatedTimeMicroSeconds);
    setUpdatedDayPending(parseInt(updatedTimeMicroSeconds / 1000 / 60 / 60 / 24))

    const createdDiff = (created.getTime() - startDate.getTime() - clientTimestampDiff);
    const createdTimeMicroSeconds = Math.abs(Math.round(createdDiff))

    setCreateTimePending(createdTimeMicroSeconds);
    setCreateDayPending(parseInt(createdTimeMicroSeconds / 1000 / 60 / 60 / 24))

    const interval = setInterval(() => {
      const startDate = new Date();
      const created = new Date(createdAt);
      const updated = new Date(updatedAt);
      const updatedDiff = (updated.getTime() - startDate.getTime() - clientTimestampDiff);
      const updatedTimeMicroSeconds = Math.abs(Math.round(updatedDiff))

      setUpdatedTimePending(updatedTimeMicroSeconds);
      setUpdatedDayPending(parseInt(updatedTimeMicroSeconds / 1000 / 60 / 60 / 24))

      const createdDiff = (created.getTime() - startDate.getTime() - clientTimestampDiff);
      const createdTimeMicroSeconds = Math.abs(Math.round(createdDiff))

      setCreateTimePending(createdTimeMicroSeconds);
      setCreateDayPending(parseInt(createdTimeMicroSeconds / 1000 / 60 / 60 / 24))

    }, 1000 * renderDelaySecond);
    return () => clearInterval(interval);
  }, [updatedAt]);

  return (
    <>
      {type=="normal" && (
          <div style={{ display: 'flex', alignContent: 'center' }} >
            <div className={classes.ticketOpening} style={
              (updatedTimePending > 0 && updatedTimePending <= (timeIdlenessGreen * 60 * 1000)) //define o texto como verde
                ? { backgroundColor: "#2AB912", color: "white" } :
                (updatedTimePending > (timeIdlenessGreen * 60 * 1000) && updatedTimePending <= (timeIdlenessYellow * 60 * 1000)) ?
                  { backgroundColor: "#fafa05", color: "black" } :
                  (updatedTimePending > (timeIdlenessYellow * 60 * 1000) && updatedTimePending <= (timeIdlenessOrange * 60 * 1000)) ?
                    { backgroundColor: "#FBA72A", color: "black" } :
                    { backgroundColor: "#FB2A2A", color: "white" }
            }
            >
              {`${updatedDayPending}d ${moment.utc(updatedTimePending).format('HH')}h ${moment.utc(updatedTimePending).format('mm')}m`}
            </div>
          </div>
      )}

      {type=="chip" && (

          <Chip icon={<AccessTimeIcon style={{color:"#00000"}}/>} 
              variant="outlined" className={classes.chip} size = "small" style={  
                        
          
          
                            (updatedTimePending > 0 && updatedTimePending <= (timeIdlenessGreen * 60 * 1000)) //define o texto como verde
                              ? { backgroundColor: green[100], borderColor: green[800], color: green[500] } :
                              (updatedTimePending > (timeIdlenessGreen * 60 * 1000) && updatedTimePending <= (timeIdlenessYellow * 60 * 1000)) ?
                                { backgroundColor: yellow[100], borderColor: yellow[800],color: orange[500] } :
                                (updatedTimePending > (timeIdlenessYellow * 60 * 1000) && updatedTimePending <= (timeIdlenessOrange * 60 * 1000)) ?
                                { backgroundColor: orange[100], borderColor: orange[800],color: red[500] }:
                                {backgroundColor: red[100], borderColor: red[800], color:"#9c1313"}
            } label={`${updatedDayPending}d ${moment.utc(updatedTimePending).format('HH')}h ${moment.utc(updatedTimePending).format('mm')}m`}
             /> 
      )}

    </>
  );
};
export default Stopwatch;

//<Chip icon={<BugReportIcon  style={ label={releaseType} className={classes.chip} size = "small" style={{backgroundColor: red[100], borderColor: red[800], color: red[500]}} variant="outlined" />)}
         
///
//var duration = moment.duration(end.diff(startTime));
//var hours = duration.asHours();