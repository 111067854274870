
import { makeStyles } from "@material-ui/core/styles";
import React, {useState, useEffect, useReducer} from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import Paper from "@material-ui/core/Paper";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";

import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";


import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	CircularProgress,
	TextField,
	Switch,
	FormControlLabel,
  } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	popupbox : {
		position: "fixed",
		background: "#00000050",
		width: "100%",
		height: "100vh",
		top: "0",
		left: "0"

	  },
	  box : {
		position: "relative" ,
		width: "50%" ,
		margin: "0 auto" ,
		height: "auto" ,
		maxHeight: "90vh" ,
		marginTop: "calc(100vh - 85vh - 20px)" ,
		background: "#fff" ,
		borderRadius: "4px" ,
		padding: "20px" ,
		border: "1px solid #999" ,
		overflow: "auto" ,
		zIndex:"1000"
	  },
	   
	  closeicon : {
		content: "x" ,
		cursor: "pointer" ,
		position: "fixed" ,
		right: "calc(22%)" ,
		top: "calc(100vh - 85vh - 33px)" ,
		background: "#ededed" ,
		width: "25px" ,
		height: "25px" ,
		borderRadius: "50%" ,
		lineHeight: "20px" ,
		textAlign: "center" ,
		border: "1px solid #999" ,
		fontSize: "20px" ,
	  }



}));

const CampaignTextsModal = props => {
	const classes = useStyles();

	const initialState = {
		messageId: "",
		messageText: "",	
		messageFK: ""
	};

	const [campaignData, setcampaignData] = useState([]);
	const [campaignMsgs, setcampaignMsgs] = useState([]);
	const [CampaignMsg, setCampaignMsg] = useState(initialState);
	const [inEditMessageId, setinEditMessageId] = useState(0)
	const [inEditMessageFK, setinEditMessageFK] = useState(0)
	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
		(async () => {
		  setLoading(true);
		  try {
			const ret  = await api.get("/campaigntexts/"+props.id);
			
			setcampaignData(ret.data.campaignData);
			setcampaignMsgs(ret.data.campaignTexts);
			setinEditMessageFK(ret.data.campaignData.id)

			setLoading(false);
		  } catch (err) {
			toastError(err);
			setLoading(false);
		  }
		})();
	  }, []);
	  

	  const handleSaveCampaignMessage = async values => {
		let saveData=values;
		let ret;
		saveData.messageFK=inEditMessageFK;
	
		console.log(saveData)

		setLoading(true);
		try {
			if (saveData.messageId!="") {
				ret  = await api.put(`/campaigntexts/${saveData.messageId}`, saveData);
			} else {
				ret  = await api.post("/campaigntexts", saveData);
			}

			setcampaignData(ret.data.campaignData);
			setcampaignMsgs(ret.data.campaignTexts);
			setinEditMessageFK(ret.data.campaignData.id)

			setLoading(false);


			toast.success(i18n.t("campaignTextModal.success"));
			
		} catch (err) {
			toastError(err);
		}
		
	};
	
		const pullMsg = (msgtext) =>{;
			setLoading(true);

			let editState = {
				messageId: "",
				messageText: "",	
				messageFK: ""
			};

			editState.messageId=msgtext.id;
			editState.messageText = msgtext.text;
			editState.messageFK=msgtext.campaignId;

			setinEditMessageId(msgtext.id);
			setinEditMessageFK(msgtext.campaignId);
			setCampaignMsg(editState);

			setLoading(false);
		
		}

		const delMsg = async (msgtext) =>{;

			setLoading(true);

			let editState = {
				messageId: "",
				messageText: "",	
				messageFK: ""
			};

			editState.messageId=msgtext.id;
			editState.messageText = msgtext.text;
			editState.messageFK=msgtext.campaignId;

			try {
				const ret  = await api.delete(`/campaigntexts/${msgtext.id}`, editState);
				
				setcampaignData(ret.data.campaignData);
				setcampaignMsgs(ret.data.campaignTexts);
				setinEditMessageFK(ret.data.campaignData.id)
	
				setLoading(false);
	
	
				toast.success(i18n.t("campaignTextModal.deleted"));
				
			} catch (err) {
				toastError(err);
			}
		}


	const handleReset = () => {	
		setCampaignMsg(initialState);
	};

	return (
		<div className={classes.popupbox}>
			<div className={classes.box}>
				<span className={classes.closeicon} onClick={props.handleClose} >x</span>
			
			
					<MainContainer>
						<MainHeader>
							<Title>{i18n.t("campaignTextModal.title")}: "{campaignData.name}"</Title>
						</MainHeader>




						<Paper className={classes.mainPaper} variant="outlined">
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell align="center">{i18n.t("campaignTextModal.table.text")}</TableCell>
										<TableCell align="center">{i18n.t("campaignTextModal.table.actions")}</TableCell>
									</TableRow>
								</TableHead>	

								<TableBody>
									<>
									{campaignMsgs.map((msgtext) => (
										<TableRow key={msgtext.id}>
											
											<TableCell align="center">{msgtext.text}</TableCell>
											<TableCell align="center">
												<IconButton
												size="small"
												onClick={(e) => {
													pullMsg(msgtext)
												}}
												>
												<Edit />
												</IconButton>

												<IconButton
												size="small"
												onClick={(e) => {
													delMsg(msgtext)
												}}
												>
												<DeleteOutline />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
									{loading && <TableRowSkeleton columns={3} />}
									</>
								</TableBody>
							</Table>
						</Paper>

						<Formik
							initialValues={CampaignMsg}
							enableReinitialize={true}
							onSubmit={(values, actions) => {
								
								setTimeout(() => {
									handleSaveCampaignMessage(values);
									actions.setSubmitting(false);
								}, 400);
								
							}}
						>
							{({ values, isSubmitting }) => (
							<Form>
								<DialogContent dividers>
							
									<div>
										<Field
											as={TextField}
											label={i18n.t("campaignTextModal.messagebox")}
											type="messageText"
											multiline
											rows={6}
											fullWidth
											name="messageText"
											
											variant="outlined"
											margin="dense"
										/>
										</div>
									
								</DialogContent>
								<DialogActions>
									
									<Button
										type="submit"
										color="primary"
										disabled={isSubmitting}
										variant="contained"
										className={classes.btnWrapper}
									>
										{inEditMessageId!=0
											? i18n.t("whatsappModal.buttons.okEdit")
											: i18n.t("whatsappModal.buttons.okAdd")}
									
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>
								</DialogActions>
							</Form>
							)}
						</Formik>
					</MainContainer>
		
			</div>
		</div>	
	);


};

export default CampaignTextsModal;
