import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SendIcon from "@material-ui/icons/Send";
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	FormControlLabel,
	Hidden,
	Menu,
	MenuItem,
	Switch,
	
  } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
	mainWrapper: {
	  background: "#eee",
	  display: "flex",
	  flexDirection: "column",
	  alignItems: "center",
	  borderTop: "1px solid rgba(0, 0, 0, 0.12)",
	  [theme.breakpoints.down("sm")]: {
		position: "fixed",
		bottom: 0,
		width: "100%",
	  },
	},
  
	newMessageBox: {
	  background: "#eee",
	  width: "100%",
	  display: "flex",
	  padding: "7px",
	  alignItems: "center",
	},
  
	messageInputWrapper: {
	  padding: 6,
	  marginRight: 7,
	  background: "#fff",
	  display: "flex",
	  borderRadius: 20,
	  flex: 1,
	  position: "relative",
	},
  
	messageInput: {
	  paddingLeft: 10,
	  flex: 1,
	  border: "none",
	},
  
	sendMessageIcons: {
	  color: "grey",
	},
  
	uploadInput: {
	  display: "none",
	},
  
	viewMediaInputWrapper: {
	  display: "flex",
	  padding: "10px 13px",
	  position: "relative",
	  justifyContent: "space-between",
	  alignItems: "center",
	  backgroundColor: "#eee",
	  borderTop: "1px solid rgba(0, 0, 0, 0.12)",
	},
  
	emojiBox: {
	  position: "absolute",
	  bottom: 63,
	  width: 40,
	  borderTop: "1px solid #e8e8e8",
	},
  
	circleLoading: {
	  color: green[500],
	  opacity: "70%",
	  position: "absolute",
	  top: "20%",
	  left: "50%",
	  marginLeft: -12,
	},
  
	audioLoading: {
	  color: green[500],
	  opacity: "70%",
	},
  
	recorderWrapper: {
	  display: "flex",
	  alignItems: "center",
	  alignContent: "middle",
	},
  
	cancelAudioIcon: {
	  color: "red",
	},
  
	sendAudioIcon: {
	  color: "green",
	},
  
	replyginMsgWrapper: {
	  display: "flex",
	  width: "100%",
	  alignItems: "center",
	  justifyContent: "center",
	  paddingTop: 8,
	  paddingLeft: 73,
	  paddingRight: 7,
	},
  
	replyginMsgContainer: {
	  flex: 1,
	  marginRight: 5,
	  overflowY: "hidden",
	  backgroundColor: "rgba(0, 0, 0, 0.05)",
	  borderRadius: "7.5px",
	  display: "flex",
	  position: "relative",
	},
  
	replyginMsgBody: {
	  padding: 10,
	  height: "auto",
	  display: "block",
	  whiteSpace: "pre-wrap",
	  overflow: "hidden",
	},
  
	replyginContactMsgSideColor: {
	  flex: "none",
	  width: "4px",
	  backgroundColor: "#35cd96",
	},
  
	replyginSelfMsgSideColor: {
	  flex: "none",
	  width: "4px",
	  backgroundColor: "#6bcbef",
	},
  
	messageContactName: {
	  display: "flex",
	  color: "#6bcbef",
	  fontWeight: 500,
	},
	messageQuickAnswersWrapper: {
	  margin: 0,
	  position: "absolute",
	  bottom: "50px",
	  background: "#ffffff",
	  padding: "2px",
	  border: "1px solid #CCC",
	  left: 0,
	  width: "100%",
	  "& li": {
		listStyle: "none",
		"& a": {
		  display: "block",
		  padding: "8px",
		  textOverflow: "ellipsis",
		  overflow: "hidden",
		  maxHeight: "32px",
		  "&:hover": {
			background: "#F1F1F1",
			cursor: "pointer",
		  },
		},
	  },
	},
	popupbox : {
	  position: "fixed",
	  background: "#00000050",
	  width: "100%",
	  height: "100vh",
	  top: "0",
	  left: "0"
  
	},
	box : {
	  position: "relative" ,
	  width: "20%" ,
	  margin: "0 auto" ,
	  height: "auto" ,
	  maxHeight: "90vh" ,
	  marginTop: "calc(100vh - 85vh - 20px)" ,
	  background: "#fff" ,
	  borderRadius: "4px" ,
	  padding: "20px" ,
	  border: "1px solid #999" ,
	  overflow: "auto" ,
	  zIndex:"1000"
	},
	 
	closeicon : {
	  content: "x" ,
	  cursor: "pointer" ,
	  position: "fixed" ,
	  right: "calc(22%)" ,
	  top: "calc(100vh - 85vh - 33px)" ,
	  background: "#ededed" ,
	  width: "25px" ,
	  height: "25px" ,
	  borderRadius: "50%" ,
	  lineHeight: "20px" ,
	  textAlign: "center" ,
	  border: "1px solid #999" ,
	  fontSize: "20px" ,
	},
  
	comment:{
	  fontSize: "12px",
	  textAlign:"justify",
	  lineHeight :"20px"
	},
	sendMessageIcons: {
	  color: "grey",
	},
	uploadInput: {
	  display: "none",
	},
  }));
  


const TicketImportModal = ({ modalOpen, onClose }) => {
    const classes = useStyles();

	const [displayfn, setDisplayFn] = useState("");
	const [medias, setMedias] = useState([]);
	const history = useHistory();

	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedContact, setSelectedContact] = useState(null);
	const [newContact, setNewContact] = useState({});
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("contacts", {
						params: { searchParam },
					});
					setOptions(data.contacts);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);

	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedContact(null);
	};

	const handleChangeMedias = (e) => {
        if (!e.target.files) {
          return;
        }
    
      const selectedMedias = Array.from(e.target.files);
        setMedias(selectedMedias);
          setDisplayFn(medias[0]?.name);
      };
    
    
      const handleUploadMedia = async (e) => {
        setLoading(true);
        e.preventDefault();
    
        const formData = new FormData();
        
        medias.forEach((media) => {
          formData.append("medias", media);
        });
    
        try {
          await api.post(`/ticketimport`, formData);
          
        } catch (err) {
          toastError(err);
        }
    
        toast.success(i18n.t("contactModal.title.xlssuccess"));
        setLoading(false);
        setMedias([]);
        handleClose();
        
      };
    

	const handleSaveTicket = async contactId => {
		if (!contactId) return;
		setLoading(true);
		try {
			const { data: ticket } = await api.post("/tickets", {
				contactId: contactId,
				userId: user.id,
				status: "open",
			});
			history.push(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err);
		}
		setLoading(false);
		handleClose();
	};


	const renderOption = option => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${i18n.t("newTicketModal.add")} ${option.name}`;
		}
	};

	const renderOptionLabel = option => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${option.name}`;
		}
	};

	return (
		<>
			
			<Dialog open={modalOpen} onClose={handleClose}>
				<DialogTitle id="form-dialog-title">
                {i18n.t("tickets.buttons.importChats")}
				</DialogTitle>
				<DialogContent>
				<Table size="small">
								<TableRow>
									<TableCell align="center">
										<input
											type="file"
											allow=".zip"
											id="upload-button"
											className={classes.uploadInput}
											onChange={handleChangeMedias}
										/>
										<label htmlFor="upload-button">
											<IconButton
												aria-label="upload"
												component="span"
											>
												<AttachFileIcon className={classes.sendMessageIcons} />
											</IconButton>
										</label>	
										
									</TableCell>
									<TableCell align="center">
										<span>
											{displayfn}
											
										</span>
									</TableCell>
									<TableCell align="center">	
										{!loading && (					
											<IconButton
												aria-label="send-upload"
												component="span"
												onClick={handleUploadMedia}
												disabled={loading}
											>
												<SendIcon className={classes.sendMessageIcons} />
												
											</IconButton> 
										)}
										{loading && (
												<CircularProgress color="success"
												size={24}
												className={classes.buttonProgress}
												/>
										)}
									 
									</TableCell>
									
									</TableRow>
					</Table>	
	
					



				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("newTicketModal.buttons.cancel")}
					</Button>
					
                    
				</DialogActions>
			</Dialog>
		</>
	);
};

export default TicketImportModal;
