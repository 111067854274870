import React, { useEffect, useState } from "react";

import {
    Chip,
    makeStyles,
    Paper,
    TextField
} from "@material-ui/core";

import api from "../../services/api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import {blue, purple, red, green, teal, orange, amber} from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
	container: {
        padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
        borderRadius: 0
    },
    chips: {
		display: "flex",
		flexWrap: "wrap",
		'&:hover': {
			filter: 'brightness(120%)',
		},
	},
	chip: {
		margin: 2,
	},
    green1: {
		color: green[500]
	},
  red1: {
		color: red[500]
	},
  blue1: {
		color: blue[500]
	},
  teal1: {
		color: teal[500]
	},
  orange1: {
		color: orange[500]
	},
  purple1: {
		color: purple[500]
	},
  amber1: {
		color: amber[500]
	},
avatar:{
    width: "60px",
    height: "60px",
    borderRadius:"5px"
},
}));


//export function TagsFilterNoPaper ({onFiltered, onChange, items }) {
export function CategoryContainer ({onFiltered, onChange, items}) {



    const classes = useStyles();

    const [tags, setTags] = useState([]);
    const [categories, setCategories] = useState([]);

    const [selecteds, setSelecteds] = useState((items?items:[]));

    useEffect(() => {
        async function fetchData () {
            await loadCategories();
        }
        fetchData();
    }, []);

    const loadCategories = async () => {
        try {
            const { data } = await api.get(`/categories`);
      
            setCategories(data?.categories);
        } catch (err) {
            toastError(err);
        }
    }

    const onCategoryChange = async (value) => {
        setSelecteds(value);

        onFiltered(value);
        onChange(value);
       
    }


    return (
            <Autocomplete
                multiple
                size="small"
                options={categories}
                value={selecteds}
                onChange={(e, v, r) => {
                                onCategoryChange(v)

                        }}
                getOptionLabel={(option) => option.categoryName}
                renderCategories={(value, getCategoryProps) =>
                    value.map((option, index) => (
                                       
                        <Chip
                            style={{
                                
                                borderColor: blue[500],
                                color: blue[800],
                            }}
                            variant="outlined"
                            label={option.category.categoryName}
                            className={classes.chip}
                            {...getCategoryProps({ index })}
                            size="small"
                        />

                      
                    ))
                }
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder={i18n.t("categories.title")} />
                )}

                PaperComponent={({ children }) => (
                    <Paper style={{width: 400, marginLeft: 12}}>
                        {children}
                    </Paper>
                )}
            />
       
    )
}