const rules = {
	user: {
		static: [
			"ticket-options:transferWhatsapp",
			"drawer-items:schedule",
			"schedule-page:add:message",
			"schedule-page:edit:message:self",
			"schedule-page:delete:message:self"
		],
	},

	userplus: {
		static: [
			"ticket-options:transferWhatsapp",
			"drawer-items:schedule",
			"schedule-page:add:message",
			"schedule-page:edit:message:self",
			"schedule-page:delete:message:self"
		],
	},

	superv: {
		static: [
			"drawer-superv-items:view",
			"tickets-manager:closeAll",
			"ticket-options:transferWhatsapp",
			"tickets-manager:showall",
			"user-modal:editProfile",
			"notifications:view",
			"drawer-items:schedule",
			"schedule-page:add:message",
			"schedule-page:edit:message:self",
			"schedule-page:delete:message:self",
			"drawer-items:super"
		],
	},

	manager: {
		static: [
			"drawer-superv-items:view",
			"tickets-manager:closeAll",
			"ticket-options:transferWhatsapp",
			"tickets-manager:showall",
			"user-modal:editProfile",
			"notifications:view",
			"drawer-items:schedule",
			"schedule-page:add:message",
			"schedule-page:edit:message:self",
			"schedule-page:delete:message:self",
			"drawer-items:super"
		],
	},

	admin: {
		static: [
			"tickets-manager:import",
			"tickets-manager:closeAll",
			"drawer-superv-items:view",
			"drawer-admin-items:view",
			"tickets-manager:showall",
			"user-modal:editProfile",
			"user-modal:editQueues",
			"ticket-options:deleteTicket",
			"ticket-options:transferWhatsapp",
			"contacts-page:deleteContact",
			"notifications:view",
			"drawer-items:schedule",
			"schedule-page:add:message",
			"schedule-page:edit:message:self",
			"schedule-page:delete:message:self",
			"drawer-items:super"
		],
	},
};

export default rules;
