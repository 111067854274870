import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import nps0 from "../../assets/nps0.jpg"
import nps1 from "../../assets/nps1.jpg"
import nps2 from "../../assets/nps2.jpg"
import nps3 from "../../assets/nps3.jpg"
import nps4 from "../../assets/nps4.jpg"
import nps5 from "../../assets/nps5.jpg"
import nps6 from "../../assets/nps6.jpg"
import nps7 from "../../assets/nps7.jpg"
import nps8 from "../../assets/nps8.jpg"
import nps9 from "../../assets/nps9.jpg"
import nps10 from "../../assets/nps10.jpg"
import eval1 from "../../assets/eval1.png"
import eval2 from "../../assets/eval2.png"
import eval3 from "../../assets/eval3.png"




import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';

import {
	BarChart,
	CartesianGrid,
	XAxis,
	YAxis,
	Label,
	ResponsiveContainer,
} from "recharts";


import {Line, Doughnut, Chart, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js'


import { makeStyles } from "@material-ui/core/styles"
import { startOfHour, parseISO, format } from "date-fns";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

import Title from "./Title";
import useTickets from "../../hooks/useTickets";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },

    fixedhwdiv:{
        padding: theme.spacing(2),
        display: "flex",
        overflow: "none",
        flexDirection: "column",
        height: 47,
        width: "100%",
        marginBottom: 10

    },

    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 240,
    },
    customFixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 200,
    },
    customFixedHeightPaperLg: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
    },
    avatar:{
        width: "160px",
        height: "160px",
        borderRadius:"80px"
    },
    paperavatar: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
        padding: theme.spacing(2),
        overflow: "auto",
        flexDirection: "column",
        height: 200,
      },
    


}))
const Satisfaction = () => {


    const classes = useStyles()

	const theme = useTheme();

	const date = useRef(new Date().toISOString());


    const [kpiByQueue,setKpiByQueue] = useState([]);
    const [kpiByUser, setKpiByUser] = useState([]);
    const [kpiGlobal, setKpiGlobal] = useState([]);
    const [globalPct, setGlobalPct]= useState(0);
    const [globaSatisfaction, setGlobaSatisfaction]= useState(0);
    const [count, setCount] = useState(0);
    const [delay, setDelay] = useState(3000);

    const [tmout,setTmout] = useState(500);
    
	const { tickets } = useTickets({ date: date.current });
   
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
                
                    const fetchSatisfactionData = async () => {
                            try {
                                    if(kpiGlobal?.totalsum>0){
                                        setTmout=0;
                                    }
                                    
                                    const ret  = await api.get("/appraisal");

                                    console.log("Satisfaction", ret)

                                    if(ret){
                                        setKpiByQueue(ret.data.kpiByQueue[0]);
                                        setKpiByUser(ret.data.kpiByUser[0]);
                                        setKpiGlobal(ret.data.kpiGlobal[0]);
                                        setGlobalPct(ret.data.kpiGlobal[0]?.totalavg);
                                        setGlobaSatisfaction(parseInt((ret.data.kpiGlobal[0]?.totalsum/(ret.data.kpiGlobal[0]?.totalcount*3))*10))
                                    }

                            } catch (err) {
                                toastError(err?.message);
                            }
                    };
                    fetchSatisfactionData();
                    clearTimeout(delayDebounceFn);
               
        }, tmout);
        return () => clearTimeout(delayDebounceFn);
      });


	return (
		<React.Fragment>
            <Grid item xs={6}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
                                <Title>{i18n.t("dashboard.satisfaction.global.title")}</Title>
                                <div style={{width:"100%", justifyContent: "center"}}>
                                    <img src={(globaSatisfaction==0?nps0:globaSatisfaction==1?nps1:globaSatisfaction==2?nps2:globaSatisfaction==3?nps3:globaSatisfaction==4?nps4:globaSatisfaction==5?nps5:globaSatisfaction==6?nps6:globaSatisfaction==7?nps7:globaSatisfaction==8?nps8:globaSatisfaction==9?nps9:nps10)} style={{width:"60%", marginTop:"10px", marginLeft:"80px"}} ></img>                                    
                                </div>                                
						</Paper>
					</Grid>
					<Grid item xs={3}>
						<Paper className={classes.paperavatar} style={{ overflow: "hidden", }}>
                        <Avatar
							className={classes.avatar} style={{backgroundColor:(globaSatisfaction<7?"#880b11":globaSatisfaction>=7 && globaSatisfaction<9?"#ffb232":"#036e2d" ), color:"#ffffff"}}
						>
                            <Typography
                                // style={{ width: "70%", margin: "auto" }} I think you should avoid break tags instead do something with the width
                                variant="h3"
                                color="textPrimary"
                                component="span"
                                style={{fontWeight:900, color:(globalPct>=7 && globalPct<9?"#3a3a3a":"#ffffff") }}
                            >
                                    {((kpiGlobal?.totalsum/(kpiGlobal?.totalcount*3))  * 100).toFixed(1)}%
                            </Typography>
                        </Avatar>
						</Paper>
					</Grid>
                    <Grid item xs={3}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
                            <div className={classes.fixedhwdiv} style={{padding: 5, border: "1px solid #c2c2c2", borderRadius:"4px"}}><img src={eval1} style={{width:35, marginLeft:10}}/><span style={{marginLeft: "101px", fontWeight: 900, fontSize: "15px", marginTop: "-26px", borderRadius:"4px"}}>{kpiGlobal.LVLc1} detratores</span></div>
                            <div className={classes.fixedhwdiv} style={{padding: 5, border: "1px solid #c2c2c2", borderRadius:"4px"}}><img src={eval2} style={{width:35, marginLeft:10}}/><span style={{marginLeft: "101px", fontWeight: 900, fontSize: "15px", marginTop: "-26px", borderRadius:"4px"}}>{kpiGlobal.LVLc2} indiferentes</span></div>
                            <div className={classes.fixedhwdiv} style={{padding: 5, border: "1px solid #c2c2c2", borderRadius:"4px"}}><img src={eval3} style={{width:35, marginLeft:10}}/><span style={{marginLeft: "101px", fontWeight: 900, fontSize: "15px", marginTop: "-26px", borderRadius:"4px"}}>{kpiGlobal.LVLc3} promotores</span></div>
                        </Paper>
					</Grid>
					
		</React.Fragment>
	);
};

export default Satisfaction;

