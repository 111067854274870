import React, { useState, useEffect, useRef, useReducer } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";

import { TagsContainer } from "../TagsContainer";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
  } from '@material-ui/core';



const useStyles = makeStyles(theme => ({

	root: {
		[theme.breakpoints.down("sm")]: {
			width: "75%",
			flexWrap: "wrap",
		},
		[theme.breakpoints.up("sm")]: {
			maxWidth: "75%",
			flexWrap: "wrap",
		},
	  },
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));


const ContactSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email"),
});

const DisclaimerModal = ({ open, onClose, contactId, initialValues, onSave }) => {
	const classes = useStyles();
	const isMounted = useRef(true);

	
	

	const handleClose = () => {
		onClose();

	};



	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
				<DialogTitle id="form-dialog-title">
					{"Condições de Uso"}
				</DialogTitle>
				

                <DialogContent>

								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
									{`Limitação de responsabilidade`}
								</Typography>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>

{`1. A SUCESSO.GLOBAL, desenvolvedora dos produtos mware Communicator, mware Biznergy e mware Atendosim isenta-se expressamente de quaisquer responsabilidades e indenizações, perdas e danos, lucros cessantes, prejuízos de quaisquer espécies, sob quaisquer títulos, perdas de negócios, perda ou extravio de dados, defeitos de computador, equipamentos ou periféricos, ou quaisquer outros danos diretos, indiretos, acidentais, especiais, consequenciais ou punitivos, decorrentes direta ou indiretamente, da aquisição, licenciamento e/ou utilização do programa, causados a você, Contratante, como usuário, ou a terceiros. `}
</Typography>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
{`2 As atualizações do WhatsApp, Facebook e demais redes sociais abrangidas, não são de responsabilidade e domínio da SUCESSO.GLOBAL e, por questões sistêmicas podem gerar instabilidades e até desconexões e banimentos de número ou números. Dessa forma, fica estabelecido que esse tipo de problema é de gênero não controlável e sem a menor responsabilidade por parte da Sucesso.Global. Ainda se entende que, em caso de finalização das atividades do WhatsApp, do Facebook e das demais redes sociais abrangidas, o sistema contratado para esse fim ficará indisponível e ambas partes envolvidas, CONTRATANTE e CONTRATADA, não terão mais responsabilidades uma com a outra.`}
</Typography>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
{`3 A SUCESSO.GLOBAL deixa igualmente estabelecido que as plataformas mware biznergy, communicator e AtendoSim não tem relação direta com os serviços do aplicativo WhatsApp, Facebook, Instagram, Telegram, Skype e WeChat. por esta razão, acredita ser imprescindível que a CONTRATANTE, como usuário, faça a leitura dos Termos de Uso, Termos de Serviço e Política de Privacidade destes aplicativos. `}
</Typography>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
{`Proteção de Dados Pessoais`}
</Typography>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
{`1 Considerando a Lei Geral de Proteção de Dados (Lei n. 13.709/18), você, CONTRATANTE1, se obriga na observância e cumprimento das regras quanto a proteção de dados, quando existir o tratamento de dados pessoais, mediante aditivos e termos específicos, de acordo com a necessidade e/ou obrigação legal de coleta dos dados.`}
</Typography>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
{`2 A CONTRATADA executará os trabalhos a partir das premissas da Lei Geral de Proteção de Dados (Lei n. 13.709/18), quando necessário, em especial os princípios da finalidade, adequação, transparência, livre acesso, segurança, prevenção e não discriminação no tratamento dos dados, incluindo a aplicação destes aos dados dos colaboradores da CONTRATANTE.`} 
</Typography>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
{`3 A CONTRATADA não fornece, em caso algum, listas de contatos, de telefones ou outros meios de comunicação. Todos os dados que trafegam na plataforma devem ser coletados pela CONTRATANTE, que deve igualmente obter as autorizações necessárias ao seu legítimo uso.`}
</Typography>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
{`4 A CONTRATANTE é a única entidade responsável pelos dados que trafegam na sua instância particular do sistema, sejam estes originários da CONTRATANTE ou nos casos em que a CONTRATANTE é a recetora desses dados. A CONTRATADA apenas disponibiliza a infraestrutura onde esses dados trafegam, ou seja, atua apenas como operadora de dados, sem qualquer responsabilidade objetiva pré-determinada. `}
</Typography>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
{`5 Em caso de necessidade de coleta de dados pessoais indispensáveis à própria prestação do serviço, responsabiliza-se a CONTRATANTE por obter o consentimento dos titulares (salvo nos casos em que opere outra hipótese legal de tratamento). Os dados assim coletados só poderão ser utilizados na execução dos serviços especificamente acordados, e em hipótese alguma poderão ser compartilhados ou utilizados para outros fins; `}
</Typography>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
{`6. A CONTRATANTE dará conhecimento formal aos seus empregados das obrigações e condições acordadas nesta subcláusula, inclusive no tocante à Política de Privacidade da CONTRATADA, cujos princípios deverão ser aplicados à coleta e tratamento dos dados pessoais de que trata a presente cláusula.`}
</Typography>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
{`7. A CONTRATANTE cooperará com a CONTRATADA no cumprimento das obrigações referentes ao exercício dos direitos dos Titulares previstos na LGPD e nas Leis e Regulamentos de Proteção de Dados em vigor e também no atendimento de requisições e determinações do Poder Judiciário, Ministério Público e órgãos de controle administrativo;`}
</Typography>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
{`8. A CONTRATANTE deverá informar imediatamente a CONTRATADA quando receber uma solicitação de um Titular de Dados, a respeito dos seus Dados Pessoais, conforme exigido pela LGPD e Leis e Regulamentos de Proteção de Dados em vigor, a responsabilidade dos agentes de tratamento deverá ser delimitada por cada caso, conforme autoridade responsável.`}
</Typography>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
{`9. O responsável pela proteção de dados da CONTRATANTE manterá contato formal com o responsável pela proteção de dados da CONTRATADA, no prazo de 24 (vinte e quatro) horas da ocorrência de qualquer incidente que implique violação ou risco de violação de dados pessoais, para que este possa adotar as providências devidas, na hipótese de questionamento das autoridades competentes.`}
								</Typography>
								
						
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									variant="outlined"
								>
									{"FECHAR"}
								</Button>
								
							</DialogActions>
						
			</Dialog>
		</div>
	);
};

export default DisclaimerModal;
