import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "../../services/socket-io";
import { toast } from "react-toastify";
import ImageIcon from '@material-ui/icons/Image';
import {blue, purple, red, green, teal, orange, amber} from '@material-ui/core/colors';

import {
  Avatar,
  Box,
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import {
  CheckCircleOutlined,
  ErrorOutline,
  ScheduleOutlined,
  DeleteOutline,
  EditOutlined,
  Search,
  WhatsApp
} from "@material-ui/icons";

import { Can } from "../../components/Can";
import ConfirmationModal from "../../components/ConfirmationModal/";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MarkdownWrapper from "../../components/MarkdownWrapper";
import ModalImageCors from "../../components/ModalImageCors";
import ScheduledMessagesModal from "../../components/ScheduledMessagesModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";

import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  blue1: {
		color: blue[500]
	},

  green1: {
		color: green[500]
	},

  red1: {
		color: red[500]
	},

  orange1: {
		color: orange[500]
	},

  


  minWidthColumns: {
    display: "flex",
    alignItems: "center",
  },
  searchMobile: {
    width: "100%",
    padding: "0 8px 16px",
  },
  formControl: {
    marginRight: "16px",
  },
  imagePreview: {
    maxHeight: "40px!important",
    maxWidth: "40px!important",
  },
  contactName: {
    paddingLeft: theme.spacing(2)
  },
  actionButtons: {
    border: "1px solid",
    marginLeft: theme.spacing(1),
  },
	buttonColorError: {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main,
	},
  link: {
    cursor: "pointer" 
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  status: {
    display: "flex",
    justifyContent: "center"
  },
  scheduledMessage: {
    // maxWidth: "100%"
  },
  actionsCell: {
    width: "10%",
    maxWidth: 150,
    minWidth: 120
  },
  preventWrap: {
    minWidth: 120
  },
  preventWrapName: {
    minWidth: 250
  }
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_SCHEDULEDMESSAGES") {
    const scheduledMessages = action.payload;
    const newScheduledMessages = [];
    
    //const filteredMessages = scheduledMessages.filter((M) => queueIds.indexOf(t.queueId) > -1)

    scheduledMessages.forEach((scheduledMessage) => {

      if(scheduledMessage?.contact.name.substring(0, action.filter.length).toLowerCase() == action.filter.toLowerCase()){
              const scheduledMessageIndex = state.findIndex((m) => m.id === scheduledMessage.id);
              if (scheduledMessageIndex !== -1) {
                state[scheduledMessageIndex] = scheduledMessage;
              } else {
                newScheduledMessages.push(scheduledMessage);
              }
      }

    });

    return [...state, ...newScheduledMessages];
  }

  if (action.type === "UPDATE_SCHEDULEDMESSAGES") {
    const scheduledMessage = action.payload;
    const scheduledMessageIndex = state.findIndex((m) => m.id === scheduledMessage.id);

    if (scheduledMessageIndex !== -1) {
      state[scheduledMessageIndex] = scheduledMessage;
      return [...state];
    } else {
      return [scheduledMessage, ...state];
    }
  }

  if (action.type === "DELETE_SCHEDULEDMESSAGES") {
    const scheduledMessageId = action.payload;

    const scheduledMessageIndex = state.findIndex((m) => m.id === scheduledMessageId);
    if (scheduledMessageIndex !== -1) {
      state.splice(scheduledMessageIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};


const ScheduledMessages = () => {
    const classes = useStyles();
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [filterParam, setFilterParam] = useState("");
    const [scheduledMessages, dispatch] = useReducer(reducer, []);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [selectedMessageId, setSelectedMessageId] = useState(null);
    const [deletingScheduledMessage, setDeletingScheduledMessage] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [scheduledMessagesModalOpen, setScheduledMessagesModalOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
      dispatch({ type: "RESET" });
      setPageNumber(1);
    }, [filterParam]);

    useEffect(() => {
      setLoading(true);
      const delayDebounceFn = setTimeout(() => {
        const fetchScheduledMessage = async () => {
          try {
            const { data } = await api.get("/scheduledMessages/", {
              params: { searchParam, pageNumber },
            });

            console.log("payload:", data.scheduledMessage)
            dispatch({ type: "LOAD_SCHEDULEDMESSAGES", payload: data.scheduledMessage, filter:filterParam });
            setHasMore(data.hasMore);
            setLoading(false);
          } catch (err) {
            toastError(err);
          }
        };
        fetchScheduledMessage();
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }, [filterParam, searchParam, pageNumber]);

    useEffect(() => {
      const socket = openSocket();
      socket.on("scheduledMessage", (data) => {

        if (data.action === "update" || data.action === "create") {
          dispatch({ type: "UPDATE_SCHEDULEDMESSAGES", payload: data.scheduledMessage });
        }

        if (data.action === "delete") {
          dispatch({ type: "DELETE_SCHEDULEDMESSAGES", payload: +data.scheduledMessageId });
        }
      });

      return () => {
        socket.disconnect();
      };
    }, []);

    const handleSearch = (event) => {
      //setSearchParam(event.target.value);
      setFilterParam(event.target.value);
    };

    const handleOpenScheduledMessagesModal = () => {
      setSelectedMessageId(null);
      setScheduledMessagesModalOpen(true);
    };

    const handleCloseScheduledMessagesModal = () => {
      setSelectedMessageId(null);
      setSelectedContactId(null);
      setScheduledMessagesModalOpen(false);
    };

    const handleSaveTicket = async (contactId) => {
      if (!contactId) return;
      setLoading(true);
      try {
        const { data: ticket } = await api.post("/tickets", {
          contactId: contactId,
          userId: user?.id,
          status: "open",
        });
        history.push(`/tickets/${ticket.id}`);
      } catch (err) {
        toastError(err);
      }
      setLoading(false);
    };

    const handleEditScheduledMessagesModal = MessageId => {
      setSelectedMessageId(MessageId);
      setScheduledMessagesModalOpen(true);
    };

    const handleDeleteScheduledMessage = async (scheduledMessageId, status) => {
      try {
        await api.delete(`/scheduledMessages/${scheduledMessageId}`);
        toast.success(i18n.t("scheduledMessages.toasts.deleted"));
      } catch (err) {
        toastError(err);
      }
      setDeletingScheduledMessage(null);
      setSearchParam("");
      setPageNumber(1);
    };

    const loadMore = () => {
      setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
      if (!hasMore || loading) return;
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (scrollHeight - (scrollTop + 100) < clientHeight) {
        loadMore();
      }
    };


    
  const renderStatusToolTips = scheduledMessage => {
		return (
			<div className={classes.customTableCell}>
				{scheduledMessage.status === "sent" && (
						<div className={classes.status}>
              <CheckCircleOutlined fontSize="small" className={classes.marginRight} style={{color:green[500]}}/> {"Enviado"}
          </div>
				)}
        {scheduledMessage.status ===  "pending" && (
						<div className={classes.status}>
            <ScheduleOutlined fontSize="small" className={classes.marginRight} style={{color:blue[500]}}/> {"Pendente"}
        </div>
				)}
        {scheduledMessage.status===  "error" && (
						<div className={classes.status}>
            <ErrorOutline fontSize="small" className={classes.marginRight} style={{color:red[500]}}/> {"Com Erro"}
        </div>
				)}
        {scheduledMessage.status.length > 7 && (
						<div className={classes.status}>
            <CheckCircleOutlined fontSize="small" className={classes.marginRight} style={{color:green[500]}}/> {scheduledMessage.status}
        </div>
				)}


			</div>
		);
	};
  
    return (
      <MainContainer className={classes.mainContainer}>
        <ScheduledMessagesModal
          user={user}
          open={scheduledMessagesModalOpen}
          onClose={handleCloseScheduledMessagesModal}
          messageId={selectedMessageId}
          contactId={selectedContactId}
        />
        <ConfirmationModal
          title={
            deletingScheduledMessage
              ? i18n.t("scheduledMessages.confirmationModal.delete.title")
              : null
          }
          open={confirmOpen}
          onClose={setConfirmOpen}
          onConfirm={(e) =>
            deletingScheduledMessage
              ? handleDeleteScheduledMessage(deletingScheduledMessage.id)
              : null
          }
        >
          {deletingScheduledMessage
            ? i18n.t("scheduledMessages.confirmationModal.delete.message")
            : null
          }
        </ConfirmationModal>
  
        <MainHeader>
          <Title>{i18n.t("scheduledMessages.title")}</Title>
          <MainHeaderButtonsWrapper>
            <Box display={{ xs: "none", sm: "inline-flex" }}>
              <TextField
                className={classes.formControl}
                placeholder={i18n.t("variables.placeholders.search")}
                type="search"
                value={filterParam}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search style={{ color: "gray" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <Can
                role={user.profile}
                perform="schedule-page:add:message"
                yes={() => (
                  <Button
                    className={classes.formControl}
                    variant="contained"
                    color="primary"
                    onClick={handleOpenScheduledMessagesModal}
                  >
                    {i18n.t("scheduledMessages.buttons.add")}
                </Button>
              )}/>
            </Box>
            <Box display={{ xs: "inline-flex", sm: "none" }}>
              <Can
                role={user.profile}
                perform="schedule-page:add:message"
                yes={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenScheduledMessagesModal}
                  >
                    {i18n.t("scheduledMessages.buttons.add")}
                </Button>
              )}/>
            </Box>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        
        <Box display={{ xs: "block", sm: "none" }}>
          <TextField
            className={classes.searchMobile}
            placeholder={i18n.t("variables.placeholders.search")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Paper
          className={classes.mainPaper}
          variant="outlined"
          onScroll={handleScroll}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
              <TableCell align="left" className={classes.preventWrapName}>{i18n.t("variables.table.contact")}</TableCell>
                <TableCell align="left">{i18n.t("variables.table.message")}</TableCell>
                <TableCell align="center">{i18n.t("variables.table.attachment.title")}</TableCell>
                <TableCell align="center" className={classes.preventWrap}>{i18n.t("variables.table.sentBy")}</TableCell>
                <TableCell align="center">{i18n.t("variables.table.createdAt")}</TableCell>
                <TableCell align="center">{i18n.t("variables.table.status.title")}</TableCell>
                <TableCell align="center">{i18n.t("variables.table.dateTime")}</TableCell>
                <TableCell align="center" className={classes.actionsCell}>{i18n.t("variables.table.actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {scheduledMessages.map((scheduledMessage) => (
                  <TableRow key={scheduledMessage.id}>
                    <TableCell align="left">
                      <div className={classes.minWidthColumns} >
                        {<Avatar src={scheduledMessage?.contact?.profilePicUrl} alt={scheduledMessage?.contact?.name} variant="rounded"/>}
                        <Typography className={classes.contactName}>
                          {scheduledMessage?.contact?.name}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        className={classes.scheduledMessage}
                        variant="body2"
                        // noWrap
                      >
                        <MarkdownWrapper>
                          {scheduledMessage.message}
                        </MarkdownWrapper>
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {scheduledMessage.imagePath ? (
                        <ImageIcon  className={classes.blue1} />
                      ) : (
                        i18n.t("variables.table.attachment.none")
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {scheduledMessage?.user?.name}
                    </TableCell>
                    <TableCell align="center">
                      {new Date(scheduledMessage.createdAt).toLocaleString(i18n.t("validation.locale"), { dateStyle: 'short', timeStyle: 'short' })}
                    </TableCell>
                    <TableCell align="center">
                      {renderStatusToolTips(scheduledMessage)}
                    </TableCell>
                    <TableCell align="center">
                      {new Date(scheduledMessage.sendAt).toLocaleString(i18n.t("validation.locale"), { dateStyle: 'short', timeStyle: 'short' })}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        className={classes.actionButtons}
                        onClick={() => handleSaveTicket(scheduledMessage.contactId)}
                        color="primary"
                      >
                        <WhatsApp />
                      </IconButton>
                      {scheduledMessage.status === "pending" || scheduledMessage.status === "error" ? (
                        <Can
                          role={user.profile}
                          perform="schedule-page:edit:message:self"
                          yes={() => (
                            <IconButton
                              size="small"
                              className={classes.actionButtons}
                              color="primary"
                              onClick={() => {
                                handleEditScheduledMessagesModal(scheduledMessage.id);
                                setSelectedContactId(scheduledMessage.contactId)
                              }}
                            >
                              <EditOutlined/>
                            </IconButton>
                          )}
                        />
                      ) : ("")}
                      {scheduledMessage.status === "sent" || scheduledMessage.status === "error" || scheduledMessage.status === "pending" || scheduledMessage.status.length > 7  ? (
                        <Can
                          role={user.profile}
                          perform="schedule-page:delete:message:self"
                          yes={() => (
                            <IconButton
                              title={i18n.t("validation.buttons.delete")}
                              size="small"
                              className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                              onClick={e => {
                                setConfirmOpen(true);
                                setDeletingScheduledMessage(scheduledMessage)
                              }}
                            >
                              <DeleteOutline />
                            </IconButton>
                          )}
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton avatar columns={6} />}
              </>
            </TableBody>
          </Table>
        </Paper>
      </MainContainer>
    );
  };
  
  export default ScheduledMessages;