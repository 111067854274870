import React from 'react';

import {
    MenuItem,
    IconButton
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";
import StatusTooltip from '../StatusTooltip';
import {blue, purple, red, green, teal, orange, amber} from '@material-ui/core/colors';

import AcUnitIcon from '@material-ui/icons/AcUnit';
import AutorenewIcon from '@material-ui/icons/Autorenew';

const useStyles = makeStyles(theme => ({
  connectInfoItem: {
	  justifyContent: "space-between",
	  width: "100%",
  },
  connectInfoItemSelected: {
    justifyContent: "space-between",
    width: "100%",
    weight:900
},
  connectionInfo: {
	  alignItems: "center",
	  display: "flex",
	  float: "right",
	  marginTop: "-5px",
	  marginBottom: "-4px",
  },
  connectionInfoSpan: {
	  opacity: "70%",
  },
  red1: {
        color: red[500]
    },
    green1: {
        color: green[500]
    },

    blue1: {
        color: blue[500]
    },
    teal1: {
        color: teal[500]
    },
    orange1: {
        color: orange[500]
    },
    purple1: {
        color: purple[500]
    },
    amber1: {
        color: amber[500]
}
}))

const BizflowItems = (bizflows, bizflowId) => {
    const classes = useStyles();

    console.log("bizflowId", bizflowId)

    const renderActiveToolTips = bizflow => {
        return (
            <div className={classes.customTableCell}>

                {bizflow?.isActive== 1 && (
                    <IconButton aria-label="active" component="span" >
                        <AutorenewIcon  className={classes.green1} />
                    </IconButton>
                )}

                {bizflow?.isActive== 0 && (                 
                    <IconButton aria-label="inactive" component="span" >
                        <AcUnitIcon  className={classes.blue1} />
                    </IconButton>       
                )}

            </div>
        );
    };
    
    return (
       
        bizflows.map((bizflow) => (
            
            <MenuItem key={bizflow.id} value={bizflow.id} className={classes.connectInfoItem} selected={bizflow.id == bizflowId?true:false}>
                {bizflow.name}
                
                <div className={classes.connectionInfo}>
                    <div className={classes.connectionInfoSpan}>
                        {i18n.t("userModal.form.connection.status")}
                    </div>
                    <div>
                        {renderActiveToolTips(bizflow)}
                    </div>
           
                </div>
            </MenuItem>
        ))
    );
};

export default BizflowItems;