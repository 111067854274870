import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import LockIcon from '@material-ui/icons/Lock';
import BugReportIcon from '@material-ui/icons/BugReport';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import PublicIcon from '@material-ui/icons/Public';
import Grid from '@material-ui/core/Grid';


import {
	Tooltip,
	Typography
} from "@material-ui/core";

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import {blue, purple, red, green, teal, orange, amber} from '@material-ui/core/colors';

import PersonIcon from '@material-ui/icons/Person';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ImageIcon from '@material-ui/icons/Image';



import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import AssessmentIcon from '@material-ui/icons/Assessment';

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import UserModal from "../../components/UserModal";
import CampaignModal from "../../components/CampaignModal"

import ConfirmationModal from "../../components/ConfirmationModal";
import CampaignStatsModal from "../../components/CampaignStatsModal";
import CampaignPlanModal from "../../components/CampaignPlanModal";
import CampaignTextsModal from "../../components/CampaignTextsModal";
import { TagsDisplayForCampaigns } from "../../components/TagsDisplayForCampaigns";

import toastError from "../../errors/toastError";
import { format, parseISO, parse, addMinutes, subMinutes } from "date-fns";
import { PinDropSharp } from "@material-ui/icons";

const reducer = (state, action) => {
  
  if (action.type === "LOAD_CAMPAIGN") {
    const campaigns = action.payload;
    const newcampaigns = [];
    
    campaigns.forEach((campaign) => {
      const campaignIndex = state.findIndex((u) => u.id === campaign.id);
      if (campaignIndex !== -1) {
        if(typeof campaign.nameFilter !== 'object'){
            campaign.nameFilter=JSON.parse(campaign.nameFilter)
        }
        
        state[campaignIndex] = campaign;
      } else {
        newcampaigns.push(campaign);
      }
      
    });
    //console.log(newcampaigns);

    console.log("reducer",...state, ...newcampaigns)
    return [...state, ...newcampaigns];
  }
/*
  if (action.type === "UPDATE_USERS") {
    const campaign = action.payload;
    const userIndex = state.findIndex((u) => u.id === campaign.id);

    if (userIndex !== -1) {
      state[userIndex] = campaign;
      return [...state];
    } else {
      return [campaign, ...state];
    }
  }
*/
  if (action.type === "DELETE_CAMPAIGN") {
    const campaignId = action.payload;

    const campaignIndex = state.findIndex((c) => c.id === campaignId);
    if (campaignIndex !== -1) {
      state.splice(campaignIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
  green1: {
		color: green[500]
	},
  red1: {
		color: red[500]
	},

  blue1: {
		color: blue[500]
	},
  teal1: {
		color: teal[500]
	},
  orange1: {
		color: orange[500]
	},
  purple1: {
		color: purple[500]
	},
  amber1: {
		color: amber[500]
	},
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },

  
}));

const Campaign = () => {
  const classes = useStyles();

  const [isKPIOpen, setIsKPIOpen] = useState(false);
  const [isMSGOpen, setIsMSGOpen] = useState(false);
  const [campaignNumber, setCampaignNumber] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignModalOpen, setCampaignModalOpen] = useState(false);
  const [selectedCampaignPlan, setSelectedCampaignPlan] = useState(null);
  const [campaignPlanModalOpen, setCampaignPlanModalOpen] = useState(false);
 
 
  const [deletingCampaign, setDeletingCampaign] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [campaigns, dispatch] = useReducer(reducer, []);

  const [credits, setCredits] = useState([]);
  const [mktCredit,setMktCredit] = useState(0);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchCampaign = async () => {
        try {
          const { data } = await api.get("/campaign/", {
            params: { searchParam, pageNumber },
          });
          console.log("Data",data)
          dispatch({ type: "LOAD_CAMPAIGN", payload: data.campaign });
          setCredits(data.credits)
          setMktCredit(data.balance)
          

          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchCampaign();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("campaigns", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_USERS", payload: data.user });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CAMPIGN", payload: +data.userId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const toggleKPIPopup = (campaign) => {
    setIsKPIOpen(!isKPIOpen);
    setCampaignNumber(campaign.id)
  }

  const toggleMSGPopup = (campaign) => {
    setIsMSGOpen(!isMSGOpen);
    setCampaignNumber(campaign.id)
  }
  
  const handleChangeCampaignStatus = async (campaign) => {
    setLoading(true);

    campaign.status=!campaign.status;
    campaign.nameFilter=JSON.stringify(campaign.nameFilter);
    console.log("campaign", campaign)
    let payload=campaign;
    
    try {
      const { data } = await api.post(`/campaign/${campaign.id}`, campaign);
  
     
      dispatch({ type: "LOAD_CAMPAIGN", payload: data.campaign });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  
  }

  const handleDeleteCampaign = async () => {
    setLoading(true);
    let campaign=deletingCampaign;
    let payload={
      id : ""
    }

    payload.id=campaign.id;
    try {
      const { data } = await api.delete(`/campaign/${campaign.id}`, payload);
  
      //console.log("Data",data)
      dispatch({ type: "DELETE_CAMPAIGN", payload: campaign.id });
      dispatch({ type: "LOAD_CAMPAIGN", payload: data.campaign });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  
  }

  function TimezoneOffset(date) {
    //const offset = date.getTimezoneOffset(); 
    //return format(Math.sign(offset) !== -1 ? addMinutes(date, offset) : subMinutes(date, Math.abs(offset)), "yyyy-MM-dd HH:mm");
    return format(date, "yyyy-MM-dd HH:mm");
}
  //novo
  const handleOpenCampaignModal = () => {
    setSelectedCampaign(null);
    setCampaignModalOpen(true);
  };
  
  const handleCloseCampaignModal = () => {
    setSelectedCampaign(null);
    setCampaignModalOpen(false);
    setCampaignPlanModalOpen(false);

    const fetchCampaign = async () => {
      try {
        const { data } = await api.get("/campaign/", {
          params: { searchParam, pageNumber },
        });
        console.log("Campaign Data", data)
        dispatch({ type: "LOAD_CAMPAIGN", payload: data.campaign });
        setHasMore(data.hasMore);
        setLoading(false);
      } catch (err) {
        toastError(err);
      }
    };
    fetchCampaign();
  };

  const handleOpenCampaignPlanModal = () => {
    setSelectedCampaignPlan(null);
    setCampaignPlanModalOpen(true);
  };

  
  //edita
  const handleEditCampaign = (campaign) => {
    setSelectedCampaign(campaign);
    setCampaignModalOpen(true);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };
 
  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };




  const renderisGroupToolTips = campaign => {
		return (
			<div className={classes.customTableCell}>
				{campaign.isGroup == 1 && (
         <PeopleAltIcon className={classes.teal1} />
				)}
        {campaign.isGroup == 0 && (		
          <PersonIcon className={classes.blue1} />	
				)}
        {campaign.isGroup ==2 && (		
          <PersonIcon className={classes.amber1}/>	
				)}
        {campaign.isGroup ==3 && (		
          <PublicIcon className={classes.green1}/>		
				)}
        {campaign.isGroup ==4 && (		
          <NewReleasesIcon className={classes.orange1}/>	
				)}
			</div>
		);
	};


  const renderStatusToolTips = campaign => {
		return (
			<div className={classes.customTableCell}>
				{campaign.status === true && (
						<CheckCircleOutlineIcon  className={classes.green1} />
				)}
        {campaign.status === false && (
						<CancelIcon  className={classes.red1} />
				)}
        {campaign.status === 3 && (
						<LockIcon  className={classes.amber1} />
				)}
			</div>
		);
	};

  const renderProcessingToolTips = campaign => {
		return (
			<div className={classes.customTableCell}>
				{campaign.processingStatus === true &&campaign.status !== 3 && (
						<DirectionsRunIcon  className={classes.orange1} />
				)}
        {campaign.processingStatus === false && campaign.status !== 3 && (
						<AccessibilityNewIcon  className={classes.purple1} />
				)}
        {campaign.status === 3 && (
						<EmojiEventsIcon  className={classes.amber1} />
				)}
			</div>
		);
	};


  const renderNbrImages = campaign => {

        let nbrPix;
        if(campaign.image===null){
            nbrPix=0;
        }else if(campaign.image.includes("|")){
          const myArray = campaign.image.split("|");
          nbrPix = myArray.length;
        }else{
            nbrPix = 1;
        }

        return (
          <div className={classes.customTableCell}>     
                {nbrPix} x  <ImageIcon  className={classes.blue1} />
          </div>
        );
	};

  const CustomToolTip = ({ title, content, children }) => {
    const classes = useStyles();
  
    return (
      <Tooltip
        arrow
        classes={{
          tooltip: classes.tooltip,
          popper: classes.tooltipPopper,
        }}
        title={
          <React.Fragment>
            <Typography gutterBottom color="inherit">
              {title}
            </Typography>
            {content && <Typography>{content}</Typography>}
          </React.Fragment>
        }
      >
        {children}
      </Tooltip>
    );
  };


  return (
    <MainContainer>
      <ConfirmationModal title={
          deletingCampaign &&
          `${i18n.t("campaign.confirmationModal.deleteTitle")} ${
            deletingCampaign.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        /*
        onConfirm={(e) => {
          handleDeleteCampaign()
        }}
        */
      >
        {i18n.t("campaign.confirmationModal.deleteMessage")}
      </ConfirmationModal>

      <MainHeader>
        <Title>{i18n.t("campaign.title")}</Title>
  

        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("campaign.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
         <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCampaignPlanModal}
          >
            {i18n.t("campaign.buttons.addcampaignplan")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCampaignModal}
          >
            {i18n.t("campaign.buttons.add")}
          </Button>





        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        {isKPIOpen && <CampaignStatsModal
          id={campaignNumber}
          handleClose={toggleKPIPopup}
        />}

        {isMSGOpen && <CampaignTextsModal
          id={campaignNumber}
          handleClose={toggleMSGPopup}
        />}


        {campaignPlanModalOpen && <CampaignPlanModal 
          open={campaignPlanModalOpen}
          onClose={handleCloseCampaignModal}
          initialCampaign={selectedCampaignPlan}
          aria-labelledby="form-dialog-title"
          selectedId={selectedCampaignPlan && selectedCampaignPlan.id}
        /> }

        {campaignModalOpen && <CampaignModal 
          open={campaignModalOpen}
          onClose={handleCloseCampaignModal}
          initialCampaign={selectedCampaign}
          aria-labelledby="form-dialog-title"
          selectedId={selectedCampaign && selectedCampaign.id}
        /> }



        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Ref:</TableCell>
              <TableCell align="center">{i18n.t("campaign.table.planname")}</TableCell>
              <TableCell align="center">{i18n.t("campaign.table.name")}</TableCell>
              <TableCell align="center">{i18n.t("campaign.table.status")}</TableCell>
              <TableCell align="center">{i18n.t("campaign.table.to")}</TableCell>
              <TableCell align="center">{i18n.t("campaign.table.processing")}</TableCell>
              <TableCell align="center">{i18n.t("campaign.table.sendfrom")}</TableCell>
              <TableCell align="center">{i18n.t("campaign.table.image")}</TableCell>   
              <TableCell align="center">{i18n.t("campaign.table.channels")}</TableCell>  
              <TableCell align="center">{i18n.t("campaign.table.tags")}</TableCell>  
              <TableCell align="center">{i18n.t("campaign.table.action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {campaigns?.length > 0 && campaigns.map(campaign => (

                //        {(campaign.campaignType=="broadcast"?"Disparo  de " + cbaTime(campaign.name.replace("Disparo ","")):campaign.name)}
                <TableRow key={campaign.id}>
                  <TableCell align="center">{campaign.id}</TableCell>
                  <TableCell align="center">{campaign?.campaignPlan?.planName}</TableCell>
                  <TableCell align="center">{(campaign.name)}</TableCell>
                  <TableCell align="center" >
                              {(campaign.status===3?"Executada":(campaign.status===true?"Ativa":"Inativa"))}
                              {renderStatusToolTips(campaign)}
                  </TableCell>
                  <TableCell align="center">{renderisGroupToolTips(campaign)}</TableCell> 
                  <TableCell align="center">
                    {(campaign.processingStatus===3?"Total":(campaign.processingStatus===true?"Sim":"Não"))}
                    {renderProcessingToolTips(campaign)}</TableCell>
                  <TableCell align="center">
                              {TimezoneOffset(new Date(campaign.sendFrom))}
                  </TableCell>
                  <TableCell align="center">{renderNbrImages(campaign)}</TableCell>
                  <TableCell align="center">{campaign.numberFilter}</TableCell>
                  <TableCell align="center">                            
                        <TagsDisplayForCampaigns campaign={campaign} displayOnly={true}/>
                  </TableCell>
                




                  <TableCell align="center">
                  <IconButton
                      
                      size="small"
                      onClick={(e) => {
                        handleChangeCampaignStatus(campaign)
                      }}>
                
                    {campaign.status==1 && <CancelIcon  disabled={!loading} />}
                    {campaign.status==0 && <CheckCircleOutlineIcon  disabled={!loading} />}

                  </IconButton>                   
                  
                  <IconButton
                      size="small"
                      onClick={(e) => {
                          toggleKPIPopup(campaign)
                      }}
                    >
                      <AssessmentIcon />

                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => handleEditCampaign(campaign)}
                    >
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingCampaign(campaign);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>



                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={8} />}
            </>
          </TableBody>
        </Table>

      </Paper>
    </MainContainer>
  );
};

export default Campaign;

const cbaTime = date => new Date(date).toLocaleString('pt-BR', { timeZone: 'America/Cuiaba' });
