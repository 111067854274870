import React, { useState, useEffect, useContext, useRef } from "react";
import "emoji-mart/css/emoji-mart.css";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { formatInTimeZone } from 'date-fns-tz'
import { getTimezoneOffset } from 'date-fns-tz'

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title"
import SendIcon from "@material-ui/icons/Send";
import { format, parseISO, parse, isValid} from "date-fns";
 
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import {
	Select,
	InputLabel,
	FormControl,
	InputAdornment,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	FormControlLabel,
	Hidden,
	Menu,
	MenuItem,
	Switch,
  } from "@material-ui/core";



import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import 'react-modern-calendar-datepicker/lib/DatePicker.css';

const useStyles = makeStyles((theme) => ({
  popupbox : {
	position: "fixed",
	background: "#00000050",
	width: "100%",
	height: "100vh",
	top: "0",
	left: "0"

  },
  box : {
	position: "relative" ,
	width: "50%" ,
	margin: "0 auto" ,
	height: "auto" ,
	maxHeight: "90vh" ,
	marginTop: "calc(100vh - 85vh - 20px)" ,
	background: "#fff" ,
	borderRadius: "4px" ,
	padding: "20px" ,
	border: "1px solid #999" ,
	overflow: "auto" ,
	zIndex:"1000"
  },
   
  closeicon : {
	content: "x" ,
	cursor: "pointer" ,
	position: "fixed" ,
	right: "calc(22%)" ,
	top: "calc(100vh - 85vh - 33px)" ,
	background: "#ededed" ,
	width: "25px" ,
	height: "25px" ,
	borderRadius: "50%" ,
	lineHeight: "20px" ,
	textAlign: "center" ,
	border: "1px solid #999" ,
	fontSize: "20px" ,
  },

  comment:{
	fontSize: "17px",
	textAlign:"justify",
	lineHeight :"30px"
  },
  sendMessageIcons: {
	color: "grey",
  },
  uploadInput: {
	display: "none",
  },
  root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 220,
	},
  smallFormControl: {
		margin: theme.spacing(1),
		minWidth: 160,
	},
  fileLabel:{
    marginTop: "6",
    fontWeight: "700!important",
    fontSize: "20!important"
  },
  field1:{
    maxWidth: "73%"
  },
  field2:{
    maxWidth: "24%",
    marginLeft: "8px!important"
  }


}));


const GroupModal = ({open, onClose, groupId})  => {

	const initialState = {
		    name: "",
        root: "" ,
        admin1:"",
        admin2:"",
        instances:"",
        maxparticipants: ""
	};
  
  const classes = useStyles();

  const [displayfn, setDisplayFn] = useState("");
  const [medias, setMedias] = useState([]);
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState(initialState);
  const [group, setGroup] = useState(initialState);
  const [isGroup, setIsGroup] = useState(0);
  const [includeDefault, setIncludeDefault]=useState(0);


    const handleClose = () => {
        onClose();
        setCampaign(initialState);
    } 

    const handleChangeMedias = (e) => {
        if (!e.target.files) {
        return;
        }

    const selectedMedias = Array.from(e.target.files);
        setMedias(selectedMedias);
        setDisplayFn(medias[0]?.name);
    };



  const handleSaveCampaign = async (e) => {
    setLoading(true);
    e.preventDefault();

    let mediaOK=false;

    

    if(group.name ==""){
      toast.error(i18n.t("groupModal.error.name"));
      setLoading(false);
      return;
    }
    if(group.root ==""){
      toast.error(i18n.t("groupModal.error.root"));
      setLoading(false);
      return;
    }

    if(group.admin1 ==""){
      toast.error(i18n.t("groupModal.error.admin1"));
      setLoading(false);
      return;
    }
    if(group.admin2 ==""){
      toast.error(i18n.t("groupModal.error.admin2"));
      setLoading(false);
      return;
    }

    if(group.instances==""){
      toast.error(i18n.t("groupModal.error.instances"));
      setLoading(false);
      return;
    }

    if(group.maxparticipants ==""){
      toast.error(i18n.t("groupModal.error.maxparticipants"));
      setLoading(false);
      return;
    }


    const formData = new FormData();
    console.log(group)
    formData.append("admin1",group.admin1);
    formData.append("admin2",group.admin2);
	  formData.append("instances", group.instances);
	  formData.append("maxparticipants",group.maxparticipants)
    formData.append("name",group.name)
    formData.append("root",group.root)
    
   
    medias.forEach((media) => {
      formData.append("medias", media);
      mediaOK=true;

    });

    if(!mediaOK){
      toast.error(i18n.t("groupModal.error.media"));
      setLoading(false);
      return;
    }
 

    for (var pair of formData.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
    } 
	
    try {
    
    await api.post(`/groups`, formData);
    
	  
    } catch (err) {
      toastError(err);
    }

	toast.success(i18n.t("campaignModal.title.addsuccess"));
    setLoading(false);
    setMedias([]);
	  handleClose();
	
  };

  const handleChangeInput = (e) => {
    setGroup({ ...group, [e.target.name]: e.target.value })  
    console.log(group);
  };

  const handleChangeDefault = (e)=>{
    console.log(e.target)
    setIncludeDefault(e.target.value)
    setGroup({ ...group, [e.target.name]: e.target.value })
  }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                        {i18n.t("groupModal.title.add")}
                </DialogTitle>
                <Formik
                    initialValues={campaign}
                    enableReinitialize={true}
        
                >
                     <Form>
                        <DialogContent dividers>
                                
                                  <div className={classes.multiFieldLine}>
                                    
                                          <Field className={classes.field1}
                                                      as={TextField}
                                                      label={i18n.t("groupModal.form.name")}
                                                      autoFocus
                                                      name="name"
                                                      onChange={(e) => handleChangeInput(e)}
                                                      value={group.name}
                                                      variant="outlined"
                                                      fullWidth
                                                      margin="dense"
                                                      
                                              />
                                
                                          <Field className={classes.field2}
                                                      as={TextField}
                                                      label={i18n.t("groupModal.form.root")}
                                                      autoFocus
                                                      name="root"
                                                      onChange={(e) => handleChangeInput(e)}
                                                      value={group.root}
                                                      variant="outlined"
                                                      fullWidth
                                                      margin="dense"
                                                     
                                              />
                                      </div>

                                    <div className={classes.multFieldLine}>
                                        <input
                                            type="file"
                                            allow=".xls, .xlsx"
                                            id="upload-button"
                                            className={classes.uploadInput}
                                            onChange={handleChangeMedias} 
                                        />
                                        <label htmlFor="upload-button">
                                        Imagem para Grupo:&nbsp;
                                        <IconButton
                                            aria-label="upload"
                                            component="span"
                                        >
                                            <AttachFileIcon className={classes.sendMessageIcons} />
                                        
                                        </IconButton>
                                        </label>	
                                        <label htmlFor="upload-button" fullWidth className={classes.fileLabel}>
                                            {medias[0]?.name}
                                        </label>

                                    </div>
                                    <div className={classes.multFieldLine}>
                                        <Field
                                                as={TextField}
                                                label={i18n.t("groupModal.form.admin1")}
                                                autoFocus
                                                name="admin1"
                                                value={group.admin1}
                                                onChange={(e) => handleChangeInput(e)}
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                        />
                                        <Field
                                                as={TextField}
                                                label={i18n.t("groupModal.form.admin2")}
                                                autoFocus
                                                name="admin2"
                                                value={group.admin2}
                                                onChange={(e) => handleChangeInput(e)}
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                        />
                                    </div>
                                    <div className={classes.multFieldLine}>

                                        <Field
                                                as={TextField}
                                                name="instances"
                                                variant="outlined"
                                                margin="dense"
                                                value={group.instances}
                                                label={i18n.t("groupModal.form.instances")}
                                                onChange={(e) => handleChangeInput(e)}
                                                type='text'
                                                fullWidth
                                            />
                                        <Field
                                                as={TextField}
                                                label={i18n.t("groupModal.form.maxparticipants")}
                                                autoFocus
                                                name="maxparticipants"
                                                value={group.maxparticipants}
                                                onChange={(e) => handleChangeInput(e)}
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                        />
                                    </div>
                                </DialogContent>
                                
                                
                                
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        color="secondary"
                                        disabled={loading}
                                        variant="outlined"
                                    >
                                        {i18n.t("userModal.buttons.cancel")}
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={loading}
                                        variant="contained"
                                        className={classes.btnWrapper}
                                        onClick={handleSaveCampaign}
                                    >
                                        
                                            {i18n.t("userModal.buttons.okAdd")}
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </Button>
                                </DialogActions>
                            </Form>
                    
                </Formik>
            </Dialog>
        </div>
    );
};

export default GroupModal;
