function getConfig(name, defaultValue=null) {
    // If inside a docker container, use window.ENV
    if( window.ENV !== undefined ) {
        return window.ENV[name] || defaultValue;
    }

    return process.env[name] || defaultValue;
}

export function getBackendUrl() {
    

      //  if (window.location.hostname === 'localhost' || window.location.hostname=="main.mware.com.br") {
      //      console.log("Original host name", window.location.hostname)
      //      return `http://api-${window.location.hostname}:8000`; 
      //  } else {
            let hostname0=`${window.location.protocol}//api-${window.location.hostname}`
            let hostname1=hostname0.replace("-lite","" )
            console.log("Original host name", window.location.hostname)
            return hostname1;
        // }
    
}

export function getHoursCloseTicketsAuto() {
    return getConfig('REACT_APP_HOURS_CLOSE_TICKETS_AUTO');
}