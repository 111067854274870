import React, { useContext, useEffect, useRef, useState, useReducer  } from "react";
import api from "../../services/api";


import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import DoneAllIcon from '@material-ui/icons/DoneAll';

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";
import { TagsFilter } from "../TagsFilter";
import { UserFilter } from "../UserFilter";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";

import { Button } from "@material-ui/core";

import TicketImportModal from "../../components/TicketImportModal";

import AddCommentIcon from '@material-ui/icons/AddComment';
import InputIcon from '@material-ui/icons/Input';
import UpdateIcon from '@material-ui/icons/Update';


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'



const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: "#eee",
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    minWidth: 120,
    width: 120,
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fafafa",
    padding: theme.spacing(1),
  },

  serachInputWrapper: {
    flex: 1,
    background: "#fff",
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },

  badge: {
    right: "-10px",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },
  sendMessageIcons: {
    color: "grey",
    },
    uploadInput: {
    display: "none",
    },
}));



const TicketsManager = () => {
  const classes = useStyles();

  const [searchParam, setSearchParam] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [importTicketModalOpen, setImportTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);

  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);

  const userQueueIds = user.queues.map((q) => q.id);
  
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

 
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [userList, setUserList] = useState();
  const [ticketsPerUser, setTicketsPerUser] = useState([]);
  const [isFileOpen, setIsFileOpen] =useState(false);


  const toggleFilePopup = (myaction) => {
    setIsFileOpen(!isFileOpen);
  }
  

  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN") {
      setShowAllTickets(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
    }
  }, [tab]);

  let searchTimeout;  

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };


  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
      ////console.log("handleChangeTabOpen",newValue )
      setTabOpen(newValue)
    
  };

  const handleSelectedTags = (selectedTags) => {
    ////console.log("handleSelectedTags", selectedTags )
    const tags = selectedTags.map(t => t.id);
    setSelectedTags(tags);
	}

  const handleSelectedUsers = (selectedUsers) => {
    ////console.log("handleSelectedUser", selectedUsers )
    const users = selectedUsers.map(u => u.id);
    setSelectedUsers(users);   
	}


  const handleRefreshTickets = () => {

        const edata = {
          "dispatchConfig": null,
          "_targetInst": null,
          "nativeEvent": null,
          "type": null,
          "target": null,
          "currentTarget": null,
          "eventPhase": null,
          "bubbles": null,
          "cancelable": null,
          "timeStamp": null,
          "defaultPrevented": null,
          "isTrusted": null,
          "view": null,
          "detail": null,
          "screenX": null,
          "screenY": null,
          "clientX": null,
          "clientY": null,
          "pageX": null,
          "pageY": null,
          "ctrlKey": null,
          "shiftKey": null,
          "altKey": null,
          "metaKey": null,
          "getModifierState": null,
          "button": null,
          "buttons": null,
          "relatedTarget": null,
          "movementX": null,
          "movementY": null,
          "_dispatchListeners": null,
          "_dispatchInstances": null
        }
        handleChangeTab(edata, "closed")

        setTimeout(()=>{
          handleChangeTab(edata, "open")
          
          if(tabOpen=="pending"){
            handleChangeTabOpen(edata,"pending")
          }
                    
        }, 500)
       
      
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const applyFilter = (values) =>{
    setTimeout(()=>{
      setShowAllTickets(true)
    }, 500)
    setTimeout(()=>{
      setShowAllTickets(false);
    }, 500)
 }

 const CloseAllTicket = async () => {

 //console.log("GOT IN CloseAllTicket")

  try {
    const { data } = await api.patch("/tickets/closeAll", {queue:23})
    //console.log("CloseAllTicket", data)
  } catch (err) {
    //console.log("Error: ", err);
  }
}


  return (
    <>
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(e) => setNewTicketModalOpen(false)}
      />

      <TicketImportModal 
           modalOpen={importTicketModalOpen}
           onClose={(e) => setImportTicketModalOpen(false)}
        />

      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
          <Tab
            value={"open"}
            icon={<MoveToInboxIcon />}
            label={i18n.t("tickets.tabs.open.title")}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"closed"}
            icon={<CheckBoxIcon />}
            label={i18n.t("tickets.tabs.closed.title")}
            classes={{ root: classes.tab }}zzzzzzzzzzw
          />
          <Tab
            value={"search"}
            icon={<SearchIcon />}
            label={i18n.t("tickets.tabs.search.title")}
            classes={{ root: classes.tab }}
          />
        </Tabs>
      </Paper>
      
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        {tab === "search" ? (
          <div className={classes.serachInputWrapper}>
            <SearchIcon className={classes.searchIcon} />
            <InputBase
              className={classes.searchInput}
              inputRef={searchInputRef}
              placeholder={i18n.t("tickets.search.placeholder")}
              type="search"
              onChange={handleSearch}
            />
          </div>
        ) : (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setNewTicketModalOpen(true)}
            >
              <AddCommentIcon />
            </Button>
      
            <Can
              role={user.profile}
              perform="tickets-manager:import"
              yes={() => (
    
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setImportTicketModalOpen(true)}
                >
                  <InputIcon />
                </Button>

              )}
            />



            <Can
              role={user.profile}
              perform="tickets-manager:closeAll"
              yes={() => (
    
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => CloseAllTicket()}
                >
                  <DoneAllIcon />
                </Button>

              )}
            />    









             <Button
                  variant="outlined"
                  color="primary"      
                  onClick={handleRefreshTickets}
            >
              <UpdateIcon />
            </Button>














            <Can
              role={user.profile}
              perform="tickets-manager:showall"
              yes={() => (
                <FormControlLabel
                  label={i18n.t("tickets.buttons.showAll")}
                  labelPlacement="start"
                  control={
                    <Switch
                      size="small"
                      checked={showAllTickets}
                      onChange={() =>
                        setShowAllTickets((prevState) => !prevState)
                      }
                      name="showAllTickets"
                      color="primary"
                    />
                  }
                />
              )}
            />
          </>
        )}
        


        <TicketsQueueSelect
          style={{ marginLeft: 6 }}
          selectedQueueIds={selectedQueueIds}
          userQueues={user?.queues}
          onChange={(values) => setSelectedQueueIds(values)}
        />


      </Paper>
      <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
        <Can
          role={user.profile}
          perform="tickets-manager:showall"
          yes={() => (
            <>
                <UserFilter
                    onFiltered={handleSelectedUsers}
                    onChange={handleSelectedUsers}
                />
                <TagsFilter
                    onFiltered={handleSelectedTags}
                    onChange={handleSelectedTags}
                />
            </>
          )}
          />
        
         
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={openCount}
                color="primary"
              >
                {i18n.t("ticketsList.assignedHeader")}
              </Badge>
            }
            value={"open"}
          />
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={pendingCount}
                color="secondary"
              >
                {i18n.t("ticketsList.pendingHeader")}
              </Badge>
            }
            value={"pending"}
          />
        </Tabs>
        <Paper className={classes.ticketsWrapper}>
          <TicketsList
            status="open"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            tags = { selectedTags }
            users = {selectedUsers}
            updateCount={(val) => setOpenCount(val)}
            style={applyPanelStyle("open")}
          />
          <TicketsList
            status="pending"
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setPendingCount(val)}
            style={applyPanelStyle("pending")}
          />
        </Paper>
      </TabPanel>
      <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
        <Can
            role={user.profile}
            perform="tickets-manager:showall"
            yes={() => (
              <>
                  <UserFilter
                      onFiltered={handleSelectedUsers}
                      onChange={handleSelectedUsers}
                  />
                  <TagsFilter
                      onFiltered={handleSelectedTags}
                      onChange={handleSelectedTags}
                  />
              </>
            )}
            />
        <TicketsList
          status="closed"
          showAll={true}
          selectedQueueIds={selectedQueueIds}
          tags = { selectedTags }
          users = {selectedUsers}
         
        />
      </TabPanel>

      <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
     
       
        <TicketsList
          searchParam={searchParam}
          tags={selectedTags}
          users={selectedUsers}
          showAll={true}
          selectedQueueIds={selectedQueueIds}
        />
      </TabPanel>

      
    </Paper>
    </>
             
  );
};

export default TicketsManager;
