import React, { useEffect, useReducer, useState } from "react";

import openSocket from "socket.io-client";

import BizFlowBoard from "../../components/BizFlowBoard";

import CodeIcon from '@material-ui/icons/Code';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';

import {
    Button,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

import Grid from '@material-ui/core/Grid';

import { styled } from '@mui/material/styles';

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@material-ui/icons";
import BotModal from "../../components/CommandModal";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import MenuModal from "../../components/MenuModal";
import {blue, purple, red, green, teal, orange, amber} from '@material-ui/core/colors';
import BizFlowModal from "../../components/BizFlowModal";

import speechbubble1 from "../../assets/speechbubble1.png";
import Audio from "../../components/Audio";

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    article : {
        height: "330px",
        width: "250px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
        display: "grid",
        placeItems: "center"
    },
    header : {
        fontSize: "20px",
        color: "black",
        textAlign: "center",
        lineHeight: "1.5em"
    },
    green1: {
		color: green[500]
	},
    red1: {
            color: red[500]
        },

    blue1: {
            color: blue[500]
        },
    teal1: {
            color: teal[500]
        },
    orange1: {
            color: orange[500]
        },
    purple1: {
            color: purple[500]
        },
    amber1: {
            color: amber[500]
        },
    }));

const reducer = (state, action) => {
    if (action.type === "LOAD_BIZFLOWS") {
        const bizflows = action.payload;
        const newBizflows = [];

        bizflows.forEach((bizflow) => {
            const bizflowIndex = state.findIndex((q) => q.id === bizflow.id);
            if (bizflowIndex !== -1) {
                state[bizflowIndex] = bizflow;
            } else {
                newBizflows.push(bizflow);
            }
        });

        return [...state, ...newBizflows];
    }

    if (action.type === "UPDATE_BIZFLOWS") {

        const bizflow = action.payload;
        console.log("bizflow",bizflow)
        const bizflowIndex = state.findIndex((q) => q.id === bizflow.id);
        console.log("bizflowIndex",bizflowIndex)
        
        if (bizflowIndex !== -1) {
            state[bizflowIndex] = bizflow;
            return [...state];
        } else {
            return [bizflow, ...state];
        }
        
    }

    /*

    if (action.type === "DELETE_BOT") {
        const botId = action.payload;
        console.log('BOTID => ', botId);
        const botIndex = state.findIndex((q) => q.id === botId);
        if (botIndex !== -1) {
            state.splice(botIndex, 1);
        }
        return [...state];
    }
*/

    if (action.type === "RESET") {
        return [];
    }
};

const  AlexaParrot = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const { data } = await api.post("/parrot/getOverallSatisfaction");
                console.log(data)
                //dispatch({ type: "LOAD_BIZFLOWS", payload: data });
                //setLoading(false);
            } catch (err) {
                toastError(err);
                setLoading(false);
            }
        })();
    }, []);

 
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));

  

    return (
        <MainContainer>
            <MainHeader>
                <Title>Alexa</Title>

            </MainHeader>

            <Paper className={classes.mainPaper} variant="outlined">

            <Grid container spacing={2} style={{textAlign:"center"}}>
                <Grid item xs={4}>
                   
                       
                            <article className={classes.article} style={{ backgroundImage: `url(${speechbubble1})` }} >
                                <p className={classes.header}>Como estará meu<br/>nível geral<br/>de satisfação?</p>
                            </article>
                       
                   
                </Grid>
                <Grid item xs={4}>
                    <Item><Audio url={"http://iasb.mware.com.br:8001/public/7170b6bc-97bb-455f-9b70-366dd58d5a2f.mp3"}/></Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>xs=4</Item>
                </Grid>

            </Grid>
        
            </Paper>
        </MainContainer>
    );
};

export default  AlexaParrot;
