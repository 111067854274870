import React, { useState, useEffect, useReducer, useContext } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";


const iconv = require('iconv-lite');



const NewTicket = () => {
    const history = useHistory();
    const [pageNumber, setPageNumber] = useState(1);


    const { user } = useContext(AuthContext);

    const handlePhoneAndTicketCreation = async () => {
        const variable3 = getSearchParameters();
        console.log("variable3", variable3);
        const phoneNumber = variable3["phone"];
        const textMessage = variable3["text"];
 
/*
        var decodedString = decodeURIComponent(textMessage);

        let isoText = decodedString;
        let buffer = Buffer.from(isoText, 'latin1');
        let utf8Text = iconv.decode(buffer, 'utf8');
        var wrongText = utf8Text
        var correctText = Buffer.from(wrongText, 'latin1').toString('utf8');

        let utf8Corrected = Buffer.from(correctText, 'latin1').toString('utf8');
        console.log("textMessage",textMessage)      
        console.log("decodedString",decodedString)
        console.log("buffer",buffer)
        console.log("correctText",correctText)
        console.log("utf8Corrected",utf8Corrected)
        */
        let latin1Text = decodeURIComponent(textMessage);
        let utf8Textv2 = Buffer.from(latin1Text, 'latin1').toString('utf8');
        //console.log("utf8Textv2",utf8Textv2);




        let contact;
        let contactData

        

        contactData = await api.get("/contacts/", {params: {searchParam: phoneNumber.substring(phoneNumber.length-8), pageNumber}});
         
            if(!contactData?.data?.contacts[0] ){

                    let WAnumber;

                    let ddi = phoneNumber.substr(0,2)
                    let ddd = phoneNumber.substr(2,2)
                    let nmrWZap = phoneNumber.substr(phoneNumber.length-8)
                    
                    if (ddi==55){
                            if (ddd<30){
                            WAnumber=ddi+ddd+"9"+nmrWZap;
                            }else{
                            WAnumber=ddi+ddd+nmrWZap;
                            }
                    }
                    let values={    name: phoneNumber, 
                                    number: phoneNumber, 
                                    email: '' }

                    contactData = await api.post("/contacts", values);
                    
                    contact=contactData?.data?.id;
                    //console.log("Data 2a", contact)
                    
            }else{
                contact=contactData.data.contacts[0].id
                ///console.log("Data 1a",contact)
               
            }
            

           
            const { data: ticket } = await api.post("/tickets", {
                contactId: contact,
                userId: user?.id,
                status: "open",
            });
    
            let message = {
                read: 1,
                fromMe: true,
                mediaUrl: "",
                body:  utf8Textv2,
                quotedMsg: null
              };

            try {
                await api.post(`/messages/${ticket.id}`, message);
              } catch (err) {
                toastError(err);
              }
    
            history.push(`/tickets/${ticket.id}`);
 
           

    };

    useEffect(() => {
        handlePhoneAndTicketCreation();   
    }, []); // only on initial render.

    return <></>;
};

/*
const NewTicket = () => {

	const history = useHistory();
   
  	const [pageNumber, setPageNumber] = useState(1);
    const [searchParams, setSearchParams] = useState();
    const [currPhone, setCurrPhone] = useState(null);
    const [currText, setCurrText ] = useState("")

    const { user } = useContext(AuthContext)



    (async () => {

        var variable3 = getSearchParameters()
        console.log("variable3", variable3)
        
        setCurrPhone(variable3["phone"])
        
        console.log("currPhone", currPhone)

        const { data: contact } = await api.get("/contacts/", {params: { searchParam:currPhone, pageNumber}});

        console.log("NewTicket::Contact",(contact.contacts[0].id?contact.contacts[0].id:"NoContact"))

        const { data: ticket } = await api.post("/tickets", {
            contactId: contact.contacts[0].id,
            userId: user?.id,
            status: "open",
        });

        console.log("NewTicket::Ticket",ticket)

        history.push(`/tickets/${ticket.id}`);

    }); 

    
	return (
        <></>
	);
  
  };

  */
/*
const NewTicket2 = () => {

	const history = useHistory();
   

	const [searchParams, setSearchParams] = useState();
    const [phone, setPhone ] = useState()
    const [text, setText ] = useState()

	const [loading, setLoading] = useState(false);
  	const [pageNumber, setPageNumber] = useState(1);

    const { user } = useContext(AuthContext)


    useEffect(async () => {

        var variable3 = getSearchParameters()
        console.log("variable3b", variable3?.phone)

        setSearchParams(variable3)

        console.log("searchParams?.phone", searchParams?.phone)

        try {

        

                    
            const { data: contact } = await api.get("/contacts/", {params: { searchParam:searchParams?.phone, pageNumber}});

            console.log("NewTicket::Contact",contact.contacts[0].id)
    

            const { data: ticket } = await api.post("/tickets", {
                contactId: contact.contacts[0].id,
                userId: user?.id,
                status: "open",
            });

            console.log("NewTicket::Ticket",ticket)

            history.push(`/tickets/${ticket.id}`);
            

                            

            setLoading(false);
        } catch (err) {
            history.push(`/tickets`);
            toastError(err);
        }


      });

	return (
        <></>
	);
};
*/

export default NewTicket;


function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    var items = window.location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName){

            result = decodeURIComponent(tmp[1]);
            console.log("result",result)
            return result;

        }
    }
    
}






function getSearchParameters() {
    var prmstr = window.location.search.split("?")

    console.log("prmstr",prmstr)
    if (prmstr[1] != undefined && prmstr != ""){
        var prmstrret=  transformToAssocArray(prmstr[1]);
    }
    
    //console.log("prmstrret",prmstrret)
    

    return prmstrret
}

function transformToAssocArray( prmstr ) {
    var params = {};
    var prmarr = prmstr.split("&");
    for ( var i = 0; i < prmarr.length; i++) {
        var tmparr = prmarr[i].split("=");
        params[tmparr[0]] = tmparr[1];
    }

    //console.log("params",params)
    return params
}
