import React, {useState} from 'react';

import {
	Chip,
    makeStyles,
    TextField
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { i18n } from "../../translate/i18n";
import variables from "./variables";

const useStyles = makeStyles(theme => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
		'&:hover': {
			filter: 'brightness(120%)',
		},
	},
	chip: {
		margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main+"20",
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
	},
	marginTop: {
		marginTop: theme.spacing(1),
	},
    deleteIcon: {
      display: "none"
    },
    input: {
        '& input': {
            minWidth: "0!important",
            padding: "0!important",
        }
    }
}));


const setVariables = (group) => {
    let selected = [];
    for(let variable of variables){
        for(let varGroup of variable.groups){
            if(varGroup === group){
                selected.push(variable)
            }
        }
    }
    return selected
}

const InputVariables = (props) => {
    const classes = useStyles();
    const [readOnly] = React.useState(true);
    const [selected] = useState(setVariables(props.group));

    const handleClick = (e) => {
        if(props.field){
            let content;
            let variable=e.currentTarget.getAttribute('content')
            if( variable.substring(0, 1) == "@"){
                content = `${e.currentTarget.getAttribute('content')}`;
            }else{
                content = `{{${e.currentTarget.getAttribute('content')}}}`;
            }

            
            if(props.setFieldValue) {
                props.setFieldValue(props.field.name, props.field.value += " "+content);
            } else {
                props.field.value += " "+content;
            }
        }
    };
    
    return (
        <Autocomplete
            multiple
            className={classes.marginTop}
            options={selected}
            defaultValue={selected}
            variant="outlined"
            open={false}
            disableClearable={true}
            freeSolo
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        label={i18n.t(option.label)}
                        onClick={handleClick}
                        className={classes.chip}
                        content={option.value}
                        component="span"
                        size="small"
                        variant="outlined"
                        clickable
                        deleteIcon={<div className={classes.deleteIcon}></div>}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField {...params} 
                    variant="outlined"
                    label={i18n.t("broadcasts.variables.title")}
                    className={classes.input}
                    InputProps={{ ...params.InputProps, readOnly }}
                />
            )}
        />
	);
};

export default InputVariables;