
import React, { useState, useReducer,  useEffect, } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";

import {
	Avatar,
	Button,
	CssBaseline,
	TextField,
	Grid,
	Box,
	Typography,
	Container,
	InputAdornment,
	IconButton,
	Link
} from '@material-ui/core';
import L from "leaflet";
import leaflet from 'leaflet';
import shp from 'shpjs';
import api from "../../services/api";
import {hiveapi, shapeapi} from "../../services/hiveapi"

import toastError from "../../errors/toastError";

const LeafletMap = () => {

    let map;

    const [bounce, setBounce] = useState(1);
    const [loading, setLoading] = useState(false);
    const [searchParam, setSearchParam] = useState(false);
    const [pageNumber, setPageNumber] = useState(false);
    const [currMap, setCurrMap] = useState();
    const [shpListed, setShpListed] = useState();


    useEffect(() => {
       loadmap()
       setLoading(true)
      }, [bounce]); 



    useEffect(() => {
  
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
            
            let json0 = JSON.stringify({
                "operacao": "getcars",
                "uid": 1000
              });
              
              let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'http://www.geohive.com.br/webservices/webservice.php',
                headers: { 
                  'Content-Type': 'application/json', 
                  'Accept': 'application/json', 
                  'Access-Control-Allow-Origin': 'http://main.mwae.com.br', 
                  'Access-Control-Allow-Headers': 'origin, content-type, accept, authorization, x-request-with', 
                  'Access-Control-Allow-Credentials': 'false', 
                  'Authorization': 'Bearer YWRtaW46cGFzc3dvcmQ='
                },
                data : json0
              };

            try {
                const { data } = await hiveapi.request(config);
                loadshape("http://www.geohive.com.br/shpmunicipais/5103403_CAR.zip")


                console.log(data)
                
            } catch (err) {
                toastError(err);
            }
            };
            fetchContacts();
        }, 1500);
    return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);



    const loadmap = async()=> {
            //this.map = leaflet.map("map").fitWorld();
            map = leaflet.map('map').setView([-15.6050264,-56.0990338], 10);
        
            leaflet.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attributions: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
            maxZoom: 18
            }).addTo(map);
        
            map.locate({
            setView: true,
            maxZoom: 3
            }).on('locationfound', (e) => {
            
        
            let markerGroup = leaflet.featureGroup();
            let marker = leaflet.marker([-15.6050264,-56.0990338]).on('click', () => {
                alert('Marker clicked');
            })

            markerGroup.addLayer(marker);

            map.addLayer(markerGroup);
            }).on('locationerror', (err) => {
                alert(err.message);
            })
        
    }

    const loadshape= async (url)=>{

     
        console.log("loadshape::map", map)
       
        var myStyle = {"color": "#ff0000","weight": 5,"opacity": 0.65};

        var geo = leaflet.geoJson( {style: myStyle, features:[]},{onEachFeature:function popUp(f,l){
          var out = [];
          if (f.properties){
              out.push('<img src=assets/svgs/logo.21.4.color-v1.svg style=width:60px;><br/>');
              for(var key in f.properties){
                out.push(key+": "+f.properties[key]);
              }
                l.bindPopup(out.join("<br />"));
            }
        }}).addTo(map);

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://api-main.mware.com.br:8000/public/5103403_CAR.zip',
            headers: {
                
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Headers': 'origin, content-type, accept, authorization, x-request-with'
             }
          };
          /*
          axios.request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
    
          })
          .catch((error) => {
            console.log(error);
          });
*/
            shp('http://api-main.mware.com.br:8000/public/5103403_CAR.zip').then(function(data){
                geo.addData(data);
              });
 
  
  








        
    }



	return (
		<Container component="main" maxWidth="xs">
			
				<Typography component="h1" variant="h4" style={{marginBottom:80, textAlign:"center"}}>
                    Mapa com pure leaflet
				</Typography>

                <div id="map" style={{width:800, height:600}}></div>
				
		</Container>
	);

}

export default LeafletMap