import React from "react";
import { makeStyles } from "@material-ui/core/styles";
/*
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import { i18n } from "../../translate/i18n";
import MainContainer from "../MainContainer";
import Paper from "@material-ui/core/Paper";
*/
import CampaignDashboard from "../../components/CampaignStats/CampaignDashboard";

const useStyles = makeStyles(theme => ({
	popupbox : {
		position: "fixed",
		background: "#00000050",
		width: "100%",
		height: "100vh",
		top: "0",
		left: "0"

	  },
	  box : {
		position: "relative" ,
		width: "68%" ,
		margin: "0 auto" ,
		height: "auto" ,
		maxHeight: "90vh" ,
		marginTop: "calc(100vh - 85vh - 20px)" ,
		background: "#fff" ,
		borderRadius: "4px" ,
		padding: "20px" ,
		border: "1px solid #999" ,
		overflow: "auto" ,
		zIndex:"1000"
	  },
	   
	  closeicon : {
		content: "x" ,
		cursor: "pointer" ,
		position: "fixed" ,
		right: "calc(15% - 30px)" ,
		top: "calc(100vh - 85vh - 33px)" ,
		background: "#ededed" ,
		width: "25px" ,
		height: "25px" ,
		borderRadius: "50%" ,
		lineHeight: "20px" ,
		textAlign: "center" ,
		border: "1px solid #999" ,
		fontSize: "20px" ,
	  }



}));

//const CampaignStatsModal = ({ props, title, children, open, onClose, onConfirm }) => {
const CampaignStatsModal = props => {
	const classes = useStyles();
	
	return (
		<div className={classes.popupbox}>
			<div className={classes.box}>
				<span className={classes.closeicon} onClick={props.handleClose} >x</span>
					<CampaignDashboard 
						selectedId={props.id}
					>
					</CampaignDashboard>
			</div>
		</div>	
	);
};

export default CampaignStatsModal;
