import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import {blue, purple, red, green, teal, orange, amber} from '@material-ui/core/colors';

import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PersonIcon from '@material-ui/icons/Person'; 

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Paper, Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import Stopwatch from "../Stopwatch";
import Chip from '@material-ui/core/Chip';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import TransferTicketModal from "../TransferTicketModal";


import {CallMissedOutgoing } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

import VisibilityIcon from "@material-ui/icons/Visibility";
import TicketMessagesDialog from "../TicketMessagesDialog";
import ControlCameraIcon from '@material-ui/icons/ControlCamera';


const useStyles = makeStyles(theme => ({
	'& .MuiBadge-badge': {
		backgroundColor: '#44b700',
		color: '#44b700',
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		'&::after': {
		  position: 'absolute',
		  top: 0,
		  left: 0,
		  width: '100%',
		  height: '100%',
		  borderRadius: '50%',
		  animation: 'ripple 1.2s infinite ease-in-out',
		  border: '1px solid currentColor',
		  content: '""',
		},
	  },
	ticket: {
		position: "relative",
	},

	pendingTicket: {
		cursor: "unset",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},

	lastMessageTime: {
		justifySelf: "flex-end",
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 32,
		marginLeft: "auto",
	},

	contactLastMessage: {
		paddingRight: 20,
	},

	newMessagesCount: {
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	acceptButton: {
		position: "absolute",
		left: "50%",
	},

	ticketQueueColor: {
		flex: "none",
		width: "11px",
		height: "110%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},
	timeTag: {
		right: "-47px",
		bottom: "2px",
		background: "#beaed2",
		color: "#000000",
		border:"1px solid #CCC",
		padding: 2,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 3,
		fontSize: "0.9em"
	},
	userTag: {
		position: "absolute",
		marginRight: 35,
		right: -34,
		bottom: 36,
		background: "#4bc0c0",
		color: "#000000",
		border:"1px solid #CCC",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 3,
		fontSize: "0.9em"
	},
	connTag: {
		position: "absolute",
		marginRight: 35,
		right: -34,
		bottom: 9,
		background: theme.palette.primary.main,
		color: "#ffffff",
		border:"1px solid #CCC",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 3,
		fontSize: "0.9em"
	},
	avatar:{
        width: "60px",
        height: "60px",
        borderRadius:"10%"
    },
}));


const TicketPanelItem = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleAcepptTicket = async id => {
		setLoading(true);
		try {
			
			await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		//history.push(`/tickets/${id}`);
	};

	const handleOpenTransferModal = e => {
		setTransferTicketModalOpen(true);
		//handleClose();
	};

	const handleCloseTransferTicketModal = () => {
		if (isMounted.current) {
			setTransferTicketModalOpen(false);
		}
	};

	return (
		<React.Fragment key={ticket.id}>

			<TransferTicketModal
				modalOpen={transferTicketModalOpen}
				onClose={handleCloseTransferTicketModal}
				ticketid={ticket.id}
				from ={"panel"}
			/>

			<TicketMessagesDialog
					open={openTicketMessageDialog}
					handleClose={() => setOpenTicketMessageDialog(false)}
					ticketId={ticket.id}
				></TicketMessagesDialog>

            <Paper elevation={3} style={{padding:5}}>
			<ListItem
				dense
				button
				selected={ticketId && +ticketId === ticket.id}
				className={clsx(classes.ticket, {
					[classes.pendingTicket]: ticket.status === "pending",
				})}
			>
			
				<ListItemAvatar>
						<Avatar src={ticket?.contact?.profilePicUrl} className={classes.avatar} />
				</ListItemAvatar>
				<ListItemText
					primary={
						<span className={classes.contactNameWrapper}>
							<Typography
								noWrap
								component="span"
								variant="body2"
								color="textPrimary"
								style={{marginLeft:10}}
							>
								{ticket?.contact?.name}
							</Typography>

							

						</span>
					}/>
				{true && (
					<>
					<span title={i18n.t("messagesList.header.buttons.transfer")}> 
						<IconButton
						style={{ backgroundColor: '#FF30A5', margin:2}}
						loading={loading}
						variant="contained"
						size="small"
						onClick={e => handleOpenTransferModal()}
						>
							<CallMissedOutgoing style={{  color: "#ffffff", fontSize: 25}} />
						</IconButton>
					</span>

					<span title={i18n.t("messagesList.header.buttons.transfer")}> 
						<IconButton
						style={{ backgroundColor: '#FF30A5', margin:2}}
						loading={loading}
						variant="contained"
						size="small"
						onClick={() => setOpenTicketMessageDialog(true)}
						>
							<VisibilityIcon style={{  color: "#ffffff", fontSize: 25}} />
						</IconButton>
					</span>

				</>

					
				)}
                



			</ListItem>
				
				{ticket.user==undefined ? (
						<span style={{padding:5}}>
                            <Chip icon={<PersonIcon style={{color:blue[500]}}/>} label="Não Atribuído" className={classes.chip} size = "small" style={{backgroundColor: blue[100], borderColor: blue[800],  color: blue[500], }} variant="outlined" />
						</span>
			    ):(
				<span style={{padding:5}}>
                            <Chip icon={<PersonIcon style={{color:blue[500]}}/>} label={ticket.user?.name} className={classes.chip} size = "small" style={{backgroundColor: blue[100], borderColor: blue[800], color: blue[500], }} variant="outlined" />
						</span>
				)}
                

				<span style={{padding:5}}>
				<Stopwatch  type="chip" ticketStatus={ticket.status} createdAt={ticket.createdAt} updatedAt={ticket.updatedAt} ticketQueue={ticket.queue}/>
				</span>
				<span style={{padding:5}}>
					<Chip icon={<AlarmOnIcon style={{color:purple[500]}}/>} label= 

									{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
										<>{format(parseISO(ticket.updatedAt), "HH:mm")} <Stopwatch ticketStatus={ticket.status} createdAt={ticket.createdAt} updatedAt={ticket.updatedAt} ticketQueue={ticket.queue}/></>
										) : (
										<>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")} <Stopwatch ticketStatus={ticket.status} createdAt={ticket.createdAt} updatedAt={ticket.updatedAt} ticketQueue={ticket.queue}/></>
										)}

							className={classes.chip} size = "small" style={{backgroundColor: purple[100], borderColor: purple[800], color: purple[500]}} variant="outlined" />
                </span>
				<span style={{padding:5}}>
					<Chip icon={<ControlCameraIcon style={{color:teal[500]}}/>} label={ticket.queue?.name || "Sem fila"} className={classes.chip} size = "small" style={{backgroundColor: "#ffffff", borderColor: teal[500],  color: teal[500], }} variant="outlined" />
                </span>








            </Paper>

			<Divider variant="inset" component="li" />
		</React.Fragment>
	);
};

export default TicketPanelItem;
/*
badgeContent={<span className={classes.timeTag}>{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
    <>{format(parseISO(ticket.updatedAt), "HH:mm")} <Stopwatch ticketStatus={ticket.status} createdAt={ticket.createdAt} updatedAt={ticket.updatedAt} ticketQueue={ticket.queue}/></>
    ) : (
    <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")} <Stopwatch ticketStatus={ticket.status} createdAt={ticket.createdAt} updatedAt={ticket.updatedAt} ticketQueue={ticket.queue}/></>
    )}
</span>
}
>*/