import React, { useContext, useEffect, useState, useMemo, useReducer } from "react";

import {
    makeStyles,
    MenuItem
} from '@material-ui/core';
import {
	Select,
	InputLabel,
	FormControl,
    TextField
	
  } from "@material-ui/core";
  import { Formik, Form, Field } from "formik";
  import { i18n } from "../../translate/i18n";


const useStyles = makeStyles(theme => ({
  connectInfoItem: {
	  justifyContent: "space-between",
	  width: "100%",
  },
  connectionInfo: {
	  alignItems: "center",
	  display: "flex",
	  float: "right",
	  marginTop: "-5px",
	  marginBottom: "-4px",
  },
  connectionInfoSpan: {
	  opacity: "70%",
  }
}))




//const WabaTemplateItems = ({ templates, onSelect}) => {
export default function WabaTemplateItems({ templates, onSelect }) {
    const classes = useStyles();
    const [selectedTemplate, setSelectedTemplate] = useState("promo_mware_november_2023");


      const handleClick = event => {

        const myValue1  = event.target.dataset.template;       
        const Templateindex =  templates.findIndex(s => s.reference === myValue1);    
        console.log("handleClick::event.target.dataset", event.target.dataset)
        setSelectedTemplate(templates[Templateindex].templateName)
        console.log("handleClick::selectedTemplate", selectedTemplate)
        onSelect(myValue1)
    }




    return (
        <>
            <Field
                as={Select}
                label={i18n.t("broadcasts.form.template")}
                name="template"
                autoFocus
                variant="outlined"
                margin="dense"
                className={classes.textField}
                
                fullWidth
                placeholder={i18n.t("broadcasts.form.template")}
                >
                { templates.map((template) => (
                        <MenuItem 
                            
                            data-template={template.reference}
                            value={template.reference} 
                            className={classes.connectInfoItem}
                            onClick={handleClick} 
                        >
                            {template.templateName} ({template.reference})
                        </MenuItem>
                    ))
                }
            </Field>           
         
        </>

    );
};

