import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const CategorySelectSingle = () => {
    const classes = useStyles();
    const [categories, setCategories] = useState([]);


    const handleSelectedCategoryId = (selectedId) => {

        console.log("selectedId",selectedId.target.value)
        
      }

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/categories");
                console.log('Categories => ', data.categories);
                
                setCategories(data.categories);
            } catch (err) {
                toastError(err);
            }
        })();
    }, []);

    return (
        <div style={{ marginTop: 6 }}>
            <FormControl
                variant="outlined"
                className={classes.FormControl}
                margin="dense"
                fullWidth
            >
                <InputLabel id="category-selection-input-label">
                    {i18n.t("categorySelect.inputLabel")}
                </InputLabel>

                <Field
                    as={Select}
                    label={i18n.t("categorySelect.inputLabel")}
                    name="categoryId"
                    labelId="category-selection-label"
                    id="category-selection"
                   //onChange={(e) => handleSelectedCategoryId(e)}
                >
                    {categories.map(category => (
                        <MenuItem key={category.id} value={category.id}>
                            {category.categoryName}
                        </MenuItem>
                    ))}
                </Field>

            </FormControl>
        </div>
    );
};

export default CategorySelectSingle;
