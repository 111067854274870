import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputLabel,
	FormControl,
	makeStyles,
	Select,
	TextField,
	Typography
} from "@material-ui/core";
import {
	AttachFile,
	Cancel
} from "@material-ui/icons"

import api from "../../services/api";
import ConnectionItems from "../ConnectionItems";
import { i18n } from "../../translate/i18n";
import InputVariables from "../../components/InputVariables";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import useWhatsApps from "../../hooks/useWhatsApps";
import { systemVars } from "../../../package.json";

const useStyles = makeStyles(theme => ({
	inputNumber: {
		width: 200
	},
	inputConnection: {
		margin: "dense",
		marginTop: 8,
		width: "24ch"
	},
	inputDateTime: {
		width: 460
	},
	newMessageBox: {
		marginTop: theme.spacing(1),
		width: "100%",
		display: "flex",
		padding: "7px",
		alignItems: "left",
		border: "1px solid",
		borderRadius: 15
	},
	uploadInput: {
		display: "none",
	},
	multFieldLine: {
		display: "flex",
		justifyContent: "space-between",
		"& > *:last-child": {
			marginRight: 0,
		},
		width: "100%",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	buttonColorError: {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main,
	},
	buttonProgress: {
		color: theme.palette.secondary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));



const ScheduleSchema = Yup.object().shape({
	message: Yup.string()
		.min(3, i18n.t("validation.too_short"))
		.max(400, i18n.t("validation.too_long")),
	phoneNumber: Yup.string()
		.min(12, i18n.t("validation.too_short"))
		.max(15, i18n.t("validation.too_long")),
	sendAt: Yup.date()
		.min(new Date(), i18n.t("validation.dateNow"))
})

const ScheduleMessageModal = ({ user, open, onClose, contactId, messageId, ticket, ticketId }) => {
	const initialState = {
		id: messageId || '',
		contactId: contactId || ticket?.contact.id || '',
		phoneNumber: ticket?.contact.number || '',
		message: `${i18n.t("variables.campaign")}{{firstName}}, `,
		hasMedia: false,
		wpId: ticket?.whatsappId || '',
		userId: user.id,
		status: 'pending',
		ticketId: ticketId || 0,
		sendAt: '',
	};

	console.log("ScheduleMessageModal:ticket 1", ticketId)
	console.log("ScheduleMessageModal:ticket 2", initialState)

	const classes = useStyles();
	const { whatsApps } = useWhatsApps();
	const [loadContactField, setLoadContactField] = useState(false);
	const [inputs, setInputs] = useState(initialState);
	
	const [medias, setMedias] = useState([]);
	const [title, setTitle] = useState(i18n.t("scheduledMessagesModal.form.title"))
	const [field, setField] = useState();

	useEffect(() => {
		const fetchContact = async () => {
			if (!contactId) {
				setInputs(inputs => ({ ...inputs, message: `${i18n.t("variables.campaign")}` }));
				return;
			}
			try {
				const { data } = await api.get(`/contacts/${contactId}`);
				setInputs(inputs => ({
					...inputs,
					contactId: data.id,
					phoneNumber: data.number,
					message: `${i18n.t("variables.campaign")}{{firstName}}, `,
				}));
				setTitle(i18n.t("scheduledMessagesModal.form.titleContact", { contact: data.name }))
			} catch (err) {
				toastError(err);
			}
		};
		fetchContact();
	},[contactId, open])

	useEffect(() => {		
		if (!ticket) {
			return;
		} else {
			setInputs(inputs => ({
				...inputs,
				contactId: ticket.contact.id,
				phoneNumber: ticket.contact.number,
				ticketId: ticket.id,
				wpId: ticket.whatsappId,
			}));
		}
	}, [ticket]);

	//componentize
	const getFileName = (mediaPath) => {
		let breakPath = mediaPath.split("/");
		let pathCount = breakPath.length - 1;
		let fileName = breakPath[pathCount]
		return fileName
	}
	const handleImageAsAttachment = (mediaPath) => {
		fetch(mediaPath).then(async response => {
			const contentType = response.headers.get('content-type')
			const blob = await response.blob()
			const file = new File([blob], getFileName(mediaPath), { contentType })
			let arrayMedias = Array.from([file])
			setMedias(arrayMedias)
			return 
		})
	}

	useEffect(() => {
		const fetchScheduledMessages = async () => {
			if (!messageId) return;
			try {
				const { data } = await api.get(`/scheduledMessages/${messageId}`);
				setInputs(inputs => ({
					...inputs,
					id: data.id,
					contactId: data.contactId,
					phoneNumber: data.phoneNumber,
					message: data.message,
					hasMedia: data.imagePath?.length > 0 ? true : false,
					wpId: data.wpId,
					userId: data.userId,
					status: data.status,
					ticketId: data.ticketId,
					sendAt: data.sendAt.substring(0, 16)
				}));
				if (data.imagePath) {
					handleImageAsAttachment(data.imagePath)
				};
				setTitle(i18n.t("scheduledMessagesModal.form.titleContact", { contact: data.name }))
			} catch (err) {
				toastError(err);
			}
		};

		fetchScheduledMessages();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messageId]);

	useEffect(() => {
		// let contact = (ticket?.id || contactId ) ? false : true;
		setLoadContactField(() => (contactId || messageId || ticket?.id) ? false : true)
	}, [contactId, messageId, ticket]);

	//Componentize
	const handleInputMedias = e => {
		if (!e.target.files) return;
		const selectedMedias = Array.from(e.target.files);
		setMedias(selectedMedias);
	};

	const handleClose = () => {
		onClose();
		setInputs(initialState);
		setInputs(inputs => ({ ...inputs, message: `${i18n.t("variables.campaign")}{{firstName}}` }));
		setMedias([]);
	};

	const handleFocus = (e) => {
		setField(e.target);
	}

	//Componentize
	const InputFile = () => {
		if (medias?.length > 0)
			return <>
				<div className={classes.newMessageBox}>
					<Button
						startIcon={<Cancel color="error" />}
						component="span"
						onClick={e => setMedias([])}
						size="small"
					>
						<Typography>
							{medias[0]?.name}
							{medias.length > 1 ? i18n.t("validation.medias.hasOthers", { medias: medias.length }) : ''}
						</Typography>
					</Button>
				</div>
			</>;
		else
			return <>
				<div className={classes.newMessageBox}>

					<input
						multiple
						type="file"
						id="upload-button2"
						className={classes.uploadInput}
						onChange={handleInputMedias}
					/>
					<label htmlFor="upload-button2">
						<Button
							startIcon={<AttachFile />}
							component="span"
							size="small"
						>
							<Typography>{i18n.t("validation.medias.none")}</Typography>
						</Button>
					</label>
				</div>
			</>
	};

	const checkContent = (inputs) => {
		if (inputs.phoneNumber === "") {
			toast.error(i18n.t("scheduledMessagesModal.toasts.missingNumber"))
			return
		}
		if (inputs.wpId === "") {
			toast.error(i18n.t("scheduledMessagesModal.toasts.missingConnection"))
			return
		}
		if (!inputs.sendAt) {
			toast.error(i18n.t("scheduledMessagesModal.toasts.missingSchedule"))
			return
		}
		if (inputs.message === "") {
			toast.error(i18n.t("scheduledMessagesModal.toasts.missingMessage"))
			return
		}
		medias ? handleSendMedia(inputs,medias) : handleSendMessage(inputs);
	}

	const handleSaveSchedule = (values) => {
		let scheduledMessageData = {
			...inputs,
			phoneNumber: values.phoneNumber ? values.phoneNumber : inputs.phoneNumber,
			message: values.message,
			wpId: values.wpId,
			sendAt: values.sendAt,
			status: "pending"
		};

		setInputs(inputs => ({
			...inputs,
			phoneNumber: values.phoneNumber ? values.phoneNumber : inputs.phoneNumber,
			message: values.message,
			wpId: values.wpId,
			sendAt: values.sendAt
		}));

		checkContent(scheduledMessageData)
	}

	const handleSendMedia = (inputs,medias) => {
		handleSendMessage(inputs,medias);
	}

	const handleSendMessage = async (inputs,medias) => {
		try {
			let values
			if (medias.length > 0) {
				values = new FormData();
				values.append("contactId", inputs.contactId);
				values.append("phoneNumber", inputs.phoneNumber);
				values.append("message", inputs.message);
				values.append("wpId", inputs.wpId);
				values.append("userId", inputs.userId);
				values.append("status", inputs.status);
				values.append("ticketId", ticketId);
				values.append("sendAt", inputs.sendAt);
				medias.forEach((media) => {
				values.append("file", media);
				});
			} else {
				values = {
					contactId: inputs.contactId,
					phoneNumber: inputs.phoneNumber,
					message: inputs.message,
					imagePath: null,
					wpId: inputs.wpId,
					userId: inputs.userId,
					status: inputs.status,
					ticketId:ticketId,
					sendAt: inputs.sendAt,
				};
			}
			
			if (messageId) {
				await api.put(`/scheduledMessages/${messageId}`, values);
			} else {
				await api.post('/scheduledMessages', values);
			}
			handleClose();
			toast.success(i18n.t("scheduledMessagesModal.toasts.success"));

		} catch (err) {
			toastError(err);
		}
	}

	return (
		<Dialog open={open} onClose={handleClose} scroll="paper">
			<DialogTitle id="form-dialog-title">
				{title}
			</DialogTitle>
			<Formik
				initialValues={inputs}
				enableReinitialize={true}
				validationSchema={ScheduleSchema}
				onSubmit={(values, actions, e) => {
					setTimeout(() => {
						handleSaveSchedule(values);
						actions.setSubmitting(false)
					}, 400);
				}}
			>
				{({ touched, errors, isSubmitting, values, setFieldValue }) => (
					<Form>
						<DialogContent dividers>
							<div className={classes.multFieldLine}>
								{loadContactField ?
									<>
										<Field
											as={TextField}
											label={i18n.t("scheduledMessagesModal.form.number")}
											id="phoneNumber"
											name="phoneNumber"
											variant="outlined"
											margin="dense"
											className={classes.textField}
											error={touched.phoneNumber && Boolean(errors.phoneNumber)}
											helperText={touched.phoneNumber && errors.phoneNumber}
											placeholder={systemVars.examples.phone}
											// onChange={e => { handleChange(e) }}
										/>
									</> : ''}
								<Field
									as={TextField}
									label={i18n.t("scheduledMessagesModal.form.date")}
									type="datetime-local"
									ampm="false"
									autoFocus
									InputLabelProps={{ shrink: true, }}
									inputProps={{ step: 600 }} //5 minutes
									name="sendAt"
									error={touched.sendAt && Boolean(errors.sendAt)}
									helperText={touched.sendAt && errors.sendAt}
									variant="outlined"
									margin="dense"
									// onChange={e => { handleChange(e) }}
									fullWidth
									className={classes.textField}
								/>
							</div>

							<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
								<InputLabel>{i18n.t("campaigns.form.id")}</InputLabel>
								<Field
									as={Select}
									label={i18n.t("campaigns.form.id")}
									name="wpId"
									variant="outlined"
									margin="dense"
									fullWidth
									placeholder={i18n.t("campaigns.form.id")}
									// onChange={e => { handleChange(e) }}
								>
									{ConnectionItems(whatsApps)}
								</Field>
							</FormControl>

							<Field
								as={TextField}
								label={i18n.t("campaigns.form.message")}
								id="message"
								name="message"
								onFocus={handleFocus}
								multiline
								minRows={3}
								fullWidth
								error={touched.message && Boolean(errors.message)}
								helperText={touched.message && errors.message}
								variant="outlined"
								margin="dense"
								// onChange={e => {handleChange(e)}}
							/>
							<InputVariables group={contactId ? "scheduledMessages" : "scheduledMessagesNoContact"} field={field} setFieldValue={setFieldValue} />
							<InputFile />


						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleClose}
								className={classes.buttonColorError}
								disabled={isSubmitting}
								variant="outlined"
							>
								{i18n.t("validation.buttons.cancel")}
							</Button>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								disabled={isSubmitting}
								className={classes.btnWrapper}
							>
								{contactId
									? `${i18n.t("validation.buttons.okEdit")}`
									: `${i18n.t("validation.buttons.schedule")}`}
								{isSubmitting && (
									<CircularProgress
										size={24}
										className={classes.buttonProgress}
									/>
								)}
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default ScheduleMessageModal;