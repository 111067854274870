import React from 'react';
import { format, parseISO, parse, isValid} from "date-fns";

export default props => {


  function format_number(number){
    return parseFloat(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

    const rows = props.opps.map( opp => {
        return(
            <tr>
                         <td style={{textAlign:"left"}}>{opp.contactname}</td>
                         <td style={{textAlign:"center"}}>{opp.oppname}</td> 
                         <td style={{textAlign:"center"}}>{format_number(opp.oppval)}</td> 
                         <td style={{textAlign:"center"}}>{opp.oppstage} ({opp.opppct}%)</td> 
                         <td style={{textAlign:"center"}}>{format(new Date(opp.updatedAt), "yyyy-MM-dd HH:MM")}</td>      
            </tr>
        )
      })

      
return(
           <div className="card-body table-responsive">
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th style={{width: "20%"}}>Contato</th>
                        <th style={{width: "20%", textAlign:"center"}}>Oportunidade</th>
                        <th style={{width: "20%", textAlign:"center"}}>Valor (R$)</th>
                        <th style={{width: "20%", textAlign:"center"}}>Estágio</th>
                        <th style={{width: "20%", textAlign:"center"}}>Atualizado em</th>                   
                      </tr>
                    </thead>
                    <tbody>
                      {rows}
                    </tbody>
                  </table>
                </div>
     )
}