const variables = [

    // Contact vars
    {value: 'firstName', label: 'variables.contact.firstName', groups: ['campaigns','connections','queues','quickAnswers','scheduledMessages','scheduledMessagesNoContact','ticket']},
    {value: 'fullName', label: 'variables.contact.fullName', groups: ['connections','queues','quickAnswers','scheduledMessages','ticket']},
    {value: 'email', label: 'variables.contact.email', groups: ['connections','queues','quickAnswers','scheduledMessages','ticket']},
    {value: 'phone', label: 'variables.contact.phone', groups: ['connections','queues','quickAnswers','scheduledMessages','scheduledMessagesNoContact','ticket']},
    {value: 'isGroup', label: 'variables.contact.isGroup', groups: ['connections','queues','quickAnswers','scheduledMessages','ticket']},
    {value: 'avatar', label: 'variables.contact.avatar', groups: ['connections','queues','quickAnswers','scheduledMessages','scheduledMessagesNoContact','ticket']},
    {value: 'contactCreate', label: 'variables.contact.dateCreate', groups: ['connections','queues','quickAnswers','scheduledMessages','ticket']},
    {value: 'contactUpdate', label: 'variables.contact.dateUpdate', groups: ['connections','queues','quickAnswers','scheduledMessages','ticket']},

    // Ticket Vars
    {value: 'ticketId', label: 'variables.ticket.id', groups: ['queues','quickAnswers','ticket']},
    // {value: 'ticketStatus', label: 'variables.ticket.status', groups: ['queues','quickAnswers','ticket']}, //solve translations
    {value: 'ticketLastMessage', label: 'variables.ticket.lastMessage', groups: ['queues','quickAnswers','ticket']},
    {value: 'ticketCreate', label: 'variables.ticket.dateCreate', groups: ['queues','quickAnswers','ticket']},
    {value: 'ticketUpdate', label: 'variables.ticket.dateUpdate', groups: ['queues','quickAnswers','ticket']},
    {value: 'ticketIsGroup', label: 'variables.ticket.isGroup', groups: ['queues','quickAnswers','ticket']},
    {value: 'ticketQueue', label: 'variables.ticket.queue', groups: ['quickAnswers','ticket']},
    
    // WahtsApp Vars
    {value: 'wppName', label: 'variables.ticket.wppName', groups: ['connections','queues','quickAnswers','scheduledMessages','ticket']},
    // {value: 'wppStatus', label: 'variables.ticket.wppStatus', groups: ['connections','queues','quickAnswers','scheduledMessages','ticket']}, // solve translation
    
    // User Vars
    {value: 'userName', label: 'variables.user.name', groups: ['quickAnswers','scheduledMessages','ticket']},
    {value: 'userEmail', label: 'variables.user.email', groups: ['quickAnswers','scheduledMessages','ticket']},
    // {value: 'userProfile', label: 'variables.user.profile', groups: ['quickAnswers','scheduledMessages','ticket']}, //solve translation
    
    // Date Vars
    {value:'day', label:'variables.date.day', groups: ['campaigns','connections','queues','quickAnswers','scheduledMessages','scheduledMessagesNoContact','ticket']},
    {value:'month', label:'variables.date.month', groups: ['campaigns','connections','queues','quickAnswers','scheduledMessages','scheduledMessagesNoContact','ticket']},
    {value:'year', label:'variables.date.year', groups: ['campaigns','connections','queues','quickAnswers','scheduledMessages','scheduledMessagesNoContact','ticket']},
    {value:'dateISO', label:'variables.date.iso', groups: ['campaigns','connections','queues','quickAnswers','scheduledMessages','scheduledMessagesNoContact','ticket']},
    {value:'dateUS', label:'variables.date.us', groups: ['campaigns','connections','queues','quickAnswers','scheduledMessages','scheduledMessagesNoContact','ticket']},
    {value:'dateBR', label:'variables.date.br', groups: ['campaigns','connections','queues','quickAnswers','scheduledMessages','scheduledMessagesNoContact','ticket']},
        
    //Time vars
    {value:'hour', label:'variables.time.hour', groups: ['campaigns','connections','queues','quickAnswers','scheduledMessages','scheduledMessagesNoContact','ticket']},
    {value:'minute', label:'variables.time.minute', groups: ['campaigns','connections','queues','quickAnswers','scheduledMessages','scheduledMessagesNoContact','ticket']},
    {value:'second', label:'variables.time.second', groups: ['campaigns','connections','queues','quickAnswers','scheduledMessages','scheduledMessagesNoContact','ticket']},

    // Misc Vars
    {value:'greeting', label:'variables.greeting', groups: ['campaigns','connections','queues','quickAnswers','scheduledMessages','scheduledMessagesNoContact','ticket']}, //solve translations
    {value:'protocol', label:'variables.protocol', groups: ['connections','queues','quickAnswers','scheduledMessages','ticket']},


    // File Defined Vars
    {value:'@param1', label:'@param1', groups: ['campaigns']},
    {value:'@param2', label:'@param2', groups: ['campaigns']},
    {value:'@param3', label:'@param3', groups: ['campaigns']},
    {value:'@param4', label:'@param4', groups: ['campaigns']},
    {value:'@param5', label:'@param5', groups: ['campaigns']},






]
export default variables;