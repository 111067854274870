import React from 'react';

import Chart from '../assets/lib/chartjs/Chart.js';
class PierChart extends React.Component {

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    console.log("props", props)
  }

  componentDidUpdate() {
        var labelsGrafico = [];
				var quantidades = [];
        var colors = [];
        if(this.props.data != null) {
          for( var idx = 0; idx < this.props.data.length; idx++ ){
            labelsGrafico.push( this.props.data[idx].status );
            quantidades.push( this.props.data[idx].quantidade );
            colors.push( this.props.data[idx].color );
          }
        }
				
    this.myChart.data.labels = labelsGrafico;
    this.myChart.data.datasets[0].data = quantidades;
    this.myChart.update();
  }

  componentDidMount() {
    var labelsGrafico = [];
    var quantidades = [];
    var colors = [];
    if(this.props.data != null) {
      for( var idx = 0; idx < this.props.data.length; idx++ ){
        labelsGrafico.push( this.props.data[idx].status );
        quantidades.push( this.props.data[idx].quantidade );
        colors.push( this.props.data[idx].color );
      }
    }

    this.myChart = new Chart(this.chartRef.current, {
      type: 'pie',
      data: {
        labels: labelsGrafico,
        datasets: [{
          data: quantidades,
          backgroundColor: this.props.colors
        }]
      }
    });
  }
      
    render() {
        return <canvas ref={this.chartRef} />;
    }
}

export default PierChart;