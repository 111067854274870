
import axios from "axios";


let apiUrl ='http://www.geohive.com.br/webservices/webservice.php'
let docUrl ='https://www.geohive.com.br/index.php?option=com_edocman&task=document.forcedownload&id=74';
let capiUrl='https://www.geohive.com.br/api/v1/';


let geoheaders = {
      'AuthType':'Basic',
      'Content-Type':'application/json',
      'Accept':'application/json',
      'Authorization':'Basic YWRtaW46cGFzc3dvcmQ=',
      'Access-Control-Allow-Origin':'http://main.mware.com.br',
      'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT, OPTIONS',
      'Access-Control-Allow-Headers': 'origin, content-type, accept, authorization, x-request-with',
      'Access-Control-Allow-Credentials':'true'
    }

    
export const hiveapi = axios.create({
	baseURL: apiUrl,
	withCredentials: false,
    headers:geoheaders
});


export const shapeapi = axios.create({
	baseURL: "http://www.geohive.com.br/shpmunicipais/5103403_CAR.zip",
	withCredentials: false,
    headers:geoheaders
});


