import React, { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";
import Avatar from "@material-ui/core/Avatar";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";


import { getBackendUrl } from "../../config"


import {blue, purple, red, green, teal, orange, amber} from '@material-ui/core/colors';

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Chip from '@material-ui/core/Chip';

import ConfirmationModal from "../../components/ConfirmationModal";

import CatalogItemViewModal from "../../components/CatalogItemViewModal";
import CatalogItemModal from "../../components/CatalogItemModal";

import toastError from "../../errors/toastError";



import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';

import BugReportIcon from '@material-ui/icons/BugReport';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';       
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'; 
import InfoIcon from '@material-ui/icons/Info';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';

const reducer = (state, action) => {
  
  if (action.type === "LOAD_CATALOG") {
    const catalogData = action.payload;
    const newcatalog = [];
    
    catalogData.forEach((catalogItem) => {
      const catalogIndex = state.findIndex((u) => u.id === catalogItem.id);
      if (catalogIndex !== -1) {
        state[catalogIndex] = catalogItem;
      } else {
        newcatalog.push(catalogItem);
      }
      
    });
   
    return [...state, ...newcatalog];
  }

  if (action.type === "DELETE_CATALOGITEM") {

    const catalogData = action.payload;
   
    catalogData.forEach((catalogItem) => {
      const catalogIndex = state.findIndex((u) => u.id === catalogItem.id);
      if (catalogIndex !== -1) {
        state.splice(catalogIndex, 1);
      } 
      /*else {
        newcatalog.push(catalogItem);
      }*/
    });
      
    return [...state];




  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
  green1: {
		color: green[500]
	},
  red1: {
		color: red[500]
	},

  blue1: {
		color: blue[500]
	},
  teal1: {
		color: teal[500]
	},
  orange1: {
		color: orange[500]
	},
  purple1: {
		color: purple[500]
	},
  amber1: {
		color: amber[500]
	},
avatar:{
    width: "60px",
    height: "60px",
    borderRadius:"5px"
},

}));

const Catalog = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
 
  const [catalogItemViewModalOpen, setCatalogItemViewModalOpen] = useState(false);
  const [catalogItemModalOpen, setCatalogItemModalOpen] = useState(false);
  const [catalogItem, setCatalogItem] = useState();

  const [searchParam, setSearchParam] = useState("");
  const [catalog, dispatch] = useReducer(reducer, []);
  const [deletingCampaign, setDeletingCampaign] = useState("");


  const backendServer = getBackendUrl();
  
  const directoryKeyPart1=backendServer.split(".")[0];
  const directoryKey="_"+directoryKeyPart1.split("api-")[1]+"/";

  
  

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchCampaign = async () => {
        try {
          const { data } = await api.get("/catalog/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_CATALOG", payload: data.catalog });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchCampaign();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("campaigns", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_USERS", payload: data.user });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CAMPIGN", payload: +data.userId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);


  const handleOpenCatalogItemAddModal = () => {
      
      setCatalogItemModalOpen(true)

  }

  const handleOpenCatalogItemViewModal = (action, catalogItem) => {

    

    if (action === "show") {
       
        setCatalogItem(catalogItem);
        setCatalogItemModalOpen(false);
        setCatalogItemViewModalOpen(true);
    }

    if (action === "edit") {

      
      setCatalogItem(catalogItem);
      setCatalogItemModalOpen(true);
      setCatalogItemViewModalOpen(false);
  }

  };

  const handleCloseCatalogItemModal = () => {
    setCatalogItem(null);
    setCatalogItemModalOpen(false);

    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchCatalog = async () => {
        try {
          const { data } = await api.get("/catalog/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "RESET", payload: data.catalog });
          dispatch({ type: "LOAD_CATALOG", payload: data.catalog });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchCatalog();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  };

  const handleCloseCatalogItemViewModal = () => {
    setCatalogItem(null);
    setCatalogItemModalOpen(false);

    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchCampaign = async () => {
        try {
          const { data } = await api.get("/catalog/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_CATALOG", payload: data.catalog });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchCampaign();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };
 
  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleDeleteCatalogItem = async (action, catalogItem) => {
    if (action === "delete") {
       
        try {
          if (catalogItem.id!=0) {
              await api.delete(`/catalog/${catalogItem.id}`);
              
              setTimeout(async ()=>{
                  const { data } = await api.get("/catalog/", {
                    params: { searchParam, pageNumber },
                  });
                  dispatch({ type: "LOAD_CATALOG", payload: data.catalog });
                  setHasMore(data.hasMore);
                  setLoading(false);
              },500)
               
          } 
          
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
    }
    
  }

  const getItemBackground = catalogItem => {
		return (
			<>
				{catalogItem.active =="false" && (
						<TableCell align="center" style = {{color:'red'}} >{catalogItem.itemName}</TableCell>
				)}
        {catalogItem.active =="true" && (
						<TableCell align="center" >{catalogItem.itemName}</TableCell>
				)}
			</>
		);
	};
  
  const handleDelete =  async (action, catalogItem) => {
  }

  const handlecatalogItemCategories = categories => {

    
    let iCategories=[]

    for (let i=0; i<categories.length; i++){
    
        const found = iCategories.find((element) => element == categories[i].categoryName);
            if(found){}
            else{iCategories.push(categories[i].categoryName)}

    }

    return (
      iCategories.map((iCategory) => (
            <>
            
               <Chip  label={iCategory} className={classes.chip} size = "small" style={{borderColor: blue[500], color: blue[800], marginLeft:"3px"}} variant="outlined" />
          
            </>
        ))
    );
  }



  return (
    <MainContainer>
      <CatalogItemViewModal
            open={catalogItemViewModalOpen}
            onClose={handleCloseCatalogItemViewModal}
            catalogItem={catalogItem}
      />

      <CatalogItemModal
            open={catalogItemModalOpen}
            onClose={handleCloseCatalogItemModal}
            catalogItem={catalogItem}
      />   
      
      <MainHeader>
        <Title>{i18n.t("catalog.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("campaign.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
           
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCatalogItemAddModal}
          >
            {i18n.t("catalog.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
                <TableCell align="center" >
                    {i18n.t("catalog.table.itemId")}
                </TableCell>
                <TableCell align="center" >
                    {i18n.t("catalog.table.productImg")}
                </TableCell>
           
              <TableCell align="center" >
                {i18n.t("catalog.table.itemName")}</TableCell>
              <TableCell align="center">
                {i18n.t("catalog.table.baseValue")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("catalog.table.deliveryValue")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("catalog.table.manufacturer")}
              </TableCell>
            
              <TableCell            
              align="center">
                {i18n.t("catalog.table.category")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("catalog.table.dimensions")}
              </TableCell>   
          
              <TableCell align="center">
                {i18n.t("catalog.table.action")}
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {catalog?.length > 0 && catalog.map(catalogItem => (
                /*
                      {if(catalogItem.deleted == 1 ){
                        console.log("foundOne.");
                        backgroundColor = pickHex(red,white,ratio-1);              
                      } else {
                        backgroundColor = pickHex(white,green, ratio);              
                      }}

                  */  
                <TableRow key={catalogItem.id}>
                    <TableCell align="center">{catalogItem.id}</TableCell>
                    <TableCell style={{ paddingRight: 0 }}>
                    <Avatar 
                        src={backendServer+"/public/"+catalogItem.productImg}
                        className={classes.avatar}  
                        onClick={e => {
                            handleOpenCatalogItemViewModal("show", catalogItem);
                        }} />
                  </TableCell>
     
                  {getItemBackground(catalogItem)}
                
                  <TableCell align="center" >{"R$ "+catalogItem.baseValue}</TableCell>
                  <TableCell align="center" >{"R$ "+catalogItem.deliveryValue}</TableCell>
                  <TableCell align="center">{catalogItem.manufacturer}</TableCell> 
                  <TableCell align="center">{handlecatalogItemCategories(catalogItem.categories)}</TableCell>
              
                  <TableCell align="center">{catalogItem.dimensions}</TableCell>
                
                  
                  
                  <TableCell align="center">

              
                    <IconButton
                      size="small"
                      onClick={e => {
                        handleOpenCatalogItemViewModal("edit", catalogItem);
                        }} 
                      >
                    
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        handleDeleteCatalogItem("delete", catalogItem);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>



                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={8} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Catalog;
