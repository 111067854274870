import React, { useState, useEffect, useContext } from "react";

import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography";

import useTickets from "../../hooks/useTickets"

import { AuthContext } from "../../context/Auth/AuthContext";

import { i18n } from "../../translate/i18n";

import Chart from "./Chart"
import Satisfaction from "./Satisfaction"
import UserStats from "./UserStats"
import SalesFunnel from "./SalesFunnel"
import ProcessFunnel from "./ProcessFunnel"
import DashboardPrincipal from "./ChartsBoby/dashboards/DashboardPrincipal";
import ExportToExcel from "../../components/xlsBulkExport"
import axios from 'axios'

import api from "../../services/api";
import { Can } from "../../components/Can"
const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 240,
	},
	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 120,
	},
	customFixedHeightPaperLg: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "100%",
	},
}))

const Dashboard = () => {
	const classes = useStyles()

	const { user } = useContext(AuthContext);
	var userQueueIds = [];

	const [data, setData] = useState([])
  	const fileName = "BulkDataExport"; // here enter filename for your excel file


	
	  useEffect(() => {
		const fetchData = async () =>{
		 const ret  = await api.get("/bulkdata");
		 setData(ret.data)
		}
		fetchData()
	  }, [])





	if (user.queues && user.queues.length > 0) {
		userQueueIds = user.queues.map(q => q.id);
	}

	const GetTickets = (status, showAll, withUnreadMessages) => {

		const { count } = useTickets({
			status: status,
			showAll: showAll,
			withUnreadMessages: withUnreadMessages,
			queueIds: JSON.stringify(userQueueIds)
		});
		return count;
	}

	return (
		<div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container spacing={3}>

				<Grid item xs={12}>
						<Paper style={{ padding:"20px"}}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
										{i18n.t("dashboard.messages.filteredStats.title")}
							</Typography>
							


							<DashboardPrincipal />

						</Paper>
					</Grid>

					<Grid item xs={12}>
						<Paper style={{ padding:"20px"}}>
							
							aaaaaaaaaaaaaaaaaaaaaaaaaaa




						</Paper>
					</Grid>



					
					<Can
						role={user.profile}
						perform="drawer-admin-items:view"
						yes={() => (
							<Grid item xs={4}>
								<Paper style={{ overflow: "hidden", padding:"10px", textAlign:"center" }}>
									<ExportToExcel apiData={data} fileName={fileName} />
								</Paper>
							</Grid>
							)}
					/>

					<Grid item xs={12}>
						<Paper className={classes.fixedHeightPaper}>
							<Chart />
						</Paper>
					</Grid>
					
					<Satisfaction />

					<Grid item xs={12}>
						<Paper className={classes.fixedHeightPaper} style={{minHeight:240, overflow:"hidden", padding:"20px"}}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
										{i18n.t("dashboard.messages.distribution.title")}
							</Typography>
							<UserStats />
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<Paper style={{minHeight:240, padding:"20px"}}>
							<SalesFunnel/>
						</Paper>
					</Grid>

				</Grid>
			</Container>
		</div>
	)
}

export default Dashboard