import React, { useEffect, useState } from "react";

import {
    Chip,
    makeStyles,
    Paper,
    TextField
} from "@material-ui/core";

import api from "../../services/api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	container: {
        padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
        borderRadius: 0
    },
    chips: {
		display: "flex",
		flexWrap: "wrap",
		'&:hover': {
			filter: 'brightness(120%)',
		},
	},
	chip: {
		margin: 2,
	}
}));


export function TagsFilterNoPaper ({onFiltered, onChange, items }) {
    const classes = useStyles();

    const [tags, setTags] = useState([]);
    const [selecteds, setSelecteds] = useState((items?items:[]));

    useEffect(() => {
        async function fetchData () {
            await loadTags();
        }
        fetchData();
    }, []);

    const loadTags = async () => {
        try {
            const { data } = await api.get(`/tags/list`);
            setTags(data);
        } catch (err) {
            toastError(err);
        }
    }

    const onTagChange = async (value) => {
        setSelecteds(value);
        onFiltered(value);
        //onChange(value);
       
    }


    return (
            <Autocomplete
                multiple
                size="small"
                options={tags}
                value={selecteds}
                onChange={(e, v, r) => {
                                onTagChange(v)
                                console.log("onTagChange", selecteds)
                               
                        }}
                getOptionLabel={(option) => option.name}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            color="secondary"
                            style={{
                                backgroundColor: option.color+"20",
                                borderColor: option.color,
                                color: option.color,
                            }}
                            variant="outlined"
                            label={option.name}
                            className={classes.chip}
                            {...getTagProps({ index })}
                            size="small"
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder={i18n.t("tags.placeholder.filterByName")} />
                )}

                PaperComponent={({ children }) => (
                    <Paper style={{width: 400, marginLeft: 12}}>
                        {children}
                    </Paper>
                )}
            />
       
    )
}