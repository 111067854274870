import openSocket from "socket.io-client";
import { getBackendUrl } from "../config";

function connectToSocket() {

  let userId = null;
  
  if (localStorage.getItem("userId")) {
    userId = localStorage.getItem("userId");
  }
  /*
  
  const backendServer = getBackendUrl();

  return openSocket( backendServer, {
    transports: ["websocket", "polling", "flashsocket"],
    pingTimeout: 18000,
    pingInterval: 18000,
    query: { userId },
  });
  */
  return openSocket(getBackendUrl(),{
    transports: ["websocket", "polling", "flashsocket"],
    //query: { userId }
                                   }
          );
}

export default connectToSocket;