
import { makeStyles } from "@material-ui/core/styles";
import React, {useState, useEffect, useReducer} from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import Paper from "@material-ui/core/Paper";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";

import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { format, parseISO, parse, addMinutes, subMinutes } from "date-fns";

import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	CircularProgress,
	TextField,
	Switch,
	FormControlLabel,
  } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	popupbox : {
		position: "fixed",
		background: "#00000050",
		width: "100%",
		height: "100vh",
		top: "0",
		left: "0",
        zIndex:"1000"

	  },
	  box : {
		position: "relative" ,
		width: "35%" ,
		margin: "0 auto" ,
		height: "auto" ,
		maxHeight: "90vh" ,
		marginTop: "calc(100vh - 85vh - 20px)" ,
		background: "#fff" ,
		borderRadius: "4px" ,
		padding: "20px" ,
		border: "1px solid #999" ,
		overflow: "auto" ,
		zIndex:"1000"
	  },
	   
	  closeicon : {
		content: "x" ,
		cursor: "pointer" ,
		position: "fixed" ,
		right: "calc(22%)" ,
		top: "calc(100vh - 85vh - 33px)" ,
		background: "#ededed" ,
		width: "25px" ,
		height: "25px" ,
		borderRadius: "50%" ,
		lineHeight: "20px" ,
		textAlign: "center" ,
		border: "1px solid #999" ,
		fontSize: "20px" ,
        zIndex:"1000"
	  }



}));

const TicketHistoryModal = props => {
	const classes = useStyles();

	const initialState = {
		messageId: "",
		messageText: "",	
		messageFK: ""
	};

	const [ticketHistoryData, setTicketHistoryData] = useState([]);
	const [campaignMsgs, setcampaignMsgs] = useState([]);

	const [CampaignMsg, setCampaignMsg] = useState(initialState);

	const [inEditMessageId, setinEditMessageId] = useState(0)
	const [inEditMessageFK, setinEditMessageFK] = useState(0)

	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
		(async () => {
		  setLoading(true);
		  try {
			const ret  = await api.post("/tickets/"+props.ticketId);
			console.log(ret.data);
			setTicketHistoryData(ret.data);
			setLoading(false);
		  } catch (err) {
			toastError(err);
			setLoading(false);
		  }
		})();
	  }, []);
	  
      function TimezoneOffset(date) {
        const offset = date.getTimezoneOffset();
        return format(Math.sign(offset) !== -1 ? addMinutes(date, offset) : subMinutes(date, Math.abs(offset)), "yyyy-MM-dd HH:mm");
    }

	return (
		<div className={classes.popupbox}>
			<div className={classes.box}>
				<span className={classes.closeicon} onClick={props.handleClose} >x</span>
			
			
					<MainContainer>
						<MainHeader>
							<Title>{i18n.t("historyTicketModal.title")}</Title>
						</MainHeader>




						<Paper className={classes.mainPaper} variant="outlined">
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell align="center">{i18n.t("historyTicketModal.fieldData")}</TableCell>
										<TableCell align="center">{i18n.t("historyTicketModal.fieldUser")}</TableCell>
                                        <TableCell align="center">{i18n.t("historyTicketModal.fieldAction")}</TableCell>
                                        <TableCell align="center">{i18n.t("historyTicketModal.fieldtargetUser")}</TableCell>
									</TableRow>
								</TableHead>	
                                <TableBody>
                                <>
                                {ticketHistoryData.map((tktlog) => (
                                    <TableRow key={tktlog.id}>
                                        
					
												<TableCell align="center">{TimezoneOffset(new Date(tktlog.createdat))}</TableCell>
												<TableCell align="center">{(tktlog.username == "Sistema" && tktlog.actionname=="transferiu")?tktlog.targetname:tktlog.username}</TableCell>
												<TableCell align="center">{(tktlog.username == "Sistema" && tktlog.actionname=="transferiu")?"abriu":tktlog.actionname}</TableCell>
												<TableCell align="center">{(tktlog.username == "Sistema")?"": (tktlog.targetname == "Sistema")?"":tktlog.targetname}</TableCell>
												
                                        
                                    </TableRow>
                                ))}
                                </>
                                </TableBody>

							</Table>
						</Paper>
					</MainContainer>
		
			</div>
		</div>	
	);


};

export default TicketHistoryModal;
