import React, { useCallback, useContext, useEffect, useRef, useState, useReducer, useThemes  } from "react";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import Swal from 'sweetalert2'

import FacebookLogin from 'react-facebook-login';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import { blue } from "@material-ui/core/colors";
import connKill from "../../assets/killwarning.svg"
import kill from "../../assets/kill.svg"
import {
	Button,
	TableBody,
	TableRow,
	TableCell,
	IconButton,
	Table,
	TableHead,
	Paper,
	Tooltip,
	Typography,
	CircularProgress,
} from "@material-ui/core";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import WabaIcon from '../../assets/waba2.svg'
import {CallMissedOutgoing } from "@material-ui/icons";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Chip from '@material-ui/core/Chip';
import BlockIcon from '@material-ui/icons/Block';
import VisibilityIcon from "@material-ui/icons/Visibility";

import {
	Edit,
	CheckCircle,
	SignalCellularConnectedNoInternet2Bar,
	SignalCellularConnectedNoInternet0Bar,
	SignalCellular4Bar,
	CropFree,
	DeleteOutline,
	RedeemOutlined,
} from "@material-ui/icons";

import Grid from "@material-ui/core/Grid";

import ch_waba from "../../assets/channel_waba.svg"
import ch_whatsapp from "../../assets/channel_whatsapp.svg"
import ch_instagram from "../../assets/channel_instagram.svg"
import ch_messenger from "../../assets/channel_messenger.svg"
import ch_facebook from "../../assets/channel_facebook.svg"


import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import AnnouncementIcon from '@material-ui/icons/Announcement';

import api from "../../services/api";
import WhatsAppModal from "../../components/WhatsAppModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import QrcodeModal from "../../components/QrcodeModal";
import { i18n } from "../../translate/i18n";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../errors/toastError";


import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import WabaAccountModal from "../../components/WabaAccountModal"


const useStyles = makeStyles(theme => ({
	mainPaper: {
		flex: 1,
		padding: theme.spacing(1),
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	tooltip: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: theme.typography.pxToRem(14),
		border: "1px solid #dadde9",
		maxWidth: 450,
	},
	tooltipPopper: {
		textAlign: "center",
	},
	buttonProgress: {
		color: green[500],
	},
	chatContainer: {
		flex: 1,
		// // backgroundColor: "#eee",
		// padding: theme.spacing(4),
		height: `calc(100% - 48px)`,
		overflowY: "hidden",
	  },
	
	  chatPaper: {
		// backgroundColor: "red",
		display: "flex",
		height: "100%",
	   overflow: 'auto'
	  },
	  avatar:{
        width: "40px",
        height: "40px",
        borderRadius:"20px",
		fontSize:"30px"
    },
	ticketActionButtons: {
		maxWidth: "50%",
		flexBasis: "50%",
		display: "flex",
		[theme.breakpoints.down("sm")]: {
		  maxWidth: "100%",
		  flexBasis: "100%",
		  marginBottom: "5px",
		},
	  },
	
}));

const nbsp = '\u00A0';
const breakline = '\u000A';

const CustomToolTip = ({ title, content, children }) => {
	const classes = useStyles();

	return (
		<Tooltip
			arrow
			classes={{
				tooltip: classes.tooltip,
				popper: classes.tooltipPopper,
			}}
			title={
				<React.Fragment>
					<Typography gutterBottom color="inherit">
						{title}
					</Typography>
					{content && <Typography>{content}</Typography>}
				</React.Fragment>
			}
		>
			{children}
		</Tooltip>
	);
};

const CustomComment2 = ({ title, content }) => {
	return (
			<>
			
					<Typography 
						gutterBottom color="inherit">
						{title}
					</Typography>
					{content && <Typography >{content}</Typography>}
			</>	
			
	)
};

const CustomComment = ({ title, content }) => {
	return (
			<>
				<ListItemText
					primary={title}
					secondary={content}																				
				/>
			</>	
			
	)
};


const cbaTime = date => new Date(date).toLocaleString('pt-BR', { timeZone: 'America/Cuiaba' });



const Connections = () => {
	const classes = useStyles();



	const {whatsApps, loading } = useContext(WhatsAppsContext);
	const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
	const [wabaModalOpen, setWabaModalOpen] = useState(false);
	const [qrModalOpen, setQrModalOpen] = useState(false);
	const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [pings, setPings] = useState([]);
	const [conns, setConns] = useState([]);



	
	function delay(ms) {
		return new Promise( resolve => setTimeout(resolve, ms) );
	}
	

	
	function extract_time(string){
		var date = new Date(string);
		return date.toISOString().substring(11, 19);
	}

	

	const confirmationModalInitialState = {
		action: "",
		title: "",
		message: "",
		whatsAppId: "",
		open: false,
	};
	const [confirmModalInfo, setConfirmModalInfo] = useState(
		confirmationModalInitialState
	);

	const handleStartWhatsAppSession = async whatsAppId => {

		Swal.fire({
			title: 'Atenção!',
			text:`Ao clicar no botão abaixo você vai apenas renovar a validade das credenciais existentes, sem precisar de ler o QRCode. Dseseja continuar?`,
			icon: 'success',
			showDenyButton: true,
			showCancelButton:false,
			confirmButtonText: 'Sim',
			denyButtonText: `Não`,
		  }).then(async (result) => {
			if (result.isConfirmed) {
		
				try {
					await api.post(`/whatsappsession/${whatsAppId}`);
				} catch (err) {
					toastError(err);
				}
		
			}
		  })


		
	};

	const handleRequestNewQrCode = async whatsAppId => {
		try {
			await api.post(`/whatsappsession/${whatsAppId}`);
			
			await api.put(`/whatsappsession/${whatsAppId}`);
		} catch (err) {
			toastError(err);
		}
	};

	const handleKillRequest = async(whatsAppId) =>{
		Swal.fire({
			title: 'Muita Atenção!',
			text:`Você está prestes a deletar completamente as configurações dessa conexão ao Whatsapp. Isto resetará a sua criptografia e reiniciará sua instância do mWare. Se não tem a certeza do que está fazendo, não continue!!!`,
			//icon: "warning",
			imageUrl: connKill,
      		imageWidth: 100,
			showDenyButton: true,
			showCancelButton:false,
			confirmButtonText: 'Tenho a Certeza!',
			denyButtonText: `Não Resete`,
		  }).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await handleKillConfirmed(whatsAppId)
				} catch (err) {
					toastError(err);
				}
		
			}
		  })
	}

	const handleKillConfirmed = async whatsAppId => {
		try {
			await api.delete(`/whatsappcreds/${whatsAppId}`);
		} catch (err) {
			toastError(err);
		}
	};

	const handleOpenWhatsAppModal = () => {
		setSelectedWhatsApp(null);
		setWhatsAppModalOpen(true);
	};

	const handleCloseWhatsAppModal = useCallback(() => {
		setWhatsAppModalOpen(false);
		setSelectedWhatsApp(null);
	}, [setSelectedWhatsApp, setWhatsAppModalOpen]);

	const handleOpenWabaModal = () => {
		setSelectedWhatsApp(null);
		setWabaModalOpen(true);
	};

	const handleCloseWabaModal = useCallback(() => {
		setWabaModalOpen(false);
		setSelectedWhatsApp(null);
	}, [setSelectedWhatsApp, setWabaModalOpen]);


	const handleOpenQrModal = whatsApp => {
		setSelectedWhatsApp(whatsApp);
		setQrModalOpen(true);
	};

	const handleCloseQrModal = useCallback(() => {
		setSelectedWhatsApp(null);
		setQrModalOpen(false);
	}, [setQrModalOpen, setSelectedWhatsApp]);

	const handleEditWhatsApp = whatsApp => {

		setSelectedWhatsApp(whatsApp);
		if(whatsApp.channel=="whatsapp"){
			setWhatsAppModalOpen(true);
		}else if(whatsApp.channel=="waba"){
			setWabaModalOpen(true);
		}

	};

	const handleOpenConfirmationModal = (action, whatsAppId) => {
		if (action === "disconnect") {
			setConfirmModalInfo({
				action: action,
				title: i18n.t("connections.confirmationModal.disconnectTitle"),
				message: i18n.t("connections.confirmationModal.disconnectMessage"),
				whatsAppId: whatsAppId,
			});
		}

		if (action === "delete") {
			setConfirmModalInfo({
				action: action,
				title: i18n.t("connections.confirmationModal.deleteTitle"),
				message: i18n.t("connections.confirmationModal.deleteMessage"),
				whatsAppId: whatsAppId,
			});
		}
		setConfirmModalOpen(true);
	};

	const handleSubmitConfirmationModal = async () => {
		if (confirmModalInfo.action === "disconnect") {
			try {
				await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
			} catch (err) {
				toastError(err);
			}
		}

		if (confirmModalInfo.action === "delete") {
			try {
				await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
				toast.success(i18n.t("connections.toasts.deleted"));
			} catch (err) {
				toastError(err);
			}
		}

		setConfirmModalInfo(confirmationModalInitialState);
	};

	const renderActionButtons = whatsApp => {
		return (
			<>
				{whatsApp.status === "qrcode" && (
					<Button
						size="small"
						variant="contained"
						color="primary"
						onClick={() => handleOpenQrModal(whatsApp)}
					>
						{i18n.t("connections.buttons.qrcode")}
					</Button>
				)}
				{whatsApp.status === "DISCONNECTED" && (
					<>
						<Button
							size="small"
							variant="outlined"
							color="primary"
							onClick={() => handleStartWhatsAppSession(whatsApp.id)}
						>
							{i18n.t("connections.buttons.tryAgain")}
						</Button>{" "}
						<Button
							size="small"
							variant="outlined"
							color="secondary"
							onClick={() => handleRequestNewQrCode(whatsApp.id)}
						>
							{i18n.t("connections.buttons.newQr")}
						</Button>
					</>
				)}
				{(whatsApp.status === "CONNECTED" ||
					whatsApp.status === "PAIRING" ||
					whatsApp.status === "TIMEOUT") && (
					<Button
						size="small"
						variant="outlined"
						color="secondary"
						onClick={() => {
							handleOpenConfirmationModal("disconnect", whatsApp.id);
						}}
					>
						{i18n.t("connections.buttons.disconnect")}
					</Button>
				)}
				{(whatsApp.status === "STALLED") && (
					<Button
						size="small"
						variant="outlined"
						color="#B53737"
						onClick={() => {
							handleOpenConfirmationModal("disconnect", whatsApp.id);
						}}
					>
						{i18n.t("connections.buttons.stalled")}
					</Button>
				)}






				{whatsApp.status === "OPENING" && (
					<Button size="small" variant="outlined" disabled color="default">
						{i18n.t("connections.buttons.connecting")}
					</Button>
				)}
			</>
		);
	};

	const renderStatusToolTips = whatsApp => {
		return (
			<div className={classes.customTableCell}>
				{whatsApp.status === "DISCONNECTED" && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.disconnected.title")}
						content={i18n.t("connections.toolTips.disconnected.content")}
					>
						<SignalCellularConnectedNoInternet0Bar color="secondary" />
					</CustomToolTip>
				)}
				{whatsApp.status === "OPENING" && (
					<CircularProgress size={24} className={classes.buttonProgress} />
				)}
				{whatsApp.status === "qrcode" && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.qrcode.title")}
						content={i18n.t("connections.toolTips.qrcode.content")}
					>
						<CropFree />
					</CustomToolTip>
				)}
				{whatsApp.status === "CONNECTED" && (
					<CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
						<SignalCellular4Bar style={{ color: green[500] }} />
					</CustomToolTip>
				)}
				{(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.timeout.title")}
						content={i18n.t("connections.toolTips.timeout.content")}
					>
						<SignalCellularConnectedNoInternet2Bar color="secondary" />
					</CustomToolTip>
				)}
				{(whatsApp.status === "STALLED" ) && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.stalled.title")}
						content={i18n.t("connections.toolTips.stalled.content")}
					>
						<SignalCellularConnectedNoInternet2Bar style={{ color: red[500] }} />
					</CustomToolTip>
				)}
			</div>
		);
	};

	const renderComment = whatsApp => {
		return (
			<div className={classes.customTableCell}>
				{whatsApp.status === "DISCONNECTED" && (
					<CustomComment
						title={i18n.t("connections.toolTips.disconnected.title")}
						content={i18n.t("connections.toolTips.disconnected.content")}
					/>

				)}
				{whatsApp.status === "OPENING" && (
					<CustomComment
						title={i18n.t("connections.toolTips.disconnected.title")}
						content={i18n.t("connections.toolTips.disconnected.content")}
					/>
				)}
				{whatsApp.status === "qrcode" && (
					<CustomComment
						title={i18n.t("connections.toolTips.qrcode.title")}
						content={i18n.t("connections.toolTips.qrcode.content")}
					/>
						
				)}
				{whatsApp.status === "CONNECTED" && (
					<CustomComment 
						title={i18n.t("connections.toolTips.connected.title")} 
						content={i18n.t("connections.toolTips.connected.content")}/>
						
				)}
				{(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
					<CustomComment
						title={i18n.t("connections.toolTips.timeout.title")}
						content={i18n.t("connections.toolTips.timeout.content")}
					/>
				)}
				{(whatsApp.status === "STALLED" ) && (
					<CustomComment
						title={i18n.t("connections.toolTips.stalled.title")}
						content={i18n.t("connections.toolTips.stalled.content")}
					/>
				)}
			</div>
		);
	};

	const renderStatus = whatsApp => {
		return (
				<>
					{whatsApp.status === "DISCONNECTED" && (
							<SignalCellularConnectedNoInternet0Bar color="secondary" style={{fontSize:32}}/>
					)}
					{whatsApp.status === "OPENING" && (
						<CircularProgress style={{fontSize:32}} className={classes.buttonProgress} />
					)}
					{whatsApp.status === "qrcode" && (
							<CropFree style={{fontSize:32}} />
					)}
					{whatsApp.status === "CONNECTED" && (
							<SignalCellular4Bar style={{ color: green[500] , fontSize:32 }} />
					)}
					{(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
							<SignalCellularConnectedNoInternet2Bar color="secondary" style={{fontSize:32 }}/>
					)}
					{(whatsApp.status === "STALLED" ) && (
							<SignalCellularConnectedNoInternet2Bar style={{ color: red[500],fontSize:32 }}/>	
				)}
				</>
		);
	};

	const responseFacebook = (response) => {
		console.log(response);
	  }
	   



	return (


		<MainContainer>
			<ConfirmationModal
				title={confirmModalInfo.title}
				open={confirmModalOpen}
				onClose={setConfirmModalOpen}
				onConfirm={handleSubmitConfirmationModal}
			>
				{confirmModalInfo.message}
			</ConfirmationModal>
			<QrcodeModal
				open={qrModalOpen}
				onClose={handleCloseQrModal}
				whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
			/>
			<WhatsAppModal
				open={whatsAppModalOpen}
				onClose={handleCloseWhatsAppModal}
				whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
			/>

			<WabaAccountModal
				open={wabaModalOpen}
				onClose={handleCloseWabaModal}
				whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
			/>


			<MainHeader>
				<Title>{i18n.t("connections.title")}</Title>
				<MainHeaderButtonsWrapper>
					<Button
						variant="contained"
						color="primary"
						onClick={handleOpenWhatsAppModal}
					>
						<img src={ch_whatsapp} style={{width:15}}/>&nbsp;&nbsp;Adicionar Whatsapp
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={handleOpenWabaModal}
					>
						<img src={ch_waba} style={{width:15}} />&nbsp;&nbsp;Adicionar WABA
					</Button>

					<Button
						variant="contained"
						color="primary"
						onClick={handleOpenWabaModal}
					>
						<img src={ch_instagram} style={{width:15}} />&nbsp;&nbsp;Adicionar Instagram
					</Button>

					<Button
						variant="contained"
						color="primary"
						onClick={handleOpenWabaModal}
					>
						<img src={ch_facebook} style={{width:15}} />&nbsp;&nbsp;Adicionar Facebook
					</Button>
				</MainHeaderButtonsWrapper>
			</MainHeader>

			
						<Grid container 
						spacing={3}
						direction="row"
						justifyContent="space-around"
						alignItems="stretch"  >
										{whatsApps?.length > 0 && (
											<>
												{whatsApps?.map(whatsapp => (

													
															
																<Grid  item xs="auto" style={{marginTop:"5px", marginBottom:"5px", borderRadius:"5px"}} variant="outlined">
																	<>
																	<Paper elevation={3} style={{padding:5, height: '300px', width:"300px"}}>

																	
																	{true && (
																				<>
													
																				<div className={classes.ticketActionButtons} style={{float: 'right'}}>
																					<span title="Editar">
																						<IconButton
																							size="small"
																							onClick={() => handleEditWhatsApp(whatsapp)}
																							style={{ backgroundColor: '#ffffff', margin:2}}
																							variant="contained"
																						>
																							<Edit style={{  color: blue[500]}} />
																						</IconButton>
																					</span>

																					<span title="Remover">
																						<IconButton
																							size="small"
																							onClick={e => {
																								handleOpenConfirmationModal("delete", whatsapp.id);
																							}}
																							style={{ backgroundColor: '#ffffff', margin:2}}
																							variant="contained"
																						>
																							<DeleteOutline style={{  color: red[500]}}  />
																						</IconButton>	
																					</span>

																					<span title="Resetar">
																							<IconButton
																								size="small"
																								onClick={() => handleStartWhatsAppSession(whatsapp.id)}
																								style={{ backgroundColor: '#ffffff', margin:2}}
																								variant="contained"
																							>
																								<AutorenewIcon color="secondary"/>
																							</IconButton>	
																						</span>

																					{whatsapp?.status=="qrcode" && (
																						<span title="Ler QR Code">
																							<IconButton
																							size="small"
																							onClick={() => handleOpenQrModal(whatsapp)}
																							style={{ backgroundColor: '#ffffff', margin:2}}
																							variant="contained"
																						>
																							<CropFree/>
																						</IconButton>	
																						</span>		
																					)}
																				</div>
																				</>
																		)}

																		<ListItem dense alignItems="flex-start">

																		{whatsapp?.channel=="whatsapp" && (
																			<ListItemAvatar >
																					<WhatsAppIcon style={{ backgroundColor: "#ffffff", color: green[500], fontSize:32 }}  />
																			</ListItemAvatar>
																		)}
																		{whatsapp?.channel=="waba" && (
																			<ListItemAvatar >
																					<img src={WabaIcon} style={{ width:"33px", backgroundColor: "#ffffff", color: green[500], fontSize:32 }}  />
																			</ListItemAvatar>
																		)}
																			
																			
																			<ListItemText
																				primary={<span>
																					<Typography
																					
																					component="span"
																					variant="h6"
																					color="textPrimary"
																					
																					>
																					{"Nome: " + whatsapp?.name}
																					</Typography>
																				</span>}
																				
																			
																				secondary={whatsapp?.number}
																						
																			/>
																		</ListItem>

																		<ListItem dense alignItems="flex-start">
																			
																			<ListItemText																		
																				primary={"Última Atualização: " + cbaTime(whatsapp.updatedAt)}
																						
																			/>
																		</ListItem>

																		<ListItem dense alignItems="flex-start">
																				{whatsapp?.isDefault==1 && (
																					<Chip  label="Padrão" icon={<CheckCircle style={{ color: green[500] }} />} className={classes.chip} size = "small" style={{borderColor:green[500], color:green[800], marginLeft:"3px"}} variant="outlined" />
																				)}
																				{whatsapp?.outChannel==1 && (
																					<Chip  label="Disparo" icon={<AnnouncementIcon style={{ color: red[500] }} />} className={classes.chip} size = "small" style={{borderColor:red[500], color: red[800], marginLeft:"3px"}} variant="outlined" />
																				)}	

																				{whatsapp?.isDefault==0 && whatsapp?.outChannel==0 && (		
																					<Chip  label="Sem Função Específica" icon={<BlockIcon style={{ color:"#2a2727" }} />} className={classes.chip} size = "small" style={{borderColor:"#2a2727", color:"#2a2727", marginLeft:"3px"}} variant="outlined" />
																				)}
																		</ListItem>

																		<ListItem dense alignItems="flex-start">
																			<ListItemAvatar >
																						{renderStatus(whatsapp)}	
																			</ListItemAvatar>
																			{renderComment(whatsapp)}
																		</ListItem>
																		<>
																		<div className={classes.ticketActionButtons} style={{float: 'right'}}>
																					<img className={classes.img} 
																						src={connKill} alt="fubar" 
																						style={{width: '2.1em'}} 
																						onClick={() => handleKillRequest(whatsapp.id)}
																					/>
																							
																				</div>
																		</>

								
																	</Paper>
																			
																	</>
																</Grid>
										
												
												))}
											</>
										)}
						</Grid>		

		</MainContainer>



	);
};

export default Connections;
