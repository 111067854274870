import React, { useContext, useState, useEffect } from "react";
import openSocket from "socket.io-client";

import { green } from "@material-ui/core/colors";
import {
	Button,
	makeStyles,
	Paper,
    TextField,
	Typography
 } from "@material-ui/core/";

import { Can } from "../../components/Can";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n.js";
import { systemVars } from "../../../package.json";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
	root: {
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		[theme.breakpoints.up("sm")]: {
			maxWidth: "600px",
			flexWrap: "wrap",
		},
	  },
	container:{
		padding: "16px 24px",
	},
	mainPaper:{
		margin:"0 auto",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
		[theme.breakpoints.up("sm")]: {
			minWidth: "600px",
		},
	},
	title: {
	},
	titleH2: {
		fontSize: "1.25rem",
		fontWeight: "500",
	},
	textField: {
		marginRight: theme.spacing(1),
		width: "100%",
	},
	btnWrapper: {
		flex: "none",
        marginLeft: "auto",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	textCampaignsContainer: {
		width: "100%",
	},
	content: {
		padding: "0 24px 16px",
	},
	formActions: {
		flex: "0 0 auto",
		display: "flex",
		padding: "8px",
		alignItems: "center",
		justifyContent: "flex-end", 		
	},
	option: {
		display: "inline-flex",
		width: "100%",
		margin: "8px 0",
	},
	settingLabel: {
		padding: "5px 0",
	},
	settingOption: {
		marginLeft: "auto",
	},
	settingTextField: {
		margin: "0 0 0 auto",
		// maxWidth: "109px",
        width: "280px",
	},
	divider: {
		margin: "16px 0",
		border: "none",
		height: "1px",
		width: "100%",
		backgroundColor: "rgba(0, 0, 0, 0.12)",
	},
}));
// Get hostname of the environment variable
// const getHostname = (url) => {
//     return new URL(url).hostname;
// };

const env_host = window.location.hostname;

const subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;


//get propper port of the environment
let http = require('http');

const getPort = (host) => {
	if (host === 'localhost') {
		return 8080;
	} else {
		http = require('https');
		return '';
	}
}
const env_port = getPort(env_host);

const init = {
  host: env_host,
  path: '/api/new-message',
  port: env_port,
  method: 'POST',
  headers: {
    'content-type': 'application/json; charset=utf-8'
  }
};

const callback = function(response) {
  let result = Buffer.alloc(0);
  response.on('data', function(chunk) {
    result = Buffer.concat([result, chunk]);
  });
  
  response.on('end', function() {
    console.log(result.toString());
  });
};

const clientType = function(tier) {
	switch (tier) {
        case 'tier_1':
            return {label: i18n.t("supportModal.form.tiers.tier_1"),
					available: {
						tier_1: false,
						tier_2: false,
						tier_3: true,
						tier_4: true,
					}
				};
        case 'tier_2':
            return {label: i18n.t("supportModal.form.tiers.tier_2"),
					available: {
						tier_1: false,
						tier_2: false,
						tier_3: true,
						tier_4: true,
					}
				};
        case 'tier_3':
            return {label: i18n.t("supportModal.form.tiers.tier_3"),
					available: {
						tier_1: false,
						tier_2: false,
						tier_3: false,
						tier_4: true,
					}
				};
        case 'tier_4':
            return {label: i18n.t("supportModal.form.tiers.tier_4"),
					available: {
						tier_1: false,
						tier_2: false,
						tier_3: false,
						tier_4: false,
					}
				};
        default:
            return {label: i18n.t("supportModal.form.tiers.tier_0"),
					available: {
						tier_1: false,
						tier_2: false,
						tier_3: true,
						tier_4: true,
					}
				};
    }
}

async function whatsappCampaignSender(campaignWhatsappId) {
	// await api.post() // Do again in the future using post api for security
	const req = http.request(init, callback);

    const msg = i18n.t("supportModal.form.whatsapp.helpText") + '/n'
                + i18n.t("supportModal.form.whatsapp.clientId") + '*' + subdomain + '*/n'
                + i18n.t("supportModal.form.whatsapp.clientType") + '*' + clientType +'*/n';

	const body = '{"campaignContactNumber":"'+ systemVars.support.whatsapp +
                    '","campaignMsg":"' + msg.replace(/\n/g, "\\n") +
                    '","campaignWhatsappId":' + campaignWhatsappId +
                 '}';
	await req.write(body);
	req.end();
};

const Support = () => {
	const { user } = useContext(AuthContext);

	const classes = useStyles();
    const [inputs] = useState({});

    const handleTier1Support = () => {
		window.open(systemVars.support.training, "_blank")
	}

    const handleTier2Support = () => {
		window.open(systemVars.support.mail, "_blank")
	}

    const handleTier3Support = (event) => {
		event.preventDefault();
		toast.success(i18n.t("supportModal.form.whatsapp.messageSent"));
		whatsappCampaignSender(inputs.contact, inputs.message, inputs.id);
	}

    const handleTier4Support = (event) => {
		window.open(systemVars.support.phone, "_blank")
	}
	
	useEffect(() => {
		const socket = openSocket();
		return () => {
			socket.disconnect();
		};
	}, []);
	
	return (
		<MainContainer>
			<MainHeader>
				<Title>{i18n.t("support.title")}</Title>
				<MainHeaderButtonsWrapper>
					<Button
						variant="contained"
						color="primary"
						disabled
						// onClick={enableSupport}
					>
						{i18n.t("support.buttons.support")}
					</Button>
				</MainHeaderButtonsWrapper>
			</MainHeader>

			<Paper
			className={classes.mainPaper}
			variant="outlined"
			>
				<div className={classes.root}>  
					<div className={classes.container}>
						<div className={classes.title}>
							<h2 className={classes.titleH2}>{i18n.t("supportModal.title")}</h2>
						</div>
						<div className={classes.divider}></div>
						
						<div className={classes.content}>
						<div className={classes.option}>
							<Typography variant="body1">
								<span className={classes.settingLabel}>{i18n.t("supportModal.form.tier")}</span>
							</Typography>
							<TextField
                                margin="dense"
                                variant="outlined"
                                id="clientType-setting"
                                name="clientType"
                                value={clientType(systemVars.tier.type).label}
                                className={classes.settingTextField}
                                disabled
                                
                            />
						</div>
						
						<Can
							role={user.profile}
							perform="support:supervisor"
							yes={() => (
							<>
								<div className={classes.divider}></div>	
								<div className={classes.option}>
									<Typography variant="body1" >
										<span className={classes.settingLabel}>{i18n.t("supportModal.form.training")}</span>
									</Typography>
									<Button
										type="submit"
										color="primary"
										variant="contained"
										className={classes.btnWrapper}
                                        onClick={handleTier1Support}
										disabled={clientType(systemVars.tier.type).available.tier_1}
										>
											{i18n.t("support.buttons.access")}
									</Button>
								</div>

								<div className={classes.option}>
									<Typography variant="body1" >
										<span className={classes.settingLabel}>{i18n.t("supportModal.form.mail")}</span>
									</Typography>
									<Button
										type="submit"
										color="primary"
										variant="contained"
										className={classes.btnWrapper}
                                        onClick={handleTier2Support}
										disabled={clientType(systemVars.tier.type).available.tier_2}
										>
											{i18n.t("support.buttons.send")}
									</Button>
								</div>

                                <Can
									role={user.profile}
									perform="support:admin"
									yes={() => (
									<>
										<div className={classes.option}>
											<Typography variant="body1" >
												<span className={classes.settingLabel}>{i18n.t("supportModal.form.whatsapp.title")}</span>
											</Typography>
											<Button
												type="submit"
												color="primary"
												variant="contained"
												className={classes.btnWrapper}
												onClick={handleTier3Support}
												disabled={clientType(systemVars.tier.type).available.tier_3}
												>
													{i18n.t("support.buttons.start")}
											</Button>
										</div>

										<div className={classes.option}>
											<Typography variant="body1" >
												<span className={classes.settingLabel}>{i18n.t("supportModal.form.phone")}</span>
											</Typography>
											<Button
												type="submit"
												color="primary"
												variant="contained"
												className={classes.btnWrapper}
												onClick={handleTier4Support}
												disabled={clientType(systemVars.tier.type).available.tier_4}
												>
													{i18n.t("support.buttons.call")}
											</Button>
										</div>
									</>
									)}
								/>
							</>
							)}
						/>
					</div>
					</div>
				</div>
			</Paper>
		</MainContainer>
			
	);
};

export default Support;
