import React, { useContext, useEffect, useRef, useState, useReducer  } from "react";
import api from "../../services/api";

import { makeStyles } from "@material-ui/core/styles";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import SalesbyStage from "./SalesbyStage";

import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography";
import { i18n } from "../../translate/i18n"

import stage1 from "../../assets/stage1.svg"
import stage2 from "../../assets/stage2.svg"
import stage3 from "../../assets/stage3.svg"
import stage4 from "../../assets/stage4.svg"

import Avatar from "@material-ui/core/Avatar";


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

import {Line} from 'react-chartjs-2';
import {Bar} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  avatar:{
    width: "40px",
    height: "40px",
    borderRadius:"20px",
    border: "1px solid #585454"
},
  contactAvatar: {
		margin: 15,
		width: 40,
		height: 40,
	},

  tabsHeader: {
    flex: "none",
    backgroundColor: "#eee",
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    minWidth: 120,
    width: 120,
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fafafa",
    padding: theme.spacing(1),
  },

  serachInputWrapper: {
    flex: 1,
    background: "#fff",
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },

  badge: {
    right: "-10px",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },
  sendMessageIcons: {
    color: "grey",
    },
    uploadInput: {
    display: "none",
    },
}));



const SalesFunnel = () => {

  const classes = useStyles();

  const [searchParam, setSearchParam] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [importTicketModalOpen, setImportTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);

  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const userQueueIds = user.queues.map((q) => q.id);

  const [loading, setLoading] = useState(false);
  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

 
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedUserId, setSelectedUserId] = useState();
  const [userList, setUserList] = useState();

  const [ticketsPerUser, setTicketsPerUser] = useState([]);
  const [oppsPerContact, setOppsPerContact] = useState([]);
  const [totalsPerStage, setTotalsPerStage] = useState([]);
  const [funnelCount, setFunnelCount] = useState([]);
  const [funnelContacts, setFunnelContacts]= useState(0);
  const [funnelLeads, setFunnelLeads]= useState(0);
  const [funnelOpps, setFunnelOpps]= useState(0);
  const [funnelSales, setFunnelSales]= useState(0);

  const [stage12, setStage12]= useState(0);
  const [stage23, setStage23]= useState(0);
  const [stage34, setStage34]= useState(0);
 

  const [isFileOpen, setIsFileOpen] =useState(false);



  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
            const fetchOppsData = async () => {
              try {
                const ret  = await api.get("/contact/sales");
                const ret2  = await api.get("/contact/funnel");
     

                setTotalsPerStage(ret?.data?.salesTotals)
                setOppsPerContact(ret?.data?.salesOpps?.salesOpps);
                setFunnelCount(ret2?.data?.salesFunnel[0])
                setFunnelContacts(funnelCount[0]?.ctcontacts)
                setFunnelLeads(funnelCount[0]?.cttickets)
                setFunnelOpps(funnelCount[0]?.ctopps)
                setFunnelSales(funnelCount[0]?.ctsales)

                setStage12(funnelCount[0]?.ctcontacts >0?((funnelCount[0]?.cttickets/funnelCount[0]?.ctcontacts)*100):0)
                setStage23(funnelCount[0]?.cttickets >0?((funnelCount[0]?.ctopps/funnelCount[0]?.cttickets)*100):0)
                setStage34(funnelCount[0]?.ctopps>0?((funnelCount[0]?.ctsales/funnelCount[0]?.ctopps)*100):0)




              } catch (err) {
                toastError(err);
              }
            };
            fetchOppsData();
            clearTimeout(delayDebounceFn);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  });


  useEffect(() => {
    const fetchTicketData = async () => {
        try {
            //const ret = await api.get("/ticketskpi");
            //setTicketsPerUser(ret.data.userData);

        } catch (err) {
            toastError(err);
        }
    };
    
    const delayDebounceFn = setTimeout(fetchTicketData, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  /*
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
            const fetchTicketData = async () => {
              try {
                const ret  = await api.get("/ticketskpi");
                setTicketsPerUser(ret.data.userData);

              } catch (err) {
                toastError(err);
              }
            };
            fetchTicketData();
            clearTimeout(delayDebounceFn);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  });
*/


  
  function format_number(number){
    return parseFloat(number).toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }


  let t_labels=[];
  let t_data=[];
  let nameArray=[];

    for (let i = 0; i < totalsPerStage.length;  i++) {
        t_labels.push(totalsPerStage[i].oppstage+` (${totalsPerStage[i].opppct}%)`)
        t_data.push(totalsPerStage[i].totalval)
        }
 
  const tickets = {
    labels: t_labels,
    datasets: [
      {
        label: '',
        color:"#DC143C",
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'rgb(93 54 141,1)',
        borderColor: 'rgba(74,20,140,1)',
        borderWidth: 2,
        borderRadius: 15,
        borderSkipped: false,
        data: t_data
      }
    ]
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' ,
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };

  return (
    <>
     <div className="col-md-12">      
          <div className="row">
              <div className="col-8 col-lg-8">
              <Typography component="h3" variant="h6" color="primary" paragraph>
										{i18n.t("dashboard.messages.salesfunnel.title")}
							</Typography>
                  <Bar  
                      style={{maxHeight: 250}}
                      data={tickets} 
                        height={160}
                        options={{
                                plugins:{legend:{labels:{color:"#DC143C",boxWidth:0,boxHeight:0}}},
                                maintainAspectRatio: false,
                                pointBackgroundColor:"#DC143C",
                                title:{
                                  display:true,
                                  text:'Average Rainfall per month',
                                  fontSize:20,
                                  color:"#DC143C",
                                },
                                legend:{
                                  display:true,
                                  position:'right',
                                  color:"#DC143C",
                                }
                              }}
                    />
                 </div> 

                 <div className="col-4 col-lg-4">
                      <Typography component="h3" variant="h6" color="primary" paragraph>
                          Funil, Últimos 7 dias
                      </Typography>
                            <div className="row">
                              <div className="col-md-12" style={{textAlign:"center"}}>
                                <img src={stage1} style={{width: "100%",  position: "relative"}} />  
                                <div class="text">
                                    <h4 style={{position: "absolute", top: "11px",  left: "47px", height: "50px", fontWeight:900}}>{funnelContacts} Contatos</h4>
                                </div>
                              </div>
                            </div>
                            
                            <div className="row">
                              <div className="col-md-12" style={{textAlign:"center",  position: "relative"}}>
                                  <img src={stage2} style={{width: "87%"}} />
                                  <div class="text">
                                      <h4 style={{position: "absolute", top: "11px",  left: "70px", height: "50px", fontWeight:900}}>{funnelLeads} Leads</h4>
                                  </div>
                              </div>
                            </div> 

                            <Avatar className={classes.avatar} style={{
                                        backgroundColor:(stage12<20?"#860f06":(stage12>=20 && stage12<40?"#b86b07":(stage12>=40 && stage12<60?"#ced010":(stage12>=60 && stage12<80?"#8fa42c":"#036e2d")))), 
                                        color:(stage12<20?"#ffffff":(stage12>=20 && stage12<40?"#ffffff":(stage12>=40 && stage12<60?"#000000":(stage12>=60 && stage12<80?"#000000":"#ffffff")))),  
                                        position: "absolute", 
                                        left: "337px",  
                                        top:"92px", 
                                        fontSize: "9px"}}>
                                <Typography>
                                      {format_number(stage12)}%
                                </Typography>
                            </Avatar>
                           
                            <div className="row">
                                <div className="col-md-12" style={{textAlign:"center",  position: "relative"}}>
                                    <img src={stage3} style={{width: "74%"}} />
                                    <div class="text">
                                        <h4 style={{position: "absolute", top: "11px",  left: "94px", height: "50px", fontWeight:900}}>{funnelOpps} Oportunidades</h4>
                                    </div>
                                </div>
                            </div>

                            <Avatar className={classes.avatar} style={{
                                        backgroundColor:(stage23<20?"#860f06":(stage23>=20 && stage23<40?"#b86b07":(stage23>=40 && stage23<60?"#ced010":(stage23>=60 && stage23<80?"#8fa42c":"#036e2d")))), 
                                        color:(stage12<20?"#ffffff":(stage23>=20 && stage23<40?"#ffffff":(stage23>=40 && stage23<60?"#000000":(stage23>=60 && stage23<80?"#000000":"#ffffff")))),  
                                        position: "absolute", 
                                        left: "315px",  
                                        top:"160px", 
                                        fontSize: "9px"}}>
                                <Typography>
                                      {format_number(stage23)}%
                                </Typography>
                            </Avatar>

                            <div className="row">
                                <div className="col-md-12" style={{textAlign:"center",  position: "relative"}}>
                                    <img src={stage4} style={{width: "62%"}} />
                                    <div class="text">
                                        <h4 style={{position: "absolute", top: "11px",  left: "120px", height: "50px", fontWeight:900}}>{funnelSales} Vendas</h4>
                                    </div>
                                </div>
                            </div>

                            <Avatar className={classes.avatar} style={{
                                        backgroundColor:(stage34<20?"#860f06":(stage34>=20 && stage34<40?"#b86b07":(stage34>=40 && stage34<60?"#ced010":(stage34>=60 && stage34<80?"#8fa42c":"#036e2d")))), 
                                        color:(stage12<20?"#ffffff":(stage34>=20 && stage34<40?"#ffffff":(stage34>=40 && stage34<60?"#000000":(stage34>=60 && stage34<80?"#000000":"#ffffff")))),  
                                        position: "absolute", 
                                        left: "290px",  
                                        top:"230px", 
                                        fontSize: "9px"}}>
                                <Typography>
                                      {format_number(stage34)}%
                                </Typography>
                            </Avatar>
                 </div>
            </div>   
        </div>
            <div className="col-md-12">      
                <div className="row">
                  <div className="col-12 col-lg-12">
                    
                        <Typography component="h3" variant="h6" color="primary" paragraph  style={{padding:10}}>
                            {i18n.t("dashboard.messages.saleslist.title")}
                        </Typography>
                        <SalesbyStage opps={oppsPerContact}/>
                   
                </div> 
            </div>   
        </div>

    </>
             
  );
};


export default SalesFunnel;




                        
                        
