import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	
  } from "@material-ui/core";
import marketingstruct from "../../assets/marketingstruct.jpg"

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Colorize } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
    imgWidth: {
        width:"500px"
    }
}));



const CampaignPlanModal = ({ open, onClose, campaignPlanId }) => {
	const classes = useStyles();

	const initialState = {
		planName: ""
	};

	
    const handleClose = () => {
        setCampaignPlan(initialState);
		onClose();
		
	};




	const [campaignPlan, setCampaignPlan] = useState(initialState);
    const [loading, setLoading] = useState(false);

    const handleChangeInput = (e) => {
        setCampaignPlan({ ...campaignPlan, [e.target.name]: e.target.value })   
      };



    const handleSaveCampaignPlan = async (values) =>{
        setLoading(true);

        if(campaignPlan.planName ===undefined){
            toast.error(i18n.t("campaignModal.title.nameerror"));
            setLoading(false);
            return;
          }

        try{ 
               
            await api.post(`/campaignplans`, campaignPlan);         
        } catch (err) {
          toastError(err);
        }
    
        toast.success(i18n.t("campaignModal.title.addsuccess"));
        	setLoading(false);
          handleClose();
        
      };




	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{campaignPlanId
						? `${i18n.t("campaignPlan.modal.edit")}`
						: `${i18n.t("campaignPlan.modal.add")}`}
				</DialogTitle>
                <Table size="small">
                    <TableRow>
                        <TableCell align="center"><img src={marketingstruct} className={classes.imgWidth}></img></TableCell>
                    </TableRow>
                </Table>
				<Formik
					initialValues={campaignPlan}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveCampaignPlan(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								<Field
									as={TextField}
									label={i18n.t("campaignPlan.modal.planName")}
									autoFocus
                                    fullWidth
									name="planName"
									error={touched.planName && Boolean(errors.planName)}
									helperText={touched.planName && errors.planName}
									variant="outlined"
									margin="dense"
									className={classes.imgWidth}
                                    onChange={(e) => handleChangeInput(e)}
								/>

							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("queueModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{campaignPlanId
										? `${i18n.t("queueModal.buttons.okEdit")}`
										: `${i18n.t("queueModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default CampaignPlanModal;
