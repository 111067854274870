
import React, { useEffect, useReducer, useState } from "react";

import Swal from 'sweetalert2'

import fs from "fs";
import util from 'util';

import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import QueueIcon from '@material-ui/icons/Queue';
import FaceIcon from '@material-ui/icons/Face';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckIcon from '@material-ui/icons/Check';
import ChatIcon from '@material-ui/icons/Chat';
import AttachmentIcon from '@material-ui/icons/Attachment';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FlareIcon from '@material-ui/icons/Flare';


import menu from "../../assets/bizflow/menu.png"
import informativo from "../../assets/bizflow/informativo.png"
import atendente from "../../assets/bizflow/atendente.png"
import fila from "../../assets/bizflow/fila.png"
import categoria from "../../assets/bizflow/categoria.png"
import mensagem from "../../assets/bizflow/mensagem.png"
import media from "../../assets/bizflow/media.png"
import anexo from "../../assets/bizflow/anexo.png"
import QuestaoIcon from "../../assets/bizflow/questao.png"
import IntegracaoIcon from "../../assets/bizflow/integracao.png"


import menu_g from "../../assets/bizflow/menu-g.png"
import informativo_g from "../../assets/bizflow/informativo-g.png"
import atendente_g from "../../assets/bizflow/atendente-g.png"
import fila_g from "../../assets/bizflow/fila-g.png"
import categoria_g from "../../assets/bizflow/categoria-g.png"
import mensagem_g from "../../assets/bizflow/mensagem-g.png"
import media_g from "../../assets/bizflow/media-g.png"
import anexo_g from "../../assets/bizflow/anexo-g.png"
import questao_g from "../../assets/bizflow/questao-g.png"
import integracao_g from "../../assets/bizflow/integracao-g.png"

import menu_r from "../../assets/bizflow/menu-r.png"
import informativo_r from "../../assets/bizflow/informativo-r.png"
import atendente_r from "../../assets/bizflow/atendente-r.png"
import fila_r from "../../assets/bizflow/fila-r.png"
import categoria_r from "../../assets/bizflow/categoria-r.png"
import mensagem_r from "../../assets/bizflow/mensagem-r.png"
import media_r from "../../assets/bizflow/media-r.png"
import anexo_r from "../../assets/bizflow/anexo-r.png"
import questao_r from "../../assets/bizflow/questao-r.png"
import integracao_r from "../../assets/bizflow/integracao-r.png"


import api from "../../services/api";

import Drawflow from './Drawflow'
import styleDrawflow from './Drawflow.css'
import beautiful from './beautiful.css'
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import {
	
	TableBody,
	TableRow,
	TableCell,
	Table,
	TableHead,
	Tooltip,
	Typography,
	CircularProgress,
} from "@material-ui/core";

import { Button, makeStyles,Paper} from "@material-ui/core";
import { IconButton} from "@material-ui/core";
import { purple } from '@material-ui/core/colors';
import { Replay, Check, Assignment} from "@material-ui/icons";
import AppsIcon from '@material-ui/icons/Apps';

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";

import Grid from '@material-ui/core/Grid';

import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";

import LockIcon from '@material-ui/icons/Lock';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import CommandModal from "../../components/CommandModal";
import BizNodeModal from "../BizNodeModal";

const useStyles = makeStyles(theme => ({
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
    customTableCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: theme.typography.pxToRem(14),
      border: "1px solid #dadde9",
      maxWidth: 450,
    },
    tooltipPopper: {
      textAlign: "center",
    },
    buttonProgress: {
      color: green[500],
    },
    actionButtons: {
      marginRight: 6,
      flex: "none",
      alignSelf: "center",
      marginRight: "auto",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  }));


const BizFlowBoard = ({bizflow, onClose}) => {

  const classes = useStyles();
  var editor ;

  var transform = '';

  
  const [loading, setLoading] = useState(false);
  const [nodeEditorLoading, setNodeEditorLoading] = useState(false);
  const [activeNode, setActiveNode] = useState(0);
  const [currEditor, setCurrEditor] = useState();
  const [nodeArray, setNodeArray] = useState([])
  const [flashSend, setFlashSend] = useState(0)

  const [currNode, setCurrNode] = useState([])
  const [currFlow, setCurrFlow] = useState([])
  
  const handleLoading =(param) => {
      console.log("ActiveNode", activeNode)
  }

  useEffect(() => {
    var id = document.getElementById("drawflow");
    editor = new Drawflow(id);
    editor.reroute = true;
    editor.start();
  

    if(bizflow.dataJson){
      editor.import(JSON.parse(bizflow.dataJson))
    }
    setCurrEditor(editor)

  }, []);

  useEffect(() => {
    editor.on('nodeCreated', function(id) {
      console.log("Node created " + id);
      handleActiveNode(id)
    })

  editor.on('nodeRemoved', function(id) {
      console.log("Node removed " + id);
    })

  editor.on('nodeSelected', function(id) {
      console.log("Node selected " + id);
      handleActiveNode(id)
    })

  editor.on('moduleCreated', function(name) {
      //console.log("Module Created " + name);
    })

  editor.on('moduleChanged', function(name) {
      //console.log("Module Changed " + name);
    })

  editor.on('connectionCreated', function(connection) {
      //console.log('Connection created');
      //console.log(connection);
    })

  editor.on('connectionRemoved', function(connection) {
      //console.log('Connection removed');
      //console.log(connection);
    })

  editor.on('mouseMove', function(position) {
      //console.log('Position mouse x:' + position.x + ' y:'+ position.y);
    })

  editor.on('nodeMoved', function(id) {
      //console.log("Node moved " + id);
      //setActiveNode(id)
    })

  editor.on('zoom', function(zoom) {
      console.log('Zoom level ' + zoom);
    })

  editor.on('translate', function(position) {
    // console.log('Translate x:' + position.x + ' y:'+ position.y);
    })

  editor.on('addReroute', function(id) {
      //console.log("Reroute added " + id);
    })

  editor.on('removeReroute', function(id) {
      //console.log("Reroute removed " + id);
    })

    editor.on('click', function(id) {
    
    })

  }, []);

  const handleClick = (e) =>{
      console.log(e)
      //editor.addNode('welcome', 0, 0, 50, 50, 'welcome', {}, welcome );
      
  }

  const handleLoadFlow = () =>{

    var id = document.getElementById("drawflow");
    editor = new Drawflow(id);
    editor.reroute = true;
    editor.start();
  

    if(bizflow.dataJson){
      editor.import(JSON.parse(bizflow.dataJson))
    }
    setCurrEditor(editor)

  }
  
  const handleActiveNode = (id) => {
  
    let editorObj = editor.export();
    //console.log(bizflow)
    //console.log(editorObj.drawflow.Home.data[id])
    setCurrNode(editorObj.drawflow.Home.data[id])
    setCurrFlow(bizflow)
    //setNodeEditorLoading(true)
    
  } 

  const handleEditNode = () => {
  
    console.log("currNode", currNode)
    setNodeEditorLoading(true)

  } 

  const handleAddNode = ( name, pos_x, pos_y, inConn, outConn )=>{

    //pos_x = pos_x * (currEditor.precanvas.clientWidth / (currEditor.precanvas.clientWidth * currEditor.zoom)) - (currEditor.precanvas.getBoundingClientRect().x * ( currEditor.precanvas.clientWidth / (currEditor.precanvas.clientWidth * currEditor.zoom)));
    //pos_y = pos_y * (currEditor.precanvas.clientHeight / (currEditor.precanvas.clientHeight * currEditor.zoom)) - (currEditor.precanvas.getBoundingClientRect().y * ( currEditor.precanvas.clientHeight / (currEditor.precanvas.clientHeight * currEditor.zoom)));

    var label = name.toUpperCase();
    let tokenimg;

    switch(name){

      case "menu":
        tokenimg=JSON.stringify(menu_r);
        break;
      case "atendente":
        tokenimg=JSON.stringify(atendente_r);
        break;
      case "fila":
        tokenimg=JSON.stringify(fila_r);
        break;
      case "informativo":
        tokenimg=JSON.stringify(informativo_r);
        break;
      case "categoria":
        tokenimg=JSON.stringify(categoria_r);
        break;
      case "media":
        tokenimg=JSON.stringify(media_r);
        break;
      case "anexo":
        tokenimg=JSON.stringify(anexo_r);
        break;
      case "mensagem":
        tokenimg=JSON.stringify(mensagem_r);
        break;
      case "questao":
        tokenimg=JSON.stringify(questao_r);
        break;
      case "integracao":
        tokenimg=JSON.stringify(integracao_r);
        break;
    }

    var token = `<div><div class="title-box"><img src=${tokenimg}> ${label}</div></div>`;

    let currNode = currEditor.addNode(name, inConn, outConn, pos_x, pos_y, name, {},token );
    setNodeArray(nodeArray =>[...nodeArray,{id:currNode, inConn, outConn, name, conns:{}}])
  }

  const handleExport = async () =>{

    Swal.fire(
      'Atenção!',
      'Apenas os passos que foram corretamente configurados (apresentam um ícone verde) serão salvos no ambiente de execução.\nObrigado.',
      'info'
    )

    let editorObj = currEditor.export(); 

    let payload={id:bizflow.id,
      name:bizflow.name,
      description: bizflow.description,
      isActive: 0,
      dataJson: JSON.stringify(editorObj)}

      //console.log("handleCloseBizNodeModal 5", payload)
      currEditor.import(editorObj)

    

    try {      
        const { data } = await api.put(`/bizflow/${bizflow.id}`, payload);
        setNodeEditorLoading(false)
    } catch (err) {
        console.log(err);
        setNodeEditorLoading(false)
    }
   

  }

  const handleClose = () => {
    onClose();
    //setCampaign(initialState);
  } 

  const handleCloseBizNodeModal = async (nodedata) => {
    //console.log("handleCloseBizNodeModal 1", nodedata)
    //console.log("handleCloseBizNodeModal 2", nodedata.nId)

    if(nodedata?.nId){
          let editorObj = currEditor.export();   
          editorObj.drawflow.Home.data[nodedata.nId].name= nodedata.cType + ": "+nodedata.dBot;
          editorObj.drawflow.Home.data[nodedata.nId].data= nodedata;
          
          var tokenimg;
          var label

          switch(nodedata.cType){
            case "menu":
              tokenimg=JSON.stringify(menu_g);
              label=nodedata.dBot
              break;
            case "atendente":
              tokenimg=JSON.stringify(atendente_g);
              label=nodedata.dBot
              break;
            case "fila":
              tokenimg=JSON.stringify(fila_g);
              label=nodedata.dBot
              break;
            case "informativo":
              tokenimg=JSON.stringify(informativo_g);
              label=nodedata.dBot
              break;
            case "categoria":
              tokenimg=JSON.stringify(categoria_g);
              label=nodedata.dBot
              break;
            case "media":
              tokenimg=JSON.stringify(media_g);
              label=nodedata.fId
              break;
            case "anexo":
              tokenimg=JSON.stringify(anexo_g);
              label=nodedata.fId
              break;
            case "mensagem":
              tokenimg=JSON.stringify(mensagem_g);
              label="Mensagem OK"
              break;
            case "questao":
              tokenimg=JSON.stringify(questao_g);
              label=nodedata.fId
              break;
            case "integracao":
              tokenimg=JSON.stringify(integracao_g);
              label=nodedata.fId
              break;

              
          }

          
          editorObj.drawflow.Home.data[nodedata.nId].html=  `<div><div class="title-box"><img style="width:20px"src=${tokenimg}><span style="font-size:8px"> ${label}<span></div></div>`;

          let payload={id:bizflow.id,
            name:bizflow.name,
            description: bizflow.description,
            isActive: bizflow.isActive,
            dataJson: JSON.stringify(editorObj)}
      
            //console.log("handleCloseBizNodeModal 5", payload)





            currEditor.import(editorObj)
          
            const formData = new FormData();
            formData.append("id", bizflow.id);
            formData.append("name", bizflow.name);
            formData.append("description", bizflow.description);
            formData.append("isActive", bizflow.isActive);
            formData.append("dataJson", JSON.stringify(editorObj));
      
          try {  
            
              const { data } = await api.put(`/bizflow/${bizflow.id}`, formData);
              console.log("handleCloseBizNodeModal",data)

              

              //const { data } = await api.put(`/bizflow/${bizflow.id}`, payload);
              setNodeEditorLoading(false)
          } catch (err) {
              console.log(err);
              setNodeEditorLoading(false)
          }
    }


    setNodeEditorLoading(false)




  }


  const handleCloseBizNodeModal2 = async (currnode, values) => {
    console.log("handleCloseBizNodeModal 1", currnode)

    let editorObj = currEditor.export();




    editorObj.drawflow.Home.data[currnode.id]=currnode;
    currEditor.import(editorObj)

    console.log("handleCloseBizNodeModal 2", editorObj)

    try {
          let payload={id:bizflow.id,
                    name:bizflow.name,
                    description: bizflow.description,
                    isActive: bizflow.isActive,
                    dataJson: JSON.stringify(editorObj)}

                  

        const { data } = await api.put(`/bizflow/${bizflow.id}`, payload);
        console.log("handleCloseBizNodeModal 3", data)
        setNodeEditorLoading(false)
    } catch (err) {
        console.log(err);
        setNodeEditorLoading(false)
    }

  

    setNodeEditorLoading(false)




  }

  return (
      <MainContainer>



        <div className={classes.actionButtons}>
        <span title={"MENU"}> 
              <IconButton                              
                  size="small"
                  variant="contained"
                  onClick={() => handleAddNode("menu",100,100,1,1)}
              >
                 <VerticalSplitIcon  style={{ color: purple[900], fontSize: 33 }}/>
              </IconButton>MENU
          </span>

          <span title={"FILA"}> 
              <IconButton                              
                  size="small"
                  variant="contained"
                  onClick={() =>handleAddNode("fila",75,75,1,0)}
              >
                  <QueueIcon  style={{ color: purple[900], fontSize: 33 }}/>
              </IconButton>FILA
          </span>

          <span title={"ATENDENTE"}> 
              <IconButton                              
                  size="small"
                  variant="contained"
                  onClick={() =>handleAddNode("atendente",125,125,1,0)} 
              >
                   <FaceIcon style={{ color: purple[900], fontSize: 33 }}/>
              </IconButton>ATENDENTE
          </span>

          <span title={"CATEGORIA"}> 
              <IconButton                              
                  size="small"
                  variant="contained"
                  onClick={() =>handleAddNode("categoria",50,50,1,1)}
              >
                  <AssignmentIcon  style={{ color: purple[900], fontSize: 33 }}/>
              </IconButton>CATEGORIA
          </span>

         
          <span title={"INFORMATIVO"}> 
              <IconButton                              
                  size="small"
                  variant="contained"
                  onClick={() => handleAddNode("informativo",150,150,1,1)}
              >
                <InfoOutlinedIcon style={{ color: purple[900], fontSize: 33 }}/>
              </IconButton>INFORMATIVO
          </span>

          <span title={"MIDIA"}> 
              <IconButton                              
                  size="small"
                  variant="contained"
                  onClick={() => handleAddNode("media",175,175,1,1)}
              >
                <PermMediaIcon style={{ color: purple[900], fontSize: 33 }}/>
              </IconButton>MIDIA
          </span>

          <span title={"MENSAGEM"}> 
              <IconButton                              
                  size="small"
                  variant="contained"
                  onClick={() => handleAddNode("mensagem",200,200,1,1)}
              >
                <ChatIcon style={{ color: purple[900], fontSize: 33 }}/>
              </IconButton>MENSAGEM
          </span>

          <span title={"ANEXO"}> 
              <IconButton                              
                  size="small"
                  variant="contained"
                  onClick={() => handleAddNode("anexo",225,225,1,1)}
              >
                <AttachmentIcon style={{ color: purple[900], fontSize: 33 }}/>
              </IconButton>ANEXO
          </span>

          <span title={"QUESTÃO"}> 
              <IconButton                              
                  size="small"
                  variant="contained"
                  onClick={() => handleAddNode("questao",250,250,1,1)}
              >
                <HelpOutlineIcon style={{ color: purple[900], fontSize: 33 }}/>
              </IconButton>PERGUNTA
          </span>
         

          <span title={"INTEGRAÇÃO"}> 
              <IconButton                              
                  size="small"
                  variant="contained"
                  onClick={() => handleAddNode("integracao",275,275,1,1)}
              >
                <FlareIcon style={{ color: purple[900], fontSize: 33 }}/>
              </IconButton>INTEGRAÇÃO
          </span>

         








        </div>

        <Paper  className={classes.mainPaper} variant="outlined">
              {nodeEditorLoading && ( <BizNodeModal
                      currflow={currFlow}
                      currnode={currNode}
                      onClose={handleCloseBizNodeModal} 
                      open={nodeEditorLoading} 
                      />)}
                      
                <div id="drawflow"style={{minHeight:500, height:"90vh", width:"100%"}}>
                  <div class="btn-export" style={{top:17}} onClick={handleExport} ><VerticalAlignBottomIcon />Salvar</div>
                  <div class="btn-export" style={{top:57}} onClick={handleClose}><CheckIcon  />Fechar</div>
                  <div class="btn-export" style={{top:97}} onClick={handleEditNode}><AppsIcon/>Editar Node</div>
                </div>               
        </Paper>
 
      </MainContainer>
  )
  
}

export default BizFlowBoard;