import React, { useContext, useEffect, useState, useMemo, useReducer } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";

import {
  Button,
  Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
  InputLabel,
  makeStyles,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
	TextField,
  Tooltip,
	Typography
} from "@material-ui/core";
import {
  AttachFile,
  Cancel,
  CheckCircleOutlined,
  DeleteOutline,
  ErrorOutline,
  ScheduleOutlined
} from "@material-ui/icons"

import ConnectionItems from "../../components/ConnectionItems";
import WabaTemplateItems from "../../components/WabaTemplateItems"

import InputVariables from "../../components/InputVariables";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import useWhatsApps from "../../hooks/useWhatsApps";

import api from "../../services/api";
import Dropzone, { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
	mainPaper: {
		flex: 1,
		padding: theme.spacing(1),
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	actionButtons: {
		border: "1px solid",
		marginLeft: theme.spacing(1),
	},
  divider: {
		margin: "16px 0",
		border: "none",
		height: "1px",
		width: "100%",
		backgroundColor: "rgba(0, 0, 0, 0.12)",
	},
  modalWrap: {
    display: "flex",
    flexWrap: "wrap"
  },
  modal: {
		display: "flex",
		flexWrap: "wrap",
    margin: "0 auto"
	},
	flexWrap: {
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		justifyContent: "space-between",
		// "& > *:not(:last-child)": {
		"& > *:last-child": {
			// marginRight: theme.spacing(1),
			marginRight: 0,
		},
		width: "100%",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	btnWrapper: {
		position: "relative",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	uploadInput: {
		display: "none",
	},
	newMessageBox: {
		marginTop: theme.spacing(1),
		width: "100%",
		display: "flex",
		padding: "7px",
		alignItems: "left",
		border: "1px solid",
		borderRadius: 15
	},
  marginRight: {
    marginRight: theme.spacing(1),
  },
  status: {
    display: "flex",
    justifyContent: "center"
  },
	buttonColorError: {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main,
	},
  preventLineBreak: {
    width: "15%",
    minWidth: 240
  }
}));

const baseStyle = {
	alignItems: "center",
	backgroundColor: "#fafafa",
	borderColor: "#131B43", // theme.palette.primary.main,
	borderRadius: 15,
	borderStyle: "dashed",
	borderWidth: 1,
	color: "#131B43", // theme.palette.primary.main,
	cursor: "pointer",
  display: "flex",
	flex: 1,
	flexDirection: "column",
  marginBottom: "8px",
	outline: "none",
	padding: "20px",
	transition: "border .24s ease-in-out",
  minWidth: 365,
  width: "100%"
};

const activeStyle = {
	borderColor: "#2196f3",
};

const acceptStyle = {
	borderColor: "#00e676",
};

const rejectStyle = {
	borderColor: "#ff1744",
};

const CampaignSchema = Yup.object().shape({
  message: Yup.string()
		.min(3, `${i18n.t("validation.too_short")}`)
		.max(4000, `${i18n.t("validation.too_long")}`)
})

const reducer = (state, action) => {
  
  if (action.type === "LOAD_TEMPLATES") {
    const templateData = action.payload;
    const newtemplate = [];
    
    templateData.forEach((templateItem) => {
      const templateIndex = state.findIndex((u) => u.id ===  templateItem.id);
      if (templateIndex !== -1) {
        state[templateIndex] =  templateItem;
      } else {
        newtemplate.push(templateItem);
      }
      
    });
   
    return [...state, ...newtemplate];
  }
};


const Campaign = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  

  const initialState = {
    id: '',
    name: 'InstantCampaign_'+new Date().toISOString().substring(0,19)
      .replaceAll("T",".")
      .replaceAll("-","")
      .replaceAll(":",""),
    message: `${i18n.t("variables.campaign")}{{firstName}}, `,
    hasMedia: false,
    userId: user.id
  };
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [templateItem, setTemplateItem] = useState();
  const [channel, setChannel] = useState("");
  const [templates, dispatch] = useReducer(reducer, []);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  
  const {whatsApps} = useWhatsApps();
  const history = useHistory();
  const [inputs, setInputs] = useState(initialState);
  const [campaignModalOpen, setCampaignModalOpen] = useState(false);
  const [campaignId, setCampaignId] = useState();
	
  const [medias, setMedias] = useState([]);
  useEffect(() => {
    let setHasMedia
    medias.length > 0 ?
       setHasMedia = true :
       setHasMedia = false;
    if(inputs.hasMedia !== setHasMedia) {
      setInputs(inputs => ({...inputs, hasMedia: setHasMedia}))
    }
  }, [inputs.hasMedia, medias])
  
  const [speaches, setSpeaches] = useState({});
  const [speachCount, setSpeachCount] = useState(0);
  const [csvFile, setCsvFile] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [field, setField] = useState();
  const [loading, setLoading] = useState(false);
  const [importContacts, setImportContacts] = useState(false);

  const [settings, setSettings] = useState([]);
  const [usage, setUsage] = useState({
    limit: 1,
    usage: 0,
    message: i18n.t("campaigns.buttons.credits.loading")
  });
  const [usageInfo, setUsageInfo] = useState({
    color: 'primary',
    message: usage.message,
    tooltip: i18n.t("campaigns.tooltips.trial")
  })

  
	useEffect(() => {
		const fetchSession = async () => {
      try {
        const { data } = await api.get("/broadcastsettings/limit");
        console.log("/broadcast/limit", data[0].count)
        setUsage(info => ({...info, usage: data[0].count}));
			} catch (err) {
				toastError(err);
			};
			try {
				const { data } = await api.get("/settings");
				setSettings(data); 
        let limit = data.find(s => s.key === 'broadcastLimit');
        if(limit?.value) {
          setUsage(info => ({...info, limit: limit.value}));
        }
			} catch (err) {
				toastError(err);
			};
		};
    fetchSession();
	}, []);



  useEffect(() => {
    let limit = Number(usage.limit);
    let use = Number(usage.usage);
    let credits = limit - use;
    let percent = credits/limit;
    
    let message = i18n.t("broadcasts.buttons.credits.left", { credits: credits.toLocaleString("pt-BR"), limit: limit.toLocaleString("pt-BR") } );

    let buy = i18n.t("broadcasts.buttons.credits.left") + credits.toLocaleString("pt-BR"); 
    
    if(percent <= 0.15 || credits < 1) {
        setUsageInfo(info => ({...info, color: "secondary"}));
        setUsageInfo(info => ({...info, message: buy}));
        setUsageInfo(info => ({...info, tooltip: i18n.t("broadcasts.tooltips.buyCredits")}));
      } else {
        setUsageInfo(info => ({...info, color: "primary"}));
        setUsageInfo(info => ({...info, message: message}));
        setUsageInfo(info => ({...info, tooltip: i18n.t("broadcasts.tooltips.creditsInfo")}));
    }
  },[usage]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchCampaign = async () => {
        try {
          const { data } = await api.get("/wabatemplates", {
            params: { searchParam, pageNumber },
          });
          console.log("/wabatemplates", data)
          dispatch({ type: "LOAD_TEMPLATES", payload: data.templates });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchCampaign();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);


  const {
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone();

  const style = useMemo(
      () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      }),
      [isDragActive, isDragReject, isDragAccept]
  );

  useEffect(() => {setSpeachCount(1);}, [contacts]);

  const checkContent = (inputs) => {

    //console.log("handleSaveInstantCampaign",inputs)

    if(!inputs.id) {
      toast.error(i18n.t("broadcasts.toasts.error.noWpId"));
      return
    }
    if (contacts.length === 0) {
      toast.error(i18n.t("broadcasts.toasts.error.noContacts"));
      return
    }
    if (contacts.length > (usage.limit - usage.usage)) {
      toast.error(i18n.t("broadcasts.toasts.error.noCredits"));
      return
    }
    //handleSaveInstantCampaign(inputs)
    
    handleQueueMessages()
  }

  const handleSaveCampaign = (values) => {
    setInputs(inputs => ({...inputs, message: values.message}));
    checkContent(inputs);
  } 

  const handleSaveInstantCampaign = async inputs => {
    handleQueueMessages()
	};

  const getSettingValue = key => {
    console.log(settings)
    const { value } = settings.find(s => s.key === key);
    return value;
	};

  const goToExampleFile = () => {
    const content = [{
        name: "Nome da Pessoa",
        phoneNumber: "Número de Whatsapp",
        param1:"variavel 1o",
        param2:"variavel 2",
        param3:"variavel 3",
        param4:"variavel 4",
        param5:"variavel 5"
      },{
        name: "mWare Communicator",
        phoneNumber: 556584086942,
        param1:"variavel 1 para substituição",
        param2:"variavel 2 para substituição",
        param3:"variavel 3 para substituição",
        param4:"variavel 4 para substituição",
        param5:"variavel 5 para substituição"
      }
    ]
    downloadFile(
      i18n.t("campaigns.buttons.example.fileName")+"_-_" +i18n.t("validation.locale")+'.csv',
      content,
      'text/csv; charset=utf-8'
    );
  }

  //Componentize
  const downloadFile = (fileName, content, fileType) => {
    const makeCSV = (content) => {
      let csv = '';
      content.forEach(item => {
        Object.entries(item).forEach(([, value], index) => {
          let innerValue = value == null ? '' : value.toString();
          let result = innerValue.replace(/"/g, '""');
          if (result.search(/("|,|\n)/g) >= 0) {
            result = '"' + result + '"'
          }
          if (index > 0) {csv += ';'}
          csv += result;
        
        });
        csv += '\n';
      })
      return csv
    }
    const universalBOM = "\uFEFF";
    const file = new Blob([universalBOM+makeCSV(content)], {type: fileType});
    
    const element = document.createElement("a");
      element.href = URL.createObjectURL(file);
      element.download = fileName;
    
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const handleDeleteFromCampaign = (index) => {
    contacts.splice(index, 1);
    setContacts(contacts);
    setSpeaches(state => state + 1);
  };

  //componentize
  const handleInputMedias = e => {
    if (!e.target.files) return;
		const selectedMedias = Array.from(e.target.files);
    let renamedMedias = [];
    for(let media of selectedMedias) {
      const file = new File([media], `temp_${media.name}`, { type: media.type });
      renamedMedias.push(file)
    }
		setMedias(renamedMedias);

    console.log("message", inputs);
	};

  const handleChange = (event) => {
    const name = event.target.name;
		const value = event.target.value;


		setInputs(values => ({...values, [name]: value}))

  }
  const handleChannelChange = (event) => {
    const name = event.target.name;
		const value = event.target.value;


		setInputs(values => ({...values, [name]: value}))

    const WAindex =  whatsApps.findIndex(s => s.id === event.target.value);    
    console.log("handleChange", whatsApps[WAindex]?.channel)
    setChannel(whatsApps[WAindex]?.channel)
    
  }


  const handleTemplateChange = (event) => {
    const name = event.target.name;
		const value = event.target.value;
    
    const Templateindex =  templates.findIndex(s => s.reference === event.target.value);    
    console.log("handleChange", templates[Templateindex].templateName)
		setInputs(values => ({...values, [name]: "$_"+templates[Templateindex].templateName}))
  }

  const handleTemplateSelect = (value) => {
    console.log(`Selected template: ${value}`);
    const Templateindex =  templates.findIndex(s => s.reference === value);    
    console.log("handleChange", templates[Templateindex].templateName)
    setSelectedTemplate(value);
    setInputs(values => ({...values, message: "$_"+templates[Templateindex].templateName}))
  }

  const handleOpenCampaignModal = () => {
    setCampaignModalOpen(true);
  };

  const handleCloseCampaignModal = () => {
    setCampaignModalOpen(false);
  };

  const checkFile = (files, rejectedFiles) => {
		if (files[0].e) {}
		if (rejectedFiles.length > 0) {
			toastError(i18n.t("validation.medias.fileTooBig"));
		} else {
			var reader = new FileReader();
			let cl = [];
			try {
				reader.onload = function (e) {
					cl = reader.result.split("\n");
          console.log("Broadcast Contacts", cl)
          let alertLimit = false;

					for (let j = 0; j < cl.length; j++) {

            if(getSettingValue("broadcastLimit") >= j && cl[j] !== '' && cl[j] !== null) {
              let a = cl[j].replace(/\r?\n|\r/g, "");
              console.log("Broadcast Contact Single", a)
              cl[j] = a.split(";");
              cl[j].push('',new Date().toLocaleString(i18n.t("validation.locale"), { dateStyle: 'short', timeStyle: 'short' }));
            } else if (cl[j] !== '' && cl[j] !== null){
              alertLimit = true;
            }
					}
					cl = cl.filter(x => x[3] !== '').filter(x => !isNaN(x[1]))
					setContacts(cl);
          console.log("Pages.broadcast.index.checkFile", cl)
          if(alertLimit) toast.error(i18n.t("broadcast.toasts.error.maxContactsReached", { maxContacts: getSettingValue("broadcastMaxContacts")}));
				};
				reader.readAsText(files[0]);
				setCsvFile(files);
			} catch (err) {
				toastError(i18n.t("broadcast.medias.notCSV") + "\n" + err);
			}
		}
	};

  const handleFocus = (e) => {
    setField(e.target);
  }

  //Componentize
  const InputFile = () => {
		if(medias?.length > 0) 
			return <>
				<div className={classes.newMessageBox}>
					<Button
						startIcon={<Cancel color="error"/>}
						component="span"
						onClick={e => setMedias([])}
						size="small"
					>
            <Typography>
              {medias[0]?.name}
              {medias.length > 1 ? i18n.t("validation.medias.hasOthers", {medias: medias.length}) : ''}
            </Typography>
					</Button>
				</div>
			</>;
		else
			return <>
      	<div className={classes.newMessageBox}>

          <input
              multiple
              type="file"
              id="upload-button2"
              className={classes.uploadInput}
              onChange={handleInputMedias}
          />
          <label htmlFor="upload-button2">
              <Button
                startIcon={<AttachFile />}
                component="span"
                size="small"
              >
							  <Typography>{i18n.t("validation.medias.none")}</Typography>
              </Button>
            </label>
        </div>
			</>
	};

  const cleanNumber = (number) => {
    return number
      .replace("-", "")
      .replace(" ", "")
      .replace("(", "")
      .replace(")", "")
      .replace("+", "")
      .replace(".", "")
      .replace("_", "")
  }

  const handleSendMedia = (instantCampaignId) => {
    handleSendMessage(medias, instantCampaignId);
  }

  const  handleImport = () =>{
    setImportContacts(true)
    handleImportMinedContacts()
  };

  const  handleImportMinedContacts = async () =>{

    //console.log("getSettingValue(broadcastLimit)",getSettingValue("broadcastLimit"))
    //console.log("usage 1",usage)
    //console.log("usage 2",parseInt(usage.limit))

    

    let maxBroadcast=parseInt(usage.limit)-usage.usage

        try {
          const { data } = await api.get("/contact/generated");
              
          let currContacts=data.minedContacts.contacts
          let alertLimit = false;
          let cl=[]
          let loopcounter=0;

          (async () => {         
            for (const prop of currContacts) {
              if (loopcounter == maxBroadcast) { break; }
              await sleep(100)

              if(inputs.prefix){
                console.log("inputs 1", inputs.prefix)

                let prefixLength=inputs.prefix.length;
                console.log("inputs 2", prefixLength)
                console.log("inputs 3",prop.name.slice(0,prefixLength))
                
                if(prop.name.slice(0,prefixLength)==inputs.prefix){
                    

                    cl.push([prop.name, prop.number,'',new Date().toLocaleString(i18n.t("validation.locale"), { dateStyle: 'short', timeStyle: 'short' })]);
                }


              }else{
                cl.push([prop.name, prop.number,'',new Date().toLocaleString(i18n.t("validation.locale"), { dateStyle: 'short', timeStyle: 'short' })]); 
              }


              loopcounter++;
            }
            
            console.log('added all', cl)
            setContacts(cl);
            
          })()

        } catch (err) {
          toastError(err);
        }
  };

  const handleQueueMessages = async () => {

    const currContacts = contacts.map((contact) => ({ destino: contact[1], nome:contact[0], param1:"", param2:"",extradetail:[{param1:contact[2],param2:contact[3],param3:contact[4],param4:contact[5],param5:contact[6]}]}));

    let msgtemp = inputs.message;

    let payload={
      "message": inputs.message,
      "contactArray": currContacts,
      "wpId": inputs.id
      }

      const formData = new FormData();
      formData.append("message", inputs.message); 
      formData.append("contactArray", JSON.stringify(currContacts));
      formData.append("wpId",inputs.id);
      medias.forEach((media) => {
        formData.append("file", media);
      });
      let respApi ;
    
      try {
       
        respApi = await api.post(`/broadcast/queue`, formData)
      

        if (respApi.status == 200) {
          toast.success(respApi.data.quantity+" "+respApi.data.response);
          setContacts(contacts);
        } 

        if (respApi.status == 409) {
          toast.error("Ja existe um disparo ativo. Por favor tente mais tarde");
          setContacts(contacts);
        } 

        if (respApi.status == 402) {
          toast.error("O seu plano não permite esta funcionalidade. Por favor faça um upgrade.");
          setContacts(contacts);
        } 


    
      } catch (err) {
        toast.error("Existe um conflito:" + err);
        setContacts(contacts);
      }
  
    handleCloseCampaignModal();
    history.push(`/campaign`);
};




  const handleSendMessage = async (medias, instantCampaignId) => {

      console.log("handleSendMessage",instantCampaignId )

      try {
        setSpeachCount(0);
        for (let i = 0; i < contacts.length; i++) {
          let msgtemp = inputs.message;
          for (let [key, value] of Object.entries(speaches)) {
            if (value[speachCount] !== "") {
              msgtemp = msgtemp.replace("$" + key, value[speachCount]);
            }
          }
          if (typeof speaches["SPEACH1"] !== "undefined") {
            if (speachCount === speaches["SPEACH1"].length - 1) {
              setSpeachCount(0);
            } else {
              setSpeachCount(state => state + 1);
            }
          }
          let values;
          let contactName=contacts[i][0];
          let contact = String(cleanNumber(contacts[i][1])).replace(/\D/g, "");

          if (medias.length > 0) {
            values = new FormData();
            values.append("campaignId", instantCampaignId);
            values.append("contactName", (contactName?contactName:contact))
            values.append("wpId", inputs.id);
            values.append("number", contact);
            values.append("message", msgtemp);
            medias.forEach((media) => {
              values.append("file", media);
            });
          } else {
            values = {
              campaignId: instantCampaignId,
              wpId: inputs.id,
              number: contact,
              message: msgtemp
            }
          }
          try {
            if (!contacts[i][4]) {
              let respApi = await api.post(`/broadcast/${instantCampaignId}/send`, values)

              if (respApi.status !== 200) {
                toast.error(i18n.t("campaigns.toasts.error.toUser", {contact: contacts[1][0]}));
                contacts[i][4] = "Error";
                setContacts(contacts);
              } else {
                contacts[i][2] = "Success";
                setContacts(contacts);
              }
            } else {
              let contact = contacts[1][0] +" (+"+contacts[1][1]+")";
              toast.error(i18n.t("campaigns.toasts.error.toUser", {contact: contact}));
            }
          } catch (err) {
            toast.error(i18n.t("campaigns.toasts.error.fail") + err);
            contacts[i][2] = "Error";
            setContacts(contacts);
          }
        }
        setContacts(contacts);
        setSpeaches(state => state + 1);
      } catch (err) {
        toastError(err);
      }
      setContacts(contacts);
      setSpeaches(state => state + 1);
      toast.success(i18n.t("campaigns.toasts.success"));

      setInputs(initialState);
      setCsvFile([]);
      setMedias([]);
      setCampaignId()
  };

  return (
    <MainContainer>
      <div className={classes.modalWrap}>
        <Dialog open={campaignModalOpen} onClose={handleCloseCampaignModal} scroll="paper">
          <DialogTitle>
            {i18n.t("broadcasts.form.title")}
          </DialogTitle>
          <Formik
            initialValues={inputs}
            enableReinitialize={true}
            validationSchema={CampaignSchema}
            onSubmit={(values, actions, e) => {
                setTimeout(() => {
                  handleSaveCampaign(values);
                  actions.setSubmitting(false)
                }, 400);
            }}
          >
            {({ touched, errors, isSubmitting, values, setFieldValue}) => (
              <Form>
                <DialogContent dividers>
                <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                  <Dropzone 
                    onDrop={checkFile} 
                    accept={{
                      'text/plain': ['.csv'],
                      'text/csv': ['.csv'],
                      'text/x-csv': ['.csv'],
                      'application/csv': ['.csv'],
                      'text/comma-separated-values': ['.csv'],
                      'text/x-comma-separated-values': ['.csv'],
                      'text/tab-separated-values': ['.csv'],
                      'application/vnd.ms-excel': ['.csv'],
                  }}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>
                        {csvFile.length !== 0
                        ? csvFile[0].name
                        : i18n.t("broadcasts.form.addContacts")}
                      </p>
                      </div>
                    )}
                  </Dropzone>
                  <div className={classes.multFieldLine}>
                      <Field
                        as={TextField}
                        label={i18n.t("broadcasts.form.prefix")}
                        id="prefix"
                        name="prefix"
                        minRows={1}
                        fullWidth
                        error={touched.prefix && Boolean(errors.prefix)}
                        helperText={touched.prefix && errors.prefix}
                        variant="outlined"
                        margin="dense"
                        onBlur={e => {handleChange(e)}}
                        style={{maxWidth: "213px"}}
                      />
                      <Button
                        onClick={handleImport}
                        color="primary"
                        variant="contained"
                        className={classes.btnWrapper}
                        style={{maxHeight: "33px", marginTop: "8px"}}
                      >
                        {i18n.t("broadcasts.buttons.importMinedContacts")}
                      </Button>
                  </div>
                  </FormControl>



                  <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                    <InputLabel>{i18n.t("broadcasts.form.id")}</InputLabel>
                    <Field
                      as={Select}
                      label={i18n.t("broadcasts.form.id")}
                      name="id"
                      autoFocus
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                      fullWidth
                      placeholder={i18n.t("broadcasts.form.id")}
                      onChange={e => {handleChannelChange(e)}}
                    >
                      {ConnectionItems(whatsApps)}
                    </Field>
                  </FormControl>

                  {channel === "waba" && (
                      <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                      <InputLabel>{i18n.t("broadcasts.form.template")}</InputLabel>
                      <WabaTemplateItems 
                        templates={templates}
                        onSelect={handleTemplateSelect} 
                    />
                    </FormControl>
                 
                   
                  )}

                {channel === "whatsapp" && (
                      <>
                            <Field
                            as={TextField}
                            label={i18n.t("broadcasts.form.message")}
                            id="message"
                            name="message"
                            onFocus={handleFocus}
                            
                            multiline
                            minRows={3}
                            fullWidth
                            error={touched.message && Boolean(errors.message)}
                            helperText={touched.message && errors.message}
                            variant="outlined"
                            margin="dense"
                            onBlur={e => {handleChange(e)}}
                            onChange={e => {handleChange(e)}}
                          />
                          <InputVariables  group="campaigns" field={field} setFieldValue={setFieldValue}/>
                          <InputFile />
           
                          </>
                )}
                    



                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleCloseCampaignModal}
                    className={classes.buttonColorError}
                    disabled={isSubmitting}
                    variant="outlined"
                  >
                    {i18n.t("broadcasts.buttons.reviewContacts")}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={classes.btnWrapper}
                  >
                    {i18n.t("broadcasts.buttons.send")}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
      
      <MainHeader>
        <Title>{i18n.t("broadcasts.title")}</Title>
        <MainHeaderButtonsWrapper>
            
          <Button
              variant="contained"
              color="primary"
              onClick={goToExampleFile}
            >
              {i18n.t("broadcasts.buttons.example.title")}
					</Button>
          <Button
						variant="contained"
						color="primary"
						onClick={(e) => handleOpenCampaignModal()}
					>
						{inputs.id ??
             medias > 0 ??
             contacts.length > 0 ?
              i18n.t("broadcasts.buttons.edit") : i18n.t("broadcasts.buttons.add")}
					</Button>
				</MainHeaderButtonsWrapper>
      </MainHeader>

      
			<Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{i18n.t("broadcasts.table.name")}</TableCell>
              <TableCell align="center">{i18n.t("broadcasts.table.number")}</TableCell>
              <TableCell align="center">{i18n.t("broadcasts.table.status.title")}</TableCell>
              <TableCell align="center">{i18n.t("broadcasts.table.extraParams")}</TableCell>

              <TableCell align="center">{i18n.t("broadcasts.table.dateTime")}</TableCell>
              <TableCell align="center" className={classes.actionsCell}>{i18n.t("broadcasts.table.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contacts.map((el, i) => (
                <TableRow key={el[0]}>
                  <TableCell align="center">{el[0]}</TableCell>
                  <TableCell align="center">{cleanNumber(el[1])}</TableCell>
                  <TableCell align="center">
                    {el[2] === "Success" ?
                      (<div className={classes.status}>
                        <CheckCircleOutlined fontSize="small" className={classes.marginRight}/> {i18n.t("variables.table.status.sent")}
                      </div>) :
                      el[2] === "Error" ?
                        (<div className={classes.status}>
                          <ErrorOutline fontSize="small" className={classes.marginRight} color="error"/> {i18n.t("variables.table.status.error")}
                        </div>) :
                        (<div className={classes.status}>
                          <ScheduleOutlined fontSize="small" className={classes.marginRight}/> {i18n.t("variables.table.status.pending")}
                        </div>)}
                  </TableCell>
                  <TableCell align="center">{el[2]}, {el[3]},{el[4]},{el[5]},{el[6]}</TableCell>
                  <TableCell align="center">{cbaTime(new Date)}</TableCell>

                  <TableCell align="center">
                    <IconButton
                      size="small"
                      className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                      onClick={() => {
                        handleDeleteFromCampaign(i);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </>
          </TableBody>
        </Table>
        
      </Paper>    
    </MainContainer>
  );
};

export default Campaign;

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const cbaTime = date => new Date(date).toLocaleString('pt-BR', { timeZone: 'America/Cuiaba' });