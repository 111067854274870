
import React, { useState, useEffect, useReducer, useContext } from "react";
import { useHistory } from "react-router-dom";

import api from "../../services/api";
import Swal from 'sweetalert2'

import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import Grid from '@material-ui/core/Grid';

import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";

import {blue, purple, red, green, teal, orange, amber} from '@material-ui/core/colors';

import TableRowSkeleton from "../../components/TableRowSkeleton";
import {Typography} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import GamesIcon from '@material-ui/icons/Games';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';

import BugReportIcon from '@material-ui/icons/BugReport';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';       
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'; 
import InfoIcon from '@material-ui/icons/Info';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';


const reducer = (state, action) => {
    if (action.type === "LOAD_UPGRADES") {
      const upgrades = action.payload;
      const newUpgrades = [];
  
      upgrades.forEach((upgrade) => {
        const contactIndex = state.findIndex((c) => c.id === upgrade.id);
        if (contactIndex !== -1) {
          state[contactIndex] = upgrade;
        } else {
          newUpgrades.push(upgrade);
        }
      });
  
      return [...state, ...newUpgrades];
    }
  };
/*
  const KanbanReducer = (state, action) => {
    if (action.type === "LOAD_KANBAN") {
      const kanbanLeafs = action.payload;
  
      const newKanbanData = [];
      
      
      newKanbanData.push({columns: []})
      kanbanLeafs.forEach((kanbanLeaf) => {
          const newKanbanCard = [];
  
          const kanbanCards =  kanbanLeaf.ContactSalesOpportunities;
          const leafConfidence=kanbanLeaf.tagvalue;
    
          kanbanCards.forEach((kanbanCard) => {
            newKanbanCard.push( 
                {
                  id: kanbanCard.id,
                  title: " ",
                  description: formatCard(leafConfidence,kanbanCard)   
                }
            )
          })
  
          if (newKanbanCard[0]){
                newKanbanData[0].columns.push({
                  id: kanbanLeaf.id,
                  title:  kanbanLeaf.name,
                  cards: newKanbanCard
                });
  
          }else{
                newKanbanData[0].columns.push({
                  id: kanbanLeaf.id,
                  title:  kanbanLeaf.name,
                  cards: []
                });
          } 
      });
      
      return newKanbanData[0];
    }
  
  };

*/
  
  const useStyles = makeStyles((theme) => ({
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
    green1: {
          color: green[500]
      },
    red1: {
          color: red[500]
      },
  
    blue1: {
          color: blue[500]
      },
    teal1: {
          color: teal[500]
      },
    orange1: {
          color: orange[500]
      },
    purple1: {
          color: purple[500]
      },
    amber1: {
          color: amber[500]
      },
    avatar:{
      width: "60px",
      height: "60px",
      borderRadius:"10%",
      marginRight:"10px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
    '&:hover': {
        filter: 'brightness(120%)',
    },
    },
    chip: {
        margin: 2,
        paddingLeft: 5
    }
    
  }));
  
  const UpgradeInfo = () => {
    const classes = useStyles();
  
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");

    const [upgrades, dispatch] = useReducer(reducer, []);

  

    useEffect(() => {
      setLoading(true);
      const delayDebounceFn = setTimeout(() => {
        const fetchUpgrades = async () => {
          try {
            const { data } = await api.get("/upgradeinfo");
            console.log("upgrades", data)
            dispatch({ type: "LOAD_UPGRADES", payload: data });
            setLoading(false);
          } catch (err) {
            toastError(err);
          }
        };
        fetchUpgrades();
      }, 1500);
      return () => clearTimeout(delayDebounceFn);
    });

    function format_date(date){
        var dateTime = new Date(date).toLocaleDateString(undefined, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        })
    
        return dateTime;
    
    }

    

    const handleItems = (items) => {
       /*
        const renderActiveToolTips = bizflow => {
            return (
                <div className={classes.customTableCell}>
    
                    {bizflow?.isActive== 1 && (
                        <IconButton aria-label="active" component="span" >
                            <AutorenewIcon  className={classes.green1} />
                        </IconButton>
                    )}
    
                    {bizflow?.isActive== 0 && (                 
                        <IconButton aria-label="inactive" component="span" >
                            <AcUnitIcon  className={classes.blue1} />
                        </IconButton>       
                    )}
    
                </div>
            );
        };





        */
        return (
            items.map((item) => (
                <>
                 <TableRow key={item.id}>
                  <TableCell align="left">{handleItemType(item.releaseType)}</TableCell>
                  <TableCell align="left">{item.releaseDescription}</TableCell> 
                </TableRow>               
                </>
            ))
        );
    };



    const handleItemTypes = (items) => {

                            //erro
                            //desenvolvimento
                            //api
                            //informativo
                            //ux



       
                                let iTypes=[]
                                for (let i=0; i<items.length; i++){
                                
                                    const found = iTypes.find((element) => element == items[i].releaseType);
                                        if(found){}
                                        else{iTypes.push(items[i].releaseType)}

                                }


                                return (
                                    iTypes.map((iType) => (
                                        <>
                                        
                                           {iType=="erro" &&(               <Chip icon={<BugReportIcon style={{color:red[500]}}/>} label={iType} className={classes.chip} size = "small" style={{backgroundColor: red[100], borderColor: red[500], color: red[800]}} variant="outlined" />)}
                                           {iType=="desenvolvimento" &&(    <Chip icon={<DeveloperModeIcon style={{color:green[500]}}/>} label={iType} className={classes.chip} size = "small" style={{backgroundColor: green[100], borderColor: green[500], color: green[800]}} variant="outlined" />)}
                                           {iType=="api" &&(                <Chip icon={<OpenInBrowserIcon  style={{color:orange[500]}} />} label={iType} className={classes.chip} size = "small" style={{backgroundColor: orange[100], borderColor: orange[500], color: orange[800]}} variant="outlined" />)}
                                           {iType=="informativo" &&(        <Chip icon={<InfoIcon style={{color:blue[500]}} />} label={iType} className={classes.chip} size = "small" style={{backgroundColor: blue[100], borderColor: blue[500], color: blue[800]}} variant="outlined" />)}
                                           {iType=="ux" &&(                 <Chip icon={<AspectRatioIcon  style={{color:purple[500]}}/>} label={iType} className={classes.chip} size = "small" style={{backgroundColor: purple[100], borderColor:purple[500], color: purple[800]}} variant="outlined" />)}
                                                    
                                        </>
                                    ))
                                );
                            

     

    }

    const handleItemType = (releaseType) => {

        //erro
        //desenvolvimento
        //api
        //informativo
        //ux

            return (
                
                    <>
                    
                       {releaseType=="erro" &&(               <Chip icon={<BugReportIcon style={{color:red[500]}}/>} label={releaseType} className={classes.chip} size = "small" style={{backgroundColor: red[100], borderColor: red[800], color: red[500]}} variant="outlined" />)}
                       {releaseType=="desenvolvimento" &&(    <Chip icon={<DeveloperModeIcon style={{color:green[500]}}/>} label={releaseType} className={classes.chip} size = "small" style={{backgroundColor: green[100], borderColor: green[800], color: green[500]}} variant="outlined" />)}
                       {releaseType=="api" &&(                <Chip icon={<OpenInBrowserIcon  style={{color:orange[500]}} />} label={releaseType} className={classes.chip} size = "small" style={{backgroundColor: orange[100], borderColor: orange[800], color: orange[500]}} variant="outlined" />)}
                       {releaseType=="informativo" &&(        <Chip icon={<InfoIcon style={{color:blue[500]}} />} label={releaseType} className={classes.chip} size = "small" style={{backgroundColor: blue[100], borderColor: blue[500], color: blue[800]}} variant="outlined" />)}
                       {releaseType=="ux" &&(                 <Chip icon={<AspectRatioIcon  style={{color:purple[500]}}/>} label={releaseType} className={classes.chip} size = "small" style={{backgroundColor: purple[100], borderColor:purple[500], color: purple[800]}} variant="outlined" />)}
                                
                    </>
              
            );
        



}
               
    

    const renderTypeAvatar = upgrade => {
		return (
			<>
				{upgrade.releaseType=="Programada" && (
                    <><EventAvailableIcon style={{color: green[500]}}/> Versão {upgrade.releaseVersion}</>
				)}

                {upgrade.releaseType=="Emergencial" && (
                    <><GamesIcon style={{color:  red[500]}}/> Versão {upgrade.releaseVersion}</>
				)}
			</>
		);
	};





    /*
        const renderisGroupToolTips = upgrade => {
            return (
                <div className={classes.customTableCell}>
                    {upgrade.isGroup == 1 && (
            <><label>Grupo</label><br /><PeopleAltIcon className={classes.teal1} /></>
                    )}
            {upgrade.isGroup == 0 && (		
            <><label>Contato</label><br /><PersonIcon className={classes.blue1} /></>		
                    )}
            {upgrade.isGroup ==2 && (		
            <><label>Lead</label><br /><PersonIcon className={classes.amber1}/></>		
                    )}
            {upgrade.isGroup ==3 && (		
            <><label>Persona Non Grata</label><br /><BugReportIcon className={classes.red1}/></>		
                    )}
            {upgrade.isGroup ==4 && (		
            <><label>Silenciados</label><br /><NewReleasesIcon className={classes.orange1}/></>		
                    )}
                </div>
            );
        };
    */
    return (
        <MainContainer className={classes.mainContainer}>  
          <MainHeader>
            <Title>{i18n.t("upgrades.title")}</Title>
          </MainHeader>
         
          <Paper
            className={classes.mainPaper}
            variant="outlined"
           
          >
           <Grid container alignItems="center" stackable>
                        
                                { upgrades && (upgrades.map((upgrade) => (
                                            <Grid item xs={12} key={upgrade.id} style={{padding:20}}>
                                                        <Card sx={{ maxWidth: "100%" }}  style={{padding:20}}>
                                                    <>
                                                        <span>{handleItemTypes(upgrade.upgradeItems)}</span>
                                                        <Typography component="h3" variant="h3" color="primary">
                                                            Versão {upgrade.releaseVersion}
                                                        </Typography>
                                                        <Typography component="h3" variant="h7" color="primary">
                                                            Data: {format_date(upgrade.releaseDate)}
                                                        </Typography>
                                                
                                                        <Table size="small">
                                                            <TableBody>
                                                                <span>{handleItems(upgrade.upgradeItems)}</span>
                                                            </TableBody>
                                                        </Table>
                                                                                                            
                                                    </>
                                            </Card> 
                                            </Grid>
                                        )))}
               
            </Grid>
          </Paper>
        </MainContainer>
      );

}

export default UpgradeInfo;