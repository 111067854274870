import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import Mustache from "mustache";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Button,
	DialogActions,
	CircularProgress,
	TextField,
    
	Switch,
	FormControlLabel,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import whtsphone from "../../assets/whtsphone.png"

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	uploadInput: {
		display: "none",
	  },
	fileLabel:{
		marginTop: "12px",
    	fontWeight: "900"
	},
    avatar:{
        width: "180px",
        height: "180px",
        borderRadius:"20px"
    },
    descRow:{
        marginTop:"12px"
    },

    whtsphone:{
        backgroundImage: `url(${whtsphone})`,
        backgroundRepeat : 'no-repeat',
        minHeigh: "770px",
        maxHeight: "770px",
       

    },

    contentBox:{
        backColor:"#d9fdd3",
        boxShadow: "0 1px 0.5px #c2c2c2,.13)",
        padding: "6px 7px 8px 9px",
        position: "relative",
        marginRight:"5px",
        borderRadius: "7.5px",
        zIndex: 200,
    }
}));


const CatalogItemViewModal = ({ open, onClose, catalogItem }) => {
	const classes = useStyles();
	const initialState = catalogItem;

	const [lcatalogItem, setlcatalogItem] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [loading, setLoading] = useState(false);

	const [displayGreetingfn, setDisplayGreetingFn] = useState("");
	const [greetingMedias, setGreetingMedias] = useState([]);
    const [mustaccioResult, setMustaccioResult] = useState("");

   
    useEffect(() => {
         if (catalogItem){
            //const json = JSON.parse(catalogItem)
            const json = catalogItem;
            console.log("CatalogItemViewModal::json", json);
            
            if(json.categories.length>0){

                let template = json.categories[0].mustaccio 
               
                let keys = Object.keys(json);
    
                function converter(_template, obj){
                    return Mustache.render(_template, obj);
                }
    
                let obj = new Object();
                for (var i=0; i< keys.length; i++) {
                    if (keys[i] != "category") {
                        obj[keys[i]] = eval("json."+keys[i]);
                    }
                    else{
                        obj[keys[i]] = json.category.categoryName;
                    }
                };
                let mustresult = converter(template, obj);
                setMustaccioResult(mustresult.replace(/\\n/g,"<br />"));
            }

         }
})

const handleClose = () => {
		onClose(false); 
	};


    if(catalogItem){
                return (
                    <div className={classes.root}>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            maxWidth="sm"
                            fullWidth
                            scroll="paper"
                        >
 
                                        <DialogContent>
                                        
                                            <div className="row" style={{backgroundImage: `url(${whtsphone})`,
                                                                            backgroundRepeat : 'no-repeat',
                                                                            minHeight: "770px",
                                                                            maxHeight: "770px",
                                                                            marginLeft:"50px"}}>
                                                    
                                                
                                                    <div className="col-sm-7" style={{ backgroundColor: "#d9fdd3",
                                                                                        marginTop: "204px",
                                                                                        marginLeft: "26px",
                                                                                        padding: "14px",
                                                                                        borderRadius: "7.5px",
                                                                                        fontSize: "11px",
                                                                                        maxHeight:"447px"}}>
                                                        <div classname="contentBox">
                                                                
                                                                    <img src={process.env.REACT_APP_BACKEND_URL+"/public/"+catalogItem.productImg} className={classes.avatar} /><br />
                                                                    <div>{ReactHtmlParser (mustaccioResult)}</div>
                                                               
                                                        </div>
                                                    </div>
                                               
                                            </div>

                        </DialogContent>    
                    </Dialog>        
                    </div>
                );
    }
};

export default React.memo(CatalogItemViewModal);
