import React, { useEffect, useState } from "react";

import {
    Chip,
    makeStyles,
    Paper,
    TextField
} from "@material-ui/core";

import api from "../../services/api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	container: {
        padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
        borderRadius: 0
    },
    chips: {
		display: "flex",
		flexWrap: "wrap",
		'&:hover': {
			filter: 'brightness(120%)',
		},
	},
	chip: {
		margin: 2,
	}
}));


export function UserFilter ({ onFiltered, onChange }) {
    const classes = useStyles();

    const [users, setUsers] = useState([]);
    const [selecteds, setSelecteds] = useState([]);

    useEffect(() => {
        async function fetchData () {
            await loadUsers();
        }
        fetchData();
    }, []);

    const loadUsers = async () => {
        try {
            const { data } = await api.get("/users/list");
            setUsers(data);
        } catch (err) {
            toastError(err);
        }
    }

    const onUserChange = async (value) => {
        setSelecteds(value);
        onFiltered(value);
        onChange(value)
    }

    return (
        <Paper className={classes.container}>
            <Autocomplete
                multiple
                size="small"
                options={users}
                value={selecteds}
                onChange={(e, v, r) => onUserChange(v)}
                getOptionLabel={(option) => option.name}
                renderUsers={(value, getUserProps) =>
                    value.map((option, index) => (
                        <Chip
                            style={{
                                backgroundColor: "#ebd6f5",
                                borderColor: "#8226f5",
                                color: "#beaed2",
                            }}
                            variant="outlined"
                            label={option.name}
                            className={classes.chip}
                            {...getUserProps({ index })}
                            size="small"
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder={i18n.t("ticketsUserSelect.placeholder")} />
                )}
            />
        </Paper>
    )
}