import React, { useContext, useEffect, useRef, useState,  useMemo, } from "react";
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Button,
  Divider,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import Dropzone, { useDropzone } from "react-dropzone";


import {
  GetApp,
} from "@material-ui/icons";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import VcardPreview from "../../components/VcardPreview";
import LocationPreview from "../../components/LocationPreview";
import ModalImageCors from "../../components/ModalImageCors";
import ModalImageChat from "../../components/ModalImageChat";

import { AuthContext } from "../../context/Auth/AuthContext";
import { useDate } from "../../hooks/useDate";
import api from "../../services/api";

import { getBackendUrl } from '../../config';


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflow: "hidden",
    borderRadius: 0,
    height: "100%",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
  },
  uploadInput: {
    display: "none",
  },
  messageList: {
    position: "relative",
    overflowY: "auto",
    height: "100%",
    ...theme.scrollbarStyles,
    backgroundColor: "#eee",
  },
  inputArea: {
    position: "relative",
    height: "auto",
  },
  input: {
    padding: "20px",
  },
  buttonSend: {
    margin: theme.spacing(1),
  },
  boxLeft: {
    padding: "10px 10px 5px",
    margin: "10px",
    position: "relative",
    backgroundColor: "white",
    maxWidth: 450,
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
  boxRight: {
    padding: "10px 10px 5px",
    margin: "10px 10px 10px auto",
    position: "relative",
    backgroundColor: "#E3F3F9",
    textAlign: "right",
    maxWidth: 450,
    borderRadius: 10,
    borderBottomRightRadius: 0,
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
}));


const baseStyle = {
	alignItems: "center",
	backgroundColor: "#fafafa",
	borderColor: "#131B43", // theme.palette.primary.main,
	borderRadius: 15,
	borderStyle: "dashed",
	borderWidth: 1,
	color: "#131B43", // theme.palette.primary.main,
	cursor: "pointer",
  display: "flex",
	flex: 1,
	flexDirection: "column",
  marginBottom: "8px",
	outline: "none",
	padding: "20px",
	transition: "border .24s ease-in-out",
  minWidth: 365,
  width: "100%"
};

const activeStyle = {
	borderColor: "#2196f3",
};

const acceptStyle = {
	borderColor: "#00e676",
};

const rejectStyle = {
	borderColor: "#ff1744",
};


export default function ChatMessages({
  chat,
  messages,
  handleSendMessage,
  handleLoadMore,
  scrollToBottomRef,
  pageInfo,
  loading,
}) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { datetimeToClient } = useDate();
  const baseRef = useRef();
  const [medias, setMedias] = useState([]);
  const [msg, setMsg] = useState();
  const [currentVerb, setCurrentVerb] = useState("");

  const [contentMessage, setContentMessage] = useState("");

  const scrollToBottom = () => {
    if (baseRef.current) {
      baseRef.current.scrollIntoView({});
    }
  };

  const unreadMessages = (chat) => {
    if (chat !== undefined) {
      const currentUser = chat.users.find((u) => u.userId === user.id);
      return currentUser.unreads > 0;
    }
    return 0;
  };

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone();
  

  const style = useMemo(
    () => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
);



  useEffect(() => {
    if (unreadMessages(chat) > 0) {
      try {
        api.post(`/chats/${chat.id}/read`, { userId: user.id });
      } catch (err) {}
    }
    scrollToBottomRef.current = scrollToBottom;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (e) => {
    const { scrollTop } = e.currentTarget;
    if (!pageInfo.hasMore || loading) return;
    if (scrollTop < 600) {
      handleLoadMore();
    }
  };

  const handleInputPaste = (e) => {
    if (e.clipboardData.files[0]) {
      setMedias([e.clipboardData.files[0]]);
      console.log([e.clipboardData.files[0]])
      setContentMessage("Clipboard Data")
    }
  };

  const handleActionVerb = (actionid) =>{

    let verbs = ["Anexado", "Colado","Arrastado"]
    setCurrentVerb(verbs(actionid))
  }

  const handleChangeMedias = (e) => {
    
    if (!e.target.files[0]) {
      return;
    }
    const selectedMedias = Array.from(e.target.files);
    setMedias(selectedMedias);
    setContentMessage(contentMessage + " " + e.target.files[0].name)
  };

  const checkMessageMedia = (message) => {

    let currMediaType=message.mediaName.split("#")[0].split("/")[0] 
    let currMediaPath=message.mediaPath
    let backendUrl = getBackendUrl()
    
    if (message.mediaType === "location" && message.body.split('|').length >= 2) {
      let locationParts = message.body.split('|')
      let imageLocation = locationParts[0]
      let linkLocation = locationParts[1]

      let descriptionLocation = null

      if (locationParts.length > 2)
        descriptionLocation = message.body.split('|')[2]

      return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />
    }
    else if (message.mediaType === "vcard") {
      //console.log("vcard")
      //console.log(message)
      let array = message.body.split("\n");
      let obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        let values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("+") !== -1) {
            obj.push({ number: values[ind] });
          }
          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }
      return <VcardPreview contact={contact} numbers={obj[0].number} />
    }else if (currMediaType === "image") {
      return <ModalImageChat imageUrl={currMediaPath} />;
    }else if (currMediaType === "audio") {
      return (
        <audio controls>
          <source src={`${backendUrl}/public/${currMediaPath}`} 
                  type={message.mediaName.split("#")[0]}>
          </source>
        </audio>
      );
    }else if (currMediaType === "video") {
      return (
        <video
          className={classes.messageMedia}
          src={`${backendUrl}/public/${currMediaPath}`}
           type={message.mediaName.split("#")[0]}
          controls
          style={{maxWidth:"98%"}}
        />
      );
    }else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              target="_blank"
              href={`${backendUrl}/public/${currMediaPath}`}
            >
              Download
            </Button>
          </div>
          <Divider />
        </>
      );
    }
    


  };

  
  const checkFile = (files, rejectedFiles) => {
		if (files[0]) {
      
      console.log("CheckFile",files) 
      const selectedMedias = Array.from(files);
      setMedias(selectedMedias);
      setContentMessage("Arquivo Arrastado: "+files[0].name)
      

    }		
	};

  return (
    <Paper className={classes.mainContainer}>
      <div onScroll={handleScroll} className={classes.messageList}>
        {Array.isArray(messages) &&
          messages.map((item, key) => {
            if (item.senderId === user.id) {
              return (
                <Box key={key} className={classes.boxRight}>
                <Typography variant="subtitle2"  style={{textAlign:"left", fontWeight:600}}>
                    {item.sender.name}
                  </Typography>
                    {(item.mediaPath != undefined) && checkMessageMedia(item)} 
                    {item.message}          
                  <Typography variant="caption" display="block"  style={{textAlign:"right", marginTop:10}}>
                    {datetimeToClient(item.createdAt)}
                  </Typography>
                </Box>
              );
            } else {
              return (
                <Box key={key} className={classes.boxLeft}>
                  <Typography variant="subtitle2"  style={{textAlign:"left",fontWeight:600}}>
                    {item.sender.name}
                  </Typography>
                  {(item.mediaPath != undefined) && checkMessageMedia(item)}
                  {item.message}
                  <Typography variant="caption" display="block" style={{textAlign:"right", marginTop:10}}>
                    {datetimeToClient(item.createdAt)}
                  </Typography>
                </Box>
              );
            }
          })}
        <div ref={baseRef}></div>
      </div>
      <div className={classes.inputArea}>

        <FormControl variant="outlined" fullWidth>


          <InputBase
            multiline
            value={contentMessage}
            onPaste={(e) => {
                handleInputPaste(e);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter" && contentMessage.trim() !== "") {
                handleSendMessage(contentMessage, (medias ? medias : []));
                setContentMessage("");
              }
            }}
            onChange={(e) => {
              console.log("e", e.target.value)
              setContentMessage(e.target.value)
            }}
              
            className={classes.input}
            endAdornment={
              <InputAdornment position="end">
                      <input
              multiple
              type="file"
              id="upload-button"
              disabled={loading}
              className={classes.uploadInput}
              onChange={handleChangeMedias}
             
            />
            <label htmlFor="upload-button">
              <IconButton
                aria-label="upload"
                component="span"
                disabled={loading}
              >
                <AttachFileIcon className={classes.sendMessageIcons} />
              </IconButton>
            </label>
                <IconButton
                  onClick={() => {
                    if (contentMessage.trim() !== "") {
                      handleSendMessage(contentMessage, (medias ? medias : []));
                      setMedias([])
                      setContentMessage("");
                    }
                  }}
                  className={classes.buttonSend}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <Dropzone 
                    onDrop={checkFile} 
                    style={{maxWidth:"98%"}}
                   >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>
                        {medias.length !== 0
                        ? "Arquivo Arrastado: " + medias[0].name
                        : "Arraste para aqui o arquivo, para enviar"}
                      </p>
                      </div>
                    )}
          </Dropzone>
        </FormControl>
      </div>
    </Paper>
  );
}
