
import React, { useState, useEffect, useReducer, useContext, useRef } from "react";
import openSocket from "../../services/socket-io";

import { List,
	makeStyles,
	Paper,
} from "@material-ui/core";

import api from "../../services/api";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import TicketPanelItem from "../TicketPanelItem";
import TicketsListSkeleton from "../TicketsListSkeleton";

import useTicketPanel from "../../hooks/useTicketPanel";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { ContactSupportOutlined } from "@material-ui/icons";
import Grid from '@mui/material/Grid';
import Typography from "@material-ui/core/Typography";

import TicketTransferButton from '../TicketOptionsMenu/transferT.jxs'


const useStyles = makeStyles(theme => ({
	ticketsListWrapper: {
		position: "relative",
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflow: "hidden",
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},
	root: {
		flexGrow: 1,
	  },

	ticketsList: {
		flex: 1,
		overflowY: "scroll",
		...theme.scrollbarStyles,
		borderTop: "2px solid rgba(0, 0, 0, 0.12)",
	},

	ticketsListHeader: {
		color: "rgb(67, 83, 105)",
		zIndex: 2,
		backgroundColor: "white",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},

	ticketsCount: {
		fontWeight: "normal",
		color: "rgb(104, 121, 146)",
		marginLeft: "8px",
		fontSize: "14px",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
}));

const reducer = (state, action) => {

	

	if (action.type === "LOAD_TICKETS") {
		const newTickets = action.payload;

		newTickets.forEach(ticket => {
			const ticketIndex = state.findIndex(t => t.id === ticket.id);
			if (ticketIndex !== -1) {
				state[ticketIndex] = ticket;
				state.push(state.splice(ticketIndex, 1)[0]);
				
			} else {
				state.push(ticket);
			}
		});

		return [...state];
	}
	
	if (action.type === "RELOAD_TICKETS") {
		
		const filteredTickets = action.payload;
		state.length = 0;

		filteredTickets.forEach(ticket => {
			state.push(ticket);
		});

		return [...state];
	}
	
	if (action.type === "RESET_UNREAD") {
		const ticketId = action.payload;

		const ticketIndex = state.findIndex(t => t.id === ticketId);
		if (ticketIndex !== -1) {
			state[ticketIndex].unreadMessages = 0;
		}

		return [...state];
	}

	if (action.type === "UPDATE_TICKET") {
		const ticket = action.payload;

		

		const ticketIndex = state.findIndex(t => t.id === ticket.id);
		if (ticketIndex !== -1) {
			state[ticketIndex] = ticket;
		} else {
			state.push(state.splice(ticketIndex, 1)[0]);
		}
	

		return [...state];
	}

	if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
		
		const ticket = action.payload;
		
		const ticketIndex = state.findIndex(t => t.id === ticket.id);
		

		if (ticketIndex !== -1) {
			state[ticketIndex] = ticket;
			state.push(state.splice(ticketIndex, 1)[0]);
		} else {
			state.push(ticket);
		}
		

		return [...state];
	}

	

	if (action.type === "UPDATE_TICKET_CONTACT") {
		const contact = action.payload;
		const ticketIndex = state.findIndex(t => t.contactId === contact.id);
		if (ticketIndex !== -1) {
			state[ticketIndex].contact = contact;
		}
		return [...state];
	}

	if (action.type === "DELETE_TICKET") {
		const ticketId = action.payload;
		const ticketIndex = state.findIndex(t => t.id === ticketId);
		if (ticketIndex !== -1) {
			state.splice(ticketIndex, 1);
		}

		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}


};

	const TicketPanel = ({ status, searchParam, showAll, tags, users, selectedQueueIds, updateCount, style }) => {
		

	const classes = useStyles();
	const [pageNumber, setPageNumber] = useState(1);
	const [listUsers, setlistUsers] = useState(users);

	const [ticketsList, dispatch] = useReducer(reducer, []);
	const { user } = useContext(AuthContext);
	const { profile, queues } = user;

	useEffect(() => {
		dispatch({ type: "RESET" });
		setPageNumber(1);
	}, [status, searchParam, dispatch, showAll, tags, selectedQueueIds]);

	const { tickets, hasMore, loading } = useTicketPanel();


	useEffect(() => {
		const queueIds = queues.map((q) => q.id);
		const filteredTickets = tickets.filter((t) => queueIds.indexOf(t.queueId) > -1);

		if (profile === "user") {
			dispatch({ type: "LOAD_TICKETS", payload: filteredTickets });
		} else {
			dispatch({ type: "LOAD_TICKETS", payload: tickets });
		}
	}, [tickets, status, searchParam, tags, users, queues, profile]);

	useEffect(() => {

		const queueIds = queues.map((q) => q.id);
		const filteredTickets = tickets.filter((t) => queueIds.indexOf(t.queueId) > -1);

		if ((tags || users) && tickets){
			console.log("RELOAD_TICKETS 1", tags, users,tickets)	

			if (profile === "user") {
				dispatch({ type: "RELOAD_TICKETS", payload: filteredTickets });
			} else {
				dispatch({ type: "RELOAD_TICKETS", payload: tickets });
			}
		}
	}, [tickets, tags, users, queues, profile]);
	

	useEffect(() => {
		const socket = openSocket();
		/*
		const shouldUpdateTicket = ticket =>
			(!ticket.userId || ticket.userId === user?.id || showAll) && (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1);
		*/
		const shouldUpdateTicket = ticket => (true);

	


		socket.on("connect", () => {
			if (status) {
				socket.emit("joinTickets", status);
			} else {
				socket.emit("joinNotification");
			}
		});

		socket.on("ticket", data => {

			
			if (data.action === "reload") {
				dispatch({
					type: "LOAD_TICKETS",
					payload: tickets,
				});
			}

			if (data.action === "updateUnread") {
				dispatch({
					type: "RESET_UNREAD",
					payload: data.ticketId,
				});
			}


			if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
				dispatch({
					type: "UPDATE_TICKET",
					payload: data.ticket,
				});
			}


			if (data.action === "delete") {
				dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
			}
		});
		
		socket.on("appMessage", data => {

			if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
				dispatch({
					type: "UPDATE_TICKET_UNREAD_MESSAGES",
					payload: data.ticket,
				});
			}
		});

		socket.on("contact", data => {
			console.log("socket.on(contact)", data )
			if (data.action === "update") {
				dispatch({
					type: "UPDATE_TICKET_CONTACT",
					payload: data.contact,
				});
			}
		});


		return () => {
			socket.disconnect();
		};
	}, [status, showAll, user, selectedQueueIds, tags]);

	useEffect(() => {
		if (typeof updateCount === "function") {
		updateCount(ticketsList.length);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketsList]);

	const loadMore = () => {
		setPageNumber(prevState => prevState + 1);
	};

	const handleScroll = e => {
		if (!hasMore || loading) return;

		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			loadMore();
		}
	};

	return (

		
    <Paper className={classes.ticketsListWrapper} style={style}>
			<Paper
				square
				name="closed"
				elevation={3}
				className={classes.ticketsList}
				onScroll={handleScroll}
				style={{padding:10}}
			>
				<Typography
					noWrap
					component="h3"
					variant="h6"
					color="textPrimary"
				>
					{status=="open"?"Em Atendimento":"Aguardando"}
				</Typography>
				<div className={classes.root}>
					<Grid container spacing={1}>
									{ticketsList.length > 0 && (
										<>
											{ticketsList.map(ticket => (

												(ticket.status==status && (
														
															<Grid item xs={4} style={{marginTop:"5px", marginBottom:"5px", borderRadius:"5px"}} variant="outlined">
																<TicketPanelItem ticket={ticket} key={ticket.id} />
															</Grid>
									
												))
											))}
										</>
									)}
					</Grid>	
				</div>
					
				
			</Paper>
    </Paper>
	);
};

export default TicketPanel;

