import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Button,
	DialogActions,
	CircularProgress,
	TextField,
	Switch,
	FormControlLabel,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	uploadInput: {
		display: "none",
	  },
	fileLabel:{
		marginTop: "12px",
    	fontWeight: "900"
	}
}));

const SessionSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		number:"",
		greetingMessage: "",
		farewellMessage: "",
		greetingImg: "",
		farewellImg: "",
		isDefault: false,
		outChannel: true,
		dailyMax:0,
        channel:"waba",
        waAccountId: "",
        waApiToken: "",
        waPhoneId: "",
        waApiVersion: "v17.0",
	};

	const [whatsApp, setWhatsApp] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [loading, setLoading] = useState(false);

	const [displayGreetingfn, setDisplayGreetingFn] = useState("");
	const [displayFarewellfn, setDisplayFarewellFn] = useState("");
	const [greetingMedias, setGreetingMedias] = useState([]);
	const [farewellMedias, setFarewellMedias] = useState([]);



	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`whatsapp/${whatsAppId}`);
                console.log(data)
				setWhatsApp(data);

				const whatsQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(whatsQueueIds);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	const handleSaveWhatsApp = async values => {
		//const whatsappData = { ...values, queueIds: selectedQueueIds };


		setLoading(true);

		const formData = new FormData();
	
		formData.append("name",values.name);
		formData.append("number",values.number);
		formData.append("isDefault", values.isDefault);
		formData.append("outChannel", values.outChannel);
		formData.append("greetingMessage",values.greetingMessage)
		formData.append("farewellMessage", values.farewellMessage)
		formData.append("dailyMax", values.dailyMax)
		formData.append("queueIds", selectedQueueIds)
        formData.append("channel","waba")
        formData.append("waAccountId", values.waAccountId)
        formData.append("waApiToken", values.waApiToken)
        formData.append("waPhoneId", values.waPhoneId)
        formData.append("waApiVersion", "v17.0")
		
		greetingMedias.forEach((media) => {
			console.log("media1", media)
			formData.append("medias", media);
		});

		farewellMedias.forEach((media) => {
			console.log("media2", media)
			formData.append("medias", media);
		});

		
		for (var pair of formData.entries()) {
		console.log(pair[0]+ ', ' + pair[1]); 
		} 
		

		try {
			if (whatsAppId) {
				await api.put(`/whatsapp/${whatsAppId}`, formData);
			} else {
				await api.post("/whatsapp", formData);
			}
			toast.success(i18n.t("whatsappModal.success"));
			handleClose();
			setLoading(false);
		} catch (err) {
			toastError(err);
		}
	};

	const handleClose = () => {
		onClose();
		setWhatsApp(initialState);
	};

	const handleChangeGreetingMedias = (e) => {
				
		console.log(e.target.files)

		if (!e.target.files) {
		  return;
		}
		const selectedMedias = Array.from(e.target.files);
		setGreetingMedias(selectedMedias);
		setDisplayGreetingFn(greetingMedias[0]?.name);
	};

	const handleChangeFarewellMedias = (e) => {
		
		console.log(e.target.files)

		if (!e.target.files) {
		  
		  return;
		}
		const selectedMedias = Array.from(e.target.files);
		setFarewellMedias(selectedMedias);
		setDisplayFarewellFn(farewellMedias[0]?.name);
		console.log(farewellMedias)
		console.log(displayFarewellfn)
	};





	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				fullWidth
				scroll="paper"
			>
				<DialogTitle>
					{whatsAppId
						? i18n.t("waba.title.edit")
						: i18n.t("waba.title.add")}
				</DialogTitle>
				<Formik
					initialValues={whatsApp}
					enableReinitialize={true}
					validationSchema={SessionSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveWhatsApp(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("waba.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>

									<Field
										as={TextField}
										label={i18n.t("waba.form.accountid")}
										autoFocus
										name="waAccountId"
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
									<Field
										as={TextField}
										label={i18n.t("waba.form.phoneid")}
										autoFocus
										name="waPhoneId"
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
								</div>
                                <div>
								<Field
										as={TextField}
										label={i18n.t("waba.form.apitoken")}
										autoFocus
										name="waApiToken"
                                        fullWidth
                                        multiline
                                        rows={5}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
                                </div>

								<div>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.greetingMessage")}
										type="greetingMessage"
										multiline
										rows={5}
										fullWidth
										name="greetingMessage"
										error={
											touched.greetingMessage && Boolean(errors.greetingMessage)
										}
										helperText={
											touched.greetingMessage && errors.greetingMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<div className={classes.multFieldLine}>
									<input				
										type="file"
										id="upload-button"
										className={classes.uploadInput}
										onChange={handleChangeGreetingMedias} 
									/>
									<label htmlFor="upload-button">
									Imagem para Saudação:&nbsp;
									<IconButton
										aria-label="upload"
										component="span"
									>
										<AttachFileIcon className={classes.sendMessageIcons} />
									
									</IconButton>
									</label>	
									<label htmlFor="upload-button" fullWidth className={classes.fileLabel}>
										{greetingMedias.length>0 ? greetingMedias[0]?.name : ""}
									</label>

								</div>
								<div>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.farewellMessage")}
										type="farewellMessage"
										multiline
										rows={5}
										fullWidth
										name="farewellMessage"
										error={
											touched.farewellMessage && Boolean(errors.farewellMessage)
										}
										helperText={
											touched.farewellMessage && errors.farewellMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<div className={classes.multFieldLine}>
									<input				
										type="file"
										id="upload-button2"
										className={classes.uploadInput}
										onChange={handleChangeFarewellMedias} 
									/>
									<label htmlFor="upload-button2">
									Imagem para Despedida:&nbsp;
									<IconButton
										aria-label="upload2"
										component="span"
									>
										<AttachFileIcon className={classes.sendMessageIcons} />
									
									</IconButton>
									</label>	
									<label htmlFor="upload-button2" fullWidth className={classes.fileLabel}>
										{farewellMedias.length>0 ? farewellMedias[0]?.name : ""}
									</label>

								</div>
								<QueueSelect
									selectedQueueIds={selectedQueueIds}
									onChange={selectedIds => setSelectedQueueIds(selectedIds)}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("whatsappModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{whatsAppId
										? i18n.t("whatsappModal.buttons.okEdit")
										: i18n.t("whatsappModal.buttons.okAdd")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default React.memo(WhatsAppModal);
