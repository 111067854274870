import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Button,
	DialogActions,
	CircularProgress,
	TextField,
	Switch,
	FormControlLabel,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	uploadInput: {
		display: "none",
	  },
	fileLabel:{
		marginTop: "12px",
    	fontWeight: "900"
	}
}));


const BizFlowModal = ({ open, onClose, id }) => {
	const classes = useStyles();

	const initialState = {
		id:null,
		name: "",
		description:"",
		isActive: false
	};



	const [bizflow, setBizflow] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchSession = async () => {
			if (!id) return;

			try {
				const { data } = await api.get(`bizflow/${id}`);
				setBizflow(data);

				const whatsQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(whatsQueueIds);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [id]);

	const handleSaveBizflow = async values => {

		bizflow.isActive = values.isActive 

		try {
			if (id) {			
				await api.put(`/bizflow/${id}`, bizflow);
			} else {
				await api.post("/bizflow", bizflow);
			}
			toast.success(i18n.t("bizflowModal.success"));
			handleClose();
			setLoading(false);
		} catch (err) {
			toastError(err);
		}
	};

	const handleChangeInput = (e) => {
		
		setBizflow({ ...bizflow, [e.target.name]: e.target.value })  
		
	};

	const handleClose = () => {
		onClose();
		setBizflow(initialState);
	};



	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				fullWidth
				scroll="paper"
			>
				<DialogTitle>
					{id
						? i18n.t("bizFlowModal.title.edit")
						: i18n.t("bizFlowModal.title.add")}
				</DialogTitle>
				<Formik
					initialValues={bizflow}
					enableReinitialize={true}
					
					onSubmit={(values, actions) => {

						setTimeout(() => {
							handleSaveBizflow(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("bizFlowModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										className={classes.textField}
										onChange={(e) => handleChangeInput(e)}
									/>

                                    <FormControlLabel
										label={i18n.t("bizFlowModal.form.isActive")}
										control={
											<Field
												as={Switch}
												color="primary"
												name="isActive"
												checked={values.isActive}
												
											/>
										}
										
									/>          
								</div>
							
								<div>
									<Field
										as={TextField}
										label={i18n.t("bizFlowModal.form.description")}
										type="description"
										multiline
										rows={5}
										fullWidth
										name="description"
										variant="outlined"
										margin="dense"
										onChange={(e) => handleChangeInput(e)}
									/>
								</div>

							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("whatsappModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{id
										? i18n.t("whatsappModal.buttons.okEdit")
										: i18n.t("whatsappModal.buttons.okAdd")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default React.memo(BizFlowModal);
