import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import ModalImage from "react-modal-image";
import api from "../../services/api";
import { getBackendUrl } from '../../config';

const useStyles = makeStyles(theme => ({
	messageMedia: {
		objectFit: "cover",
		width: 200,
		height: 150,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
	},
}));


const getMediaPath = (filename) => {   
    return (`/public/${filename}`)
};

const ModalImageChat = ({ imageUrl }) => {
	const classes = useStyles();
	const [fetching, setFetching] = useState(true);
	const [blobUrl, setBlobUrl] = useState("");


    let backendUrl = getBackendUrl();
    let mediaPath = getMediaPath(imageUrl)

	return (
		<ModalImage
			className={classes.messageMedia}
			smallSrcSet={`${backendUrl}${mediaPath}`}
			medium={`${backendUrl}${mediaPath}`}
			large={`${backendUrl}${mediaPath}`}
			showRotate="true"
			alt="image"
		/>
	);
};


export default ModalImageChat;
