
import api from '../../services/api';
import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Button from "@material-ui/core/Button";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CachedIcon from '@material-ui/icons/Cached';

export const ExportAuditTrailReport = ({ ticketId }) => {
  const fileType ="application/pdf";
  const fileExtension = "pdf";
  const fileName=`ticketaudit-ref~${ticketId}`

  const exportToPDF = (ticketid) => {

    (async () => {
       
        try {
            const { data } = await api.get(`/ws/tickets-export-report?ticketId=${ticketId}`);   
            console.log(data)
            var decodedBlob = Buffer.from(data,"base64")   
            const newFile = new Blob([decodedBlob], { type: fileType });
            //FileSaver.saveAs(newFile, fileName + fileExtension);
            

        } catch (err) {
           console.log(err)
        }
    })();
  };


  return (
    <>
    <Button
    variant="contained"
    color="primary"
    onClick={(e) => exportToPDF(ticketId)}
    >
        Exportar Relatório de Audioria
  </Button>

  <Button
              startIcon={<CloudDownloadIcon />}
              color="primary"
              variant="outlined"
              target="_blank"
              href={fileName+"."+fileExtension}
              />
  </>
  );
};

export default ExportAuditTrailReport;
