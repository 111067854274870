import api from "../../services/api";

const useActiveQueues = () => {
	const findAll = async () => {
        const { data } = await api.get("/activeQueues");
        return data;
    }

	return { findAll };
};

export default useActiveQueues;
