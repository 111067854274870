import {
	Link as RouterLink
} from "react-router-dom";

import whatsBackground from "../../assets/wa-background7-100.png";
import splash from "../../assets/splash.jpg"
import logo from "../../assets/as-letter-w.svg"

import Copyright from "../../components/Copyright";

import { Gradient } from 'react-gradient';
//import '@fontsource/roboto/700.css';

import DisclaimerModal from "../../components/DisclaimerModal";

import {
	Box,
	Button,
	Container,
	CssBaseline,
	Grid,
  Paper,
	IconButton,
	InputAdornment,
	Link,
	makeStyles,
	TextField,
	Typography
} from '@material-ui/core';
import {
	Visibility,
	VisibilityOff
} from '@material-ui/icons';


import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import React, { useState, useContext } from "react";

const gradients = [
  ['#F89A21', '#FF30A5']
];




const WithLove = () => {
	return (
		<Typography variant="body2" style={{color:"#ffffff"}} align="center">
			{"Feito com ❤️ + ☕ no Mato Grosso "}
		</Typography>
 	);
 };

const useStyles = makeStyles((theme) => ({
  container:{
		/*backgroundImage: `url(${whatsBackground})`,*/
		width: "100%", 
		height: "103vh",
		marginTop:"-100px",
    overflowY :'scroll'

	},
  splash :{
    [theme.breakpoints.up('lg')]: {
      marginTop: "120px",
    },
  },

  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "transparent",
    margin: "10px",
    boxShadow: "none"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "70%", // Fix IE 11 issue.
    [theme.breakpoints.up('lg')]: {
      marginTop: "220px",
    },
    [theme.breakpoints.down('md')]: {
      marginTop: "-71px",
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
	img: {
		margin: theme.spacing(1),
		marginBottom: "30px",
		paddingBottom: "30px",
	},
}));

const Login = () => {
  const classes = useStyles();

  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [DiscaimerModalOpen, setDisclaimerModalOpen] = useState(false);

  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleDisclaimer = () =>{
    setShowDisclaimer(!showDisclaimer)
  }

  
  const closeDisclaimer = () =>{
    setShowDisclaimer(false)
  }


  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };


  const Disclaimer = () => {
    return (
      <>
        <Typography variant="body2" style={{color:'#ffffff', fontWeigth:900, textAlign:"center"}} >

          {"O uso deste sistema implica a aceitação das seguintes "}

          <Link color="inherit" onClick={handleDisclaimer} >
            <span style={{color:'#a10882'}}>
            {"Condições de Uso"}
            </span>
            

          </Link>
          </Typography>

          <Typography variant="body2" style={{color:'#ffffff', fontWeigth:900, textAlign:"center"}} >

          {"Se não concorda, não use."}
          </Typography>

  
      </>
  
  
     );
   };



  return (
    <div>
      <Gradient
          gradients={ gradients } // required
          property="background"
          duration={ 3000 }
          angle="45deg"
          style={{height:"110vh", width:"100vw"}}
        > 




          <div className={classes.container}>

          <DisclaimerModal
            open={showDisclaimer}
            onClose={closeDisclaimer}
           ></DisclaimerModal>

            <Grid container spacing={16} justify="flex-start" style={{marginTop:"100px"}}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Paper className={classes.paper}>
                  <img src={splash} className={classes.splash} style={{width: '70%', borderRadius:"15px", border:"5px solid #ffffff"}} />
                  <img className={classes.img} src={logo} alt="Logo" style={{width: '70%', marginTop: '20px'}} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Paper className={classes.paper}>
               
            <form className={classes.form} noValidate onSubmit={handlSubmit}>
              <Typography component="h1" variant="h2" style={{color:"#ffffff", textAlign:"center"}}>
                  {i18n.t("login.title")}
                </Typography>

              <TextField
                style={{
                  border: "ipx solid #ffffff",
                  borderRadius: "5px",
                  color: "#ffffff"
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={i18n.t("login.form.email")}
                name="email"
                value={user.email}
                onChange={handleChangeInput}
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={i18n.t("login.form.password")}
                id="password"
                value={user.password}
                onChange={handleChangeInput}
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((e) => !e)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {i18n.t("login.buttons.submit")}
              </Button>

            </form>
            <Box mt={8} style={{marginTop:"1px"}}>
              {<Copyright style={{fontWeight:900}}/>}<br/>{<WithLove  style={{fontWeight:900}}/>}<br/>{<Disclaimer  style={{fontWeight:900}}/>}
            
            </Box>
           
                </Paper>
              </Grid>
            </Grid>
          </div>


      </Gradient>
    </div>
  );
};

export default Login;