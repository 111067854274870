import React from 'react';

import { red } from "@material-ui/core/colors";

import {
    CircularProgress,
    makeStyles,
} from '@material-ui/core';
import {
	CropFree,
	SignalCellularConnectedNoInternet2BarRounded,
	SignalCellularConnectedNoInternet0BarRounded,
    SignalCellularConnectedNoInternet2Bar,
	SignalCellular4BarRounded,
} from "@material-ui/icons";
import { green } from "@material-ui/core/colors";

import CustomTooltip from '../CustomTooltip';
import { i18n } from "../../translate/i18n";

import ch_waba from "../../assets/channel_waba.svg"
import ch_whatsapp from "../../assets/channel_whatsapp.svg"
import ch_instagram from "../../assets/channel_instagram.svg"
import ch_messenger from "../../assets/channel_messenger.svg"
import ch_facebook from "../../assets/channel_facebook.svg"

const useStyles = makeStyles(theme => ({
	customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
    colorGreen: {
        color: green[500]
    },
	buttonProgress: {
		color: theme.palette.secondary.main,
	}
}))

const ChannelTooltip = ({whatsApp}) => {
    const classes = useStyles();
    
    return (
        <>
        <div className={classes.customTableCell}>

            {whatsApp.channel === "whatsapp" && (
               <CustomTooltip title="Whatsapp">
                   <img src={ch_whatsapp} style={{width:15, marginLeft:5}} />
                </CustomTooltip>
            )}
            {whatsApp.channel === "waba" && (
               <CustomTooltip title="Whatsapp Cloud API">
                    <img src={ch_waba} style={{width:15, marginLeft:5}} />
                </CustomTooltip>
            )}
            
            {whatsApp.channel === "facebook" && (
                <CustomTooltip title="Facebook">
                    <SignalCellular4BarRounded className={classes.colorGreen} />
                </CustomTooltip>
            )}
            
            {whatsApp.channel === "messenger" && (
                <CustomTooltip title="Messenger">
                    <SignalCellularConnectedNoInternet2Bar style={{ color: red[500] }} />
                </CustomTooltip>
            )}

            {whatsApp.channel === "instagram" && (
                <CustomTooltip title="Instagram">
                    <SignalCellularConnectedNoInternet2Bar style={{ color: red[500] }} />
                </CustomTooltip>
            )}  

        </div>
        </>
    );
};

export default ChannelTooltip;