import React, { useState } from "react";
import { styled } from '@material-ui/core/styles';
import clsx from 'clsx';
import api from "../../services/api";


import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from "@material-ui/icons/Close";
import BugReportIcon from '@material-ui/icons/BugReport';
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import InfoIcon from '@material-ui/icons/Info';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';

import { CAccordion } from '@coreui/react'
import { CAccordionBody } from '@coreui/react'
import { CAccordionHeader } from '@coreui/react'
import { CAccordionItem } from '@coreui/react'

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Badge from "@material-ui/core/Badge";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import FunctionsIcon from '@material-ui/icons/Functions';
import FlashOnIcon from '@material-ui/icons/FlashOn';

import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

import { i18n } from "../../translate/i18n";

import ContactModal from "../ContactModal";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";

import contactDrawerMask from "./../../assets/contactdrawermask.png"
import { Map, TileLayer, useMap, Marker, Popup} from 'react-leaflet'

import {blue, purple, red, green, teal, orange, amber} from '@material-ui/core/colors';

import L from 'leaflet';
import { Icon } from "leaflet";
import markerIcon2x from '../../libraries/leaflet/images/marker-icon-2x.png';
import markerIcon from '../../libraries/leaflet/images/marker-icon.png';
import markerShadow from '../../libraries/leaflet/images/marker-shadow.png';

import "../../libraries/leaflet/leaflet.css"

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	largestroke:{
		fontFamily: "Raleway",
		fontSize: "2em",
		fontWeight: 700,
		color: "#4a148c",
		marginLeft: "26px",
		whiteSpace: "pre",
	},
	regularstroke:{
		fontFamily: "Raleway",
		fontSize: "1.7em",
		fontWeight: 600,
		color: "#4a148c",
		marginLeft: "26px",
		whiteSpace: "pre",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		backgroundColor: "#eee",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: "#eee",
		flexDirection: "column",
		
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
		  duration: theme.transitions.duration.shortest,
		}),
	  },
	expandOpen: {
		transform: 'rotate(180deg)',
	  },
	contactAvatar: {
		margin: 15,
		width: 160,
		height: 160,
	},

	contactHeader: {
		display: "flex",
		padding: 8,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 4,
		},
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
	},
	contactExtraInfo: {
		marginTop: 4,
		marginLeft:6,
		marginRight:6,
		padding: 6,
	},

	accordionButton1: {
		marginLeft: "5px",
	  	marginBottom: "10px",
		border: 0,
	 	borderRadius: 15,
	  	width: "88%",
	  	color: "#4a148c",
		backgroundColor:"#ffffff"
	  },

	subHeader: {
	  color: purple[900], 
	  fontSize: 16, 
	  width:200, 
	  borderRadius:10,
	  textAlign:  "left"
	},

	paperItems:{
		marginLeft: "10px",
		marginTop: "5px",
		marginBottom: "10px",
		width:215, 
		borderRadius:10
	},
	label1:{
	  paddingLeft:"20px",
	},
	label2:{
	  display: "none",
	}
}));

const ContactDrawer = ({ open, handleDrawerClose, contact, user, tags, loading }) => {
	const classes = useStyles();

	const [modalOpen, setModalOpen] = useState(false);

	const [expanded, setExpanded] = useState(false);

	const handleExpandClick = () => {
	  setExpanded(!expanded);
	};

	const handleClosingStatus = async (oppStatus,oppId) =>{
        let oppData = {closingStatus:oppStatus, oppId, oppData}
        console.log("handleClosingStatus")
        handleUpdateCard(oppId, oppData)
    }

	const handleUpdateCard = async (oppId,oppData) =>{
		try {
		  const { data } = await api.put(`/opportunity/${oppId}`,oppData);
		  console.log(data)
		
		} catch (err) {
		console.log(err);
		}
	  }

	function generateRandomColor(){
	let maxVal = 0xFFFFFF; // 16777215
	let randomNumber = Math.random() * maxVal; 
	randomNumber = Math.floor(randomNumber);
	randomNumber = randomNumber.toString(16);
	let randColor = randomNumber.padStart(6, 0);   
	return `#${randColor.toUpperCase()}`
	}
	
	function setBackgoundColor(status){
	
			let bgColor;
	
			if(status==1){
				bgColor="#edfbe6";
			} else  if(status==0){
				bgColor="#fbe6e8";
			}else{
				bgColor="#ffffff";
			}
	return  bgColor;
	}
 
	const retrieveConfidence = (tagid) => {
				if(tagid){
					tags.forEach(tag => {
						if(tag.id==tagid){
								console.log("retrieveConfidence", tag.tagvalue)
								return (<span>tag.tagvalue</span>)
						}
					});
				}else{
					return 0;
				}
	}	

	const vars = { 
		'--cui-accordion-bg': '#ffffff',
		'--cui-accordion-border-color': '#ffffff',
		'--cui-accordion-border-width': "1",
		'--cui-accordion-border-radius': "5",
		"--cui-accordion-body-padding-x": "0!important",
		"--cui-accordion-body-padding-y": "10px",
		"--cui-accordion-btn-focus-border-color": "#ffffff",
		"--cui-accordion-btn-color": "#ffffff",
    	"--cui-accordion-btn-bg": "#ffffff",
		"--cui-body-color":"#ffffff",
	}




	console.log("Sales Opps 1", contact?.salesOpps)
	console.log("Sales Opps 2", tags)

	const ExpandMore = styled((props) => {
		const { expand, ...other } = props;
		return <IconButton size="small" variant="contained" {...other} />;
	  })(({ theme, expand }) => ({
		transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
		  duration: theme.transitions.duration.shortest,
		}),
	  }));
	  
	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="right"
			open={open}
			PaperProps={{ style: { position: "absolute" } }}
			BackdropProps={{ style: { position: "absolute" } }}
			ModalProps={{
				container: document.getElementById("drawer-container"),
				style: { position: "absolute" },
			}}
			classes={{
				paper: classes.drawerPaper,
			}}
		>

			{loading ? (
				<ContactDrawerSkeleton classes={classes} />
			) : (
				<div className={classes.content}>
					
						<Card sx={{ maxWidth: "100%" }}>

							<div style={{ position: "relative", width:"100%" }}>
								<CardMedia 
									width={"100%"} 
									component="img" 
									image={contact.profilePicUrl}
									title={contact.name}
									alt={contact.name}
								/> 	
							
								<div style={{position: "absolute", top: 0, width:"100%"}}> 
									<img src={contactDrawerMask} style={{width: "313px", top: "0px", position: "absolute"}}/>
								</div>

								<div style={{position: "absolute", color: "white",top: 240,left: "30%", transform: "translateX(-50%)",}}> 
									<span className={classes.largestroke}>{contact.name}</span><br />
									<span className={classes.regularstroke}>{contact.number}</span><br />
									<span className={classes.regularstroke}>{contact.email}</span><br />
								</div>
								<div style={{position: "absolute", color: "white", top: 0,left: "90%"}}> 
									<IconButton size="small" variant="contained" onClick={handleDrawerClose}>
										<CloseIcon onClick={handleDrawerClose}  style={{ color: purple[900], fontSize: 20 }}/>
									</IconButton>	
								</div>
								
							</div>

							<CardActions disableSpacing>
								<IconButton size="small" variant="contained"><EditIcon onClick={() => setModalOpen(true)}  style={{ color: purple[900], fontSize: 20 }}/></IconButton>	
								<IconButton size="small" variant="contained"><BugReportIcon  style={{ color: purple[900], fontSize: 20 }}/></IconButton>
								<IconButton size="small" variant="contained"><CloseIcon onClick={handleDrawerClose}  style={{ color: purple[900], fontSize:20 }}/></IconButton>	
								<IconButton size="small" variant="contained"
									className={clsx(classes.expand, {
										[classes.expandOpen]: expanded,
									})}
									>
									
								</IconButton>
							</CardActions>
								
							<CardContent>

								<CAccordion style={vars} flush activeItemKey={1}>

									
										<CAccordionItem itemKey={1} style={{border:0}}>
											<CAccordionHeader className={classes.accordionButton1} style={{width:"85%"}}>
												<Typography variant="subtitle1" style={{marginTop:"0px", color:"#4a148c", fontWeight:600}}>
													<InfoIcon style={{ color: purple[900], fontSize:20 }}/> {i18n.t("contactDrawer.extraInfo")}
												</Typography>
												<ExpandMoreIcon style={{ color: purple[900], fontSize: 20, position: "absolute", left: "90%" }} />
											</CAccordionHeader>

											<CAccordionBody>	
												{contact?.extraInfo?.map(info => (
													<Paper
														key={info.id}
														square
														variant="outlined"
														className={classes.contactExtraInfo}
													>
														{info.name == "location" ? (
															<Map  center={JSON.parse(info.value)} zoom={13} scrollWheelZoom={true} style={{width: "100%", height: "200px", position: "relative"}}>
															<TileLayer
																attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
																url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
															/>
															<Marker 
																position={JSON.parse(info.value)} 
																icon={
																	new Icon({
																		iconUrl: markerIcon,
																		iconSize: [25, 41],
																		shadowUrl: markerShadow,
																		shadowSize: [41,41]
																	})
																}
															>
																<Popup>
																	A pretty CSS3 popup. <br /> Easily customizable.
																</Popup>
															</Marker>
															</Map>
														) : (
															<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom><span style={{marginTop:"10px", color:"#4a148c", fontWeight:600}}>{info.name} : </span>{info.value}</Typography>
														)}
													</Paper>
												))}
						
											</CAccordionBody>
										</CAccordionItem>
									

									

										<CAccordionItem itemKey={2} style={{border:0}}>

											<CAccordionHeader  className={classes.accordionButton1} style={{width:"85%"}}>
												<Typography variant="subtitle1"  style={{marginTop:"0px", color:"#4a148c", fontWeight:600}}>
													<MonetizationOnIcon style={{ color: purple[900], fontSize:20 }}/> {i18n.t("contactDrawer.salesOpp")}
												</Typography>
												<ExpandMoreIcon style={{ color: purple[900], fontSize: 20, position: "absolute", left: "90%" }} />
											</CAccordionHeader>

											<CAccordionBody>	
												{contact?.salesOpps.map(info => (
													

													<>
														<div>
															<Card style={{maxWidth: 345, backgroundColor:setBackgoundColor(info.closingStatus)}}>
																<CardHeader
																			avatar={
																				<Avatar aria-label="recipe" style={{backgroundColor: generateRandomColor(),fontWeight:600 }} >{user.name.split(" ")[0].substring(0,1)}{user.name.split(" ")[1].substring(0,1)}</Avatar>
																			}
																			action={
																			<IconButton aria-label="info">
																				<InfoIcon />
																			</IconButton>
																			}
																			title={info.name}
																			subheader="2022-12-28"
																		/>
																<CardContent>
																	<Typography variant="body2" color="textSecondary" component="p">
																		<div><MonetizationOnIcon style={{color:green[500]}}/> Valor: <span style={{fontWeight:700}}> R$ {info.value}</span></div>
																		<div><EventIcon style={{color:purple[500]}}/> Fechamento: <span style={{fontWeight:700}}>2022-12-28</span></div>
																		<div><PersonIcon  style={{color:teal[500]}} />Responsável: <span style={{fontWeight:700}}>{user.name}</span></div>
																		<div><FunctionsIcon  style={{color:orange[500]}} />Estágio: <span style={{fontWeight:700}}>{info.stage}</span></div>
																		<div><FlashOnIcon style={{color:blue[500]}} />Proxima Atividade: <span style={{fontWeight:700, color:red[500]}}>2022-12-20</span></div>
																	</Typography>
																</CardContent>
																<CardActions disableSpacing>

																	{ !info.closingStatus && (
																		<>
																		<IconButton onClick={() => handleClosingStatus(1,info.id)} >
																				<ThumbUpIcon style={{color:green[500], fontSize: 33}} />
																		</IconButton>
																	
																		<IconButton  onClick={() => handleClosingStatus(0, info.id)}>
																				<ThumbDownIcon style={{color:red[500], fontSize: 33}}/>
																		</IconButton>
																		</>
																	)}
																			
																	{info.closingStatus == 1 && (
																		<ThumbUpIcon style={{color:green[500], fontSize: 33}} />
																	)}
																	{ info.closingStatus == 0 && (
																		<ThumbDownIcon style={{color:red[500], fontSize: 33}}/>
																	)}
																

																</CardActions>
															</Card>
														</div> 
												</>
									
												))}
											</CAccordionBody>
										</CAccordionItem>
									

								</CAccordion>
							</CardContent>

						</Card>
						 
					
					
						<ContactModal
							open={modalOpen}
							onClose={() => setModalOpen(false)}
							contactId={contact.id}
						></ContactModal>

		
					
				
				
					
			</div>
			)}
		</Drawer>
	);
};

export default ContactDrawer;
