import React from 'react';
import PierChart from '../graficos/PierChart';

import '../assets/css/app.css';
import AgentesDesempenhoTable from '../datatables/AgentesDesempenhoTable';
import AgentesTotais from '../datatables/AgentesTotais';
import FilasTotais from '../datatables/FilasTotais';
import TagsTotais from '../datatables/TagsTotais';
import socketIOClient from "socket.io-client";
import { format } from "date-fns";

import Paper from "@material-ui/core/Paper"
import { Typography, Box} from "@material-ui/core";
import { i18n } from "../../../../translate/i18n"

import { getBackendUrl } from '../../../../config';

//const backendServer = getBackendUrl();

var socket;

class DashboardPrincipal extends React.Component {

    constructor(props, ) {
        super(props);
        this.refs= React.createRef();
        this.state = {
          dataStatus: [],
          quantitativoContatoTicket: [],
          desempenhosAgente: [],
          quantitativoReceptivo: {quantidade: 0},
          quantitativoAtivo: {quantidade: 0},
          tempomedioPrimeiraResposta: "",
          piorTempoPrimeiraResposta: "",
          tempomedioconcluirConversa: "",
          piortempoconcluirconversa: "",
          dataInicialSelecionado: format(new Date(),"yyyy-MM-dd"),
          horaInicialSelecionado: "00:00",
          dataFinalSelecionado: format(new Date(),"yyyy-MM-dd"),
          horaFinalSelecionado: "23:59",
          colors:[],
          alturaDivTempo: 453,
      
          endpoint:getBackendUrl(),

          agentesTotais: [],
          filasTotais:[],
          tagsTotais:[],
          contatosTotais:[],
          contatosUnicos:[],

          atendimentosNovos:[],
          atendimentosTotais:[]

          
        };
        console.log(this.state.endpoint);
        socket = socketIOClient(this.state.endpoint);

        this.carregarDataHora();

    }

    getRequest = retornoRequisicao => {
      console.log("retornoRequisicao", retornoRequisicao);

      this.setState({
          quantitativoContatoTicket: [],
          desempenhosAgente: [],
          agentesTotais:[],
          filasTotais:[],
          tagsTotais:[],
          contatosTotais: [],
          contatosUnicos: [],
          atendimentosNovos:[],
          atendimentosTotais:[],
          quantitativoReceptivo: {quantidade: 0},
          quantitativoAtivo: {quantidade: 0},
          tempomedioPrimeiraResposta: "",
          piorTempoPrimeiraResposta: "",
          tempomedioconcluirConversa: "",
          piortempoconcluirconversa: ""
          
      
      })


      if(retornoRequisicao != null) {

        if(retornoRequisicao[0] != null) {
          this.listarStatus(retornoRequisicao[0] );
        }
		 
        if(retornoRequisicao[1] != null) {
          this.listarQantitativoContatoTicket(retornoRequisicao[1] );
        }
		 
        if(retornoRequisicao[2] != null && retornoRequisicao[2][0] != null) {
          this.tempomedioprimeiraresposta(retornoRequisicao[2][0].tempo_medio_primeira_resposta);
        }

        if(retornoRequisicao[3] != null && retornoRequisicao[3][0] != null) {
          this.piortempoprimeiraresposta(retornoRequisicao[3][0].pior_tempo_primeira_resposta );
        }
		 
        if(retornoRequisicao[4] !=null && retornoRequisicao[4][0] != null) {
          this.tempomedioconcluirConversa(retornoRequisicao[4][0].tempo_medio_concluir_conversa );
        }
	 
        if(retornoRequisicao[5] !=null && retornoRequisicao[5][0] != null) {
          this.piortempoconcluirconversa(retornoRequisicao[5][0].pior_tempo_concluir_conversa);
        }

        if(retornoRequisicao[6] != null) {
          this.listarDashboardPrincipalAgenteDesempenho(retornoRequisicao[6]);
        }
        
        if(retornoRequisicao[7] !=null) {
          this.agentesTotais(retornoRequisicao[7]);
        }
		  
        if(retornoRequisicao[8] !=null) {     
          this.filasTotais(retornoRequisicao[8]);
        }

        if(retornoRequisicao[9] !=null) {     
          this.tagsTotais(retornoRequisicao[9]);
        }

        if(retornoRequisicao[10] !=null) {     
          this.setState({contatosTotais: retornoRequisicao[10][0]?.novos});
        }

        if(retornoRequisicao[11] !=null) {     
          this.setState({contatosUnicos: retornoRequisicao[11][0]?.unicos});
        }

        if(retornoRequisicao[12] !=null) {     
          this.setState({atendimentosNovos: retornoRequisicao[12][0]?.novos});
        }

        if(retornoRequisicao[13] !=null) {     
          this.setState({atendimentosTotais: retornoRequisicao[13][0]?.unicos});
        }
     
      }

    };
   
    carregarDataHora =() => {
    
	    var dtinicial = format(new Date(),"yyyy-MM-dd");
	 
	  
      this.setState({
        dataInicialSelecionado: dtinicial
        })
	  
	  
      this.setState({
        horaInicialSelecionado: ("00:00")
        })
     

      let date = new Date();
      date.setDate(date.getDate() + 1);
	    var dtfinal = format(date,"yyyy-MM-dd");

	 
      this.setState({
        dataFinalSelecionado: dtfinal
        })
	  
      this.setState({
        horaFinalSelecionado: ("23:59")
        })

      //var dataHoraInicialString =   this.state.dataInicialSelecionado+' '+ this.state.horaInicialSelecionado+':00';
      //var dataHoraFinalString = 	this.state.dataFinalSelecionado  +' '+ this.state.horaFinalSelecionado+':59';

      
      //socket.emit("bi_request", {'oper' : 'kpi_data', 'dhi'  : dataHoraInicialString,	'dhf'	:dataHoraFinalString});
      //socket.on("bi_response", this.getRequest);
      
    }


    filtraDashboard =() => {
      var dataHoraInicialString =   this.state.dataInicialSelecionado+' '+ this.state.horaInicialSelecionado+':00';
			var dataHoraFinalString = 	this.state.dataFinalSelecionado  +' '+ this.state.horaFinalSelecionado+':59';

      console.log("dataHoraInicialString", dataHoraInicialString)
      console.log("dataHoraFinalString", dataHoraFinalString)

      socket.emit("bi_request", {'oper' : 'kpi_data', 'dhi'  : dataHoraInicialString,	'dhf'	:dataHoraFinalString});
      socket.on("bi_response", this.getRequest);

    }

    

 
    listarStatus = (resultado) => {
      this.setState({
        dataStatus: resultado
      })
    }
	
    listarQantitativoContatoTicket = (resultado) => {
      this.setState({
        quantitativoContatoTicket: resultado
      })
     
    }


    listarDashboardPrincipalAgenteDesempenho = (resultado) => {
      this.setState({
        desempenhosAgente: resultado
      })
    }

    tempomedioprimeiraresposta = (resultado) => {
      this.setState({
        tempomedioPrimeiraResposta: resultado
      })
    }

    piortempoprimeiraresposta = (resultado) => {
      this.setState({
        piorTempoPrimeiraResposta: resultado
      })
    }

    tempomedioconcluirConversa = (resultado) => {
      this.setState({
        tempomedioconcluirConversa: resultado
      })
    }


    tempomedioconcluirConversa = (resultado) => {
      this.setState({
        tempomedioconcluirConversa: resultado
      })
    }

    piortempoconcluirconversa = (resultado) => {
      this.setState({
        piortempoconcluirconversa: resultado
      })
    }

    agentesTotais = (resultado) => {
      this.setState({
        agentesTotais: resultado
      })
    }

    filasTotais = (resultado) => {
      this.setState({
        filasTotais: resultado
      })
    }

    tagsTotais = (resultado) => {
      this.setState({
        tagsTotais: resultado
      })
    }
    
    
  

    /*

      contatosTotais = (resultado) => {
      this.setState({
        contatosTotais: resultado
      })
    }

    contatosUnicos = (resultado) => {
      this.setState({
        contatosUnicos: resultado
      })
    }
*/

  componentDidMount() {
      const height = this.refs.divTempoAtendimento.clientHeight;

      this.setState({
        alturaDivTempo: height+55
      })
      
  }
  
    onchangeDataInicial(event){
        console.log(event.target.value);
	      this.setState({
              dataInicialSelecionado: event.target.value
        })
    }

    onchangeDataFinal(event){
      console.log(event.target.value);
      this.setState({
            dataFinalSelecionado: event.target.value
      })
    }
    

 

    render() {
  
      

      for( let idx = 0; idx < this.state.quantitativoContatoTicket.length; idx++ ){
        if(this.state.quantitativoContatoTicket[idx].status === 'Receptivo') {
					// eslint-disable-next-line
					this.state.quantitativoReceptivo = this.state.quantitativoContatoTicket[idx];
					//this.setState({
					//		quantitativoReceptivo: this.state.quantitativoContatoTicket[idx]
					//})
			
          
         } else if(this.state.quantitativoContatoTicket[idx].status === 'Ativo') {
					// eslint-disable-next-line
					this.state.quantitativoAtivo = this.state.quantitativoContatoTicket[idx];
					//this.setState({
					//			quantitativoAtivo: this.state.quantitativoContatoTicket[idx]
					//})
         }
      }

      
     
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card card-border-color card-border-color-primary">
                    <div className="card-body">
                       <div className="form-group row">
                          <div className="col-md-4">
                              <div className="row horizontal">
                                 <div className="col-md-6 p-2">
                                        <label htmlFor="dataInicial">Desde a data:</label>
                                        <input   id="dataInicial"  type="date"   defaultValue={this.state.dataInicialSelecionado}  value={this.state.dataInicialSelecionado} onChange={event => this.setState({dataInicialSelecionado: event.target.value})}   className="form-control form-control-sm"/>
                                  </div>
                                 <div className="col-md-6 p-2">
                                    <label htmlFor="horaInicial">Hora</label>
                                    <input type="time"   defaultValue={this.state.horaInicialSelecionado} className="form-control form-control-sm" /> 
                                  </div>
                               </div>
                          </div>
                          <div className="col-md-4">
                            <div className="row horizontal">
                                <div className="col-md-6 p-2">
                                    <label htmlFor="dataFinal">até a data:</label>
                                    <input type="date" name="dataFinal" id="dataFinal" defaultValue={this.state.dataFinalSelecionado}  value={this.state.dataFinalSelecionado} onChange={event => this.setState({dataFinalSelecionado: event.target.value})}   className="form-control form-control-sm"/>
                                </div>
                                <div className="col-md-6 p-2">
                                    <label htmlFor="horaFinal">Hora</label>
                                    <input type="time"  defaultValue={this.state.horaFinalSelecionado} className="form-control form-control-sm"/> 
                                </div>
                            </div>
					                </div>
                          <div className="col-md-3">
                            <div className="row horizontal">
                                <div className="col-md-4 p-7">
                                  <button className="btn btn-primary" type="submit" onClick={this.filtraDashboard} >Filtrar</button>
                                </div> 
                            </div> 
				                  </div>
                       </div>
                    </div>
                </div>
            </div>  

            <div className="col-md-12">      
                <div className="row">
                    <div className="col-lg-6">
                    <div className="card" >
                        <div className="card-header card-header-divider">
                        <div className="tools">
                            <span className="icon mdi mdi-chevron-down">
                            </span>
                            <span className="icon mdi mdi-refresh-sync">
                            </span>
                            <span className="icon mdi mdi-close">
                            </span>
                          </div>
                          <Typography component="h3" variant="h6" color="primary" paragraph>
                              Status dos Atendimentos
                          </Typography>   
                        </div>
                        <div className="card-body">
                          <PierChart data={this.state.dataStatus}
                                title={''}
                                colors={['#4a148c', '#6727b5', '#700ee9', '#9040f3', '#a366ef', '#c198f3']}            
                                />

                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6">

                    <div  className="card" >
                        <div className="card-header card-header-divider">
                        <div className="tools"><span className="icon mdi mdi-chevron-down">
                            </span>
                            <span className="icon mdi mdi-refresh-sync"></span><span className="icon mdi mdi-close">
                            </span></div>
                            
                            <Typography component="h3" variant="h6" color="primary" paragraph>
                              Tempo dos Atendimentos
                          </Typography>
                        </div>
                        <div ref="divTempoAtendimento" id="divTempoAtendimento" className="card-body">
                    

                          <div className="row be-booking-promos">
                            <div className="col-xl-6">
                              <div className="mware-data mware-data-1" >
                                <div className="be-booking-desc" style={{flex: "auto"}}>
                                  
                                      <h4 className="be-booking-desc-title">{this.state.tempomedioPrimeiraResposta}</h4>
                                      <span className="be-booking-desc-details">Tempo médio de primeira resposta</span>
                                </div>
                              </div>
                              <div className="mware-data mware-data-2">
                                <div className="be-booking-desc" style={{flex: "auto"}}>
                                  <h4 className="be-booking-desc-title">{this.state.tempomedioconcluirConversa}</h4>
                                  <span className="be-booking-desc-details">Tempo médio para concluir a conversa</span>
                                </div>

                            </div>
                          </div>
                          <div className="col-xl-6">
                            <div className="mware-data mware-data-3">
                              <div className="be-booking-desc"  style={{flex: "auto"}}>
                                <h4 className="be-booking-desc-title">{this.state.piorTempoPrimeiraResposta}</h4>
                                <span className="be-booking-desc-details">Pior tempo de primeira resposta</span>
                              </div>
                            </div>
                            <div className="mware-data mware-data-4">
                              <div className="be-booking-desc" style={{flex: "auto"}}>
                                <h4 className="be-booking-desc-title">{this.state.piortempoconcluirconversa}</h4>
                                <span className="be-booking-desc-details">Tempo máximo para concluir a conversa</span>
                              </div>
                  
                          </div>
              
                         </div>
                          </div>
                        </div>
                    </div>
                         
                    </div>
                </div>
            </div>

            <div className="col-md-12 col-lg-12">  
                <div className="row">
                   
                      <div className="col-6 col-lg-6">
                          <div className="card card-table">
                            <div className="card-header">
                                <Typography component="h3" variant="h4" color="primary" paragraph>
                                  Contatos Novos para o Período
                                </Typography>
                            </div>
                            <div className="card" >
                                    <Typography component="div">
                                      <Box sx={{ textAlign:"center", fontWeight: 900, fontSize:"3.5em", color:"#fe3f93" }}> {this.state.contatosTotais}</Box>
                                        
                                    
                                    </Typography>
                                    <Typography component="h6" variant="h8" color="primary" paragraph style={{textAlign:"center"}}>
                                    Contatos Novos são aqueles que chamaram pela primeira vez durante o período, nunca tendo havido qualquer interação anterior
                                    </Typography>
                            </div>
                        </div>
                      </div>
                   

                      <div className="col-6 col-lg-6">
                          <div className="card card-table">
                            <div className="card-header">
                                <Typography component="h3" variant="h4" color="primary" paragraph>
                                  Atendimentos Novos para o Período
                                </Typography>
                            </div>
                            <div className="card" >
                                    <Typography component="div">
                                      <Box sx={{ textAlign:"center", fontWeight: 900, fontSize:"3.5em", color:"#fe3f93" }}> {this.state.atendimentosNovos} </Box>
                                    </Typography>
                                    <Typography component="h6" variant="h8" color="primary" paragraph style={{textAlign:"center"}}>
                                    Atendimentos Novos são aqueles que foram iniciados no período
                                    </Typography>
                            </div>
                        </div>
                      </div>
              </div>
            </div>

            <div className="col-md-12">      
                <div className="row">
                <div className="col-12 col-lg-12">
                  <div className="card card-table">
                    <div className="card-header">
                        <Typography component="h3" variant="h6" color="primary" paragraph>
                          Desempenho dos Agentes
                        </Typography>
                    </div>
                   <AgentesDesempenhoTable desempenhos={this.state.desempenhosAgente}/>
                  </div>
                </div>   
              </div>
            </div>


            <div className="col-md-12">   
                    <Paper>
                        <Typography component="h3" variant="h6" color="primary" paragraph style={{padding:10}}>
                            {i18n.t("dashboard.messages.totals.queue")}
                        </Typography>
                        <FilasTotais filas={this.state.filasTotais}/>
                    </Paper>
            </div>

            <div className="col-md-12">  
                    <Paper>
                        <Typography component="h3" variant="h6" color="primary" paragraph style={{padding:10}}>
                            {i18n.t("dashboard.messages.totals.tag")}
                        </Typography>
                        <TagsTotais tags={this.state.tagsTotais}/>
                    </Paper>
                </div>
        </div> 
   
  
    )
    }
}


export default DashboardPrincipal;