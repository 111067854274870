import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import QueueSelectSingle from "../QueueSelectSingle";
import CategorySelectSingle from "../CategorySelectSingle"
import FileAttachSingle from "../FileAttachSingle";
import MediaAttachSingle from "../MediaAttachSingle";
import RestVerbSelectSingle from "../RestVerbSelectSingle"

import UserSelect from "../UserSelect";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import CommandTypeSelect from "../CommandTypeSelect";


const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));



const BizNodeModal = ({ open, onClose, currflow, currnode }) => {
	const classes = useStyles();

	const nodeId=currnode.id;

	console.log("currnode", currnode)

	const initialState = {
		commandBot: (currnode.id ? currnode.id : ""),
		commandType: (currnode.id ? currnode.class : ""),
		descriptionBot: (currnode.id ? currnode.data?.dBot : ""),
		showMessage: (currnode.id ? currnode.data?.sMsg : ""),
		userId: (currnode.id ? currnode.data?.uId : 0),
		queueId:(currnode.id ? currnode.data?.qId : 0),
		categoryId:(currnode.id ? currnode.data?.cId : 0),
		fileId:0,
		verbId: (currnode.id ? currnode.data?.vId : 0)
	};

	const [showFields, setShowFields] = useState({ queues: false, users: false, message: false, descriptionBot: false, commandType: "", categories:false, fileAttach: false, mediaAttach:false });
	const [command, setCommand] = useState(initialState);

	const [fileValue, setFileValue] = useState([])
	const greetingRef = useRef();

	useEffect(() => {
		const fetchCommand = async () => {
			
			if (!nodeId) return;
			try {        
				switch (currnode.class) {
					case "menu":
						setShowFields({ queues: false, users: false, categories:false, message: true, descriptionBot: true, commandType: currnode.class, fileAttach: false, mediaAttach:false, verb:false });
						break;
					case "fila":
						setShowFields({ queues: true, users: false, categories:false, message: true, descriptionBot: true, commandType: currnode.class, fileAttach: false, mediaAttach:false, verb:false});
						break;
					case "atendente":
						setShowFields({ queues: false, users: true, categories:false, message: true, descriptionBot: true, commandType: currnode.class,fileAttach: false, mediaAttach:false, verb:false });
						break;
					case "categoria":
						setShowFields({ queues: false, users: false, categories:true, message: true, descriptionBot: true, commandType: currnode.class, fileAttach: false, mediaAttach:false, verb:false });
						break;
					case "informativo":
						setShowFields({ queues: false, users: false, categories:false, message: true, descriptionBot: true, commandType: currnode.class, fileAttach: false, mediaAttach:false, verb:false });
						break;
					case "media":
						setShowFields({ queues: false, users: false, categories:false, message: true, descriptionBot: true, commandType: currnode.class, fileAttach: false, mediaAttach: true, verb:false });
						break;
					case "mensagem":
						setShowFields({ queues: false, users: false, categories:false, message: true, descriptionBot: true, commandType: currnode.class, fileAttach: false, mediaAttach:false, verb:false });
						break;
					case "anexo":
						setShowFields({ queues: false, users: false, categories:false, message: true, descriptionBot: true, commandType: currnode.class, fileAttach: true, mediaAttach:false, verb:false });
						break;
					case "questao":
						setShowFields({ queues: false, users: false, categories:false, message: true, descriptionBot: true, commandType: currnode.class, fileAttach: false, mediaAttach:false, verb:false});
						break;
					case "integracao":
						setShowFields({ queues: false, users: false, categories:false, message: true, descriptionBot: true, commandType: currnode.class, fileAttach: true, mediaAttach:false, verb:true });
						break;


				}
			} catch (err) {
				toastError(err);
			}
		};

		fetchCommand();
	}, [nodeId, open]);

	const handleClose = (values) => {
		onClose();
		setCommand(initialState);
	};

	const handleSaveCommand = async values => {
    
		let data={
			nId:currnode.id,
			nStatus:0,
			cType:currnode.class,
			sMsg:values.showMessage,
			dBot:values.descriptionBot,
			uId:(values.userId?values.userId:0),
			qId:(values.queueId?values.queueId:0),
			cId:(values.categoryId?values.categoryId:0),
			fId:(fileValue?.filename?fileValue.filename:0),
			vId:(values.verbId?values.verbId:0)
		}

		onClose(data);
		setCommand(initialState);

	};


	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{nodeId
						? `Editar ${currnode.class}`
						: `${i18n.t("botModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={command}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveCommand(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>

								{showFields.descriptionBot && <Field
									as={TextField}
									label={"Opção no Menu"}
									name="descriptionBot"								
									variant="outlined"
									margin="dense"
									fullWidth
								/>}
								{showFields.verb && <RestVerbSelectSingle vId={command.verbId}/>}
								{showFields.users && <UserSelect uId={command.userId}  />}
								{showFields.queues && <QueueSelectSingle qId={command.queueId} />}
								{showFields.categories && <CategorySelectSingle cId={command.categoryId}/>}
								{showFields.fileAttach && <FileAttachSingle fId={command.fileId} name="fileId" onChange={(value) => setFileValue(value)} />}
								{showFields.mediaAttach && <MediaAttachSingle fId={command.fileId} name="fileId" onChange={(value) => setFileValue(value)} />}
								{showFields.message && <Field
									as={TextField}
									label={"Mensagem de Confirmação da Escolha"}
									name="showMessage"
									
									multiline
									inputRef={greetingRef}
									rows={5}
									fullWidth
									variant="outlined"
									margin="dense"
								/>}








							</DialogContent>




							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("botModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{nodeId
										? `${i18n.t("botModal.buttons.okEdit")}`
										: `${i18n.t("botModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default BizNodeModal;
