import React, { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  TextField,
} from "@material-ui/core";

import { Edit, DeleteOutline } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import CategoriesModal from "../../components/CategoriesModal";
import ConfirmationModal from "../../components/ConfirmationModal";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";

import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_CATEGORIES") {
    const categories = action.payload;
    const newCategories = [];

    categories.forEach((category) => {
      const categoryIndex = state.findIndex((q) => q.id === category.id);
      if (categoryIndex !== -1) {
        state[categoryIndex] = category;
      } else {
        newCategories.push(category);
      }
    });

    return [...state, ...newCategories];
  }

  if (action.type === "UPDATE_CATEGORIES") {
    const category = action.payload;
    const categoryIndex = state.findIndex((q) => q.id === category.id);

    if (categoryIndex !== -1) {
      state[categoryIndex] = category;
      return [...state];
    } else {
      return [category, ...state];
    }
  }

  if (action.type === "DELETE_CATEGORIES") {
    const categoryId = action.payload;

    const categoryIndex = state.findIndex((q) => q.id === categoryId);
    if (categoryIndex !== -1) {
      state.splice(categoryIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const Categories = () => {
  const classes = useStyles();


  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [categories, dispatch] = useReducer(reducer, []);
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [categoriesModalOpen, setCategoriesModalOpen] = useState(false);
  const [deletingCategories, setDeletingCategories] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchCategories = async () => {
        try {
          const { data } = await api.get("/categories/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_CATEGORIES", payload: data.categories });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchCategories();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("category", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CATEGORIES", payload: data.category });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_CATEGORIES",
          payload: +data.categoryId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenCategoriesModal = () => {
    setSelectedCategories(null);
    setCategoriesModalOpen(true);
  };

  const handleCloseCategoriesModal = () => {
   
    setSelectedCategories(null);
    setCategoriesModalOpen(false);
  };

  const handleEditCategories = (category) => {
    setSelectedCategories(category);
    setCategoriesModalOpen(true);
  };

  const handleDeleteCategories = async (categoryId) => {
    try {
      await api.delete(`/categories/${categoryId}`);
      toast.success(i18n.t("categories.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingCategories(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingCategories &&
          `${i18n.t("categories.confirmationModal.deleteTitle")} ${
            deletingCategories.categoryName
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteCategories(deletingCategories.id)}
      >
        {i18n.t("categories.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <CategoriesModal
        open={categoriesModalOpen}
        onClose={handleCloseCategoriesModal}
        aria-labelledby="form-dialog-title"
        categoryId={selectedCategories && selectedCategories.id}
      ></CategoriesModal>
      <MainHeader>
        <Title>{i18n.t("categories.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("categories.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCategoriesModal}
          >
            {i18n.t("categories.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("categories.table.categoryName")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("categories.table.mustaccio")}
              </TableCell>

              <TableCell align="center">
                {i18n.t("categories.table.displayName")}
              </TableCell>


              <TableCell align="center">
                {i18n.t("categories.table.isActive")}
              </TableCell>






              <TableCell align="center">
                {i18n.t("categories.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {categories.map((category) => (
                <TableRow key={category.id}>
                  <TableCell align="center">{category.categoryName}</TableCell>
                  <TableCell align="center">{category.mustaccio}</TableCell>
                  <TableCell align="center">{category.displayName}</TableCell>
                  <TableCell align="center">{category.isActive}</TableCell>




                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditCategories(category)}
                    >
                      <Edit color="secondary" />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingCategories(category);
                      }}
                    >
                      <DeleteOutline color="secondary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={5} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Categories;