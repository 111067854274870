import React, { useState, useContext, useEffect, useReducer } from "react";
import { Gradient } from 'react-gradient';
import NotificationsPopOver from "../components/NotificationsPopOver";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { isArray } from "lodash";
import { socketConnection } from "../services/socket";

import { useHistory } from "react-router-dom";

import Swal from 'sweetalert2'

import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
  Switch,
  FormControlLabel,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import {
  AccountCircle,
  ChevronLeft,
} from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";

import logo from "./../assets/as-logo.svg"
import lettering from "./../assets/as-letter-w.svg"

import UserModal from "../components/UserModal";
import BackdropLoading from "../components/BackdropLoading";

import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { i18n } from "../translate/i18n";
import { systemVars } from "../../package.json"

import api from "../services/api";
import toastError from "../errors/toastError";
import MainListItems from "./MainListItems";
import AnnouncementsPopover from "../components/AnnouncementsPopover";
import ChatPopover from "../pages/Chat/ChatPopover";


const drawerWidth = 240;

const gradients = [
  ['#F89A21', '#FF30A5']
];

const useStyles = makeStyles((theme) => ({
    root: {
		display: "flex",
		height: "100vh",
	},
  palette: {
    primary: {
      main: "#4a148c",
      contrastText: "#fff",
    },
  },
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		minHeight: "48px",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: "none",
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: {
		minHeight: "48px",
	},
	content: {
		flex: 1,
		overflow: "auto",
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
  logo: {
    display: "flex",
    alignItems: "left",
    justifyContent: "flex-start",
    width: "183px",
    // paddingLeft: "6px"
    // paddingLeft: "32px"
  },
  logoImg: {
    width: "150px",
    paddingTop: "10px"
   
  },
  logoImg1: {
    width: "150px",
    paddingTop: "10px",
    marginLeft: "30px"
   
  },
  logoImg2: {
    width: "150px",
    paddingTop: "10px",
    display : "none"
   
  },
  linearGradient: {
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15
  },
}));

const reducer = (state, action) => {

  if (action.type === "UPDATE_ANNOUNCEMENTS") {
    const announcement = action.payload;
    const announcementIndex = state.findIndex((u) => u.id === announcement.id);

    if (announcementIndex !== -1) {
      state[announcementIndex] = announcement;
      return [...state];
    } else {

      return [announcement, ...state];
    }
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "DELETE_ANNOUNCEMENT") {
    return [];
  }

  /*
 
    if (action.type === "LOAD_ANNOUNCEMENTS") {
    const announcements = action.payload;
    const newAnnouncements = [];

    if (isArray(announcements)) {
      announcements.forEach((announcement) => {
        const announcementIndex = state.findIndex(
          (u) => u.id === announcement.id
        );
        if (announcementIndex !== -1) {
          state[announcementIndex] = announcement;
        } else {
          newAnnouncements.push(announcement);
         
        }
      });
    }

    
    return [state, ...newAnnouncements];
  }

  if (action.type === "DELETE_ANNOUNCEMENT") {
    const announcementId = action.payload;

    const announcementIndex = state.findIndex((u) => u.id === announcementId);
    if (announcementIndex !== -1) {
      state.splice(announcementIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
  */

};

const LoggedInLayout = ({ children }) => {


  const history = useHistory();

  const classes = useStyles();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const [proMenu, setProMenu] = useState(true);
  const [announcements, dispatch] = useReducer(reducer,[]);
  const [invisible, setInvisible] = useState(false);
  const theme = useTheme();
  const greaterThenSm = useMediaQuery(theme.breakpoints.up("sm"));

  const [currUser, setCurrUser] = useState();

  
  const companyId = localStorage.getItem("companyId");
  const socket = socketConnection({ companyId });

  const { user } = useContext(AuthContext);
  console.log("socket invitation 0", user)
  
  if(!currUser){
    //console.log("socket invitation 1a", currUser)
     setCurrUser(user )
     //console.log("socket invitation 1b", currUser)
  }



  useEffect(() => {
      socket.on(`invitation`, (data) => {
  
      if(user.id==data.record.to){
        Swal.fire({
          title: `Convite para ${data.record.uTo} `,
          text:`O usuário ${data.record.uFrom} está convidando você para participar no atendimento de ${data.record.contact} que decorre neste momento, ${data.record.dateTime}`,
          //icon: 'success',
          imageUrl: data.record.profilePic,
      		imageWidth: 100,
          showDenyButton: true,
          showCancelButton:false,
          confirmButtonText: 'Aceitar convite',
          denyButtonText: `Não Participar`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            history.push(`/tickets/${data.record.ticket}`);
          }
        })
  
          console.log("socket invitation 4", data)
      }
    });
  });

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`announcement`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_ANNOUNCEMENTS", payload: data.record });
        setInvisible(false);
      }
      if (data.action === "delete") {
        dispatch({ type: "DELETE_ANNOUNCEMENT", payload: +data.id });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);
  
  useEffect(() => {
    if (document.body.offsetWidth > 600) {
      const fetchDrawerState = async () => {
        try {
          const { data } = await api.get("/settings");

          const settingIndex = data.filter(s => s.key === 'sideMenu');

          setDrawerOpen(settingIndex[0].value === "disabled" ? false : true);

        } catch (err) {
          setDrawerOpen(true);
          toastError(err);
        }
      };
      fetchDrawerState();
    }
  }, []);

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);



  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch({ type: "RESET"});
    }, 30000);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });



  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickSuper = () => {
    window.open("/Super", "_self")
  }

  const handleClickLogout = () => {
    let currUser = localStorage.getItem("userId");
    localStorage.removeItem("userId");

    handleCloseMenu();
    handleLogout();
  };

  const drawerClose = () => {
    if (document.body.offsetWidth < 600) {
      setDrawerOpen(false);
    }
  };

  const togglePro = () =>{
    setProMenu(proMenu?false:true)
    setDrawerOpen(proMenu?true:false)
  }




  const navigate = () =>{
    history.push(`/upgradeinfo`);
  }


  const greeting = () => {
    const greetings = ["boa madrugada,", "bom dia,", "boa tarde,", "boa noite,"];
    const h = new Date().getHours();
    // eslint-disable-next-line no-bitwise
    return greetings[(h / 6) >> 0];
  };



  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <div className={classes.root}>
      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>    
          <div className={classes.logo}> 
            <img className={drawerOpen?classes.logoImg1:classes.logoImg2} src={logo} />  
          </div>

          
          <IconButton onClick={() => setDrawerOpen(proMenu?true:!drawerOpen)}>
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems drawerClose={drawerClose} proMenu={proMenu}/>
        </List>
        <Divider />
              <div style={{textAlign:"center"}}>
                   <span>Pro Menu</span><br />

                    <Switch
                        size="small"
                        checked={proMenu}
                        onChange={(e) => {
                          togglePro();
                        }}
                        name="proMenu"
                       
                        style={{color:"#FF30A5"}}
                      />
            </div>
      </Drawer>
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        >
        <Gradient
                gradients={ gradients } // required
                property="background"
                duration={ 3000 }
                angle="45deg"
            >


                <Toolbar variant="dense" className={classes.toolbar}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setDrawerOpen(!drawerOpen)}
                    className={clsx(
                      classes.menuButton,
                      drawerOpen && classes.menuButtonHidden
                    )}
                  >
                    <MenuIcon />
                  </IconButton>

                  <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    className={classes.title}
                    style={{fontSize:"15", color:"#"}}
                  >
                    <img src={lettering} style={{width:200}}/> 
                    <span className={classes.version}  onClick={() => navigate()}>
                      &nbsp;v{systemVars.version}
                    </span>
                  </Typography>

                  <Typography
                          component="h1"
                          variant="h6"
                          color="inherit"
                          noWrap
                          className={classes.title}
                        >
                          {greaterThenSm ? (
                            <>
                              {announcements.length>0?`Novo Informativo: ${announcements[0].title}`:`Olá ${user.name}, ${greeting()} que bom que veio no AtendoSim.....`}
                             
                            </>
                          ) : (
                            user.name
                          )}
                  </Typography>
                  {user.id && <NotificationsPopOver />}

                  <AnnouncementsPopover />

                  <ChatPopover />
                

                  <div>
                    <IconButton
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={menuOpen}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem onClick={handleOpenUserModal}>
                        {i18n.t("mainDrawer.appBar.user.profile")}
                      </MenuItem>
                     
                      <MenuItem onClick={handleClickLogout}>
                        {i18n.t("mainDrawer.appBar.user.logout")}
                      </MenuItem>
                    </Menu>
                  </div>
                </Toolbar>
        </Gradient>
        
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        {children ? children : null}
      </main>
    </div>
  );
};

export default LoggedInLayout