import React from 'react';

import {
    makeStyles,
    Tooltip,
    Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	tooltip: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: theme.typography.pxToRem(14),
		border: "1px solid #dadde9",
		maxWidth: 450,
	},
	tooltipPopper: {
		textAlign: "center",
	},
}))

const CustomTooltip = ({ title, content, children }) => {
    const classes = useStyles();

    return (
        <Tooltip
        arrow
        classes={{
            tooltip: classes.tooltip,
            popper: classes.tooltipPopper,
        }}
        title={
            <React.Fragment>
            <Typography gutterBottom color="inherit">
                {title}
            </Typography>
            {content && <Typography>{content}</Typography>}
            </React.Fragment>
        }
        >
        {children}
        </Tooltip>
    );
};

export default CustomTooltip;