import React, { useEffect, useState, useMemo } from "react";
import { Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import Dropzone, { useDropzone } from "react-dropzone";

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const baseStyle = {
	alignItems: "center",
	backgroundColor: "#fafafa",
	borderColor: "#131B43", // theme.palette.primary.main,
	borderRadius: 15,
	borderStyle: "dashed",
	borderWidth: 1,
	color: "#131B43", // theme.palette.primary.main,
	cursor: "pointer",
    display: "flex",
	flex: 1,
	flexDirection: "column",
    marginBottom: "8px",
	outline: "none",
	padding: "20px",
	transition: "border .24s ease-in-out",
    minWidth: 365,
    width: "100%"
};

const activeStyle = {
	borderColor: "#2196f3",
};

const acceptStyle = {
	borderColor: "#00e676",
};

const rejectStyle = {
	borderColor: "#ff1744",
};


const FileAttachSingle = (props) => {
    const classes = useStyles();
    const [queues, setQueues] = useState([]);
    const [csvFile, setCsvFile] = useState([]);
    const [sentFile, setSentFile] = useState("");

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/queue");
                //console.log('Queue => ', data);
                //console.log('Queue Type => ', typeof (data));
                setQueues(data);
            } catch (err) {
                toastError(err);
            }
        })();
    }, []);

    const style = useMemo(
        () => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const sendFile = async (files, rejectedFiles) => {
        const formData = new FormData();
        files.forEach((file) => {
          formData.append("file", file);
        });

        try {      
            const { data } = await api.patch(`/bizflow`, formData);
            console.log("FileAttachSingle:sendFile", data)
            setSentFile(data[0].filename)
            //fileId=data[0].filename
            props.onChange(data[0])

        } catch (err) {
            console.log(err);
           
        }
    }

    const {
        isDragActive,
        isDragAccept,
        isDragReject,
      } = useDropzone();
    

    return (
        <div style={{ marginTop: 6 }}>
            <FormControl
                variant="outlined"
                className={classes.FormControl}
                margin="dense"
                fullWidth


            >

            <Dropzone 
                onDrop={sendFile} 
                accept={{'application/pdf': ['.pdf']}}
                
                >
                {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>
                        {sentFile !=""
                        ? sentFile
                        : "Adicione um arquivo"}
                    </p>
                </div>
                )}
            </Dropzone>
  

               


               
            </FormControl>
        </div>
    );
};

export default FileAttachSingle;
