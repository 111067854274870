import React, { useState, useEffect, useReducer,  useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import Swal from 'sweetalert2'

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import licon from "../../assets/leaflet/marker-icon.png"

import { makeStyles } from "@material-ui/core/styles";

import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import { Link as RouterLink } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { i18n } from "../../translate/i18n"
import MapIcon from '@material-ui/icons/Map';
import {
	Button,
	TableBody,
	TableRow,
	TableCell,
	IconButton,
	Table,
	TableHead,
	Paper,
	Tooltip,
	Typography,
	CircularProgress,
} from "@material-ui/core";
/*
import turf, {booleanContains, point, polygon} from "@turf/turf"

import pointsWithinPolygon from "@turf/turf"
import within from "@turf/turf"
*/
import red from '@material-ui/core/colors/red';

import blue from '@material-ui/core/colors/blue';

import green from '@material-ui/core/colors/green';

import L from "leaflet";
import { Map, TileLayer, FeatureGroup,  Marker, Popup, LayersControl, LayerGroup, Circle } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import osm from "./osm-providers";
import { useRef } from "react";

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";

import cities from "./cities.json";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const turf = require("@turf/turf")


const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  
  none:{},

	accordionButton: {
	  marginLeft: "5px",
    marginBottom: "10px",
	  border: 0,
    borderRadius: 15,
    width: "200px",
    color: "#4a148c"
	},
  subHeader: {
    color: '#FF30A5', 
    fontSize: 16, 
    width:200, 
    borderRadius:10,
    textAlign:  "left"
  },
  paperItems:{
      marginLeft: "10px",
      marginTop: "5px",
      marginBottom: "10px",
      width:215, 
      borderRadius:10
  },
  label1:{
    paddingLeft:"20px",
  },
  label2:{
    display: "none",
  },
  rotateIcon: {
    animation: "$spin 2s linear infinite"
  },

  shakeIcon: {
    animation: "$shake .5s infinite"
  },



  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)"
    },
    "100%": {
      transform: "rotate(360deg)"
    }
  },


 "@keyframes shake": {
    "0%": { transform: "translate(3px, 0px) rotate(0deg)"},
    "10%": { transform: "translate(-3px, -0px) rotate(0deg)"},
    "20%": { transform: "translate(3px, 0px) rotate(0deg)"},
    "30%": { transform: "translate(-3px, 0px) rotate(0deg)"},
    "40%": { transform: "translate(0px, 0px) rotate(0deg)"},
    "50%": { transform: "translate(0px, 0px) rotate(-1deg)" },
    "60%": { transform: "translate(-3px, 0px) rotate(0deg)"},
    "70%": { transform: "translate(3px, 0px) rotate(0deg)"},
    "80%": { transform: "translate(-3px, 0px) rotate(0deg)"},
    "90%": { transform: "translate(0px, 0px) rotate(0deg)"},
    "100%": { transform: "translate(0px, 0px) rotate(0deg)"}
  }






  }));


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});


const markerIcon = new L.Icon({
  iconUrl: licon,
  iconSize: [36, 56],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});



const ClientMap = () => {

    const classes = useStyles();


    var lat=-15.6144073
    var lng=-56.041807

    const [center, setCenter] = useState({ lat, lng });

    const [url, setUrl] = useState(osm.maptiler.url);
    const [attribution, setAttribution] = useState(osm.maptiler.attribution);
    const [contacts, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const layerGroupRef = useRef();
    const featureGroupContacts = useRef(); 
    const featureGroupSuppliers = useRef();
    
    

    const { user } = useContext(AuthContext);
    
    useEffect(() => {
      (async () => {
        setLoading(true);
        try {
          const { data } = await api.get("/geocontacts");
          console.log(data)
          dispatch({ type: "LOAD_CONTACTS", payload: data });

          setLoading(false);
        } catch (err) {
          toastError(err);
          setLoading(false);
        }
      })();
    }, []);


    const ZOOM_LEVEL = 12;
    const mapRef = useRef();


    const _created = (e) =>{

      switch (e.layerType){

          case "circle":
              var theCenter = e.layer._latlng;
              var theRadius = e.layer._mRadius;
              //console.log(e.layer.getRadius())

              console.log("theCenter", theCenter)
              console.log("theRadius", theRadius)

              handleCircle(theCenter, theRadius)
              break

          case "rectangle":
                console.log(e)
                handlePoly(e)
                break

          case "polygon":
                console.log(e)
                handlePoly(e)
                break


      }






      function handleCircle  (theCenter, theRadius) {
        let currContacts={
          "type": "FeatureCollection",
          "features": []
        }
        var latlngFrom = L.latLng(theCenter);




        for (let i=0; i<contacts.length; i++){
          
              var latlngTo = L.latLng(JSON.parse(contacts[i].value));

              var distance = latlngTo.distanceTo (latlngFrom)

              console.log(`Para ${contacts[i].contact.name}, a distância é de ${distance} e o raio é de ${theRadius}`)


              if(distance<theRadius){
                let coords=JSON.parse(contacts[i].value)
                let feature={
                                "type": "Feature",
                                "geometry": {
                                  "type": "Point",
                                  "coordinates": [coords[1],coords[0]]
                                },
                                "properties": {
                                  "name":contacts[i].contact.name,
                                  "id": contacts[i].contact.id,
                                  "number": contacts[i].contact.number
                                }
                            }
                currContacts.features.push(feature)
              }

        }

        
      Swal.fire({
        title: 'Sucesso!',
        text:`Sua pesquisa geográfica retornou ${currContacts.features.length} contatos. Deseja colocá-los na Campanha Geográfica?.`,
        icon: 'success',
        showDenyButton: true,
        showCancelButton:false,
        confirmButtonText: 'Sim',
        denyButtonText: `Não Salve`,
      }).then(async (result) => {
        if (result.isConfirmed) {

          if(currContacts.features.length>0){
                  setLoading(true);
                  try {
                    const { data } = await api.post("/geostore",  currContacts.features);
                    console.log(data)
                    setLoading(false);

                    Swal.fire(
                      'Confirmado!',
                      'Seus contatos foram salvos',
                      'success'
                    )

                  } catch (err) {
                    console.log(err)
                    toastError(err);
                    setLoading(false);
                  }
          }

        }
      })

      }


    }
    const _featureGroupCreated = (e) =>{

      console.log("featureGroupCreated",e)

    }

    function ListItemLink(props) {
      const { icon, primary, to, className } = props;
      const renderLink = React.useMemo(
        () =>
          React.forwardRef((itemProps, ref) => (
            <RouterLink to={to} ref={ref} {...itemProps} />
          )),
        [to]
      );

      return (
        <li>
          <ListItem button component={renderLink} className={className}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText primary={primary} />
          </ListItem>
        </li>
      );

    }

    const handleSaveTicket = async (contactId) => {
      if (!contactId) return;
      setLoading(true);
      try {
        const { data: ticket } = await api.post("/tickets", {
          contactId: contactId,
          userId: user?.id,
          status: "open",
        });
        history.push(`/tickets/${ticket.id}`);
      } catch (err) {
        toastError(err);
      }
      setLoading(false);
    };

    const  handlePoly = async (e) => {

      let currContacts={
                          "type": "FeatureCollection",
                          "features": []
                        }
      contacts.forEach(contact => {
            let coords=JSON.parse(contact.value)
            let feature={
                            "type": "Feature",
                            "geometry": {
                              "type": "Point",
                              "coordinates": [coords[1],coords[0]]
                            },
                            "properties": {
                              "name": contact.contact.name,
                              "id": contact.contact.id,
                              "number": contact.contact.number
                            }
                        }
            currContacts.features.push(feature)
      })

      var area = e.layer.toGeoJSON()
      var points=currContacts

      console.log("area", area)
      console.log("points",points)

      let ptsWithin2 = turf.within(points, area);

      console.log("Points Found", ptsWithin2);




      Swal.fire({
        title: 'Sucesso!',
        text:`Sua pesquisa geográfica retornou ${ptsWithin2.features.length} contatos. Deseja colocá-los na Campanha Geográfica?.`,
        icon: 'success',
        showDenyButton: true,
        showCancelButton:false,
        confirmButtonText: 'Sim',
        denyButtonText: `Não Salve`,
      }).then(async (result) => {
        if (result.isConfirmed) {

          if(ptsWithin2.features.length>0){
                  setLoading(true);
                  try {
                    const { data } = await api.post("/geostore", ptsWithin2.features);
                    console.log(data)
                    setLoading(false);

                    Swal.fire(
                      'Confirmado!',
                      'Seus contatos foram salvos',
                      'success'
                    )

                  } catch (err) {
                    console.log(err)
                    toastError(err);
                    setLoading(false);
                  }
          }

        }
      })


  }

   

    const layerSelect = (e) =>{

      //console.log("layerSelect1",e)
      console.log("layerSelect1",featureGroupContacts)

      console.log("layerSelect2",featureGroupSuppliers)


    }

  const icon = L.divIcon({
    className: 'custom icon',
    html: <div>Icon content</div>
    });

  const setEsri = () => {
    setUrl('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}')
    setAttribution("Tiles &copy; Esri &mdash; As bases usadas são de origem pública")
  }

  const setOsm = () => {
    setUrl('https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}.png?key=fXmTwJM642uPLZiwzhA1')
    setAttribution('&copy; <a href="https://www.maptiler.com/">MapTiler</a> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors')
  }

  const setMtb= () => {
    setUrl('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png')
    setAttribution('Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)')
  }
  const setnGeo= () => {
    setUrl('https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}')
    setAttribution('Tiles &copy; Esri &mdash; National Geographic, Esri, DeLorme, NAVTEQ, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, iPC')
  }

  return (
    <>
     
     <MainContainer>
            <MainHeader>
                <Title>ClientMap</Title>
                <MainHeaderButtonsWrapper>
                </MainHeaderButtonsWrapper>
            </MainHeader>
  
            <Paper>
                <Map id="map" center={center} zoom={ZOOM_LEVEL} ref={mapRef} style={{width: "100%", height: "82vh", position: "relative"}}>
                    <FeatureGroup>
                      <EditControl
                        position="topleft"
                        onCreated={_created}
                        draw={
                          {
                            // rectangle: false,
                            circle: {
                                      metric: true
                                    }
                          //circlemarker: false,
                          //marker: false,
                          //polyline: false, */
                          }
                        }
                      />
                    </FeatureGroup>
                    <TileLayer
                      url={url}
                      attribution={attribution}
                    />
                    <LayersControl position="topright">    

                          <LayersControl.Overlay name="Contatos AtendoSim" id="_contatos" onClick={_featureGroupCreated} >
                            <FeatureGroup pathOptions={{ color: 'purple' }} id="contacts" layerid="1" featureArray={{contacts}} ref={featureGroupContacts} >
                                { contacts && (contacts.map((contact) => (
                                  <Marker
                                    position={JSON.parse(contact.value)}
                                    icon={L.icon({
                                      iconUrl: contact.contact.profilePicUrl,            
                                      iconSize:     [33, 33], // size of the icon
                                      iconAnchor:   [18,18], // point of the icon which will correspond to marker's location
                                      shadowAnchor: [4, 62],  // the same for the shadow
                                      popupAnchor:  [-10,-15] // point from which the popup should open relative to the iconAnchor
                                  })}
                                    key={contact.id}
                                    
                                  >
                                    <Popup>
                                      <b>
                                        {contact?.contact?.name}&nbsp;
                                      </b>
                                      <WhatsAppIcon  onClick={() => handleSaveTicket(contact.contact.id)}  style={{ color: green[800], fontSize: 15, marginTop:-3 }}  />
                                    </Popup>
                                  </Marker>
                                )))}
                            </FeatureGroup>
                          </LayersControl.Overlay>

                          <LayersControl.Overlay name="Fornecedores AtendoSim" id="_formecedores" onClick={_featureGroupCreated} >
                            <FeatureGroup pathOptions={{ color: 'red' }} id="fornecedores" layerid="2" featureArray={{contacts}} ref={featureGroupSuppliers} >
                                { contacts && (contacts.map((contact) => (
                                  <Marker
                                    position={JSON.parse(contact.value)}
                                    icon={L.icon({
                                      iconUrl: contact.contact.profilePicUrl,            
                                      iconSize:     [33, 33], // size of the icon
                                      iconAnchor:   [18,18], // point of the icon which will correspond to marker's location
                                      shadowAnchor: [4, 62],  // the same for the shadow
                                      popupAnchor:  [-10,-15] // point from which the popup should open relative to the iconAnchor
                                  })}
                                    key={contact.id}
                                    
                                  >
                                    <Popup>
                                      <b>
                                        {contact?.contact?.name}&nbsp;
                                      </b>
                                      <WhatsAppIcon  onClick={() => handleSaveTicket(contact.contact.id)}  style={{ color: green[800], fontSize: 15, marginTop:-3 }}  />
                                    </Popup>
                                  </Marker>
                                )))}
                            </FeatureGroup>
                          </LayersControl.Overlay>

                        </LayersControl>
                </Map>

                <div class="row">
              
                        <div class="col col-1">
                        <span onClick={setEsri}>
                            <MapIcon  onClick={setEsri}  style={{ color: blue[800], fontSize: 33 }}  />
                            <Typography gutterBottom color="inherit">
                                ESRI Map Tiles
                            </Typography>
                        </span>
                        </div>

                        <div class="col col-1">
                        <span onClick={setOsm}>
                            <MapIcon  onClick={setOsm}  style={{ color: blue[800], fontSize: 33 }}  />
                            <Typography gutterBottom color="inherit">
                                OSM Map Tiles
                            </Typography>
                        </span>
                        </div>

                        <div class="col col-1">
                        <span onClick={setMtb}>
                            <MapIcon  onClick={setMtb}  style={{ color: blue[800], fontSize: 33 }}  />
                            <Typography gutterBottom color="inherit">
                               Topo Map Tiles
                            </Typography>
                        </span>
                        </div>
                        
                        <div class="col col-1">
                        <span onClick={setnGeo}>
                            <MapIcon  onClick={setnGeo}  style={{ color: blue[800], fontSize: 33 }}  />
                            <Typography gutterBottom color="inherit">
                                Nat Geo Map Tiles
                            </Typography>
                        </span>
                        </div>


                </div>
                




                      
            </Paper>
     
     </MainContainer>
         
    </>
  );
};

export default ClientMap;

