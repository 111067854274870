import React from 'react';

export default props => {

    const rows = props.tags.map( tag => {
        return(
            <tr>
                         <td>{tag.name}</td>
                         <td style={{textAlign:"center"}}>{tag.total_tag}</td>       
            </tr>
        )
    })

return(
           <div className="card-body table-responsive">
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th style={{width: "80%"}}>Etiqueta</th>
                        <th style={{width: "20%", textAlign:"center"}}>Nº de Ocorrências</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {rows}
                    </tbody>
                  </table>
                </div>
     )
}
