import {
	Link,
	Typography
} from '@material-ui/core';

import React from 'react';
import { systemVars } from "../../../package.json";

export default function Copyright() {
	return (
		<>
			<Typography variant="h6" style={{color:'#a10882', fontWeigth:900, textAlign:"center"}} >
				{"Copyright "}
				<Link color="inherit" href={"http://"+systemVars.controllerDomain}>
					{systemVars.appName}
				</Link>
			</Typography>

			<Typography variant="h6"  style={{color:'#a10882', fontWeigth:900, textAlign:"center"}} >
				{"Versão: "}
				{new Date().getFullYear()}
				{"."}
				{systemVars.version}
			</Typography>
		</>
	);
};