import React, { useState, useCallback } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'

import CheckBox from '@inovua/reactdatagrid-community/packages/CheckBox'

import { makeStyles } from "@material-ui/core/styles";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import {
	Button,
	TableBody,
	TableRow,
	TableCell,
	IconButton,
	Table,
	TableHead,
	Paper,
	Tooltip,
	Typography,
	CircularProgress,
} from "@material-ui/core";




const gridStyle = { height: 350, padding:10 }

const columns = [
    { name: 'name', header: 'Name', defaultFlex: 1 },
    { name: 'age', header: 'Age', defaultWidth: 200 }
  ]

  const defaultFilterValue = [
    { name: 'name', operator: 'startsWith', type: 'string', value: '' },
    { name: 'age', operator: 'gte', type: 'number', value: 21 }
  ];

  const people = [
    { id: 1, name: 'John McQueen', age: 35 },
    { id: 2, name: 'Mary Stones', age: 25 },
    { id: 3, name: 'Robert Fil', age: 27 },
    { id: 4, name: 'Roger Robson', age: 81 },
    { id: 5, name: 'Billary Konwik', age: 18 },
    { id: 6, name: 'Bob Martin', age: 18 },
    { id: 7, name: 'Matthew Richardson', age: 54 },
    { id: 8, name: 'Ritchie Peterson', age: 54 },
    { id: 9, name: 'Bryan Martin', age: 40 },
    { id: 10, name: 'Mark Martin', age: 44 },
    { id: 11, name: 'Michelle Sebastian', age: 24 },
    { id: 12, name: 'Michelle Sullivan', age: 61 },
    { id: 13, name: 'Jordan Bike', age: 16 },
    { id: 14, name: 'Nelson Ford', age: 34 },
    { id: 15, name: 'Tim Cheap', age: 3 },
    { id: 16, name: 'Robert Carlson', age: 31 },
    { id: 17, name: 'Johny Perterson', age: 40 }
  ]

 
  const useStyles = makeStyles(theme => ({
	mainPaper: {
		flex: 1,
		padding: theme.spacing(1),
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	tooltip: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: theme.typography.pxToRem(14),
		border: "1px solid #dadde9",
		maxWidth: 450,
	},
	tooltipPopper: {
		textAlign: "center",
	},
	buttonProgress: {
		color: green[500],
	},
}));


const NLPConfig = () => {
  const [activateRowOnFocus, setActivateRowOnFocus] = useState(true);
  const [enableSelection, setEnableSelection] = useState(true);
  const [filterValue, setFilterValue] = useState(defaultFilterValue);
  const [sortInfo, setSortInfo] = useState([]);
  const [selected, setSelected] = useState(null);

  const onSelectionChange = useCallback(({ selected }) => {setSelected(selected)}, [])


  return (
    <MainContainer>
        <MainHeader>
            <Title>{i18n.t("connections.title")}</Title>
            <MainHeaderButtonsWrapper>
                <Button
                    variant="contained"
                    color="primary"
                    //onClick={}
                >
                    {i18n.t("connections.buttons.add")}
                </Button>
            </MainHeaderButtonsWrapper>
        </MainHeader>

        <Paper>

            <ReactDataGrid
                idProperty="id"
                style={gridStyle}
                activateRowOnFocus={activateRowOnFocus}
                defaultFilterValue={filterValue}
                columns={columns}
                dataSource={people}
                enableSelection={enableSelection}
                onSelectionChange={onSelectionChange}
                pagination
                onSortInfoChange={setSortInfo}
                onFilterValueChange={setFilterValue}
            />
        </Paper>
        <p>
        Selected row id: {selected == null ? 'none' : people[selected-1].name}.
        </p>

    </MainContainer>
       
  );
}

export default NLPConfig;