import React from "react";

import { Avatar, CardHeader } from "@material-ui/core";

import { i18n } from "../../translate/i18n";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import eval1 from "../../assets/eval1.png"
import eval2 from "../../assets/eval2.png"
import eval3 from "../../assets/eval3.png"



import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles(theme => ({
	'& .MuiBadge-badge': {
		backgroundColor: '#44b700',
		color: '#44b700',
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		'&::after': {
		  position: 'absolute',
		  top: 0,
		  left: 0,
		  width: '100%',
		  height: '100%',
		  borderRadius: '50%',
		  animation: 'ripple 1.2s infinite ease-in-out',
		  border: '1px solid currentColor',
		  content: '""',
		},
	  },
	ticket: {
		position: "relative",
	},

	pendingTicket: {
		cursor: "unset",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},

	lastMessageTime: {
		justifySelf: "flex-end",
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 32,
		marginLeft: "auto",
	},

	contactLastMessage: {
		paddingRight: 20,
	},

	newMessagesCount: {
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	acceptButton: {
		position: "absolute",
		left: "50%",
	},

	ticketQueueColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},
	timeTag: {
		right: "-47px",
		bottom: "2px",
		padding: 2,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 3,
		fontSize: "0.9em"
	},
	userTag: {
		position: "absolute",
		marginRight: 35,
		right: -34,
		bottom: 36,
		background: "#4bc0c0",
		color: "#000000",
		border:"1px solid #CCC",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 3,
		fontSize: "0.9em"
	},
	connTag: {
		position: "absolute",
		marginRight: 35,
		right: -34,
		bottom: 9,
		background: theme.palette.primary.main,
		color: "#ffffff",
		border:"1px solid #CCC",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 3,
		fontSize: "0.9em"
	},
	avatar:{
        width: "60px",
        height: "60px",
        borderRadius:"10%",
		marginRight:"10px"
    },
}));


const TicketInfo = ({contact, ticket, onClick }) => {
	const classes = useStyles();



	//const [satval, setSatval]= useState(0);

	console.log(contact)
	console.log(contact.appraisalData?.totalsum)
	console.log(contact.appraisalData?.totalcount)
	console.log("appraisal 1",Math.round((contact.appraisalData?.totalsum/(contact.appraisalData?.totalcount*3))*10))

	let satval = (Math.round((contact.appraisalData?.totalsum/(contact.appraisalData?.totalcount*3))*10));

	console.log("appraisal 2", satval)

	return (
		<CardHeader
			onClick={onClick}
			style={{ cursor: "pointer" }}
			titleTypographyProps={{ noWrap: true }}
			subheaderTypographyProps={{ noWrap: true }}
			avatar={
						
						<Badge
							overlap="circular"
							anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
							}}
							badgeContent={<span className={classes.timeTag}>{}
												<img alt="NPS" src={(satval<7?eval1:satval>=7 && satval<9?eval2:eval3)} style={{width:"20px"}}/>	
										</span>
										}
							>
							<Avatar src={contact.profilePicUrl} alt="contact_image" className={classes.avatar} />
						</Badge>		
					}

			title={`${contact.name} #${ticket.id}`}
			subheader={
				ticket.user && `${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`
			}	
			

		/>
	);
};

export default TicketInfo;
