import React, { useState, useEffect, useRef, useContext, useReducer } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import BizflowItems from "../BizFlowItems";

import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	FormControl,
	InputLabel,
	Select,
	DialogActions,
	CircularProgress,
	FormControlLabel,
	IconButton,
	InputAdornment,
	TextField,
	Switch,
	MenuItem
} from "@material-ui/core";
import { Can } from "../Can";
import { Colorize } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import { AuthContext } from "../../context/Auth/AuthContext";

import {blue, purple, red, green, teal, orange, amber} from '@material-ui/core/colors';

import AcUnitIcon from '@material-ui/icons/AcUnit';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';


const useStyles = makeStyles(theme => ({
	customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	shortTextField: {
		marginRight: theme.spacing(1),
		flex: 1,
		width:20
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	
	red1: {
        color: red[500]
    },
    green1: {
        color: green[500]
    },

    blue1: {
        color: blue[500]
    },
    teal1: {
        color: teal[500]
    },
    orange1: {
        color: orange[500]
    },
    purple1: {
        color: purple[500]
    },
    amber1: {
        color: amber[500]
	},
	connectInfoItem: {
		justifyContent: "space-between",
		width: "100%",
	},
	connectInfoItemSelected: {
	  justifyContent: "space-between",
	  width: "100%",
	  weight:900
  },
	connectionInfo: {
		alignItems: "center",
		display: "flex",
		float: "right",
		marginTop: "-5px",
		marginBottom: "-4px",
	},
	connectionInfoSpan: {
		opacity: "70%",
	},
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_BIZFLOWS") {
        const bizflows = action.payload;
        const newBizflows = [];

        bizflows.forEach((bizflow) => {
            const bizflowIndex = state.findIndex((q) => q.id === bizflow.id);
            if (bizflowIndex !== -1) {
                state[bizflowIndex] = bizflow;
            } else {
                newBizflows.push(bizflow);
            }
        });

        return [...state, ...newBizflows];
    }

    if (action.type === "UPDATE_BIZFLOWS") {

        const bizflow = action.payload;
        console.log("bizflow",bizflow)
        const bizflowIndex = state.findIndex((q) => q.id === bizflow.id);
        console.log("bizflowIndex",bizflowIndex)
        
        if (bizflowIndex !== -1) {
            state[bizflowIndex] = bizflow;
            return [...state];
        } else {
            return [bizflow, ...state];
        }
        
    }

    if (action.type === "RESET") {
        return [];
    }
};

const QueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
	greetingMessage: Yup.string(),
	startWork: Yup.string(),
	endWork: Yup.string(),
	absenceMessage: Yup.string(),

	isNps: Yup.string()
	//,
	//encouragingMessage: Yup.string(),
	//url: Yup.string()

});

const QueueModal = ({ open, onClose, queueId }) => {
	const classes = useStyles();

	const initialState = {
		id:0,
		name: "",
		color: "",
		greetingMessage: "",
		startWork: "",
		endWork: "",
		absenceMessage: "",
		isNps: false,
		encouragingMessage: "",
		url: "",
		bizflowId:0
	};

	const [bizflows, dispatch] = useReducer(reducer, []);
	const { user: loggedInUser } = useContext(AuthContext);
	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
	const [queue, setQueue] = useState(initialState);

	const greetingRef = useRef();
	const absenceRef = useRef();
	const startWorkRef = useRef();
	const endWorkRef = useRef();
	const encouragingRef = useRef();
	const urlRef = useRef();

	useEffect(() => {
		(async () => {
			if (!queueId) return;
			try {
				const { data } = await api.get(`/queue/${queueId}`);
				console.log(data)
				setQueue(prevState => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				toastError(err);
			}
		})();

		return () => {
			setQueue({
				id: 0,
				name: "",
				color: "",
				greetingMessage: "",
				startWork: "",
				endWork: "",
				absenceMessage: "",
				isNps: false,
				encouragingMessage: "",
				url: "",
				bizflowId:0
			});
		};
	}, [queueId, open]);

	useEffect(() => {
        (async () => {
            
            try {
                const { data } = await api.get("/bizflow");
                dispatch({ type: "LOAD_BIZFLOWS", payload: data });
				

            } catch (err) {
                toastError(err);
                
            }
        })();
    }, []);

	const handleClose = () => {
		onClose();
		setQueue(initialState);
	};

	const handleChange = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setQueue({...queue,bizflowId:value})
		console.log("queue", queue.bizflowId)
	  }


	const handleSaveQueue = async values => {
		try {
			if (queueId) {
				await api.put(`/queue/${queueId}`, values);
			} else {
				await api.post("/queue", values);
			}
			toast.success(`${i18n.t("queueModal.notification.title")}`);
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};


	const renderActiveToolTips = bizflow => {
        return (
            <div className={classes.customTableCell}>

                {bizflow?.isActive== 1 && (
                    <IconButton aria-label="active" component="span" >
                        <AutorenewIcon  className={classes.green1} />
                    </IconButton>
                )}

                {bizflow?.isActive== 0 && (                 
                    <IconButton aria-label="inactive" component="span" >
                        <AcUnitIcon  className={classes.blue1} />
                    </IconButton>       
                )}

            </div>
        );
    };

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{queueId
						? `${i18n.t("queueModal.title.edit")}`
						: `${i18n.t("queueModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={queue}
					enableReinitialize={true}
					validationSchema={QueueSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveQueue(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
							
								<div className={classes.multFieldLine}>
									<Can
										role={loggedInUser.profile}
										perform="user-modal:editProfile"
										yes={() => (
											<>

											<Field
												as={TextField}
												label={i18n.t("queueModal.form.id")}
												autoFocus
												name="id"
												error={touched.id && Boolean(errors.id)}
												helperText={touched.id && errors.id}
												variant="outlined"
												margin="dense"
												className={classes.textField}
											/>
											</>
										)}
									/>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.color")}
										name="color"
										id="color"
										onFocus={() => {
											setColorPickerModalOpen(true);
											greetingRef.current.focus();
										}}
										error={touched.color && Boolean(errors.color)}
										helperText={touched.color && errors.color}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<div
														style={{ backgroundColor: values.color }}
														className={classes.colorAdorment}
													></div>
												</InputAdornment>
											),
											endAdornment: (
												<IconButton
													size="small"
													color="default"
													onClick={() => setColorPickerModalOpen(true)}
												>
													<Colorize />
												</IconButton>
											),
										}}
										variant="outlined"
										margin="dense"
										
									/>
									<ColorPicker
										open={colorPickerModalOpen}
										handleClose={() => setColorPickerModalOpen(false)}
										onChange={color => {
											values.color = color;
											setQueue(() => {
												return { ...values, color };
											});
										}}
									/>
								</div>
								<div>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.greetingMessage")}
										type="greetingMessage"
										multiline
										inputRef={greetingRef}
										rows={4}
										fullWidth
										name="greetingMessage"
										error={
											touched.greetingMessage && Boolean(errors.greetingMessage)
										}
										helperText={
											touched.greetingMessage && errors.greetingMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<form className={classes.container} noValidate>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.startWork")}
										type="time"
										ampm={false}
										defaultValue="08:00"
										inputRef={startWorkRef}
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											step: 600, // 5 min
										}}
										fullWidth
										name="startWork"
										error={
											touched.startWork && Boolean(errors.startWork)
										}
										helperText={
											touched.startWork && errors.startWork
										}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.endWork")}
										type="time"
										ampm={false}
										defaultValue="18:00"
										inputRef={endWorkRef}
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											step: 600, // 5 min
										}}
										fullWidth
										name="endWork"
										error={
											touched.endWork && Boolean(errors.endWork)
										}
										helperText={
											touched.endWork && errors.endWork
										}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
								</form>
								<div>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.absenceMessage")}
										type="absenceMessage"
										multiline
										inputRef={absenceRef}
										rows={2}
										fullWidth
										name="absenceMessage"
										error={
											touched.absenceMessage && Boolean(errors.absenceMessage)
										}
										helperText={
											touched.absenceMessage && errors.absenceMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>

								<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
									<InputLabel>Bizflow</InputLabel>

									<Field className={classes.selectCtrl}
												as={Select}
												autoFocus
												labelId="Bizflow-label"
												id="bizflowId"
												label="Bizflow"
												placeholder="Bizflow"
												variant="outlined"
												margin="dense"
												name="bizflowId"
												fullWidth
												onChange={(e) => handleChange(e)}
												//value={bizflowId}
												>
												<MenuItem value={0} className={classes.connectInfoItem} >Nenhum Bizflow
												
												<div className={classes.connectionInfo}>
															<div className={classes.connectionInfo}>
																{i18n.t("userModal.form.connection.status")}
															</div>
															<div className={classes.connectionInfo}>
															<IconButton aria-label="active" component="span" >
																<HighlightOffIcon  className={classes.red1} />
															</IconButton>
															</div>
														</div>
												
												
												</MenuItem>
										
												{bizflows.length > 0 && bizflows.map((bizflow) => (
													<MenuItem value={bizflow.id} className={classes.connectInfoItem} >{bizflow.name}
														
														<div className={classes.connectionInfo}>
															<div className={classes.connectionInfo}>
																{i18n.t("userModal.form.connection.status")}
															</div>
															<div className={classes.connectionInfo}>
																{renderActiveToolTips(bizflow)}
															</div>
														</div>
														
													</MenuItem>
												))}
												
											</Field>
											
									</FormControl>

								<FormControlLabel
									control={
										<Field
											as={Switch}
											color="primary"
											name="isNps"
											checked={values.isNps}
										/>
									}
									label={i18n.t("queueModal.form.nps")}
								/>
								<div>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.encouragingMessage")}
										type="encouragingMessage"
										multiline
										inputRef={encouragingRef}
										rows={2}
										fullWidth
										name="encouragingMessage"
										error={
											touched.encouragingMessage && Boolean(errors.encouragingMessage)
										}
										helperText={
											touched.encouragingMessage && errors.encouragingMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<div>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.url")}
										type="url"
										inputRef={urlRef}
										fullWidth
										name="url"
										error={
											touched.url && Boolean(errors.url)
										}
										helperText={
											touched.url && errors.url
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("queueModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{queueId
										? `${i18n.t("queueModal.buttons.okEdit")}`
										: `${i18n.t("queueModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default QueueModal;
