import React, { useState, useEffect, useRef, useReducer } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  makeStyles,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { i18n } from "../../translate/i18n";


import { CategoryContainer } from "../CategoryContainer";



import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
    root: {
      flexWrap: "wrap",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    btnWrapper: {
      position: "relative",
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    textCatalogContainer: {
      width: "100%",
    },
    uploadInput: {
      display: "none",
    },
    sendMessageIcons: {
      color: "grey",
    },
    multFieldLine: {
          display: "flex",
          "& > *:not(:last-child)": {
              marginRight: theme.spacing(1),
          },
      marginTop:"7px",
      },
  
      btnWrapper: {
          position: "relative",
      },
  
      buttonProgress: {
          color: green[500],
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: -12,
          marginLeft: -12,
      },
      formControl: {
          margin: theme.spacing(1),
          minWidth: 220,
      },
    smallFormControl: {
          margin: theme.spacing(1),
          minWidth: 160,
      },
    fileLabel:{
      marginTop: "6",
      fontWeight: "700!important",
      fontSize: "20!important"
    },
    selectCtrl: {
      display: "block",
      width: "100%",
      maxHeight: 36,
      fontSize: "13px!important",
      fontFamily: "Roboto",
      fontWeight: 400,
      lineHeight: -1.571429,
      color: "#404040",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid #d5d8de",
      borderRadius: "4px",
      marginTop: "8px!important",
      },
      selectLabel:{
        marginTop: "-0px",
        marginLeft: "15px",
        zIndex: 1,
      },
      fileLabel:{
        marginTop: "12px",
          fontWeight: "900"
      },
  }));
const CatalogSchema = Yup.object().shape({
    itemName: Yup.string(),
    categoryId: Yup.number(),
    description: Yup.string()
    
  });


const CatalogItemModal = ({ open, onClose, catalogItem, catalogId, initialValues, onSave, }) => {
	const classes = useStyles();
    const isMounted = useRef(true);

    useEffect(() => {
      return () => {
      isMounted.current = false;
      };
  }, []);

  const [selectedCategories, setSelectedCategories] = useState([]);


  
  useEffect(() => {      
    if (catalogItem) {
      let categories=[]
      catalogItem.categories.forEach(function(d) {categories.push({id: d.id, categoryName: d.categoryName, mustaccio: d.mustaccio})});
      setSelectedCategories(categories);
    }
}, [catalogItem]);


    let initialState = null;


    if (catalogItem && catalogItem?.id && selectedCategories ) {
        initialState = {
            id: catalogItem.id, 
            itemName:  catalogItem.itemName,
            description:  catalogItem.description,
            unitName: catalogItem.unitName,
            baseValue:  catalogItem.baseValue,
            deliveryValue:  catalogItem.deliveryValue,
            manufacturer:  catalogItem.manufacturer,
            productImg:  catalogItem.productImg,
            dimensions:  catalogItem.dimensions,
            pax:  catalogItem.pax,
            order:  catalogItem.order,
            active:  catalogItem.active,
            userData1: catalogItem.userData1,
            userData2: catalogItem.userData2,
            userData3: catalogItem.userData3,
            userData4: catalogItem.userData4,
            userData5: catalogItem.userData5,
            userData6: catalogItem.userData6,
            userData7: catalogItem.userData7,
            userData8: catalogItem.userData8,
            userData9: catalogItem.userData9,
            catalogItemCategories: selectedCategories


        };
    }else{
        initialState = {
            id: 0,
            itemName: "",
            description: "",
            unitName: "",
            baseValue: 0,
            deliveryValue: 0,
            manufacturer: "",
            productImg: "",
            dimensions: "",
            pax: 0,
            order: 0,
            active: 1,
            userData1: "",
            userData2:  "",
            userData3:  "",
            userData4:  "",
            userData5:  "",
            userData6:  "",
            userData7:  "",
            userData8:  "",
            userData9:  "",
            catalogItemCategories: []
          };

          
    }

    
    const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	  const [loading, setLoading] = useState(false);

    const [displayfn, setDisplayFn] = useState("");
    const [categoryId, setCategoryId] = useState(0);
    const [medias, setMedias] = useState([]);
    const [catalog, setCatalog] = useState(initialState);
    const [catalogItemModalOpen, setCatalogItemModalOpen] = useState(false);

    const [active,setActive] = useState(false);





	


    const handleSelectedCategories = (selectedCategories) => {
      console.log("handleSelectedCategories", selectedCategories )
      const categories = selectedCategories.map(c => c.id);
      setSelectedCategories(categories);
    }


    const handleChangeMedias = (e) => {
        if (!e.target.files) {
          return;
        }
        const selectedMedias = Array.from(e.target.files);
    
        setMedias(selectedMedias);
        setDisplayFn(medias[0]?.name);
      };
    
    const handleClose = () => {
        setCatalog(initialState);
        setCatalogItemModalOpen(false);
        onClose();
      };
    
    const CategorySelectSingle = () => {
        const classes = useStyles();
        const [categories, setCategories] = useState([]);
    
        useEffect(() => {
            (async () => {
                try {
                    const { data } = await api.get("/categories");
                    //console.log('Categories => ', data.categories);
                    
                    setCategories(data.categories);
                } catch (err) {
                    toastError(err);
                }
            })();
        }, []);
    
        return (
            <div style={{ marginTop: 6 }}>
                <FormControl
                    variant="outlined"
                    className={classes.FormControl}
                    margin="dense"
                    fullWidth
                >
                    <InputLabel id="category-selection-input-label">
                        {i18n.t("categorySelect.inputLabel")}
                    </InputLabel>
    
                    <Field
                        as={Select}
                        label={i18n.t("categorySelect.inputLabel")}
                        name="categoryId"
                        labelId="category-selection-label"
                        id="category-selection"
                        onChange={(e) => handleSelectedCategoryId(e)}
                        value={categoryId}
                    >
                        {categories.map(category => (
                            <MenuItem key={category.id} value={category.id}>
                                {category.categoryName}
                            </MenuItem>
                        ))}
                    </Field>
    
                </FormControl>
            </div>
        );
      };




    
    const handleSelectedCategoryId = (selectedId) => {
    
        console.log(selectedId.target.value)
        setCategoryId(selectedId.target.value)
         }
    
    const handleSaveCatalog = async (values) => {

          setLoading(true);
        
          const catalogoItem = { ...values, categoryId: categoryId };
    
          const formData = new FormData();
       
          formData.append("itemName", values.itemName);
          formData.append("manufacturer", values.manufacturer);
          formData.append("dimensions", values.dimensions);
          formData.append("baseValue", values.baseValue);
          formData.append("deliveryValue", values.deliveryValue);
        
          formData.append("description", values.description);
          formData.append("unitName", values.unitName);
          formData.append("pax", values.pax);
          formData.append("order", values.order);
          formData.append("active", active);
          formData.append("userData1", values.userData1);
          formData.append("userData2", values.userData2);
          formData.append("userData3", values.userData3);
          formData.append("userData4", values.userData4);
          formData.append("userData5", values.userData5);
          formData.append("userData6", values.userData6);
          formData.append("userData7", values.userData7);
          formData.append("userData8", values.userData8);
          formData.append("userData9", values.userData9);
          formData.append("catalogItemCategories", selectedCategories);


          medias.forEach((media) => {
            formData.append("medias", media);
          })
      
          for (var pair of formData.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
          } 

          let newItem;

          try {
            if (initialState.id!=0) {
              newItem = await api.put(`/catalog/${catalogItem.id}`, formData);
            } else {
                newItem = await api.post("/catalog", formData);
            }
            toast.success("REF: "+(newItem?.data?.id) + " - Item salvado com sucesso" );
            handleClose();
            setLoading(false);
          } catch (err) {
            toastError(err);
          }
      };
    
      return (
        <div className={classes.root}>
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            scroll="paper"
          >
            <DialogTitle id="form-dialog-title">
              {initialState.id!=0
                ? `${i18n.t("catalogModal.title.edit")}`
                : `${i18n.t("catalogModal.title.add")}`}
            </DialogTitle>
            <Formik
              initialValues={initialState}
              validationSchema={CatalogSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  handleSaveCatalog(values);
                  actions.setSubmitting(false);
                }, 400);
              }}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form>
                  <DialogContent dividers>
                    <div className={classes.multFieldLine}>
                      <Field
                        as={TextField}
                        label={i18n.t("catalogModal.form.itemName")}
                        name="itemName"
                        autoFocus
                        error={touched.itemName && Boolean(errors.itemName)}
                        helperText={touched.itemName && errors.itemName}
                        variant="outlined"
                        margin="dense"
                        className={classes.textField}
                        fullWidth
                      />
                      <FormControlLabel
                          style={{ marginRight: 7, color: "gray", marginTop: 17 }}
                          label={'Ativo'}
                          labelPlacement="start"
                          control={
                            <Switch
                              size="small"
                              checked={active}
                              onChange={(e) => {
                                setActive(e.target.checked);
                              }}
                              name="active"
                              color="primary"
                            />
                          }
                        />
                    </div>
                    <div>
                        <Field
                            as={TextField}
                            label={i18n.t("catalogModal.form.description")}
                            error={touched.description && Boolean(errors.description)}
                            helperText={touched.description && errors.description}
                            multiline
                            rows={6}
                            fullWidth
                            name="description"
                            className={classes.textField}
                            variant="outlined"
                            margin="dense"
                        />
                    </div>
                    <div>



                    <CategoryContainer
                      onFiltered={handleSelectedCategories}
                      onChange={handleSelectedCategories}
                      items={selectedCategories}
                    />

                    </div>

                    
                    <div className={classes.multFieldLine}>  
                      <Field
                        as={TextField}
                        label={i18n.t("catalogModal.form.manufacturer")}
                        name="manufacturer"
                        autoFocus
                        error={touched.manufacturer && Boolean(errors.manufacturer)}
                        helperText={touched.manufacturer && errors.manufacturer}
                        variant="outlined"
                        margin="dense"
                        className={classes.textField}
                        fullWidth
                      />
                    
                      <Field
                        as={TextField}
                        label={i18n.t("catalogModal.form.dimensions")}
                        name="dimensions"
                        autoFocus
                        error={touched.dimensions && Boolean(errors.dimensions)}
                        helperText={touched.dimensions && errors.dimensions}
                        variant="outlined"
                        margin="dense"
                        className={classes.textField}
                        fullWidth
                      />
                    </div>
                    <div className={classes.multFieldLine}>
                    <Field
                        as={TextField}
                        label={i18n.t("catalogModal.form.unitName")}
                        name="unitName"
                        autoFocus
                        error={touched.unitName && Boolean(errors.unitName)}
                        helperText={touched.unitName && errors.unitName}
                        variant="outlined"
                        margin="dense"
                        className={classes.textField}
                        fullWidth
                      />
                      <Field
                        as={TextField}
                        label={i18n.t("catalogModal.form.baseValue")}
                        name="baseValue"
                        autoFocus
                        error={touched.baseValue && Boolean(errors.baseValue)}
                        helperText={touched.baseValue && errors.baseValue}
                        variant="outlined"
                        margin="dense"
                        className={classes.textField}
                        fullWidth
                      />
                   
                      <Field
                        as={TextField}
                        label={i18n.t("catalogModal.form.deliveryValue")}
                        name="deliveryValue"
                        autoFocus
                        error={touched.deliveryValue && Boolean(errors.deliveryValue)}
                        helperText={touched.deliveryValue && errors.deliveryValue}
                        variant="outlined"
                        margin="dense"
                        className={classes.textField}
                        fullWidth
                      />
                      <Field
                        as={TextField}
                        label={i18n.t("catalogModal.form.pax")}
                        name="pax"
                        autoFocus
                        error={touched.pax && Boolean(errors.pax)}
                        helperText={touched.pax && errors.pax}
                        variant="outlined"
                        margin="dense"
                        className={classes.textField}
                        fullWidth
                      />
                      <Field
                        as={TextField}
                        label={i18n.t("catalogModal.form.order")}
                        name="order"
                        autoFocus
                        error={touched.order && Boolean(errors.order)}
                        helperText={touched.order && errors.order}
                        variant="outlined"
                        margin="dense"
                        className={classes.textField}
                        fullWidth
                      />
                    </div>

                    <div className={classes.multFieldLine}>
                   
                      <Field
                        as={TextField}
                        label={i18n.t("catalogModal.form.userData1")}
                        name="userData1"
                        autoFocus
                        error={touched.userData1 && Boolean(errors.userData1)}
                        helperText={touched.userData1 && errors.userData1}
                        variant="outlined"
                        margin="dense"
                        className={classes.textField}
                        fullWidth
                      />
                      <Field
                        as={TextField}
                        label={i18n.t("catalogModal.form.userData2")}
                        name="userData2"
                        autoFocus
                        error={touched.userData2 && Boolean(errors.userData2)}
                        helperText={touched.userData2 && errors.userData2}
                        variant="outlined"
                        margin="dense"
                        className={classes.textField}
                        fullWidth
                      />
                      <Field
                       as={TextField}
                       label={i18n.t("catalogModal.form.userData3")}
                       name="userData3"
                       autoFocus
                       error={touched.userData3 && Boolean(errors.userData3)}
                       helperText={touched.userData3 && errors.userData3}
                       variant="outlined"
                       margin="dense"
                       className={classes.textField}
                       fullWidth
                      />
                    </div>

                    <div className={classes.multFieldLine}>
                   
                      <Field
                        as={TextField}
                        label={i18n.t("catalogModal.form.userData4")}
                        name="userData4"
                        autoFocus
                        error={touched.userData4 && Boolean(errors.userData4)}
                        helperText={touched.userData4 && errors.userData4}
                        variant="outlined"
                        margin="dense"
                        className={classes.textField}
                        fullWidth
                      />
                      <Field
                        as={TextField}
                        label={i18n.t("catalogModal.form.userData5")}
                        name="userData5"
                        autoFocus
                        error={touched.userData5 && Boolean(errors.userData5)}
                        helperText={touched.userData5 && errors.userData5}
                        variant="outlined"
                        margin="dense"
                        className={classes.textField}
                        fullWidth
                      />
                      <Field
                       as={TextField}
                       label={i18n.t("catalogModal.form.userData6")}
                       name="userData6"
                       autoFocus
                       error={touched.userData6 && Boolean(errors.userData6)}
                       helperText={touched.userData6 && errors.userData6}
                       variant="outlined"
                       margin="dense"
                       className={classes.textField}
                       fullWidth
                      />
                    </div>

                    <div className={classes.multFieldLine}>
                   
                   <Field
                     as={TextField}
                     label={i18n.t("catalogModal.form.userData7")}
                     name="userData7"
                     autoFocus
                     error={touched.userData7 && Boolean(errors.userData7)}
                     helperText={touched.userData7 && errors.userData7}
                     variant="outlined"
                     margin="dense"
                     className={classes.textField}
                     fullWidth
                   />
                   <Field
                     as={TextField}
                     label={i18n.t("catalogModal.form.userData8")}
                     name="userData8"
                     autoFocus
                     error={touched.userData8 && Boolean(errors.userData8)}
                     helperText={touched.userData8 && errors.userData8}
                     variant="outlined"
                     margin="dense"
                     className={classes.textField}
                     fullWidth
                   />
                   <Field
                    as={TextField}
                    label={i18n.t("catalogModal.form.userData9")}
                    name="userData9"
                    autoFocus
                    error={touched.userData9 && Boolean(errors.userData9)}
                    helperText={touched.userData9 && errors.userData9}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    fullWidth
                   />
                 </div>










                    <div className={classes.multFieldLine}>
                        <input
                          type="file"
                          id="upload-button"
                          className={classes.uploadInput}
                          onChange={handleChangeMedias}
                        />
                        <label htmlFor="upload-button">
                          {i18n.t("catalogModal.form.productImg")}&nbsp;
                          <IconButton
                            aria-label="upload"
                            component="span"
                          >
                            <AttachFileIcon className={classes.sendMessageIcons} />
    
                          </IconButton>
                        </label>
                        <label htmlFor="upload-button" fullWidth className={classes.fileLabel}>
                               {medias.length > 0 ? medias[0]?.name : catalogItem?.productImg}
                        </label>
                       
                      </div>
                    
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      color="secondary"
                      disabled={isSubmitting}
                      variant="outlined"
                    >
                      {i18n.t("catalogModal.buttons.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      className={classes.btnWrapper}
                    >
                      {initialState.id!=0
                        ? `${i18n.t("catalogModal.buttons.okEdit")}`
                        : `${i18n.t("catalogModal.buttons.okAdd")}`}
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>
        </div>
      );








}


export default React.memo(CatalogItemModal);
