import React, {
  forwardRef,
} from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { systemVars } from "../../../../package.json"

const Page = forwardRef(({
  title,
  children,
  ...rest
}, ref) => {

  return (
    <div
      ref={ref}
      {...rest}
    >
      <Helmet>
        <title>{systemVars.appName}&nbsp;v{systemVars.version}</title>
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

export default Page;
