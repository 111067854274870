import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { WebSocketInterface } from 'jssip';

import {
  VolumeUp,
  VolumeOff,
  NotificationsActive,
  NotificationsOff
} from '@material-ui/icons';
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Slider,
  Switch,
  CircularProgress,
  TextField
} from '@material-ui/core';

import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '256px',
    padding: '27px'
  },
  sliderIcons: {
    marginRight: '10px', color: '#546e7a'
  }

}));


function SettingsBlock({
  struser, strpass, strserver,
  localStatePhone,
  handleConnectPhone,
  handleConnectOnStart,
  handleNotifications,
  handleSettingsSlider
}) {
	
	
	strserver=localStorage.getItem('@reactphone/voipserver');
	struser=localStorage.getItem('@reactphone/username');
	strpass=localStorage.getItem('@reactphone/password');
  

  const classes = useStyles();
  

  const {
    connectedPhone, connectingPhone, 
	notifications, ringVolume, callVolume
  } = localStatePhone;


  const [sliderValue, setSliderValue] = React.useState({
    ringVolume,
    callVolume
  });

/*
  const changeVoip = (e) =>{
    strserver = e.target.value;
    localStorage.setItem('PhoneUA.domain', strserver);
    localStorage.setItem('PhoneUA.ws_servers', 'ws://'+strserver+':8088/ws');
    localStorage.setItem('PhoneUA.sockets', 'ws://'+strserver+':8088/ws');


    localStorage.setItem('@reactphone/voipserver', strserver);
    console.log(strserver);
  }
*/


  const changeVoip = (e) =>{
      strserver = e.target.value;
      localStorage.setItem('PhoneUA.domain', strserver);
      localStorage.setItem('PhoneUA.ws_servers', 'wss://'+strserver);
      localStorage.setItem('PhoneUA.sockets', 'wss://'+strserver);


	    localStorage.setItem('@reactphone/voipserver', strserver);
	    console.log(strserver);
    }
	
  const changeUser = (e) =>{
    struser = e.target.value;
    localStorage.setItem('PhoneUA.uri', 'sip:'+struser+'@'+ localStorage.getItem('PhoneUA.domain'));
    localStorage.setItem('PhoneUA.display_name', struser);
	  localStorage.setItem('@reactphone/username', struser);
	  console.log(struser)
    }	
	
  const changePass = (e) =>{
      strpass = e.target.value;
	  localStorage.setItem('@reactphone/password', strpass);
    localStorage.setItem('PhoneUA.password', strpass);
	  console.log(strpass)
    }

  const handleSettingsSliderState = (name) => (e, newValue) => {
    setSliderValue((prevState) => ({
      ...prevState,
      [name]: newValue
    }));
	console.log(struser);
    handleSettingsSlider(name, newValue);
  };

  return (


    <div className={classes.root}>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={3}>
          {sliderValue.ringVolume === 0
            ? <NotificationsOff className={classes.sliderIcons} />
            : <NotificationsActive className={classes.sliderIcons} />}
        </Grid>
        <Grid item xs={9}>
          <Slider value={sliderValue.ringVolume} onChange={handleSettingsSliderState('ringVolume')} aria-labelledby="continuous-slider" />
        </Grid>

        <Grid item xs={3}>
          {sliderValue.callVolume === 0
            ? <VolumeOff className={classes.sliderIcons} />
            : <VolumeUp className={classes.sliderIcons} />}
        </Grid>
        <Grid item xs={9}>
          <Slider value={sliderValue.callVolume} onChange={handleSettingsSliderState('callVolume')} aria-labelledby="continuous-slider" />
        </Grid>


        <FormControl component="fieldset" className={classes.sliderIcons}>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="top"
              control={<Switch checked={notifications} color="primary" onChange={handleNotifications} />}
              label="Notificações"
              labelPlacement="start"
            />
			
			

            <FormControlLabel
              value="top"
              control={<Switch disabled={connectingPhone} checked={connectedPhone} color="primary" onChange={handleConnectPhone} />}
              label={connectedPhone ? 'Desconectar' : 'Connectar'}
              labelPlacement="start"
            />
            {connectingPhone ? <CircularProgress size={25} /> : ''}

			<TextField     
				style={{ textAlign: 'right' }}
				id="strserver"
				label="Servidor VOIP"
				fullWidth
				onChange={changeVoip}
        defaultValue = {localStorage.getItem('PhoneUA.domain')}
			
				
			/>
			<TextField     
				style={{ textAlign: 'right' }}
				id="struser"
				label="Usuário"
				fullWidth
				onChange={changeUser}
        defaultValue = {localStorage.getItem('PhoneUA.display_name')}
			
			/>
			
		    <TextField	 
				securetextentry={'true'}
				textcontenttype={'password'}
				password={'true'}
				type="password"
				style={{ textAlign: 'right' }}
				id="strpass"
				label="Senha"
				fullWidth
				onChange={changePass}
        defaultValue = {localStorage.getItem('PhoneUA.password')}
			
          />
          </FormGroup>
        </FormControl>


      </Grid>

    </div>

  );
}
SettingsBlock.propTypes = {
  strserver: PropTypes.any,
  struser: PropTypes.any,
  strpass: PropTypes.any,
  localStatePhone: PropTypes.any,
  handleConnectPhone: PropTypes.any,
  handleConnectOnStart: PropTypes.any,
  handleSettingsSlider: PropTypes.any,
  handleNotifications: PropTypes.any

};

export default SettingsBlock;
