import React, { useState, useEffect, useContext, useRef } from "react";
import "emoji-mart/css/emoji-mart.css";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import MoreVert from "@material-ui/icons/MoreVert";
import MoodIcon from "@material-ui/icons/Mood";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import MicIcon from "@material-ui/icons/Mic";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title"
import xlsImport from "../../assets/xlsImport.jpg"
import ButtonWithSpinner from "../ButtonWithSpinner";


import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	
	TextField,
	FormControlLabel,
	Hidden,
	Menu,
	MenuItem,
	Switch,
	
  } from "@material-ui/core";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";

import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";


const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: "#eee",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      width: "100%",
    },
  },

  newMessageBox: {
    background: "#eee",
    width: "100%",
    display: "flex",
    padding: "7px",
    alignItems: "center",
  },

  messageInputWrapper: {
    padding: 6,
    marginRight: 7,
    background: "#fff",
    display: "flex",
    borderRadius: 20,
    flex: 1,
    position: "relative",
  },

  messageInput: {
    paddingLeft: 10,
    flex: 1,
    border: "none",
  },

  sendMessageIcons: {
    color: "grey",
  },

  uploadInput: {
    display: "none",
  },

  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eee",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
  },

  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },

  audioLoading: {
    color: green[500],
    opacity: "70%",
  },

  recorderWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "middle",
  },

  cancelAudioIcon: {
    color: "red",
  },

  sendAudioIcon: {
    color: "green",
  },

  replyginMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
  },

  replyginMsgContainer: {
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  replyginMsgBody: {
    padding: 10,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  replyginContactMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  replyginSelfMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },
  messageQuickAnswersWrapper: {
    margin: 0,
    position: "absolute",
    bottom: "50px",
    background: "#ffffff",
    padding: "2px",
    border: "1px solid #CCC",
    left: 0,
    width: "100%",
    "& li": {
      listStyle: "none",
      "& a": {
        display: "block",
        padding: "8px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxHeight: "32px",
        "&:hover": {
          background: "#F1F1F1",
          cursor: "pointer",
        },
      },
    },
  },
  popupbox : {
	position: "fixed",
	background: "#00000050",
	width: "100%",
	height: "100vh",
	top: "0",
	left: "0"

  },
  box : {
	position: "relative" ,
	width: "50%" ,
	margin: "0 auto" ,
	height: "auto" ,
	maxHeight: "90vh" ,
	marginTop: "calc(100vh - 85vh - 20px)" ,
	background: "#fff" ,
	borderRadius: "4px" ,
	padding: "20px" ,
	border: "1px solid #999" ,
	overflow: "auto" ,
	zIndex:"1000"
  },
   
  closeicon : {
	content: "x" ,
	cursor: "pointer" ,
	position: "fixed" ,
	right: "calc(22%)" ,
	top: "calc(100vh - 85vh - 33px)" ,
	background: "#ededed" ,
	width: "25px" ,
	height: "25px" ,
	borderRadius: "50%" ,
	lineHeight: "20px" ,
	textAlign: "center" ,
	border: "1px solid #999" ,
	fontSize: "20px" ,
  },

  comment:{
	fontSize: "12px",
	textAlign:"justify",
	lineHeight :"20px"
  },
  sendMessageIcons: {
	color: "grey",
  },
  uploadInput: {
	display: "none",
  },
}));

const ContactXlsImportModal = props => {
  const classes = useStyles();
  const { ticketId } = useParams();
  const [displayfn, setDisplayFn] = useState("");
  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [quickAnswers, setQuickAnswer] = useState([]);
  const [typeBar, setTypeBar] = useState(false);
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
 
  const { user } = useContext(AuthContext);

  const handleChangeMedias = (e) => {
    if (!e.target.files) {
      return;
    }

    const selectedMedias = Array.from(e.target.files);
      setMedias(selectedMedias);
	    setDisplayFn(medias[0]?.name);

  };


  const handleUploadMedia = async (e) => {
    setLoading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("fromMe", true);
    medias.forEach((media) => {
      formData.append("medias", media);
      formData.append("body", media.name);
    });

    try {
            toast.success(i18n.t("contactModal.title.xlssuccess"));
            setLoading(false);
            setMedias([]);
            props.handleClose();
            await api.post(`/contactsxlsimport`, formData);
            
	  
    } catch (err) {
            toastError(err);
      
    }


	
  };




    return (
		<div className={classes.popupbox}>
			<div className={classes.box}>
				<span className={classes.closeicon} onClick={props.handleClose} >x</span>
				<MainContainer>
					<MainHeader>
						<Title>{i18n.t("contactModal.title.importXls")}</Title>
					</MainHeader>

					<Paper className={classes.mainPaper} variant="outlined">
						<Table size="small">
								<TableRow>
									<TableCell align="center"><img src={xlsImport}></img></TableCell>
									<TableCell align="justify" className={classes.comment}>Atenção: Lembre que a planilha deve ter apenas 3 colunas: a primeira contendo os nomes dos contatos, a segunda os celulares no formato desejado pelo Whatsapp (começando em 55&lt;ddd&gt;&lt;número local&gt;, sabendo que em determinados estados, o número local (para o whatsapp) tem 8 dígitos e não 9) e a terceira coluna deve ter o nome da campanha que vamos utilizar. Este nome atua como filtro, por isso garanta a sua exatidão na importação e na criação da campanha.</TableCell>
								</TableRow>
						</Table>	
					</Paper>
					<br/>
					<Paper>

					<Table size="small">
								<TableRow>
									<TableCell align="center">
										<input
											multiple
											type="file"
											allow=".xls, .xlsx"
											id="upload-button"
											className={classes.uploadInput}
											onChange={handleChangeMedias}
										/>
										<label htmlFor="upload-button">
											<IconButton
												aria-label="upload"
												component="span"
											>
												<AttachFileIcon className={classes.sendMessageIcons} />
											</IconButton>
										</label>	
										
									</TableCell>
									<TableCell align="center">
										<span>
											{displayfn}
											
										</span>
									</TableCell>
									<TableCell align="center">	
										{!loading && (					
											<IconButton
												aria-label="send-upload"
												component="span"
												onClick={handleUploadMedia}
												disabled={loading}
											>
												<SendIcon className={classes.sendMessageIcons} />
												
											</IconButton> 
										)}
										{loading && (
												<CircularProgress color="success"
												size={24}
												className={classes.buttonProgress}
												/>
										)}
									 
									</TableCell>
									
									</TableRow>
						</Table>	
	
				
				
					</Paper>
				</MainContainer>
	  		</div>
	  	</div>
    );
};

export default ContactXlsImportModal;
