import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));




const RestVerbSelectSingle = () => {
    const classes = useStyles();
    const [verbs, setVerbs] = useState([
                                        {id:1, verbName: "GET"},
                                        {id:2, verbName: "POST"},
                                        {id:3, verbName: "PUT"},
                                        {id:4, verbName: "PATCH"},
                                        {id:5, verbName: "DELETE"},
                                        {id:6, verbName: "OPTIONS"}
                                      ]);



    return (
        <div style={{ marginTop: 6 }}>
            <FormControl
                variant="outlined"
                className={classes.FormControl}
                margin="dense"
                fullWidth
            >
                <InputLabel id="category-selection-input-label">
                    Selecione o verbo que deseja usar
                </InputLabel>

                <Field
                    as={Select}
                    label={i18n.t("categorySelect.inputLabel")}
                    name="verbId"
                    labelId="verb-selection-label"
                    id="verb-selection"
                   //onChange={(e) => handleSelectedCategoryId(e)}
                >
                    {verbs.map(verb => (
                        <MenuItem key={verb.id} value={verb.id}>
                            {verb.verbName}
                        </MenuItem>
                    ))}
                </Field>

            </FormControl>
        </div>
    );
};

export default RestVerbSelectSingle;
