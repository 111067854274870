
import React, {useState, useEffect, useStyles} from "react";
import PierChart from './chartAssets/graficos/PierChart';
import './chartAssets/assets/css/app.css';
import { makeStyles } from "@material-ui/core/styles";
import { TagLabelDisplayForCampaigns } from "../TagLabelDisplayForCampaign"
import toastError from "../../errors/toastError";
import api from "../../services/api";
import Paper from "@material-ui/core/Paper"
import {Line} from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'

import {
  Button
} from "@material-ui/core";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)



const CampaignDashboard = props => {

    // className={classes.popupbox}
    const useStyles = makeStyles(theme => ({
      popupbox : {
        position: "fixed",
        background: "#00000050",
        width: "100%",
        height: "100vh",
        top: "0",
        left: "0"
    
        },
        catalogLabel:{
          fontSize: "1.5 rem",
          fontWeigth: "700"
        },
        catalogContent:{
          fontSize: "1.5 rem",
        }




      })
    );

   
  

  const [mydata, setMyData] = useState();

  const [campaignData, setcampaignData] = useState({ id: "", name: "" });
  const [countPerMinute, setcountPerMinute] = useState({ id: "", name: "" });
  const [readsPerMinute, setreadsPerMinute] = useState([]);
  const [totalCampaignContacts, settotalCampaignContacts] = useState({recCount: 0});
  const [totalMsgsSent, settotalMsgsSent] = useState({recCount: 0});
  const [totalMsgsDelivered, settotalMsgsDelivered] = useState({recCount: 0});
  const [totalMsgsRead, settotalMsgsRead] = useState({recCount: 0});
  const [totalTargets, setTotalTargets] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
            const fetchCampaignData = async () => {
              try {
                const ret  = await api.get("/campaign/"+props.selectedId);

                console.log("fetchCampaignData", ret)

                setMyData(ret.data.campaignData);
                setcampaignData(ret.data.campaignData);
                setcountPerMinute(ret.data.countPerMinute);
                setreadsPerMinute(ret.data.readsPerMinute);
                settotalCampaignContacts(ret.data.totalCampaignContacts[0]);
                settotalMsgsSent(ret.data.totalMsgsSent[0]);
                settotalMsgsDelivered(ret.data.totalMsgsDelivered[0]);
                settotalMsgsRead(ret.data.totalMsgsRead[0]);
                setTotalTargets(ret.data.totalList)

              } catch (err) {
                toastError(err);
              }
            };
            fetchCampaignData();
            clearTimeout(delayDebounceFn);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  });

  let c_labels=[];
  let c_data=[];

  let r_labels=[];
  let r_data=[];

  for (let i = countPerMinute.length-1; i >= 0; i--) {
    c_labels.push(countPerMinute[i].tmCreated)
    c_data.push(countPerMinute[i].recCount)
	}

  for (let i = readsPerMinute.length-1; i >= 0; i--) {
    r_labels.push(readsPerMinute[i].tmCreated)
    r_data.push(readsPerMinute[i].recCount)
	}
 
  const sends = {
    labels: c_labels,
    datasets: [
      {
        label: 'Velocidade de Envio (Mensagens por Minuto)',
        color:"#DC143C",
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: c_data
      }
    ]
  }

  const reads = {
    labels: r_labels,
    datasets: [
      {
        label: 'Períodos de Maior Leitura de Mensagens',
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: r_data
      }
    ]
  }

  function format_date(date){
    var dateTime = new Date(date).toLocaleDateString(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    })

    return dateTime;

  }

  const downloadFile = (fileName, content, fileType) => {
    const makeCSV = (content) => {
      let csv = '';
      content.forEach(item => {
        Object.entries(item).forEach(([, value], index) => {
          let innerValue = value == null ? '' : value.toString();
          let result = innerValue.replace(/"/g, '""');
          if (result.search(/("|,|\n)/g) >= 0) {
            result = '"' + result + '"'
          }
          if (index > 0) {csv += ';'}
          csv += result;
        
        });
        csv += '\n';
      })
      return csv
    }
    const universalBOM = "\uFEFF";
    const file = new Blob([universalBOM+makeCSV(content)], {type: fileType});
    
    const element = document.createElement("a");
      element.href = URL.createObjectURL(file);
      element.download = fileName;
    
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }
  const goToExecutedFile = () => {

    totalTargets.unshift({"1":"id", "2":"Destinatário", "3":"Recibo", "4":"Status", "5":"Campanha", "6":"Texto", "7":"Canal", "8":"Campos Adicionais","9":"Criado Em", "10":"Atualizado Em", });

    downloadFile(
      "Resultado Campanha "+campaignData.id+'.csv',
      totalTargets,
      'text/csv; charset=utf-8'
    );
  }
  






      return (
                <div style={{zIndex:1000}}>
                  <div className="row">
                    <div className="col-md-12">
                        <h2><strong>Campanha : </strong>{(campaignData.campaignType=="broadcast"?"Disparo  de " + cbaTime(campaignData.name.replace("Disparo ","")):campaignData.name)}</h2>
                    </div>
                  </div>
                  <div className="row">
                      <div className="col-md-5">
                          <div className="widget widget-tile">
                          <Button
                              variant="contained"
                              color="primary"
                              onClick={goToExecutedFile}
                            >
                              Baixe os resultados desta campanha
                          </Button>
                              <h2><strong>ID da Campanha : </strong>{campaignData.id}</h2>
                              
                              <h2><strong>Segmento : </strong>{(campaignData.isGroup?"Polígono Geo":"Contatos")}</h2>
                              <h2><strong>Filtro :</strong> <TagLabelDisplayForCampaigns campaign={campaignData} displayOnly={true}/></h2>
                              <h2><strong>Data de Envio : </strong>{format_date(campaignData.sendFrom)}</h2>
                              <h2><strong>Ativo : </strong>{(campaignData.status==1?"Sim":"Não")}</h2>
                              <h2><strong>Em Processamento : </strong>{(campaignData.processingStatus==1?"Sim":"Não")}</h2>
                              </div>
                        </div>
                      <div className="col-md-7">
                          <div className="row">
                              <div className="widget widget-tile">
                              <Paper >
                              <Line
                                  data={sends}
                                  height={180}
                                  options={{
                                    plugins:{legend:{labels:{color:"#DC143C",boxWidth:0,boxHeight:0}}},
                                    maintainAspectRatio: false,
                                    pointBackgroundColor:"#DC143C",
                                    title:{
                                      display:true,
                                      text:'Average Rainfall per month',
                                      fontSize:20,
                                      color:"#DC143C",
                                    },
                                    legend:{
                                      display:true,
                                      position:'right',
                                      color:"#DC143C",
                                    }
                                  }}
                                />
                              </Paper>
                              </div>
                        </div>
                        <div className="row">
                              <div className="widget widget-tile">
                              <Paper >
                              <Line
                                  data={reads}
                                  height={180}
                                  options={{
                                    plugins:{legend:{labels:{color:"#006D5B",boxWidth:0,boxHeight:0}}},
                                    maintainAspectRatio: false,
                                    pointBackgroundColor:"#006D5B",
                                    title:{
                                      display:true,
                                      text:'Average Rainfall per month',
                                      fontSize:20
                                    },
                                    legend:{
                                      display:true,
                                      position:'right'
                                    }
                                  }}
                                />
                              </Paper>
                              </div>
                        </div>
                      </div>
                  </div>
                  <div className="row">
                  <div className="col-md-3">
                    <div className="be-booking-promo be-booking-promo-warning">
                          <h2>Targets</h2>
                          <div className="data-info">
                            <div className="desc"></div>
                              <div className="value">
                                <label class="custom-control custom-radio custom-radio-icon custom-control-inline"  >
                                    <span class="custom-control-label">
                                        <h3>{totalCampaignContacts.recCount}</h3>
                                    </span>
                                </label>
                            </div>
                        </div>
                      </div>
                  </div>
                  <div className="col-md-3">
                    <div className="be-booking-promo be-booking-promo-success">
                          <h2>Enviados</h2>
                          <div className="data-info">
                            <div className="desc"></div>
                              <div className="value">
                                <label class="custom-control custom-radio custom-radio-icon custom-control-inline"  >
                                    <span class="custom-control-label">
                                        <h3>{totalMsgsSent.recCount}</h3>
                                    </span>
                                </label>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-3">
                    <div className="be-booking-promo be-booking-promo-danger">
                          <h2>Recebidos</h2>
                          <div className="data-info">
                            <div className="desc"></div>
                              <div className="value">
                                <label class="custom-control custom-radio custom-radio-icon custom-control-inline"  >
                                    <span class="custom-control-label">
                                        <h3>{totalMsgsDelivered.recCount}</h3>
                                    </span>
                                </label>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-3">
                    <div className="be-booking-promo be-booking-promo-info">
                          <h2>Lidos</h2>
                          <div className="data-info">
                            <div className="desc"></div>
                              <div className="value">
                                <label class="custom-control custom-radio custom-radio-icon custom-control-inline"  >
                                    <span class="custom-control-label">
                                        <h3>{totalMsgsRead.recCount}</h3>
                                    </span>
                                </label>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
                  
      );

}

export default CampaignDashboard;

const cbaTime = date => new Date(date).toLocaleString('pt-BR', { timeZone: 'America/Cuiaba' });