import React, { useEffect, useReducer, useState } from "react";

import openSocket from "socket.io-client";

import BizFlowBoard from "../../components/BizFlowBoard";

import CodeIcon from '@material-ui/icons/Code';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';

import {
    Button,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";


import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@material-ui/icons";
import BotModal from "../../components/CommandModal";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import MenuModal from "../../components/MenuModal";
import {blue, purple, red, green, teal, orange, amber} from '@material-ui/core/colors';
import BizFlowModal from "../../components/BizFlowModal";


const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    green1: {
		color: green[500]
	},
    red1: {
            color: red[500]
        },

    blue1: {
            color: blue[500]
        },
    teal1: {
            color: teal[500]
        },
    orange1: {
            color: orange[500]
        },
    purple1: {
            color: purple[500]
        },
    amber1: {
            color: amber[500]
        },
    }));

const reducer = (state, action) => {
    if (action.type === "LOAD_BIZFLOWS") {
        const bizflows = action.payload;
        const newBizflows = [];

        bizflows.forEach((bizflow) => {
            const bizflowIndex = state.findIndex((q) => q.id === bizflow.id);
            if (bizflowIndex !== -1) {
                state[bizflowIndex] = bizflow;
            } else {
                newBizflows.push(bizflow);
            }
        });

        return [...state, ...newBizflows];
    }

    if (action.type === "UPDATE_BIZFLOWS") {

        const bizflow = action.payload;
        console.log("bizflow",bizflow)
        const bizflowIndex = state.findIndex((q) => q.id === bizflow.id);
        console.log("bizflowIndex",bizflowIndex)
        
        if (bizflowIndex !== -1) {
            state[bizflowIndex] = bizflow;
            return [...state];
        } else {
            return [bizflow, ...state];
        }
        
    }

    /*

    if (action.type === "DELETE_BOT") {
        const botId = action.payload;
        console.log('BOTID => ', botId);
        const botIndex = state.findIndex((q) => q.id === botId);
        if (botIndex !== -1) {
            state.splice(botIndex, 1);
        }
        return [...state];
    }
*/

    if (action.type === "RESET") {
        return [];
    }
};

const  BizFlow = () => {
    const classes = useStyles();

    const [bizflows, dispatch] = useReducer(reducer, []);
    const [bizFlowDrawing, setBizFlowDrawing]= useState(false);
    const [currBizFlow, setCurrBizFlow] = useState()

    const [loading, setLoading] = useState(false);

    const [botModalOpen, setBotModalOpen] = useState(false);
    const [menuModalOpen, setMenuModalOpen] = useState(false);
    const [selectedBizflow, setSelectedBizflow] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [bizflowModalOpen, setBizflowModalOpen] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const { data } = await api.get("/bizflow");
                dispatch({ type: "LOAD_BIZFLOWS", payload: data });
                setLoading(false);
            } catch (err) {
                toastError(err);
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

        socket.on("bizflow", (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_BIZFLOWS", payload: data.bizflow });
            }

            if (data.action === "delete") {
                console.log('ACTION DELETE =>', data);
                dispatch({ type: "DELETE_BIZFLOW", payload: data.botId });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);


    const handleOpenBizFlowModal = () => {
        setBizflowModalOpen(true);
        setSelectedBizflow(null);
    };

    const handleCloseBizFlowModal = () => {
        setBizflowModalOpen(false);
        setSelectedBizflow(null);
    };

    const handleEditBizflow = (id) => {
        setSelectedBizflow(id);
        setBizflowModalOpen(true);
    };

    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false);
        setSelectedBizflow(null);
    };


    const handledrawing = async (bizflow) => {
        setCurrBizFlow(bizflow);
        setBizFlowDrawing(true)


    }

    const handleCloseBizFlow = async (bizflow) => {
        setCurrBizFlow(bizflow);
        setBizFlowDrawing(false)

    }

    const handleCompileBizFlow = async (bizflow) => {
        setCurrBizFlow(bizflow);
        setBizFlowDrawing(false)
        console.log("bizflow.id",bizflow.id)
        try {
             await api.post(`/bizflow/${bizflow.id}`);
            
            setLoading(false);
        } catch (err) {
            toastError(err);
            setLoading(false);
        }




    }
 
    const renderJsonToolTips = bizflow => {
		return (
			<div className={classes.customTableCell}>
				{bizflow?.dataJson!=null && (
						<CodeIcon  className={classes.green1} />
				)}
                {bizflow?.dataJson == null && (
						<DonutLargeIcon  className={classes.red1} />
				)}  
			</div>
		);
	};

    const renderActiveToolTips = bizflow => {
		return (
			<div className={classes.customTableCell}>
				{bizflow?.isActive== 1 && (
						<AutorenewIcon  className={classes.green1} />
				)}
                {bizflow?.isActive== 0 && (
                        
                        
                        <IconButton
                            aria-label="upload"
                            component="span"
                            onClick={() => handleCompileBizFlow(bizflow)}
                        >
                                <AcUnitIcon  className={classes.blue1} />
                        </IconButton>
                        
                        
						
				)}  
			</div>
		);
	};

    return (
        <MainContainer>
            <MainHeader>
                <Title>{i18n.t("bizflow.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenBizFlowModal}
                    >
                        {i18n.t("bizflow.buttons.add")}
                    </Button>
                </MainHeaderButtonsWrapper>

                <BizFlowModal
                    open={bizflowModalOpen}
                    onClose={handleCloseBizFlowModal}
                    id={selectedBizflow?.id}
                />


            </MainHeader>
            {bizFlowDrawing && (<BizFlowBoard 
                                        bizflow={currBizFlow}
                                        onClose={handleCloseBizFlow}
                                    />)}
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {i18n.t("bizflow.table.id")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("bizflow.table.name")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("bizflow.table.description")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("bizflow.table.isActive")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("bizflow.table.dataJson")}
                            </TableCell>
                     

                            <TableCell align="center">
                                {i18n.t("bots.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {bizflows.map((bizflow) => (
                                <TableRow key={bizflow.id}>
                                    <TableCell align="center">{bizflow.id}</TableCell>
                                    <TableCell align="center">{bizflow.name}</TableCell>
                                    <TableCell align="center">{bizflow.description}</TableCell>
                                    <TableCell align="center"> {renderActiveToolTips(bizflow)}</TableCell>
                                    <TableCell align="center"> {renderJsonToolTips(bizflow)}</TableCell>
                                                                       
                                    <TableCell align="center">
                                    <IconButton
                                            size="small"
                                            onClick={() => handledrawing(bizflow)}
                                            
                                        >
                                            <DeviceHubIcon />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleEditBizflow(bizflow)}
                                        >
                                            <Edit />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={5} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default  BizFlow;
