import React from 'react';

import {
    makeStyles,
    MenuItem
} from '@material-ui/core';

import { i18n } from "../../translate/i18n";
import StatusTooltip from '../StatusTooltip';
import ChannelTooltip from "../ChannelTooltip"

const useStyles = makeStyles(theme => ({
  connectInfoItem: {
	  justifyContent: "space-between",
	  width: "100%",
  },
  connectionInfo: {
	  alignItems: "center",
	  display: "flex",
	  float: "right",
	  marginTop: "-5px",
	  marginBottom: "-4px",
  },
  connectionInfoSpan: {
	  opacity: "70%",
  }
}))

const ConnectionItems = whatsApps => {
    const classes = useStyles();
    
    return (
        whatsApps.map((whatsApp) => (
            <MenuItem key={whatsApp.id} value={whatsApp.id} className={classes.connectInfoItem}>
                {whatsApp.name}
                <div className={classes.connectionInfo}>
                    <div className={classes.connectionInfoSpan}>
                        {i18n.t("userModal.form.connection.status")}
                    </div>
                    <StatusTooltip whatsApp={whatsApp} />
                    <ChannelTooltip whatsApp={whatsApp} />
                </div>
            </MenuItem>
        ))
    );
};

export default ConnectionItems;