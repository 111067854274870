import React, { useState, useEffect, useContext, useCallback } from "react";
import Player from "./ReactPlayer"; 
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	
  } from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import ReactPlayer from "react-player";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));



const VideoModal = ({ open,onClose, urlId }) => {
	const classes = useStyles();
	
	const handleClose = () => {
		onClose();
	
	};
	
	return (
		<div className={classes.root}>
				<Dialog open={open} 
				onClose={handleClose} 
				
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
						<DialogTitle>
							
								{i18n.t("HelpVideo.title")}
						</DialogTitle>

							<DialogContent dividers>
							<ReactPlayer
									url={urlId}
									width="100%"
									height="calc(50vh - 100px)"
									/>
							</DialogContent>
							<DialogActions>
								<Button
									
									color="secondary"
									onClick={handleClose} 
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								
							</DialogActions>
			</Dialog>
		</div>
	);
};

export default VideoModal;
