import React from 'react';

export default props => {
    const rows = props.desempenhos.map( desempenho => {
        return(
            <tr>
                         <td>
                              {desempenho.name}
                         </td>
                        <td>{desempenho.total_agente}</td>
                        <td>{desempenho.quantidade_finalizado}</td>
                        <td>{desempenho.quantidade_andamento}</td>
                        
            </tr>
        )
    })
return(
           <div className="card-body table-responsive">
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th style={{width: "55%"}}>Agente</th>
                        <th style={{width: "15%"}}>Total de Chats</th>
                        <th style={{width: "15%"}}>Finalizados</th>
                        <th style={{width: "15%"}}>Em andamento</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {rows}
                 
                    </tbody>
                  </table>
                </div>
     )
}
