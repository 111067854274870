
import React, { useState, useEffect, useReducer, useCallback } from "react";

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';

import api from "../../services/api";
import Swal from 'sweetalert2'

import toastError from "../../errors/toastError";
import Grid from '@mui/material/Grid';
import { format, parseISO } from "date-fns";

import {
  ViewState,
} from '@devexpress/dx-react-scheduler';

import {
  Scheduler,
  WeekView,
  DayView,
  Appointments,
  Toolbar,
  DateNavigator,
  ViewSwitcher,
  AppointmentForm,
  AppointmentTooltip,
  TodayButton,
} from '@devexpress/dx-react-scheduler-material-ui';

let eventcolors = {
    "1": {
     "background": "#a4bdfc",
     "foreground": "#1d1d1d"
    },
    "2": {
     "background": "#7ae7bf",
     "foreground": "#1d1d1d"
    },
    "3": {
     "background": "#dbadff",
     "foreground": "#1d1d1d"
    },
    "4": {
     "background": "#ff887c",
     "foreground": "#1d1d1d"
    },
    "5": {
     "background": "#fbd75b",
     "foreground": "#1d1d1d"
    },
    "6": {
     "background": "#ffb878",
     "foreground": "#1d1d1d"
    },
    "7": {
     "background": "#46d6db",
     "foreground": "#1d1d1d"
    },
    "8": {
     "background": "#e1e1e1",
     "foreground": "#1d1d1d"
    },
    "9": {
     "background": "#5484ed",
     "foreground": "#1d1d1d"
    },
    "10": {
     "background": "#51b749",
     "foreground": "#1d1d1d"
    },
    "11": {
     "background": "#dc2127",
     "foreground": "#1d1d1d"
    }
   }
const PREFIX = 'Demo';

const classes = {
  toolbarRoot: `${PREFIX}-toolbarRoot`,
  progress: `${PREFIX}-progress`,
};

const StyledDiv = styled('div')({
  [`&.${classes.toolbarRoot}`]: {
    position: 'relative',
  },
});

const StyledLinearProgress = styled(LinearProgress)(() => ({
  [`&.${classes.progress}`]: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
  },
}));



const pageNumber=1;
const searchParam = "";


const getBookings = async (setData, setLoading) => {
setLoading(true);
try {
    const { data } =  await api.post("/listevents", {params: { searchParam, pageNumber }});
    setTimeout(() => {
        setData(data);
        setLoading(false);
        }, 600);
} catch (err) {
    toastError(err);
    }
};

const ToolbarWithLoading = (
  ({ children, ...restProps }) => (
    <StyledDiv className={classes.toolbarRoot}>
      <Toolbar.Root {...restProps}>
        {children}
      </Toolbar.Root>
      <StyledLinearProgress className={classes.progress} />
    </StyledDiv>
  )
);

const usaTime = date => new Date(date).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
const cbaTime = date => new Date(date).toLocaleString('pt-Br', { timeZone: 'America/Cuiaba' });

const localTime = date => {
    const myDate = new Date (date)
    myDate.setHours(myDate.getHours()+7)
    return myDate.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
}

const localTime3 = date => {
  const myDate = new Date (date)
  myDate.setHours(myDate.getHours()+3)
  return myDate.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
}

//{format(parseISO(whatsApp.updatedAt), "dd/MM/yy HH:mm")}

const mapAppointmentData = appointment => ({
  id: appointment.id,
  startDate: localTime(appointment.start.dateTime),
  endDate:  localTime(appointment.end.dateTime),
  title: appointment.summary,
  notes: appointment.description,
  color:appointment.colorId
});

const initialState = {
  data: [],
  loading: false,
  currentDate: new Date(),
  currentViewName: 'Day',
};


const reducer = (state, action) => {

    switch (action.type) {
      case 'setLoading':
        return { ...state, loading: action.payload };
      case 'setData':
        return { ...state, data: action.payload.map(mapAppointmentData) };
      case 'Load_Bookings':
        return { ...state, data: action.payload.map(mapAppointmentData) };
      case 'setCurrentViewName':
        return { ...state, currentViewName: action.payload };
      case 'setCurrentDate':
        return { ...state, currentDate: action.payload };
      default:
        return state;
    }
  };
  
const GoogleCalendar = () => {

  const [state, dispatch] = useReducer(reducer, initialState);

  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [hasMore, setHasMore] = useState(false);



  const {data, loading, currentViewName, currentDate} = state;
  
  const setCurrentViewName = useCallback(nextViewName => dispatch({type: 'setCurrentViewName', payload: nextViewName}), [dispatch]);
  const setBookings = useCallback(nextData => dispatch({ type: 'Load_Bookings', payload: nextData}), [dispatch]);
  const setCurrentDate = useCallback(nextDate => dispatch({ type: 'setCurrentDate', payload: nextDate }), [dispatch]);
  const setLoading = useCallback(nextLoading => dispatch({ type: 'setLoading', payload: nextLoading }), [dispatch]);

  useEffect(() => {
    getBookings(setBookings, setLoading);
  }, [setBookings, currentViewName, currentDate]);




      function nodeClick(e) {
        console.log(e.data)

        Swal.fire({
          title: 'Informação do Agendamento',   
          icon: "info",
          html: 
          `Início : ${localTime3(e.data.startDate)}<br />
          Término :${localTime3(e.data.endDate)}<br />
          Notas : ${e.data.notes}`,
          confirmButtonText: 'Fechar'
        })
      }
    

       const Appointment = ({ children, style, ...restProps }) => {
        console.log("Child", children[1]?.props?.data)
        return(
            
            <>
        <Appointments.Appointment
          {...restProps}
          style={{
            ...style,
            backgroundColor: (eventcolors[children[1]?.props?.data?.color]?.background ? eventcolors[children[1]?.props?.data?.color]?.background : "#ff887c"),
            color:"#000000",
            fontWeigth:600,
            borderRadius: "8px"
          }}
          //onClick={handleExtraInfo()}
          onClick={nodeClick}
        >
          {children}
        </Appointments.Appointment>
        </> );
       }
        


  return (
    <>

    <Grid container alignItems="center">
        <Grid item xs={12} >
               
            <Scheduler
                data={data}
                height={880}
            >
                <ViewState
                    currentDate={currentDate}
                    currentViewName={currentViewName}
                    onCurrentViewNameChange={setCurrentViewName}
                    onCurrentDateChange={setCurrentDate}
                />
                <DayView
                    startDayHour={7.0}
                    endDayHour={23.0}
                />
                <WeekView
                startDayHour={7.5}
                endDayHour={17.5}
                />
                <Appointments />
                <Toolbar
                    {...loading ? { rootComponent: ToolbarWithLoading } : null}
                />
                <DateNavigator />
                <TodayButton />
                <ViewSwitcher />

                <Appointments 
                    appointmentComponent={Appointment} 
                />
                
            </Scheduler>
               
        </Grid>
    </Grid>

    </>

  );
};


export default GoogleCalendar