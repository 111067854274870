import React, { useState, useReducer,  useEffect, } from "react";

import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";

import {
	Avatar,
	Button,
	CssBaseline,
	TextField,
	Grid,
	Box,
	Typography,
	Container,
	InputAdornment,
	IconButton,
	Link
} from '@material-ui/core';

import { LockOutlined, Visibility, VisibilityOff } from '@material-ui/icons';

import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";


const reducer = (state, action) => {
	if (action.type === "LOAD_CONTACTS") {
	  const contacts = action.payload;
	  const newContacts = [];
  
	  contacts.forEach((contact) => {
		const contactIndex = state.findIndex((c) => c.id === contact.id);
		if (contactIndex !== -1) {
		  state[contactIndex] = contact;
		} else {
		  newContacts.push(contact);
		}
	  });
  
	  return [...state, ...newContacts];
	}
  
	if (action.type === "UPDATE_CONTACTS") {
	  const contact = action.payload;
	  const contactIndex = state.findIndex((c) => c.id === contact.id);
  
	  if (contactIndex !== -1) {
		state[contactIndex] = contact;
		return [...state];
	  } else {
		return [contact, ...state];
	  }
	}
  
	if (action.type === "DELETE_CONTACT") {
	  const contactId = action.payload;
  
	  const contactIndex = state.findIndex((c) => c.id === contactId);
	  if (contactIndex !== -1) {
		state.splice(contactIndex, 1);
	  }
	  return [...state];
	}
  
	if (action.type === "RESET") {
	  return [];
	}
  };

// const Copyright = () => {
// 	return (
// 		<Typography variant="body2" color="textSecondary" align="center">
// 			{"Copyleft "}
// 			<Link color="inherit" href="https://github.com/canove">
// 				Canove
// 			</Link>{" "}
// 			{new Date().getFullYear()}
// 			{"."}
// 		</Typography>
// 	);
// };

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%",
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Obrigatório"),
	number: Yup.string()
		.min(12, "Too Short!")
		.max(13, "Too Long!")
		.required("Obrigatório"),
	
});

const GroupJoin = () => {
	const classes = useStyles();
	const history = useHistory();

	const [searchParam, setSearchParam] = useState("");
	const initialState = { name: "", phone: "" };
	const [showPassword, setShowPassword] = useState(false);
	const [user] = useState(initialState);

	const [contacts, dispatch] = useReducer(reducer, []);
	const [loading, setLoading] = useState(false);
  	const [pageNumber, setPageNumber] = useState(1);
  	const [hasMore, setHasMore] = useState(false);
  	const [totalCount, setTotalCount] = useState();
  	const [isXLSOpen, setIsXLSOpen] = useState(false);

	const urlQuery = new URLSearchParams(window.location.search);
	console.log("urlQuery", urlQuery)
	console.log(urlQuery.has("id")); // true
	console.log(urlQuery.get("id")); // val

	


	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
		  const fetchContacts = async () => {
			try {
				setSearchParam(urlQuery.get("id"))
				const { data } = await api.get("/ws/groups/", {
					params: { searchParam, pageNumber},
				});
				console.log("GroupJoin::Groups", data)
				dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
				setTotalCount(data.count)
				setHasMore(data.hasMore);
				setLoading(false);
			} catch (err) {
			  	toastError(err);
			}
		  };
		  fetchContacts();
		}, 1500);
		return () => clearTimeout(delayDebounceFn);
	  }, [searchParam, pageNumber]);
	




	const handleSignUp = async values => {

		let payload={
			name:values.name,
			number:values.number,
			groupname: urlQuery.get("id")
		}


		try {
			var data = await api.post("/ws/groupjoin", payload);
			console.log(data)
			toast.success(i18n.t("signup.toasts.success"));
			
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}style={{width:300, height:300}} >
					<img src={contacts[0]?.profilePicUrl} className={classes.avatar} style={{width:300, height:300}} /><br />
                                                                    
				</Avatar>
				<Typography component="h1" variant="h4" style={{marginBottom:80, textAlign:"center"}}>
					Aderir ao Grupo {urlQuery.get("id")}
				</Typography>
				<Typography component="h1" variant="h6"  style={{marginBottom:80, textAlign:"center"}}>
				Junte-se ao nosso grupo '{urlQuery.get("id")}' e esteja sempre a par das mais recentes novidades
				</Typography>

				
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSignUp(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form className={classes.form}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Field
										as={TextField}
										autoComplete="name"
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										placeholder="Por favor, digite nome e sobrenome"
										variant="outlined"
										fullWidth
										id="name"
										label={i18n.t("signup.form.name")}
										autoFocus
									/>
								</Grid>

								<Grid item xs={12}>
									<Field
										as={TextField}
										variant="outlined"
										fullWidth
										id="number"
										label={i18n.t("signup.form.phone")}
										name="number"
										placeholder="55 65 99999 9999"
										error={touched.number && Boolean(errors.number)}
										helperText={touched.number && errors.number}
										autoComplete="number"
									/>
								</Grid>

							</Grid>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
							>
								Aderir
							</Button>
						</Form>
					)}
				</Formik>
			</div>
		</Container>
	);
};

export default GroupJoin;
