import React from 'react';

export default props => {

    const rows = props.filas.map( fila => {
        return(
            <tr>
                         <td>{fila.name}</td>
                         <td style={{textAlign:"center"}}>{fila.total_fila}</td>       
            </tr>
        )
    })
return(
           <div className="card-body table-responsive">
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th style={{width: "80%"}}>Fila</th>
                        <th style={{width: "20%", textAlign:"center"}}>Atendimentos</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {rows}
                    </tbody>
                  </table>
                </div>
     )
}
