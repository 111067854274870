import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	TextField,
	InputAdornment,
	IconButton
  } from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));


const MineContactsModal = ({ open, onClose }) => {
	const classes = useStyles();

	const initialState = {
		rootNumber: "",
		generateNbr: ""
	};


	const [miningConf, setMiningConf] = useState(initialState);
    const [isSubmitting, setIsSubmitting]=useState(false)

	const handleClose = () => {
		onClose();
	};


    const handleChangeInput = (e) => {
        setMiningConf({ ...miningConf, [e.target.name]: parseInt(e.target.value)})  
        
      };

	const handleSaveMining = async () => {
        setIsSubmitting(true)
        console.log(miningConf);
		try {
            toast.success(`Os seus ${miningConf.generateNbr} solicitados serão criados na retaguarda. Obrigado.`);
            handleClose(); 
            await api.post("/contact/generate", miningConf);
			toast.success(`${miningConf.generateNbr} contatos criados com sucesso.`);
		} catch (err) {
			toastError(err);
		}
        setIsSubmitting(false)
		handleClose(); 
	};


	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
						{i18n.t("contacts.buttons.mine")}
				</DialogTitle>
				<Formik
					onSubmit={() => {
						setTimeout(() => {
							handleSaveMining();
						}, 400);
					}}
				>
					{({isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
                                        autoFocus
										as={TextField}
										label={i18n.t("contacts.miningModal.rootNumber")}
                                        value={miningConf.rootNumber}
                                        onChange={(e) => handleChangeInput(e)}
										name="rootNumber"
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<Field
										as={TextField}
                                        label={i18n.t("contacts.miningModal.generateNbr")}
                                        value={miningConf.generateNbr}
                                        onChange={(e) => handleChangeInput(e)}
										name="generateNbr"
                                        variant="outlined"
										margin="dense"
										fullWidth
									/>
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>

								<Button
									onClick={handleSaveMining}
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
                                   {i18n.t("contacts.buttons.mine")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default MineContactsModal;
