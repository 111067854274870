import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { AuthContext } from "../../context/Auth/AuthContext";

import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputLabel,
	FormControl,
	makeStyles,
	Select,
	TextField,
	Typography
} from "@material-ui/core";
import {
	AttachFile,
	Cancel
} from "@material-ui/icons"

import api from "../../services/api";
import ConnectionItems from "../ConnectionItems";
import { i18n } from "../../translate/i18n";
import InputVariables from "../../components/InputVariables";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import useWhatsApps from "../../hooks/useWhatsApps";
import { systemVars } from "../../../package.json";
import TagsContainerForTasks from "../TagsContainerForTasks"

const useStyles = makeStyles(theme => ({
	inputNumber: {
		width: 200
	},
	inputConnection: {
		margin: "dense",
		marginTop: 8,
		width: "24ch"
	},
	inputDateTime: {
		width: 460
	},
	newMessageBox: {
		marginTop: theme.spacing(1),
		width: "100%",
		display: "flex",
		padding: "7px",
		alignItems: "left",
		border: "1px solid",
		borderRadius: 15
	},
	uploadInput: {
		display: "none",
	},
	multFieldLine: {
		display: "flex",
		justifyContent: "space-between",
		"& > *:last-child": {
			marginRight: 0,
		},
		width: "100%",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	buttonColorError: {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main,
	},
	buttonProgress: {
		color: theme.palette.secondary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));



const BookingSchema = Yup.object().shape({
	message: Yup.string()
		.min(3, i18n.t("validation.too_short"))
		.max(400, i18n.t("validation.too_long")),
	sendAt: Yup.date()
		.min(new Date(), i18n.t("validation.dateNow")),
    taskName: Yup.string()
		.min(3, i18n.t("validation.too_short"))
		.max(400, i18n.t("validation.too_long")),
})

const BookingModal = ({ user, open, onClose, contactId, messageId, ticket, ticketId }) => {
	const initialState = {
		id: messageId || '',
		contactId: contactId || ticket?.contact.id || '',
		phoneNumber: ticket?.contact.number || '',
		message: ``,
		hasMedia: false,
		wpId: ticket?.whatsappId || '',
		userId: user.id,
		status: 'pending',
		ticketId: ticketId || 0,
		sendAt: '',
        taskName:'',
	};

    const { user: loggedInUser } = useContext(AuthContext);

	console.log("BookingModal:ticket 1", ticketId)
	console.log("BookingModal:ticket 2", initialState)

	const classes = useStyles();
	const { whatsApps } = useWhatsApps();
	const [loadContactField, setLoadContactField] = useState(false);
	const [inputs, setInputs] = useState(initialState);
	
	const [medias, setMedias] = useState([]);
	const [title, setTitle] = useState(i18n.t("bookingModal.form.title"))
	const [field, setField] = useState();
    const [tagFilter, setTagFilter] = useState([]);

	useEffect(() => {
		const fetchContact = async () => {
			if (!contactId) {
				setInputs(inputs => ({ ...inputs, message: `${i18n.t("variables.campaign")}` }));
				return;
			}
			try {
				const { data } = await api.get(`/contacts/${contactId}`);
				setInputs(inputs => ({
					...inputs,
					contactId: data.id,
					phoneNumber: data.number,
					message: ``,
				}));
				setTitle(i18n.t("bookingModal.form.titleContact", { contact: data.name }))
			} catch (err) {
				toastError(err);
			}
		};
		fetchContact();
	},[contactId, open])

	useEffect(() => {		
		if (!ticket) {
			return;
		} else {
			setInputs(inputs => ({
				...inputs,
				contactId: ticket.contact.id,
				phoneNumber: ticket.contact.number,
				ticketId: ticket.id,
				wpId: ticket.whatsappId,
			}));
		}
	}, [ticket]);

	useEffect(() => {
		// let contact = (ticket?.id || contactId ) ? false : true;
		setLoadContactField(() => (contactId || messageId || ticket?.id) ? false : true)
	}, [contactId, messageId, ticket]);


	const handleClose = () => {
		onClose();
		setInputs(initialState);
		setInputs(inputs => ({ ...inputs, message: `` }));
		setMedias([]);
	};

	const handleFocus = (e) => {
		setField(e.target);
	}

    const handleFilterTag = (e) => { 
        console.log("handleFilterTag", e)
        const tagarray=e.map(e => e.id)
        setTagFilter(tagarray)
        setTagFilter(e)
  
      };

	const checkContent = (inputs) => {
		if (!inputs.sendAt) {
			toast.error(i18n.t("bookingModal.toasts.missingSchedule"))
			return
		}

        if (inputs.taskName === "") {
			toast.error(i18n.t("bookingModal.toasts.missingName"))
			return
		}
		if (inputs.message === "") {
			toast.error(i18n.t("bookingModal.toasts.missingMessage"))
			return
		}

        handleSendMessage(inputs);
	}

	const handleSaveSchedule = (values) => {
		let scheduledMessageData = {
			...inputs,
		
            taskName : values.taskName,
            message: values.message,
			
			sendAt: values.sendAt,

			status: "pending"
		};

		setInputs(inputs => ({
			...inputs,
			taskName : values.taskName,
			message: values.message,
			
			sendAt: values.sendAt
		}));

		checkContent(scheduledMessageData)
	}

        
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }


	const handleSendMessage = async (inputs) => {
		try {
			let values
            /*
			if (medias.length > 0) {
				values = new FormData();
                values.append("taskName", inputs.taskName)
				values.append("contactId", inputs.contactId)
				values.append("message", inputs.message);
				values.append("userId", inputs.userId);
				values.append("ticketId", ticketId);
				values.append("sendAt", inputs.sendAt);
                values.append("tagArray",tagFilter)
			} else {
                */
			//}
				values = {
                    taskName: inputs.taskName,
					contactId: inputs.contactId,
					message: inputs.message,
					userId: inputs.userId,
					ticketId:ticketId,
					sendAt: inputs.sendAt,
                    tagFilter: tagFilter
				};
				let data = await api.post('/agenda', values);
                console.log("BookingModal::handleSendMessage", data)		
			    toast.success(i18n.t("BookingModal.toasts.success"));

		} catch (err) {
			toastError(err);
		}

		handleClose();
	}

	return (
		<Dialog open={open} onClose={handleClose} scroll="paper">
			<DialogTitle id="form-dialog-title">
				{title}
			</DialogTitle>
			<Formik
				initialValues={inputs}
				enableReinitialize={true}
				validationSchema={BookingSchema}
				onSubmit={(values, actions, e) => {
					setTimeout(() => {
						handleSaveSchedule(values);
						actions.setSubmitting(false)
					}, 400);
				}}
			>
				{({ touched, errors, isSubmitting, values, setFieldValue }) => (
					<Form>
						<DialogContent dividers>
							<div className={classes.multFieldLine}>
								
                                <Field
                                    as={TextField}
                                    label={i18n.t("bookingModal.form.namePlaceholder")}
                                    id="taskName"
                                    name="taskName"
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.textField}
                                    error={touched.taskName && Boolean(errors.taskName)}
                                    helperText={touched.taskName && errors.taskName}
                                    placeholder={i18n.t("bookingModal.form.namePlaceholder")}
                                    onChange={e => { handleChange(e) }}
                                />
									
								<Field
									as={TextField}
									label={i18n.t("bookingModal.form.date")}
									type="datetime-local"
									ampm="false"
									autoFocus
									InputLabelProps={{ shrink: true, }}
									inputProps={{ step: 1800 }} 
									name="sendAt"
									error={touched.sendAt && Boolean(errors.sendAt)}
									helperText={touched.sendAt && errors.sendAt}
									variant="outlined"
									margin="dense"
									// onChange={e => { handleChange(e) }}
									fullWidth
									className={classes.textField}
								/>
							</div>

							
							<Field
								as={TextField}
								label={i18n.t("bookingModal.form.message")}
								id="message"
								name="message"
								onFocus={handleFocus}
								multiline
								minRows={5}
								fullWidth
								error={touched.message && Boolean(errors.message)}
								helperText={touched.message && errors.message}
								variant="outlined"
								margin="dense"
								// onChange={e => {handleChange(e)}}
                                placeholder={i18n.t("bookingModal.form.namePlaceholder")}
                                    
							/>

                            <TagsContainerForTasks 
                                    campaign={ticket} 
                                    onFiltered={(e) => handleFilterTag(e)}
                                    onChange={(e) => handleFilterTag(e)}

                                    />                
							

						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleClose}
								className={classes.buttonColorError}
								disabled={isSubmitting}
								variant="outlined"
							>
								{i18n.t("validation.buttons.cancel")}
							</Button>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								disabled={isSubmitting}
								className={classes.btnWrapper}
							>
								{contactId
									? `${i18n.t("validation.buttons.okEdit")}`
									: `${i18n.t("validation.buttons.schedule")}`}
								{isSubmitting && (
									<CircularProgress
										size={24}
										className={classes.buttonProgress}
									/>
								)}
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default BookingModal;